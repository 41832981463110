<template>
  <div class="mt-12">
    <meet-horaire :doctor="doctor" :type_rv="typeRV" v-if="doctor" @dateSelected="dateSelected"></meet-horaire>

    <div class="text-center font-6" v-else>Connexion d'un docteur non etablie</div>
  </div>
</template>

<script>
import meetHoraire from './meetHoraire.vue'
export default {
  components: { meetHoraire },

  data() {
    return {
      doctor: null,
      typeRV: 'cabinet'
    }
  },
  created() {
    if(this.$auth.user.is_doctor) {
      this.getDoctor()
    }
  },
  methods: {
    getDoctor() {
      this.$http.get('user').then(({data}) => {
        this.doctor = data.data
      })
    },
    dateSelected(date) {
      console.log('date selected', date)
    }
  }

}
</script>

<style>

</style>