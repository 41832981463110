<template>
    <v-app class="bg-1">
        <navbar />
        <div class="contain">
            <v-row>
                <v-col class="partieTexte" md="6" sm="12">
                    <div class="px-2">
                        <div class="font-6 font-weight-bold">Préparer les documents suivants pour valider votre inscription
                            :</div>
                        <span class="mdi mdi-numeric-1-circle" style="font-size:25px ;color:#00a19a ;"></span><span
                            class="text-center">Une copie de la pièce d'identité ou passeport.</span> <br>
                        <span class="mdi mdi-numeric-2-circle" style="font-size:25px ;color:#00a19a ;"></span> <span
                            class="text-center">Une copie d’autorisation d’exercer ou une carte professionnelle.</span> <br>
                    </div>
                </v-col>
                <v-col md="5" sm="12">
                    <v-card class="pa-3">
                        <div style="display:flex;justify-content:center;align-items:center;color:green">Vous êtes intervenant ? Un
                            membre de notre équipe vous contactera. </div>
                        <v-form v-model="valid">
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field v-model="first_name" label="Prénom" required outlined
                                            :error-messages="errors.first_name"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field v-model="last_name" label="Nom" required outlined
                                            :error-messages="errors.last_name"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field v-model="email" label="Email" required outlined
                                            :error-messages="errors.email"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <vue-tel-input-vuetify v-model="phone" label="Téléphone" outlined
                                            mode="international" ref="telInput" required :error="errorPhone"
                                            @change="verifyNumber" :error-messages="errors.phone"></vue-tel-input-vuetify>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field v-model="cabinet_name" label="nom de la structure" requiredssss outlined
                                            :error-messages="errors.cabinet_name"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field v-model="address_cabinet" label="Adresse professionnelle" required outlined
                                            :error-messages="errors.address_cabinet"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-file-input v-model="ordreDesMedcin_file"
                                            label="Autorisation ou carte professionnelle" placeholder="Choisir un document"
                                            outlined :error-messages="errors.ordreDesMedcin_file" prepend-icon=""
                                            append-icon="mdi-file-upload"></v-file-input>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-file-input v-model="cni_file" label="Carte d'identité ou du passeport"
                                            placeholder="Choisir un document" outlined :error-messages="errors.cni_file"
                                            prepend-icon="" append-icon="mdi-file-upload"></v-file-input>
                                    </v-col>
                                </v-row>
                                <row>
                                    <v-autocomplete v-model="secteurActivite_id" :items="listeSecteurActiviote"
                                        item-text="libelle" item-value="id" label="Veillez choisir votre secteur d'activité"
                                        outlined required :error-messages="errors.secteurActivite_id"></v-autocomplete>
                                </row>
                                <div style=" display: flex; justify-content: space-between;">
                                    <v-btn style="color:red; text-transform: none;" elevation="0" @click="ressetForm">
                                        Annuler
                                    </v-btn>

                                    <v-btn color="primary" style="text-transform: none;" elevation="0"
                                        @click="saveIntervenant" :loading="loading">
                                        S'inscrire
                                    </v-btn>
                                </div>
                            </v-container>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>

        </div>
    </v-app>
</template>

<script>
import navbar from "../../components/Home/utilities/navbar.vue";
export default {

    components: { navbar },

    data() {
        return {
            loading: false,
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            secteurActivite_id: '',
            cabinet_name: '',
            address_cabinet: '',
            ordreDesMedcin_file: '',
            cni_file: '',
            listeSecteurActiviote: [],
            errors: {},
            errorPhone: false,
        }
    },

    created() {
        this.secteurActiviteListe()
    },

    methods: {
        secteurActiviteListe() {
            this.$http.get(`auth/secteurActivite`).then(({ data }) => {
                this.listeSecteurActiviote = data.data;
                console.log(this.listeSecteurActiviote);
            });
        },

        saveIntervenant() {
            this.loading = true;
            let formData = new FormData();
            formData.append('first_name', this.first_name);
            formData.append('last_name', this.last_name);
            formData.append('phone', this.phone);
            formData.append('email', this.email);
            formData.append('cabinet_name', this.cabinet_name);
            formData.append('address_cabinet', this.address_cabinet);
            formData.append('secteurActivite_id', this.secteurActivite_id);
            formData.append('cni_file', this.cni_file);
            formData.append('ordreDesMedcin_file', this.ordreDesMedcin_file);
            const baseURI = 'auth/intervenantRegister';
            this.$http.post(baseURI, formData)
                .then(() => {
                    this.ressetForm();
                    this.$toast.success({
                        title: "Inscription intervenant",
                        message: "Merci d'attendre la validation de votre compte",
                    });
                    this.loading = false
                }).catch((error) => {
                    this.errors = error.response.data.errors
                    this.$toast.error({
                        title: 'Inscription intervenant',
                        message: 'Veuillez vérifier les informations saisies'
                    })
                    this.loading = false;
                   
                })
        },

        verifyNumber() {
            if (!this.$refs.telInput._computedWatchers.phoneObject.value.isValid) {
                this.errorPhone = true;
                this.errors.phone = "Le numéro est incorrecte"
            } else {
                this.errorPhone = false;
                this.errors.phone = null;
            }

        },

        ressetForm() {
            this.first_name = '',
                this.last_name = '',
                this.phone = '',
                this.cabinet_name = '',
                this.address_cabinet = '',
                this.secteurActivite_id = '',
                this.email = '',
                this.cni_file = '',
                this.ordreDesMedcin_file = '',
                this.errorPhone = '',
                this.errors = ''

        },

    },
};
</script>
<style scoped>
/* .bady {
        display: flex;
        margin-top:0;
        background-size: cover;
        height: 76vh;
        width: 100%;
        position: relative;
        margin-top: 5%;
   } */

   .contain {
    margin-top: 5%;
   }
.partieTexte {
    background: url("../../assets/images/medcinBg.png") top center;
    background-size: cover;
    padding: 20px;
    /* margin-right: 6%; */
}

@media screen and (max-width: 600px) {
   .partieTexte {
    background: none;
   }

}
</style>
