<template>
  <div>
    <table-consultations 
        :consultations="consultations" 
        :headers="headers" 
        :loading="loading"
        :page="current_page"
        :page-count="last_page"
        :items-per-page="per_page"
        @pagination="getConsultations"
    />
  </div>
</template>

<script>
import tableConsultations from '../../../components/consultation/tableConsultations.vue';

export default {
  components: { tableConsultations },
        data() {
            return {
                loading: false,
                consultations: [],
                current_page: 1,
                last_page:1,
                per_page: 10,

               
                headers: [
                    {
                        text: 'Crée par ',
                        align: 'start',
                        filterable: false,
                        value: 'owner',
                    },
                    {
                        text: 'Crée le ',
                        align: 'start',
                        filterable: false,
                        value: 'la_date',
                    },
                   
                    {
                        text: 'Patient',
                        filterable: false,
                        sortable: false,
                        value: 'patient',
                    },
                    {
                        text: 'Actions',
                        filterable: false,
                        value: 'actions',
                    },
                ]
            }
        },
        mounted() {
            if(this.$auth.user.doctor_id) {
                this.getConsultations()
            }
           
        },
        methods: {
            getConsultations(page) {
                this.loading = true;
                this.$http.get(`consultations/${this.$auth.user.doctor_id}`,{params: { page: page }}).then(({ data }) => {
                    this.consultations = data.data
                    this.current_page = data.meta.current_page
                    this.last_page = data.meta.last_page
                    this.per_page = data.meta.per_page
                }).catch(() => {
                    this.$toast.error({
                        title: 'Liste Consultation',
                        message: 'Erreur au niveau de laliste des consultations'
                    })
                }).finally(()=> this.loading = false)
            },

        }
    }
</script>

<style>

</style>