<template>
  <div>
    <div class="mt-6">
      <table-users-log 
        :current_page="current_page" 
        :pageCount="pageCount" 
        :title="`Derniers Utilisateurs connectés`" 
        :data="users_connected"
        :loading="loading"
        :items-per-page="itemsPerPage"
        @paginate="getUsersConnectedToday"
        />
    </div>
  </div>
</template>

<script>
import TableUsersLog from './logs/TableUsersLog.vue'
export default {
  components: {TableUsersLog },

  data() {
    return {
      
      itemsPerPage: 20,

      users_connected: [],
      current_page: 1,
      pageCount: 0,
      loading: true,
      // headers: [
      //   {
      //     text: 'Nom et Prénom',
      //     align: 'start',
      //     sortable: false,
      //     value: 'name',
      //   },
      //   {
      //     text: 'N° téléphone', value: 'phone'
      //   },
      //   { text: 'Profil', value: 'role' },
      //   { text: 'Derniére connexion', value: 'last_login_at' },
      // ],
    }
  },
  created() {
    
    this.getUsersConnectedToday()
  },
  methods: {
    getUsersConnectedToday(page) {
      this.$http.get('activity/auth', { params : { page: page }}).then(({ data }) => {
        this.loading = false
        this.users_connected = data.data
        this.current_page = data.pagination.current_page
        this.itemsPerPage = data.pagination.per_page
        this.pageCount = data.pagination.last_page
        
       })//.catch(() => {
      //   this.loading = false
      //   this.$toast.error({
      //     title: 'Error',
      //     message: "Une erreur c'est produite lors de la liste des utilisateurs"
      //   })
      // })
    }
  }

}
</script>

<style></style>