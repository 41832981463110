<template>
<v-dialog
    v-model="show"
    max-width="400px"
    transition="dialog-transition"
>
<v-card>



  <v-card-text class="pa-6">
    <v-text-field
        v-model="userEdited.first_name"
        name="first_name"
        label="Prénom"
        outlined
        :error-messages="errors.first_name"
    ></v-text-field>
    <v-text-field
        v-model="userEdited.last_name"
        name="last_name"
        label="Nom"
        outlined
        :error-messages="errors.last_name"
    ></v-text-field>
    <v-text-field
        v-model="userEdited.email"
        name="email"
        label="Nom complet"
        outlined
        :error-messages="errors.email"
        disabled
    ></v-text-field>
    <v-text-field
        v-model="userEdited.phone"
        name="phone"
        label="N° téléphone"
        outlined
        :error-messages="errors.phone"
        disabled
    ></v-text-field>

    <div class="d-flex justify-space-between">
        <v-btn color="error" style="text-transform: none;" outlined @click="show=false">Annuler</v-btn>
        <v-btn color="primary" style="text-transform: none;" outlined @click="save">Modifier</v-btn>
    </div>
  </v-card-text>
</v-card>
</v-dialog>
</template>

<script>
export default {
    props: {
        user: Object,
        value: Boolean
    },
    data() {
        return {
            errors: {},
            userEdited: null
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                return this.$emit('input', value)
            }
        }
    },
    watch: {
        user: {
            handler: 'updateUser',
            immediate: true
        }
    },
    methods: {
        updateUser() {
            this.userEdited = JSON.parse(JSON.stringify(this.user))
            this.userEdited.first_name = this.userEdited.name.split(' ').slice(0, -1).join(' ');
            this.userEdited.last_name = this.userEdited.name.split(' ').slice(-1).join(' ');
        },
        save() {
            const data = {
                first_name: this.userEdited.first_name,
                last_name: this.userEdited.last_name,
                email: this.userEdited.email,
                phone: this.userEdited.phone
            }

            this.$http.put(`profil/${this.user.id}`, data).then(({data}) => {
                this.$auth.setUser(data.data);
                this.$emit('updateUser', data.data)
                this.$toast.success({
                    title: 'Profil',
                    message: "Profil modifié avec succès"
                })
                this.show = false
            })
        }
    }

}
</script>

<style>

</style>