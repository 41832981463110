<template class="mybady">
  <v-app class="bg-1">
        
   <div class="mybady">
      <v-container class="cont">
        <v-row align="center" justify="center">
          <v-col cols="12" md="6">
            <div class="cardlogo">
            <v-avatar size="100" color="white">
              <img src="../../assets/logo_sahel.png" alt="logo" />
             
            </v-avatar>
           
            </div>
            <v-card class="login-card pa-6">
              
              <v-row>
                <v-col
                  cols="4"
                  md="12"
                >
                <div class="font-6 font-weight-bold text-center mb-6">Sahel Assistance Admin Login</div>
                  <v-alert
                    dense
                    outlined
                    type="error"
                    v-if="error"
                  >
                   Login ou mot de passe incorrecte !!
                  </v-alert>
                  <v-text-field
                    v-model="email"
                    name="email"
                    placeholder="Entrer votre Email"
                    outlined
                    >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="4"
                  md="12"
                >
                  <v-text-field
                    v-model="password"
                    name="password"
                    placeholder="Entrer votre mot de passe"
                    outlined
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"
                    >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="4"
                  md="12"
                >
                  <v-btn
                    block
                    class="primary white--text margin-1"
                    style="text-transform: none"
                    :loading="loading"
                    @click="login"
                    >
                    Se connecter
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container> 
    </div>   
  </v-app>   
</template>
  
  <script>
 
export default {
  
  data() {
    return {
      error: false,
      message: "",
      loading: false,
      email: "",
      password: "",
      show: false
    };
  },
  mounted() {
    if(this.$auth.check()) return this.$router.push({ name:'admin.home' });
  },
  methods: {
    async login() {
      this.error = false;
      this.message = "";
      this.loading = true;
      const data = {
        login: this.email,
        password: this.password,
      };
      this.$http
        .post("auth/login", data)
        .then((res) => {
          this.loading = false;
          this.$auth.login(res.data.token, res.data.user);
          this.email = "";
          this.password = "";
          this.$router.go()
        })
        .catch(({ response }) => {
          this.loading = false;
          this.error = true;
          this.message = response.data.message;
        });
    },
  },
};
</script>
  
  <style scoped>
  .mybady{
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
    height: 100%;
    }
    .login-card  {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 60%;
      margin: 20%;
      margin-top:2% ;
    }
    .cardlogo {
      display: flex;
      justify-content: center;
      align-content: center;
      background-size: cover;
      height: auto;
      margin: 4%;
      border-radius: 150px;
    }
    .btnfago {
      display: flex;
      justify-content: space-between;
      margin-top: 1%;
      width:  95%;
    }

   
</style>