<template>
	<div>
		<div v-if="loading">
			<loading-page />
		</div>
	<div v-if="consultation">
		<v-sheet class="d-flex pa-6 mb-3">
			<div class="font-3 font-weight-bold">Consultation N° {{ consultation_id }}</div>
			<v-spacer></v-spacer>
			<v-btn color="primary" outlined style="text-transform: none; margin-top: 10px" @click="$router.go(-1)">Retour</v-btn>
		</v-sheet>
		<v-divider></v-divider>
		<!-- Patient -->
		<v-sheet class="pa-6">
			<div class="patient">
				<div class="font-4 font-weight-bold">Patient</div>
				<patient-item 
					:auteur="consultation.proche ? 'proche' : 'patient'" 
					:patient="consultation.proche ? consultation.proche : consultation.patient" 
				/>
			</div>
		</v-sheet>
		<!-- Consultatin data -->
		<v-sheet class="pa-6 mb-3">
			<div class="d-flex">
				<div class="font-3 font-weight-bold mb-1">Consultation</div>
				<v-spacer></v-spacer>
				<div class="d-flex">
					<span class="mx-2 py-2 font-weight-bold">
					Date: {{ consultation.la_date }}
				</span>
				
				<v-tooltip bottom v-if="consultation.is_from_me">
					<template #activator="{on, attr}">
						<v-btn  icon color="primary" outlined class="mr-3" v-on="on" v-bind="attr" @click="showDate=true">
							<v-icon>mdi-calendar-edit</v-icon>
						</v-btn>
					
					</template>
					<span>Modifier la date</span>
				</v-tooltip>
				<v-tooltip bottom v-if="consultation.is_from_me">
					<template #activator="{on, attr}">
						<v-btn  
							icon 
							color="info" 
							outlined 
							class="mr-3" 
							v-on="on" 
							v-bind="attr"
							link
							:to="{ name: 'doctor.consultation.update', params: { consultation: consultation_id } }"
						>
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					
					</template>
					<span>Modifier</span>
				</v-tooltip>
				<v-tooltip bottom v-if="consultation.is_from_me">
					<template #activator="{on, attr}">
						<v-btn  
							icon 
							color="warning" 
							class="mr-2" 
							outlined 
							v-on="on" 
							v-bind="attr"
							@click="printConsultation"
						>
							<v-icon>mdi-printer</v-icon>
						</v-btn>
					
					</template>
					<span>Imprimer</span>
				</v-tooltip>
				<v-tooltip bottom v-if="consultation.is_from_me || $auth.user.is_secretaire">
					<template #activator="{on, attr}">
						<v-btn  
							color="blue-grey darken-4" 
							class="mr-2" 
							outlined 
							v-on="on" 
							v-bind="attr"
							style="text-transform: none;"
							@click="printCertificat"
						>
							<v-icon>mdi-printer</v-icon> Certificat
						</v-btn>
					
					</template>
					<span>Imprimer Certificat</span>
				</v-tooltip>
				<v-tooltip bottom v-if="consultation.is_from_me">
					<template #activator="{on, attr}">
						<v-btn  
							icon 
							color="error" 
							outlined 
							v-on="on" 
							v-bind="attr"
							@click="dialogDelete=true"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					
					</template>
					<span>Supprimer</span>
				</v-tooltip>
				</div>
				
			</div>
			<!-- Forms data -->
			<div v-if="$auth.user.is_doctor">
				<data-forms :data="consultation.data" />
			</div>
			
		</v-sheet>
		<!-- ordonnance -->
		<v-sheet class="pa-6 mb-3">
			<div class="d-flex justify-space-between">
				<div class="font-4 font-weight-bold">
					<v-icon color="black">mdi-file-document-outline</v-icon>
					Ordonnance
				</div>
				<div class="d-flex">
					<v-tooltip bottom v-if="consultation.is_from_me">
						<template #activator="{ on, attr }">
							<v-btn icon color="primary" outlined small class="mr-2" v-on="on" v-bind="attr"
								@click="addMedicament">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</template>
						<span>Ajouter un médicament</span>
					</v-tooltip>
					<v-tooltip bottom v-if="(consultation.is_from_me || $auth.user.is_secretaire) && consultation.ordonnances.length">
						<template #activator="{ on, attr }">
							<v-btn icon color="info" outlined small v-on="on" v-bind="attr" @click="printOrdonnance">
								<v-icon>mdi-printer</v-icon>
							</v-btn>
						</template>
						<span>Imprimer ordonnance</span>
					</v-tooltip>
				</div>
			</div>
			<v-divider></v-divider>
			<div class="my-6">
				<div v-if="consultation.ordonnances.length">
					<div class="row justify-space-between">
						<div class="font-weight-bold col-md-3">Nom médicament</div>
						<div class="font-weight-bold col-md-4">Dosage</div>
						<div class="font-weight-bold col-md-3">Nombre</div>
						<div class="font-weight-bold col-md-2 d-flex justify-end" v-if="consultation.is_from_me">Actions</div>
					</div>
					<item-medicament 
						v-for="medicament in consultation.ordonnances" 
						:key="medicament.id"
						:ordonnance="medicament"
						:is_from_me="consultation.is_from_me"
						@medicamentDeleted="medicamentDeleted" 
					/>
				</div>
				<div v-else class="text-center">
					<div>
						<v-icon size="50">mdi-alert-circle-outline</v-icon>
						<div class="">Pas encore d'ordonnonce</div>
					</div>

				</div>

			</div>

		</v-sheet>
		<!-- Fichiers -->
		<v-sheet class="pa-6 mb-3" v-if="$auth.user.is_doctor">
			<div class="d-flex justify-space-between">
				<div class="font-4 font-weight-bold">
					<v-icon color="black">mdi-paperclip</v-icon>
					Fichiers
				</div>
				<div class="d-flex" v-if="consultation.is_from_me">
					<v-tooltip bottom>
						<template #activator="{ on, attr }">
							<v-btn icon color="primary" outlined small class="mr-2" v-on="on" v-bind="attr"
								@click="addFile">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</template>
						<span>Ajouter un fichier</span>
					</v-tooltip>
				</div>
			</div>
			<v-divider></v-divider>
			<div class="my-6">
				<div v-if="consultation.files.length">
					<list-files :files="consultation.files" @fileDeleted="fileDeleted" />
				</div>
				<div v-else class="text-center">
					<div>
						<v-icon size="50">mdi-alert-circle-outline</v-icon>
						<div class="">Pas encore fichiers</div>
					</div>
				</div>
			</div>


		</v-sheet>
		<!-- Bulletins -->
		<v-sheet class="pa-6 mb-3">
			<div class="d-flex justify-space-between">
				<div class="font-4 font-weight-bold">
					<v-icon color="black">mdi-paperclip</v-icon>
					Bulletins
				</div>
				<div class="d-flex" v-if="consultation.is_from_me">
					<v-tooltip bottom>
						<template #activator="{ on, attr }">
							<v-btn icon color="primary" outlined small class="mr-2" v-on="on" v-bind="attr"
								@click="addBulletin">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</template>
						<span>Ajout bulletin</span>
					</v-tooltip>
				</div>
			</div>
			<v-divider></v-divider>
			<div class="my-6">
				<div v-if="consultation.bulletins.length">
					<div class="row justify-space-between">
						<div class="font-weight-bold col-md-3">Type Examen</div>
						<div class="font-weight-bold col-md-4">Diagnostic</div>
						<div class="font-weight-bold col-md-4">Examen</div>
						<div class="font-weight-bold col-md-1 d-flex justify-end" v-if="consultation.is_from_me || $auth.user.is_secretaire">Actions</div>
					</div>
					<item-bulletin 
						v-for="bulletin in consultation.bulletins"
						:is_from_me="consultation.is_from_me"
						:key="bulletin.id" :bulletin="bulletin"
						@bulletinDeleted="bulletinDeleted" 
					/>
				</div>
				<div v-else class="text-center">
					<div>
						<v-icon size="50">mdi-alert-circle-outline</v-icon>
						<div class="">Pas encore bulletins</div>
					</div>

				</div>

			</div>


		</v-sheet>
				<!-- Repos médical -->
				<v-sheet class="pa-6 mb-3">
			<div class="d-flex justify-space-between">
				<div class="font-4 font-weight-bold">
					<v-icon color="black">mdi-paperclip</v-icon>
					Repos Médical
				</div>
				<div class="d-flex" v-if="consultation.is_from_me">
					<v-tooltip bottom>
						<template #activator="{ on, attr }">
							<v-btn icon color="primary" outlined small class="mr-2" v-on="on" v-bind="attr"
								@click="addReposMedical">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</template>
						<span>Ajout repos médical</span>
					</v-tooltip>
				</div>
			</div>
			<v-divider></v-divider>
			<div class="my-6">
				<div v-if="consultation.repos_medicals.length">
					<div class="row justify-space-between">
						<div class="font-weight-bold col-md-3">Jours Traitement</div>
						<div class="font-weight-bold col-md-3">Jour Prolongation</div>
						<div class="font-weight-bold col-md-3">Date Repise </div>
						<div class="font-weight-bold col-md-3 d-flex justify-end" v-if="consultation.is_from_me || $auth.user.is_secretaire">Actions</div>
					</div>
					<item-repos-medical 
						v-for="repos_medical in consultation.repos_medicals"
						:key="repos_medical.id" 
						:repos_medical="repos_medical"
						:is_from_me="consultation.is_from_me"
						@reposMedicalDeleted="reposMedicalDeleted" 
					/>
				</div>
				<div v-else class="text-center">
					<div>
						<v-icon size="50">mdi-alert-circle-outline</v-icon>
						<div class="">Pas encore repos médical</div>
					</div>

				</div>

			</div>


		</v-sheet>
		<form-edited-date 
			v-if="showDate" 
			v-model="showDate" 
			:id="consultation_id"
			:type_url="`consultation`"
			:date="consultation.date"
			@dateEdided="dateEdided"
		/>
		<form-repos-medical
			v-if="showReposMedical"
			v-model="showReposMedical"
			:repos_medical="repos_medical" 
			@reposMedicalSaved="reposMedicalSaved"

		/>
		<!-- formulaire Ordonnance -->
		<form-ordonnance v-if="showMedicament" v-model="showMedicament" :ordonnance="ordonnance"
			@ordonnanceSaved="ordonnanceSaved" />
		<!-- formulaire creation fichier -->
		<create-file v-if="showFile" v-model="showFile" :id="consultation_id" :type_url="'consultations'"
			:proche_id="proche_id" :patient_id="patient_id" @createdFile="fileCreated" :intervenant_id="intervenant_id" />
		<!-- formulaire Bulletin -->
		<form-bulletin v-if="showBulletin" v-model="showBulletin" :bulletin="bulletin" @bulletinSaved="bulletinSaved" />
		<delete-dialog 
			v-if="dialogDelete"
			v-model="dialogDelete"
			:message="'Êtes vous sûr de vouloir supprimer la consultion. Une fois supprimée tous les dossiers rattacés à cette consultation ne seront plus disponible'" 
			:title="'Suppression consultation'"
			@confirmDelete="deleteConsultation"
		/>

	</div>
	</div>
	
</template>

<script>
import ItemReposMedical from '../../../components/reposMedicals/itemReposMedical.vue'
import FormBulletin from '../../../components/bulletins/formBulletin.vue'
import ItemBulletin from '../../../components/bulletins/itemBulletin.vue'
import CreateFile from '../../../components/consultation/createFile.vue'
import ListFiles from '../../../components/consultation/listFiles.vue'
import formOrdonnance from '../../../components/ordonnances/formOrdonnance.vue'
import ItemMedicament from '../../../components/ordonnances/itemMedicament.vue'
import FormReposMedical from '../../../components/reposMedicals/formReposMedical.vue'
import FormEditedDate from '../../../components/consultation/formEditedDate.vue'
import DeleteDialog from '../../../components/Home/utilities/DeleteDialog.vue'
import DataForms from '../../../components/consultation/dataForms.vue'
import PatientItem from '../../../components/consultation/patientItem.vue'
import LoadingPage from '../../../components/Home/utilities/LoadingPage.vue'

export default {
	components: { formOrdonnance, ItemMedicament, CreateFile, ListFiles, ItemBulletin, FormBulletin, ItemReposMedical, FormReposMedical, FormEditedDate, DeleteDialog, DataForms, PatientItem, LoadingPage },

	data() {
		return {
			loading: true,
			consultation_id: this.$route.params.consultation,
			consultation: null,
			showFacture: false,
			showMedicament: false,
			ordonnance: null,
			bulletin: null,
			showFile: false,
			showBulletin: false,
			patient_id: null,
			intervenant_id: null,
			proche_id: null,
			showReposMedical: false,
			repos_medical: null,
			showDate: false,
			dialogDelete: false
		}
	},
	mounted() {
		if (this.consultation_id) {
			this.getConsultation()
		}
	},
	created() {

	},
	methods: {
		getConsultation() {
			this.$http.get(`consultation/${this.consultation_id}/show`).then(({ data }) => {
				this.consultation = data
			}).catch(() => {
				this.$toast.error({
					title: 'Detail Consultation',
					message: 'Une Erreur est survenue , impossible de charger la consultation '
				})
			}).finally(() => this.loading = false)
		},
		addMedicament() {
			this.ordonnance = {
				id: null,
				consultation_id: this.consultation_id
			}
			this.showMedicament = true
		},
		ordonnanceSaved(ordonnance) {
			this.consultation.ordonnances.unshift(ordonnance)
			this.$toast.success({
				title: 'ordonnance',
				message: 'Un nouveaumédicament a été ajouté avec succès !'
			})
		},
		addFile() {
			if (this.consultation.proche) {
				this.proche_id = this.consultation.proche.id
			}
			this.patient_id = this.consultation.patient ? this.consultation.patient.id :null
			this.intervenant_id = this.consultation.intervenant ? this.consultation.intervenant.id : null
			this.showFile = true

		},
		fileCreated(file) {
			this.consultation.files.unshift(file)
		},
		addBulletin() {
			this.bulletin = {
				id: null,
				type: 'Consultation',
				patient_id: this.consultation.patient ? this.consultation.patient.id : null,
				intervenant_id: this.consultation.intervenant ? this.consultation.intervenant.id : null,
				proche_id: this.consultation.proche ? this.consultation.proche.id : null,
				consultation_id: this.consultation_id
			}
			this.showBulletin = true
		},
		bulletinSaved(bulletin) {
			this.$toast.success({
				title: 'Bulletin',
				message: 'Le bulletion a été crée avec succès !'
			})
			this.consultation.bulletins.unshift(bulletin)
		},
		bulletinDeleted(bulletin) {
			this.consultation.bulletins = this.consultation.bulletins.filter((value) => value.id != bulletin.id)
		},
		medicamentDeleted(medicament) {
			this.consultation.ordonnances = this.consultation.ordonnances.filter((value) => value.id != medicament.id)
		},
		fileDeleted(file) {
			this.consultation.files = this.consultation.files.filter((fichier) => fichier.id != file.id)
		},
		addReposMedical() {
			this.repos_medical = {
				id:null,
				patient_id: this.consultation.patient ? this.consultation.patient.id : null,
				intervenant_id: this.consultation.intervenant ? this.consultation.intervenant.id : null,
				proche_id: this.consultation.proche ? this.consultation.proche.id : null,
				consultation_id: this.consultation_id
			}
			this.showReposMedical = true

		},
		reposMedicalDeleted(repos_medical) {
			this.consultation.repos_medicals = this.consultation.repos_medicals.filter((value) => value.id != repos_medical.id)
		},
		reposMedicalSaved(repos_medical) {
			this.consultation.repos_medicals.unshift(repos_medical)
		},
		dateEdided() {
			this.getConsultation()
		},
		deleteConsultation() {
			this.$http.delete(`consultation/${this.consultation_id}`).then(({ data }) => {
				this.$toast.success({
					title: 'Suppression consultation',
					message: data.message
				})
				this.$router.push({ name: 'doctor.consultations'});
			}).catch(() => {
				this.$toast.error({
					title: 'Suppression',
					message: 'Une Erreur est survenu lors de la préssion des rendez-vous'
				})
			})
		},
		printConsultation(){
			
			window.open(`/printer/consultation/${this.consultation_id}`,'printWindow','height=800,width=900, align: center')
		},
		printOrdonnance() {
			window.open(`/printer/ordonnance/consultation/${this.consultation_id}`,'printWindow','height=800,width=900, align: center')
		},
		printCertificat() {
			window.open(`/printer/consultation/certicat/${this.consultation_id}`,'printWindow','height=800,width=900, align: center')
		}
		
	}
}
</script>

<style></style>