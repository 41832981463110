<template>
       <v-data-table
      :headers="headers"
      :items="AllPayement"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Les moyens de paiement</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
                <template v-slot:activator="{ on , attr }">
                <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attr"
                    v-on="on"
                    @click="save"
                >
                    Nouveau Moyen de paiement
                </v-btn>
                </template>
                <v-card>
                <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
    
                <v-card-text>
                    <v-container>
                    <v-row>
                       
                        <v-text-field
                            v-model="name"
                            label="Nom du moyen de paiement"
                            outlined
                            :error-messages="errorMessage"
                        ></v-text-field>
                    </v-row>
                    </v-container>
                </v-card-text>
    
                <v-card-actions>
                    <v-spacer></v-spacer>
                      <div class="annuler">
                        <v-btn
                        color="#FFCDD2"
                        variant="text"
                        @click="close"
                        >
                        Annuler
                        </v-btn>
                    </div>
  
                        <v-btn
                        color="primary" 
                        variant="text"
                        @click="ajoueModifieDepatement"
                        >
                          Enregistrer
                        </v-btn>
                
                </v-card-actions>
                </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Le moyen de paiement va etre supprimer</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <div style="display:flex;justify-content:space-around;width:100%">
                     <div class="primary" style="display:flex"> <v-btn  class="primary" @click="closeDelete">Annuler</v-btn></div>
                    <div class="annuler" style="display:flex;"><v-btn   color="#FFCDD2"
                        variant="text" @click="deleteItemConfirm">Supprimer</v-btn></div>
              </div>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          size="small"
          class="me-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          size="small"
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        la liste des Moyen de paiment est vide 
      </template>
    </v-data-table>
</template>

<script>
export default{
  data: () => ({
    AllPayement: [],
    formTitle : String,
    editedItem: Object.assign({}), 
    name: '',
    ajouter: false,
    modifier: false,
    id: '',
    dialog: false,
    dialogDelete: false,
    errorMessage: String,
    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      // { text: 'id', value: 'id',sortable: false },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
  }),

  created () {
      this.moyenPaimentList()
    },

    methods: {
        moyenPaimentList(){
            this.$http.get(`monyenpayements`).then(({ data }) => {
            this.AllPayement = data.data;
            console.log(this.AllPayement)
            
            })
        },

        addMoyenPaiement(){
        let data = {
            name : this.name
        };
          this.loading = true
          this.$http.post(`monyenpayement`,data).then(response => {
          this.AllPayement.unshift(response.data.data);
          this.close ()
          data = '';
          this.$toast.success({
          title: "Nouveau Moyen de paiement",
          message: "Moyen de paiement ajouter avec succes",
          });
        }).catch((error) => {
            this.errorMessage = error.response.data.message
        })
      },

      updateMoyenPaiement(id){
        let data = {
            name : this.name
        };
        try {
          this.$http.put(`monyenpayement/${id}`,data).then(response => {
          const index= this.AllPayement.findIndex((dp) => dp.id == this.editedItem.id)
          this.AllPayement.splice(index,1,response.data.data)
            this.close ()
            this.$toast.success({
            title: "Modification moyen de paiment",
            message: "Deppartement Modifier avec succes",
            });
            this.name = '';
          })
        } catch  {
          this.$toast.error({
            title: 'Modification moyen de paiment',
            message: 'Impossible de modifier le moyen de paiment'
          })
        }
      },

      //Permer de charger l'id lor de la modification d'un department
      editItem (item) {
        this.name = item.name;
        this.errorMessage = "";
        this.dialog=true;
        this.editedItem = item;
        this.formTitle = "Modification moyen de paiement"
      },
      //Ajoue et modification d'un departement 
      ajoueModifieDepatement () {
       
            if(!this.editedItem.id) {
                this.addMoyenPaiement()
            }else {
                this.updateMoyenPaiement(this.editedItem.id)
            }
        },

        //Permet de lancer le formulair d'ajoue 
      save () {
        this.ajouter = true
        this.errorMessage = "";
        this.formTitle = "Ajoue moyen de paiement"
      },
      close () {
        this.dialog = false
        this.ajouter = false
        this.name = '';
        this.errorMessage = "";
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      deletedepartement(id) {
        this.$http.delete(`monyenpayement/${id}`)
          .then(response => {
            // Traitement de la réponse de la base de données
            const index= this.AllPayement.findIndex((dp) => dp.id == id)
            this.AllPayement.splice(index,1)
            if(response.status === 200){
              this.$toast.success({
              title: "Suppression  monyen de paiment",
            message: "Moyen de paiement supprimer avec succes",
            });
          }
          
          }).catch((error) => {

              this.$toast.error({
                title: 'Suppression moyen paiement',
                message: error.response.data.message
              }) 
            })
      },

      deleteItem (item) {
        this.editedItem = item;
        this.dialogDelete = true
      },

      //Appel de la function qui confirme la suppression
      deleteItemConfirm () {
        this.deletedepartement(this.editedItem.id);
        this.closeDelete()
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
    }
}
</script>
<style scoped>
  .annuler  {
        margin-right: 10px;
}
.annuler :hover {
        background-color: red !important;
        color: aliceblue;
}
</style>
