<template>
  <div>
    <v-data-table
        :items="factures"
        :headers="headers"
        class="elevation-0" 
        item-key="id" 
        :loading="loading"
        :items-per-page="itemsPerPage"
        hide-default-footer
    >
        <template #no-data>
            <div class="pa-4">
				<v-icon size="40">mdi-alert-circle-outline</v-icon>
				<div class="">Aucune facture pour le moment</div>
			</div>
        </template>
        <template #item.patient="{item}">
            <div class="">
                    <router-link 
                        style="text-decoration: none;"
                        :to="{
                            name: $auth.user.is_secretaire ? 'secretaire.patient.detail' : 'doctor.patient.detail', 
                            params:{ 
                                auteur: item.patient.patient_id ? 'proche' : 'patient',
                                patient: item.patient.id
                            } 
                        }" 
                    >
                        <span class="">{{  item.patient.name  }}</span>
                    </router-link>

                </div>
        </template>
        <template #item.motif="{item}">
            <v-chip
                class="ma-2"
                :color="item.is_consultation ? 'success' : 'info'"
                label
                text-color="white"
            >
            <v-icon left>
                mdi-label
            </v-icon>
            <div class="text-captitalize"> {{ item.is_consultation ? 'Consultation' : 'Visite Médicale' }} </div>
            </v-chip>
        </template>
        <template #item.actions="{item}">
            <v-btn class="text-lowercase mr-3" outlined  small color="primary" v-if="!item.payee" @click="showFacture(item)"> <v-icon>mdi-pencil</v-icon> Modifier</v-btn>
            <v-btn class="text-lowercase" outlined small color="primary" v-else @click="printFacture(item)"> <v-icon>mdi-printer</v-icon> Imprimer</v-btn>
        </template>
    </v-data-table>
    <div class="text-center pt-2" v-if="pageCount>1">
        <v-pagination
            v-model="current_page"
            :length="pageCount"
            @input="paginate"
        />
    </div>
    <form-facture v-if="showForm" v-model="showForm" :facture="facture" @printFacture="printFacture"/>
  </div>
</template>

<script>
import FormFacture from './formFacture.vue';
export default {
  components: { FormFacture },
    props: {
  
        factures: {
            type: Array,
            required: true
        },
        headers: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        page: {
            type: Number,
            required: true
        },
        pageCount: {
            type: Number
        },
        itemsPerPage: {type: Number , default: () => 20 }
    },
    data() {
       return { 
        showForm: false,
        facture: null,
        current_page: this.page
    }
    },
    methods: {
        printFacture(item) {
            let type;
            let id;
            if(item) {
                type = item.is_consultation ? 'consultation' : 'medical_visit';
                id = item.is_consultation ? item.consultation_id : item.medical_visit_id;
            } else {
                type = this.facture.is_consultation ? 'consultation' : 'medical_visit';
                id = this.facture.is_consultation ? this.facture.consultation_id : this.facture.medical_visit_id;
            }
            
            window.open(`/printer/facture/${type}/${id}`, 'printWindow', 'height=800,width=900, align: center')
        },
        showFacture(item) {
            this.showForm = true;
            this.facture = item
        },
        paginate() {
            this.$emit('pagination',this.current_page)
        }
    }
}
</script>

<style>

</style>