<template>
  <div>
    <v-sheet class="pa-6">
        <item-career 
            v-for="career in careers"
            :key="career.id"
            :career="career"
            @deletedCareer="deletedCareer"
        />

    </v-sheet>
  </div>
</template>

<script>
import ItemCareer from './ItemCareer.vue'
export default {
    props: {careers: Array},
    components: { ItemCareer },
    methods: {
        deletedCareer(data) {
           this.careers = this.careers.filter((career) => career.id != data.id);
           this.$emit('deletedCareer',this.careers )
        }
    }


}
</script>

<style>

</style>