<template>
  <div>
    <v-dialog
        v-model="show"
        persistent
        width="500px"
    >
    <v-card>
        <v-card-title primary-title>
            <div class="font-6 font-weight-bold">
                {{ formTitle }}
            </div>
            <v-spacer></v-spacer>
            <v-btn  icon color="error" @click="show=fales" small>
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
            <v-form>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="repos_medical.start_traitement"
                            name="start_traitement"
                            label="Début du traitement"
                            outlined
                            :error-messages="errors.start_traitement"
                            type="date"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="repos_medical.end_traitement"
                            name="end_traitement"
                            label="Fin du traitement"
                            outlined
                            :error-messages="errors.end_traitement"
                            type="date"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field
                            v-model="repos_medical.number_days"
                            name="number_days"
                            label="Nombre de jours"
                            outlined
                            :error-messages="errors.number_days"
                            type="number"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field
                            v-model="repos_medical.reprise"
                            name="reprise"
                            label="Reprise"
                            outlined
                            :error-messages="errors.reprise"
                            type="date"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="repos_medical.start_prolongation"
                            name="start_prolongation"
                            label="Début de prolongation"
                            outlined
                            :error-messages="errors.start_prolongation"
                            type="date"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="repos_medical.end_prolongation"
                            name="end_prolongation"
                            label="Fin de prolongation"
                            outlined
                            :error-messages="errors.end_prolongation"
                            type="date"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field
                            v-model="repos_medical.date_reprise_prolongation"
                            name="date_reprise_prologongation"
                            label="Date reprise de prolongation"
                            outlined
                            :error-messages="errors.date_reprise_prolongation"
                            type="date"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
            <div class="d-flex">
            <v-btn style="text-transform: none;" color="primary" elevation="0" class="mr-3" @click="save">{{ repos_medical.id ? 'Modifier' : 'Ajouter' }}</v-btn>
            <v-btn style="text-transform: none;" color="error" outlined @click="show=fales"> Annuler</v-btn>
        </div>
        </v-card-text>
        
    </v-card>

    </v-dialog>
  </div>
</template>

<script>
export default {
    props: {
        value: Boolean,
        repos_medical: Object
    },
    data() {
        return {
            errors: {},
            loading: false
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                return this.$emit('input', value)
            }
        },
        formTitle() {
            if(this.repos_medical.id) {
                return 'Modifier Repos médical'
            }else {
                return 'Ajouter Repos médical'
            }
        }
    },
    methods: {
        save() {
            this.loading = true
            if(this.repos_medical.id == null) {
                this.$http.post('repos_medical', this.repos_medical).then(({ data }) => {
                    this.$toast.success({
                        title: 'Repos Médical',
                        message: 'Repos Médical est créé avec succès !'
                    })
                    this.$emit('reposMedicalSaved', data.data)
                    this.show = false
                }).catch((error) => {
                    if(error.response.data.errors) {
                        this.errors = error.response.data.errors
                    }
                }).finally(() => this.loading = false)
            }
            else {
                this.$http.put(`repos_medical/${this.repos_medical.id}`, this.repos_medical).then(({ data }) => {
                    this.$toast.success({
                        title: 'Repos Médical',
                        message: 'Repos Médical est modifié avec succès !'
                    })
                    this.$emit('reposMedicalUpdated', data.data)
                    this.show = false
                }).catch((error) => {
                    if(error.response.data.errors) {
                        this.errors = error.response.data.errors
                    }
                }).finally(() => this.loading = false)
            }
        }
    }

}
</script>

<style>

</style>