<template>
  <div>
    <v-dialog
        v-model="show"
        persistent
        width="400px"
    >
        <v-card>
            <v-card-title primary-title>
                Modifier la date du consultation
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-3">
                <v-text-field
                    v-model="localDate"
                    name="date"
                    label="Date Consultation"
                    id="date"
                    type="date"
                    outlined
                    :error-messages="errors.date"
                ></v-text-field>
                <div class="d-flex">
                    <v-btn style="text-transform: none;margin-right: 20px;" color="primary" elevation="0" @click="save">Modifier</v-btn>
                    <v-btn style="text-transform: none;" color="error" outlined @click="show=false"> Annuler</v-btn>
                </div>
            </v-card-text>
            
        </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
    props: {
        value: Boolean,
        id: String,
        type_url: String,
        date: {
            type: String
        }
    },
    data() {
        return {
            localDate: this.date,
            errors: {}
        }
    },
    computed: {
          show: {
              get() {
                  return this.value
              },
              set(value) {
                  return this.$emit('input', value)
                 
              }
          }
          
      },
      methods: {
        save() {
            this.$http.put(`${this.type_url}/${this.id}/date`, { date: this.localDate }).then(() => {
                this.$toast.success({
                    title: 'Date Consultation',
                    message: 'La date de votre consultation a été modifié avec succès !'
                })
                this.show = false
                this.$emit('dateEdided')
            })
        }
      }
}
</script>

<style>

</style>