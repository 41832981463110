<template>
  <div class="">
    <v-row align="end" justify="end">
      <v-col cols="12" md="6">
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Rechercher par nom"
        single-line
        hide-details
      ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="listeDepartement"
      sort-by="calories"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Les Départements</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
                <template v-slot:activator="{ on , attr }">
                <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attr"
                    v-on="on"
                    @click="save"
                >
                    Nouveau Departement
                </v-btn>
                </template>
                <v-card>
                <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
    
                <v-card-text>
                    <v-container>
                    <v-row>
                       
                        <v-text-field
                            v-model="name"
                            label="Nom du departement"
                            outlined
                            :error-messages="errorMessage"
                        ></v-text-field>
                    </v-row>
                    </v-container>
                </v-card-text>
    
                <v-card-actions>
                    <v-spacer></v-spacer>
                      <div class="annuler">
                        <v-btn
                        color="#FFCDD2"
                        variant="text"
                        @click="close"
                        >
                        Annuler
                        </v-btn>
                    </div>
  
                        <v-btn
                        color="primary" 
                        variant="text"
                        @click="ajoueModifieDepatement"
                        >
                          Enregistrer
                        </v-btn>
                
                </v-card-actions>
                </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Ce moyen de paiment sera supprimer</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" variant="text" @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          size="small"
          class="me-2"
          color="info"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          size="small"
          color="error"
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:[`item.doctors_count`]="{ item }">
        <v-chip>{{ item.doctors_count }}</v-chip>
      </template>
      <template v-slot:no-data>
        la liste des departements est vide 
      </template>
    </v-data-table>
  </div>
    
  </template>
  <script>
  export default {
    data: () => ({
      listeDepartement: [],
      formTitle : String,
      editedItem: Object.assign({}), 
      name: '',
      ajouter: false,
      modifier: false,
      id: '',
      dialog: false,
      dialogDelete: false,
      errorMessage: String,
      search: '',
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        { text: 'Doctors', value: 'doctors_count',sortable: false, filterable: false, },
        { text: 'Actions', value: 'actions', sortable: false, filterable: false, },
      ],
    }),

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.departementliste()
    },

      methods: {
        departementliste(){
          this.loading = true
          this.$http.get(`departements/doctor`).then(({ data }) => {
          this.listeDepartement = data;
          
        })
      },
      adddepartement(){
        let data = {
            name : this.name
        };
          this.loading = true
          this.$http.post(`auth/department`,data).then(response => {
          this.listeDepartement.unshift(response.data.data);
          this.close ()
          data = '';
          this.$toast.success({
          title: "Nouveau Departement",
          message: "Deppartement ajouter avec succes",
          });
        }).catch((error) => {
            this.errorMessage = error.response.data.message
        })
      },
      updatedepartement(id){
        let data = {
            name : this.name
        };
        try {
          this.loading = true
          this.$http.put(`auth/department/${id}`,data).then(response => {
          const index= this.listeDepartement.findIndex((dp) => dp.id == this.editedItem.id)
          this.listeDepartement.splice(index,1,response.data.data)
            this.close ()
            this.$toast.success({
            title: "Modification Departement",
            message: "Deppartement Modifier avec succes",
            });
            this.name = '';
          })
        } catch  {
          this.$toast.error({
            title: 'Modification departement',
            message: 'Impossible de de modifier un departements'
          })
        }
      },


      deletedepartement(id) {
        this.$http.delete(`auth/department/${id}`)
          .then(response => {
            // Traitement de la réponse de la base de données
            const index= this.listeDepartement.findIndex((dp) => dp.id == id)
            this.listeDepartement.splice(index,1)
            if(response.status === 200){
              this.$toast.success({
              title: "Suppression  Departement",
            message: "Département supprimer avec succes",
            });
          }
           /* else {
              this.$toast.error({
                title: 'Suppression departement',
                message: response.message
              }) 
            }*/
          
          }).catch((error) => {

              this.$toast.error({
                title: 'Suppression departement',
                message: error.response.data.message
              }) 
            })
      },
     

      deleteItem (item) {
        this.editedItem = item;
        this.dialogDelete = true
      },

      //Appel de la function qui confirme la suppression
      deleteItemConfirm () {
        this.deletedepartement(this.editedItem.id);
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.ajouter = false
        this.name = '';
        this.errorMessage = "";
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
//Permet de lancer le formulair d'ajoue 
      save () {
        this.ajouter = true
        this.errorMessage = "";
        this.formTitle = "Ajoue department"
      },
//Permer de charger l'id lor de la modification d'un department
      editItem (item) {
        this.name = item.name;
        this.errorMessage = "";
        this.dialog=true;
        this.editedItem = item;
        this.formTitle = "Modification Departement"
      },
//Ajoue et modification d'un departement 
      ajoueModifieDepatement () {
       
        if(!this.editedItem.id) {
            this.adddepartement()
        }else {
            this.updatedepartement(this.editedItem.id)
        }
      },
    },
  }
</script>

<style scoped>
  .annuler  {
        margin-right: 10px;
}
.annuler :hover {
        background-color: red !important;
}
</style>