<template>
    <div>
        <div class="d-flex mt-3 py-3">
            <div class="font-5 font-weight-bold">Mes rendez-vous</div>
            <v-spacer></v-spacer>
            <v-btn class="white--text mr-3 hidden-sm-and-down" color="primary" style="text-transform: none" @click="dialogAdd" >Ajouter un rendez-vous</v-btn>
        </div>
        <v-divider></v-divider>
        <div class="mt-6">
            <full-calendar :events="events" :config="config"></full-calendar>
        </div>
     
        <form-add-appointment v-if="showDialog" v-model="showDialog" :motifs="motifs" @appointmenCreated="getAgenda" />
    </div>
  </template>
  
  <script>
  import 'fullcalendar/dist/fullcalendar.css'
  import { FullCalendar } from 'vue-full-calendar'
  import 'fullcalendar/dist/locale/fr'
  import FormAddAppointment from '../../components/doctor/formAddAppointment.vue'
  export default {
    components: {
      FullCalendar,
      FormAddAppointment
    },
    data() {
      return {
        showDialog: false,
        motifs: [],
        doctor_id: this.$auth.user.doctor_id,
        config: {
          locale: 'fr',
        },
        events: []
      }
    },
    mounted() {
      this.getAgenda()
    },
    methods: {
      getAgenda() {
        //const doctor_id = this.$auth.user.doctor_id;
        this.$http.get(`appointments/${this.doctor_id}/doctor`).then(({ data }) => {
          this.events = data.data
        })
      },
      getMotif() {
        this.$http.get('motifs/'+this.doctor_id).then(({data}) => {
          this.motifs = data.data
        })
      },
      async dialogAdd() {
        this.getMotif()
        this.showDialog = true
      }
    }
  }
  </script>
  
  <style>
  
  </style>