var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.notifications),function(notification){return _c('v-sheet',{key:notification.id,staticClass:"pa-6 mb-3"},[_c('div',{staticClass:"d-flex justify-space-between"},[(notification.data.message)?_c('div',{staticClass:"font-7 text-center"},[_vm._v(" "+_vm._s(notification.data.message)+" "),(notification.data.type==='room_cancel')?_c('span',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
                      name: 'medecin.show',
                      params: { id: notification.data.doctor.id }
                  }}},[_vm._v(" Voir profil ")])],1):_vm._e(),(notification.data.type==='doctor_register')?_c('span',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
                      name: 'admin.userProfile',
                      params: { id: notification.data.doctor_id }
                  }}},[_vm._v(" Voir profil ")])],1):_vm._e(),(notification.data.type==='payement')?_c('span',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
                      name: 'admin.detail.payement',
                      params: { id: notification.data.payement_id }
                  }}},[_vm._v(" voir plus ")])],1):_vm._e(),(notification.data.url_file)?_c('span',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":notification.data.url_file}},[_vm._v(" voir plus ")])],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"mx-3"},[_c('v-btn',{attrs:{"small":"","color":"red","elevation":"0"},on:{"click":function($event){return _vm.deleteNotify(notification.id)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-delete")])],1)],1)])])}),(_vm.notifications.length == 0)?_c('div',[_c('div',[_vm._v("Vous n'avez pas de notifications pour le moment")])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }