<template>
  <div>
    <div class="career mb-2">
        <div class="">
            <span>{{ career.name }} ({{  career.address }}) </span>
            <br>
            <span class="font-weight-light caption" v-if="career.start_date && career.end_date">{{ (career.start_date).substring(0, 4) }}-{{ (career.end_date).substring(0, 4) }}</span>
            <span v-else class="font-weight-light caption"> - -</span>
        </div>
        <div class="">
            <v-btn icon class="mx-2" color="#025397" @click="showForm=true" outlined small>
                <v-icon size="20" >mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon class="" color="error" @click="dialogDelete=true" outlined small>
                    <v-icon size="20" >mdi-delete</v-icon>
                </v-btn>
        </div>
    </div>
    <delete-dialog
        v-if="dialogDelete"
        v-model="dialogDelete"
        :message="'Êtes vous sûr de vouloir ce supprimer ce parcours'" 
        :title="'Suppression motif'"
        @confirmDelete="deleteCareer"

    />
     <!-- Formulaire Career -->
     <form-career
        v-if="showForm"
        v-model="showForm"
        :career="local_career"
        @updatedCareer="updatedCareer"
    />
  </div>
</template>

<script>
import DeleteDialog from '../Home/utilities/DeleteDialog.vue'
import FormCareer from './FormCareer.vue'
    export default {
    components: { FormCareer, DeleteDialog },
        props: {
            career: Object
        },
        data() {
            return {
                showForm: false,
                local_career: this.career,
                dialogDelete: false
            }
        },
        methods: {
            updatedCareer(data) {
                this.motif = data
            },
            deleteCareer() {
                this.$http.delete(`career/${this.career.id}`).then(({data}) => {
                    this.$emit('deletedCareer', data.data)
                    this.$toast.success({
                        title: 'Suppression parcours',
                        message: 'Suppression du parcours est éffectuée avec succès'
                    })
                }).catch(() => {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Erreur du serveur'
                    })
                })
            }
        }


    }
</script>

<style scoped>
    .career {
        display: flex;
        justify-content: space-between;
    }

</style>