<template>
  <div >
   <!--  <div class="font-4">Medecins en {{ specialite.name }}</div> -->
    <div  class="medecins" v-if="doctors.length">
      <medecins-list>
        <medecin-view
          v-for="doctor in doctors"
          :key="doctor.id"
          :medecin="doctor"
          :specialite="doctor.department.name"
        />
      </medecins-list>
    </div>
    <div v-else>
      <div class="text-center font-4 mt-12">
        Aucun résultat ne correspond à votre recherche, nous vous invitons à modifier votre saisie.
      </div>
    </div>
  </div>
</template>

<script>
import MedecinView from "./utilities/medecin.vue";
import MedecinsList from "./medecins.list.vue";

export default {
  components: { MedecinView, MedecinsList },
  props: { doctors: Array },
  data() {
    return {};
  },
};
</script>


<style>
.medecin {
  padding: 20px 0 !important;
  border-top: 5px solid #00a19a;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  padding: 5px;
  text-decoration: none;
  color: inherit;
  transition: transform ease 0.3s, box-shadow ease 0.3s;
  height: 300px;
}
.profile {
  display: flex;
  align-items: center;
}
.profile-photo {
  width: 10%;
}
.calendar {
  width: 75%;
}
/* .profile-info {
  width: 15%;
}  */
.profile-photo img {
  width: 100px;
  height: 100px;
  border-radius: 100px;
}
</style>