<template>
  <v-app class="bg-1">
    <navbar />
    <div class="form-reset">
      <v-card width="500" height="300" v-show="!opt_code">
        <v-card-title class="font-6 font-weight-bold">Trouver mon compte</v-card-title>
        <v-divider></v-divider>
        <v-card-subtitle>
          Veuillez entrer votre adresse e-mail ou votre numéro de mobile pour rechercher votre compte.
        </v-card-subtitle>
        <v-card-text>
          <v-alert type="success" dense v-if="successMsg" outlined>{{ successMsg }} </v-alert>

          <vue-tel-input-vuetify v-if="isNumeric(email)" v-model="email" @input="handleTelInput" class="form" name="phone" label=""
            placeholder="Entrer votre N° téléphone" outlined mode="international" defaultCountry="SN" :error="errorPhone"
            ref="telInput" :error-messages="errors.login"></vue-tel-input-vuetify>

          <!-- Affichez v-text-field si la saisie contient des caractères autres que des chiffres -->
          <v-text-field v-else v-model="email" label="" placeholder="Adresse e-mail ou numéro téléphone" outlined
            :error-messages="errors.login" />
          <div class="d-flex justify-end">
            <v-btn color="grey lighten-4" @click="annuler" style="text-transform:none; margin-right: 10px;"
              elevation="0">Annuler</v-btn>
            <v-btn color="primary" @click="send" :loading="loading" style="text-transform:none"
              elevation="0">Rechercher</v-btn>
          </div>

        </v-card-text>
      </v-card>
      <v-card width="500" height="300" v-show="opt_code">
        <v-card-title class="font-6 font-weight-bold">Saisir code</v-card-title>
        <v-divider></v-divider>
        <v-card-subtitle>
          <div class="font-7">
            <p>Un code d'authentification a été envoyé à votre numéro de téléphone: <span class="font-weight-bold">{{ email }}</span></p>
            <p>Veuillez saisir le code ci-dessous :</p>
          </div>
        </v-card-subtitle>
        <v-card-text>
          <div class="ma-auto position-relative" style="max-width: 300px">
            <v-otp-input v-model="code" :disabled="loading_opt" @finish="onFinish"></v-otp-input>
            <v-overlay absolute :value="loading">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-overlay>
          </div>
        </v-card-text>

      </v-card>
    </div>
  </v-app>
</template>

<script>
import navbar from "../components/Home/utilities/navbar.vue";
export default {
  components: { navbar },
  data() {
    return {
      errors: {},
      errorPhone: false,
      loading: false,
      successMsg: null,
      errorMessage: null,
      email: "",
      opt_code: false,
      code: '',
      loading_opt: false
    };
  },
  methods: {
    handleTelInput() {
      // Définir le focus sur le champ d'entrée lorsque l'utilisateur saisit quelque chose
      this.$refs.telInput.focus();
    },
    onFinish() {
      this.loading_opt = true
      this.$http.post('auth/verify/code', { code: this.code }).then((response) => {
        
        this.opt_code = false
        this.code = ''
        this.loading_opt = false
        this.$router.push({ name: 'reset.password', params: { token: response.data.token } })
      }).catch((error) => {
        this.loading_opt = false
        this.$toast.error({
          title: 'Code invalide',
          message: error.response.data.message
        })
      })
    },
    annuler() {
      this.$router.push({ name: 'home' })
    },
    send() {

      this.loading = true;
      this.errors = {};
      this.errorMessage = null;
      this.$http
        .post("/auth/reset/request", { login: this.email.match(/\S+/g).join("") })
        .then((res) => {
          this.loading = false;
          if (res.data.login == 'phone') {
            this.opt_code = true
          } else {
            this.successMsg = "Nous avons envoyé par courrier électronique votre lien de réinitialisation de mot de passe"
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else {
            this.errorMessage = error.response.data.msg;
          }
        });
    },
    isNumeric(value) {
      // Utilisez une expression régulière pour vérifier si la saisie ne contient que des chiffres
      const numericPattern = /^\d+$/;
      //console.log("is numerique",numericPattern.test(value))
      return numericPattern.test(value);
    },
    verifyNumber() {
      if (!this.$refs.telInput._computedWatchers.phoneObject.value.isValid) {
        this.errorPhone = true;
        this.errors.phone = "Le numéro est incorrecte"
      } else {
        this.errorPhone = false;
        this.errors.phone = null;
      }

    },
  },
};
</script>

<style scoped>
.form-reset {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>