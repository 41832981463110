 <template>
  <div class="prive-rvd">
    <v-tabs v-model="tab" grow @change="goTo(tab)"  :slider-color="tab == 'past' ? 'error' : 'primary'">
      <v-tab href="#comming">Rendez-vous à venir</v-tab>
      <v-tab href="#past" active-class="red--text">Rendez-vous passés</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="comming" class="comming" :transition="true">
        <appointment-table :appointments="appointments" :tab="tab" :headers="headers"/>
        
      </v-tab-item>
      <v-tab-item value="past" class="past" :transition="true">
        <appointment-table :appointments="appointments" :tab="tab" :headers="headers"/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

import AppointmentTable from '../../components/rv/AppointmentTable.vue';
export default {
  components: { AppointmentTable },
  data() {
    return {
      tab: null,
      appointments: [],
      headers: [
        {
          text: "Doctor",
          align: "start",
          sortable: false,
          value: "medecin",
        },
        {
          text: 'Patient',
          value: 'patient'
        },
        { text: "Date rendez-vous", value: "formated_date" },
        { text: "Délai RDV", value: "delay_status" },
        { text: "PLus de détail", value: "view" },
      ],
    };
  },
  created() {
    //this.getInit();
  },
  methods: {
    getInit() {
      this.$http
        .get(`appointments/comming/${this.$auth.user.intervenant_id}/intervenant`)
        .then(({ data }) => {
          this.appointments = data.data;
        });
    },
    getPastAppointments() {
      
      this.$http
        .get(`appointments/past/${this.$auth.user.intervenant_id}/intervenant`)
        .then(({ data }) => {
          this.appointments = data.data;
        });
    },
    deleteAppointment(appointment) {
      this.appointments = this.appointments.filter(
        (rv) => rv.id != appointment.id
      );
      this.$toast.success({
        title: "Annulation RDV",
        message: "Votre rendez-vous a été annulé",
      });
    },
    goTo(value) {
      value == "past" ? this.getPastAppointments() : this.getInit();
    },
  },
};
</script>

<style scoped>
a.past.v-tab.v-tab--active {
  color: red;
  border-bottom: 2px solid red;
}
.prive-rvd {
  padding: 5%;
}
@media screen and (max-width: 600px) {
  .prive-rvd {
    padding: 0;
  }
}
</style>
