<template>
  <div class="mt-12"> 
    
    <send-file :files="files" :headers="headers" :loading="loading" />
  </div>
</template>

<script>
import SendFile from '../patient/SendFile.vue'

export default {
  components: { SendFile },
    data() {
        return {
            files: [],
            loading: false,
            headers: [
              {
                text: "Nom du document",
                align: "start",
                sortable: false,
                value: "filename",
              },
              { text: "Patient concerné", value: "patient" },
              { text: "Crée le", value: "uploaded_at" },
              { text: "Crée par", value: "creator.name" },
              { text: "Actions", value: "actions", sortable: false },
            ],
        }
    },
    created() {
        this.getFiles()
    },
    methods: {
        getFiles() {
      this.loading = true
      this.$http.get(`intervenant/${this.$auth.user.intervenant_id}/files`).then(({ data }) => {
          this.files = data.data;
        }).catch(() => {
          this.$toast.error({
            title: 'Error du serveur',
            message: "Impossible d'accéder à vos fichiers car une erreur c'est produite"
          })
        }).finally(() => this.loading = false);
    },
    }
}
</script>

<style>

</style>