<template>
  <div>
    <div class="header">
      <div class="d-flex justify-space-between my-2">
            <div class="text-center">
                <v-btn color="primary" style="text-transform: none;" @click="showFormPatient=true">Ajouter un nouveau patient</v-btn>
            </div>
            
        <div class="" style="width: 400px;">
            <v-text-field
                v-model="key"
                placeholder="Rechercher un patient"
                solo
                dense
                append-icon="mdi-account-search"
                @input="search"
            ></v-text-field>
        </div>
        </div>
    </div>
    <div class="user">
      <v-row>
        <v-col v-for="(patient, index) in patients" :key="patient.id+''+index" cols="12" md="3" sm="3" align-content='space-between'>
          <v-card>
            <div class="avatar">
              <img :src="patient.avatar_url">
              <div>
                <span class="mdi mdi-checkbox-marked-circle-outline" style="color: green; margin-right: 5px;"
                  ></span>
                <!-- <span class="mdi mdi-close-circle" style="color: red; margin-right: 5px;" v-if="patient.user.actif === false"></span> -->
                <span class="font-weight-bold">{{ patient.name }}</span>
              </div>
              <div class="mb-3">
                <div class="caption text-center" v-if="patient.age">{{ patient.age }}</div>
                <!-- <div class="caption text-center" v-if="patient.address">{{ patient.address }}</div> -->
                <div class="caption">Tel: {{ patient.phone }}</div>
              </div>
            </div>
            <div class="info">
                <v-btn 
                  color="info"
                  elevation="0"
                  block 
                  style="text-transform: none; text-decoration: none; color: white;" 
                  link 
                  :to="{ 
                        name: $auth.user.is_doctor ? 'doctor.patient.detail' : 'secretaire.patient.detail', 
                        params: { 
                            auteur: patient.auteur,
                            patient: patient.id
                        }
                        }"
                  >Voir profil</v-btn>
              <!-- <router-link class="button" v-if="patient.user.role === 'PATIENT'" :to="'#'">Voir le profil</router-link> -->
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <vue-infinite-loading @infinite="loadMorePatients" spinner="spiral">
        <span slot="no-more">•••</span>
        <span slot="no-results">
          <v-alert color="info" type="info" >
            Vous n'avez pas encore de patients .
          </v-alert>
        </span>
        <span slot="spinner">Chargement...</span>
    </vue-infinite-loading>
      <div class="text-center" v-if="key && patients.length == 0">
        <v-alert color="info" type="info">
          Aucun patient consernant votre recherche.
        </v-alert>
      </div>
    <form-patient :patient="patient" v-model="showFormPatient" v-if="showFormPatient" @createPatient="createPatient"/>
  </div>
</template>

<script>
import FormPatient from '../../../components/patient/FormPatient.vue';
import VueInfiniteLoading from 'vue-infinite-loading';
export default {
  components: { FormPatient , VueInfiniteLoading },
 
  data() {
    return {
      doctor_id: this.$auth.user.doctor_id,
      patients: [],
      patient: {
        id: null,
        email: '',
        first_name: '',
        last_name: '',
        date_naissance: '',
        lieu_naissance: '',
        tel_dom: '',
        tel_port: '',
        sexe: '',
        address: '',
        post: ''
      },
      key: null,
      showFormPatient: false,
      page: 1
    };
  },
  methods: {
    loadMorePatients($state) {
      //const patient_id = this.$auth.user.patient_id;
      this.$http.get(`patients/${this.doctor_id}`,{ params: { page: this.page } }).then(({ data }) => {
            if (data.data.length > 0) {
            this.patients.push(...data.data);
            
            this.page++;
            $state.loaded();
          } else {
            $state.complete();
          }
        //this.proches = res.data.data;
      }).catch(() => {
      })
    },
    getPatients() {
      this.$http.get(`patients/${this.doctor_id}`).then(({ data }) => {
        this.patients = data.data;
      });
    },
    search() {
      if(this.key) {
        this.$http.get(`patient/search/${this.$auth.user.doctor_id}`, { params: {key: this.key}}).then(({data}) => {
        this.patients = data.data
      })
      } else {
        this.loadMorePatients()
      }
    },
    createPatient(patient) {
      this.$toast.success({
        title: 'Création patient',
        message: "Patient créé avec succès !"
      })
      this.patients.unshift(patient)
    }
  },
};
</script>
<style scoped>
  .user {
    padding: 20px 0;
    box-shadow: 0 0 2px rgb(209, 207, 207);
    padding: 5px;
    text-decoration: none;
    color: inherit;
    transition: transform ease .3s, box-shadow ease .3s;
  }

  .user .actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user .avatar img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin-top: 20px;
  }

  .user .info p {
    text-align: center;
  }

  .user .info .description {
    line-height: 1;
    font-size: .9em;
    margin: 10px 0;
  }

  .user .name .profession {
    transform: translateY(-5px);
    font-style: italic;
    color: #1F3651;
    margin: 10px 0;
    font-size: .9em;
  }

  .user .info .button {
    display: block;
    /*background-color: #1F3651;*/
    padding: 5px 30px;
    text-decoration: none;
    color: #fff;
    border-radius: 290486px;
    margin-bottom: 3px;
    margin-top: 3px;
  }

  .user .avatar,
  .user .name,
  .user .info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>