<template>
  <div>
    <v-sheet class="d-flex justify-space-between pa-3 mb-6">
        <div class="d-flex info-patient">
            <div class=""> 
                <v-avatar
                    size="50"
                    color="grey"
                >
                    <img :src="room.proche? room.proche.avatar_url  : room.user.avatar_url" alt="avatar" v-if="room">
                </v-avatar>
            </div>
            <div class="mx-3"> 
                <div>
                    <router-link :to="{ name:'doctor.patient.detail' , params: {auteur:  (room.proche ? 'proche' : 'patient') , patient: room.proche ? room.proche.id : room.user.patient_id} }" style="text-decoration: none;" class="font-6" v-if="room.proche || room.user.is_patient">
                        {{ room.proche ? room.proche.name : room.user.name }}
                    </router-link>
                    <span v-else class="font-6 primary--text"> {{ room.user.name }}</span>
                    <br>
                    <span class="font-weight-thin">{{ room.user.role }} {{ room.proche ? '(proche)' : '' }}</span>
                    <!-- <div v-else>{{ room.intervenant.user.name }}</div> -->
                   
                </div>
                <div class="caption" v-if="room.patient">{{ room.user.tel_port }}</div>
            </div>
            
        </div>
        <div class="actions d-flex">
            <div class="mt-2 mx-2">
            <v-btn outlined color="red" style="text-transform: none;" small v-if="index === 0" @click="retirerPatient">
               Retirer
            </v-btn>
        </div>
        <div class="mt-2">
            <v-btn outlined color="#025397" style="text-transform: none;" small :disabled="index !=0" @click="lancerVideo">
                {{ index === 0 ? "Lancer l'appel" : "En Attente" }}
            </v-btn>
        </div>
        </div>
        
       
    </v-sheet>

  </div>
</template>

<script>
import { mapActions/*, mapMutations */} from 'vuex'
export default {
    props: {
        room: Object,
        index: Number
    },
    data(){
        return {
            
        }
    },
    methods: {
        ...mapActions(['getVisio']),
		getSession() {
			this.loadVisio = true
			const donnees = {
				email_subcriber : this.appointment.patient.user.email,
				name_puplished : this.$auth.user.name 
			}
			this.$http.post('auth/opentok_session',donnees).then((response) => {
				const { actif, apiKey, sessionId, token } = response.data
				const visio = { actif: actif, apiKey: apiKey, sessionId: sessionId, token: token, type: "direct" }
                console.log(visio)
				this.setVisio(visio)
			}).catch(() => {
				
			}).finally(() => this.loadVisio = false)
		},
        retirerPatient() {
            confirm('Êtes vous sur de vouloir retirer le patient')
            this.$http.delete(`room_waiting/${this.room.id}`).then(() => {
                this.$emit('retirerPatient',this.room)
                this.$toast.success({
                    title: 'Téléconsultation direct',
                    message: "Patient retiré avec succès!!"
                })
            })
        },
        lancerVideo() {
            this.getVisio({sessionId: this.room.sessionId, type: 'direct'})
        }
    
    }
}
</script>

<style>

</style>