<template class="bgpage">
    <v-app class="bg-1">
        <navbar />
        <div class="contain">
            <v-row>
                <v-col
                    cols="12"
                    class="partieTexte"
                    md="6"
                    sm="12"
                    >
                    <div class="px-2">
                        <div class="font-6 font-weight-bold">Préparer les documents suivants pour valider votre inscription :</div>
                    <span class="mdi mdi-numeric-1-circle" style="font-size:25px ;color:#00a19a ;"></span><span class="text-center">Une copie de la pièce d'identité ou du passeport.</span> <br>
                    <span class="mdi mdi-numeric-2-circle" style="font-size:25px ;color:#00a19a ;"></span><span class="text-center">Une copie du diplôme de médecin ou équivalent.</span> <br>
                    <span class="mdi mdi-numeric-3-circle" style="font-size:25px ;color:#00a19a ;"></span> <span class="text-center">Une copie d'autorisation d'exercer délivrée par le Ministre de la santé ou une carte professionnelle délivrée par l'ordre des médecins.</span> <br>
                    </div>
                </v-col>
                            
                <v-col
                    cols="12"
                    sm="12"
                    md="5"
                >
                    <v-hover>
                        <template v-slot:default="{ hover }">
                            <v-card
                            :elevation="hover ? 0    : 0"
                           
                            >
                            <registerDoctor/>
                            </v-card>
                        </template>
                    </v-hover>
                
                </v-col> 
            </v-row>
        </div>
    </v-app>
    
</template>
<script>
import navbar from '../../components/Home/utilities/navbar.vue';
import registerDoctor from '../../components/registerDoctor.vue';

export default {
    components:  {
        navbar, registerDoctor
    },
    data() {
        return {}
    }
}
</script>
<style scoped>
.contain {
    margin-top: 5%;
}
.partieTexte{
    background: url("../../assets/images/medcinBg.png") top center;
    background-size: cover;
    margin-right: 6%;
   

}
@media screen and (max-width: 600px) {
   .partieTexte {
    background: none;
   }

}
/* {} */
</style>
