
import Vue from 'vue';
<template>
    <div>
        <v-sheet class="pa-6">
            <div class="text-center pa-6">
                <div class="avatar">
                    <v-avatar size="150" color="white">
                        <img :src="patientItem.user.avatar_url"
                            :alt="patientItem.name" />
                    </v-avatar>
                </div>
                <div class="pt-3 font-weight-bold font-6">{{ patientItem.name }}</div>
            </div>
            <div class="patient">
                <v-row class="py-2">
                    <v-col class="subtitle-2 font-weight-bold">
                        Profession
                    </v-col>
                    <v-col>
                        {{ patientItem.post ? patientItem.post : '-' }}
                    </v-col>
                </v-row>
                <v-divider />
                <v-row class="py-2">
                    <v-col class="subtitle-2 font-weight-bold">
                        Email
                    </v-col>
                    <v-col>
                        {{ patientItem.user.email ? patientItem.user.email : '-' }}
                    </v-col>
                </v-row>
                <v-divider />
                <v-row class="py-2">
                    <v-col class="subtitle-2 font-weight-bold">
                        Adresse
                    </v-col>
                    <v-col>
                        {{ patientItem.address ? patientItem.address : '-' }}
                    </v-col>
                </v-row>
                <v-divider />
                <v-row class="py-2">
                    <v-col class="subtitle-2 font-weight-bold">
                        N° tel port
                    </v-col>
                    <v-col>
                        {{ patientItem.tel_port ? patientItem.tel_port : '-' }}
                    </v-col>
                </v-row>
                <v-divider />
                <v-row class="py-2">
                    <v-col class="subtitle-2 font-weight-bold">
                        N° tel dom
                    </v-col>
                    <v-col>
                        {{ patientItem.tel_dom ? patientItem.tel_dom : '-' }}
                    </v-col>
                </v-row>
                <v-divider />
                <v-row class="py-2">
                    <v-col class="subtitle-2 font-weight-bold">
                        Date de naissance
                    </v-col>
                    <v-col>
                        {{ patientItem.formatted_date_naissance ? patientItem.formatted_date_naissance : '-' }}
                    </v-col>
                </v-row>
                <v-divider />
                <v-row class="py-2">
                    <v-col class="subtitle-2 font-weight-bold">
                        Lieu de naissance
                    </v-col>
                    <v-col>
                        {{ patientItem.lieu_naissance ? patientItem.lieu_naissance : '-' }}
                    </v-col>
                </v-row>
                <v-divider />
                <v-row class="py-2">
                    <v-col class="subtitle-2 font-weight-bold">
                        AGE
                    </v-col>
                    <v-col>
                        {{ patientItem.age != 0 ? patientItem.AGE : '-' }}
                    </v-col>
                </v-row>
                <v-divider />
                <v-row class="py-2">
                    <v-col class="subtitle-2 font-weight-bold">
                        Entreprise
                    </v-col>
                    <v-col>
                        {{ patientItem.entreprise ? patientItem.entreprise.name : '-' }}
                    </v-col>
                </v-row>
                <v-divider />
                <v-row class="py-2">
                    <v-col class="subtitle-2 font-weight-bold">
                        Ajouté
                    </v-col>
                    <v-col>
                        {{ patientItem.created_at ? patientItem.created_at : '-' }}
                    </v-col>
                </v-row>
                <div class="py-6">
                    <div class="d-flex justify-center">
                        <v-btn color="primary" style="text-transform: none" class="mr-3" outlined rounded
                            @click="showForm = true">
                            <v-icon>mdi-pencil</v-icon> Modifier
                        </v-btn>

                    </div>
                </div>
            </div>
        </v-sheet>
        <form-patient :patient="patientItem" v-model="showForm" v-if="showForm" @updatedPatient="updatedPatient" />
    </div>
</template>

<script>
import FormPatient from './FormPatient.vue'
export default {
  components: { FormPatient },
    props: {
        patient: {type: Object, required: true}
    },
    data() {
        return {
            showForm: false,
            patientItem: null
        }
    },
    watch: {
        patient: {
            handler: 'updatePatient',
            immediate: true
        }
    },
    methods: {
        updatePatient() {
            this.patientItem = JSON.parse(JSON.stringify(this.patient))
        },
        updatedPatient(data) {
            this.patientItem = data
            this.$toast.success({
              title: "Modification profil",
              message: "Profil patient modifié avec succès"
            })
        }
    }


}
</script>

<style></style>