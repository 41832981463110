<template>
    <div>
       <table-medical-visit 
        :loading="loading" 
        :headers="headers" 
        :medical_visits="medical_visits"
        :page="current_page"
        :page-count="last_page"
        :items-per-page="per_page"
        @pagination="getMedicalVisits" 
    />
    </div>
</template>
  
<script>
import TableMedicalVisit from '../../../components/visite-medicale/TableMedicalVisit.vue';

export default {
  components: { TableMedicalVisit },
   
    data() {
        return {
            loading: false,
            medical_visits: [],
            current_page: 1,
            last_page:1,
            per_page: 10,
            headers: [
                {
                    text: 'Crée par ',
                    align: 'start',
                    filterable: false,
                    value: 'owner',
                },
                {
                    text: 'Crée le ',
                    align: 'start',
                    filterable: false,
                    value: 'la_date',
                },

                {
                    text: 'Patient',
                    filterable: false,
                    sortable: false,
                    value: 'patient',
                },
                {
                    text: 'Actions',
                    filterable: false,
                    class:'d-flex justify-end',
                    value: 'actions',
                },
            ]
        }
    },
    mounted() {
        if(this.$auth.user.doctor_id) {
			this.getMedicalVisits()
		}
        
    },
    methods: {
        getMedicalVisits(page) {
            this.loading = true;
            this.$http.get(`medical_visits/${this.$auth.user.doctor_id}`, {params: {page: page}}).then(({ data }) => {
                this.medical_visits = data.data
                this.current_page = data.meta.current_page
                this.last_page = data.meta.last_page
                this.per_page = data.meta.per_page
            }).catch(() => {
                this.$toast.error({
                    title: 'Liste medical_visit',
                    message: 'Erreur au niveau de laliste des medical_visits'
                })
            }).finally(() => this.loading = false)
        },
    }
}
</script>
  
<style></style>