<template>
  <div>
    <v-card class="pa-6" rounded="lg" :min-height="minHeight">
        <div class="d-flex justify-space-between"> 
            <div class="mx-6"> 
                <v-icon size="40" :color="color">{{ icon }}</v-icon>
            </div>
            <div> 
                <div class="font-5 font-weight-bold"> {{ title }}</div>
                <div class="font-4 font-weight-bold text-center">
                    <v-chip :color="color" class="p-2">
                        <span class="white--text">
                            {{ number }}
                        </span> 
                    </v-chip>
                </div>
            </div>
        </div>

    </v-card>
  </div>
</template>

<script>
export default {
    props: {
        icon: String,
        title: String,
        number: Number,
        color: String,
        minHeight: {
            type: Number,
            default: () => 150
        }
    }

}
</script>

<style>

</style>