<template>
  <div>
    <v-sheet class="pa-6">
        <div>
            <div class="text-center">
                <img
                    v-if="signature"
                    style="cursor:pointer;border-solid:1px;"
                    :src="url_signature"
                    width="300"
                    height="250"
                    alt="signature"
                    @click="showSignature = true"
                />
                <div v-else> Télécharger votre signature <v-btn outlined @click="showSignature=true" style="text-transform: none;" small>Télécharger</v-btn></div>
            </div>
            

            <form action enctype="mulipart/form-data">
              <my-upload
                field="signature"
                @crop-upload-success="cropUploadSuccessSignature"
                @crop-upload-fail="cropUploadFailSignature"
                v-model="showSignature"
                :width="200"
                :height="200"
                method="POST"
                :url="urlsignature"
                :params="params"
                :headers="headers"
                langType="fr"
                img-format="png"
              ></my-upload>
            </form>
        </div>
        
    </v-sheet>
  </div>
</template>

<script>
import myUpload from "vue-image-crop-upload";
export default {
    props: {
        url_signature: String,
        signature: String
    },
    components: { myUpload },
    data() {
        return {
            showSignature: false,
            urlsignature: `${this.$api.url_base}change-signature/${this.$auth.user.doctor_id}`,
            params: { _method: "POST" },
            headers: { 
            'Authorization': 'Bearer ' + this.$auth.token 
            },
        }
    },
    methods: {
        cropUploadSuccessSignature(jsonData,) {
      //const signature_url = jsonData.signature_url;
      this.$emit('saveSignature', {url: jsonData.signature_url, signature: jsonData.signature })
      this.showSignature = false;
      this.$toast.success({
        title: 'Signature',
        message: 'Votre signature a bien été modifié'
      })
      //this.$handleMessage(`Votre signature a bien été modifié`, "success");
    },

    cropUploadFailSignature() {
      this.showSignature = false;
      this.$toast.error({
        title: 'Signature',
        message: 'Impossible de modifier le signature'
      })
      //this.$handleMessage(`Impossible de modifier le signature`, "danger");
    }
    }
}
</script>

<style>

</style>