<template>
    <v-dialog
        v-model="show"
        persistent
        width="500"
    >
        <v-card>
            <v-card-title>
                <div class="font-6 font-weight-bold">{{ title }}</div>
                <v-spacer></v-spacer>
                <v-btn  icon color="error" @click="show=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="pa-6">
                <v-form>
                    <v-row no-gutters>
                        <v-col cols="12" md="12">
                            <div class="font-7 font-weight-bold mb-2">Nom</div>
                            <v-text-field
                                v-model="local_career.name"
                                name="name"
                                label="Nom du parcours"
                                id="name"
                                outlined
                                dense
                                :error-messages="errors.name"
                            />

                        </v-col>
                        <v-col cols="12" md="12">
                            <div class="font-7 font-weight-bold mb-2">Lieu</div>
                            <v-text-field
                                v-model="local_career.address"
                                name="address"
                                placeholder="Lieu"
                                id="address"
                                outlined
                                dense
                                :error-messages="errors.address"
                            />

                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="font-7 font-weight-bold mb-2">Date début</div>
                            <v-text-field
                                v-model="local_career.start_date"
                                name="start_date"
                                placeholder="Date début"
                                id="start_date"
                                outlined
                                dense
                                type="date"
                                class="mr-1"
                                :error-messages="errors.start_date"
                            />

                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="font-7 font-weight-bold mb-2 ml-1">Date fin</div>
                            <v-text-field
                                v-model="local_career.end_date"
                                name="end_date"
                                placeholder="Date fin"
                                id="end_date"
                                outlined
                                dense
                                type="date"
                                class="ml-1"
                                :error-messages="errors.end_date"
                            />

                        </v-col>
                        <v-col cols="12" md="12">
                            <div class="font-7 font-weight-bold mb-2">Description</div>
                            <v-text-field
                                v-model="local_career.description"
                                name="description"
                                placeholder="Description"
                                id="description"
                                outlined
                                dense
                                :error-messages="errors.description"
                            />

                        </v-col>
                        <v-col cols="12" md="12">
                            <div class="d-flex">
                            <v-btn 
                                color="primary" 
                                style="text-transform: none;margin-right: 10px" 
                                elevation="0" 
                                :loading="loading"
                                @click="save"
                            >
                                Enregistrer
                            </v-btn>
                            <v-btn color="error" style="text-transform: none;margin-right: 10px" elevation="0" outlined @click="show=false">
                            Annuler
                            </v-btn>
                        </div>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props: {
            value: {
                type: Boolean,
                required: true
            },
            career: {
                type: Object,
                default(){
                    return {}
                }
            }
        },
        data() {
            return {
                errors: {},
                loading: false,
                local_career: this.career
            }
        },
        computed: {
        show: {
        get() {
            return this.value;
        },
        set(value) {
            return this.$emit('input', value)
        }
        },
        title() {
            return this.career.id ? 'Modification parcours' : 'Ajouter un nouveau parcours';
        }
    },
    methods: {
        save() {
            this.loading = true
            if(this.career.id){
                this.$http.put(`career/${this.career.id}`, this.local_career).then(({ data }) => {
                    this.$emit('updatedCareer', data.data)
                    this.show =false
                    this.$toast.success({
                        title: 'Modification parcours',
                        message: 'parcours modifier avec succès !'
                    })
                }).catch((error) => {
                    this.errors = error.response.data.errors
                }).finally(() => {
                    this.loading = false
                })
            } else {
                this.$http.post('career', this.local_career).then(({ data }) => {
                    this.$emit('createdCareer', data.data)
                    this.show = false
                }).catch((error) => {
                    this.errors = error.response.data.errors
                }).finally(() => {
                    this.loading = false
                })
            }
        }
    }

    }
</script>

<style>

</style>