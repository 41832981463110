<template>
  <div>
    <v-dialog
        v-model="show"
        scrollable 
        persistent :overlay="false"
        max-width="500px"
        transition="dialog-transition"
    >
    <v-card>
        <v-card-title>
            <div class="">Besoin d'aide</div>
            <v-spacer></v-spacer>
            <v-btn icon color="red" @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-6">
            <div class="form">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="name"
                            name="name"
                            label="Nom complet"
                            outlined
                            :error-messages="errors.name"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="email"
                            name="email"
                            label="votre email"
                            outlined
                            :error-messages="errors.email"
                        ></v-text-field>
                    </v-col>
                    
                
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field
                        v-model="objet"
                        name="objet"
                        label="Objet"
                        outlined
                        :error-messages="errors.objet"
                    ></v-text-field>
                    </v-col>
                    
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-textarea
                            v-model="message"
                            name="message"
                            label="Message"
                            placeholder="Ecrire votre message"
                            outlined
                            :error-messages="errors.message"
                        ></v-textarea>
                    </v-col>
                   
                </v-row>
                
            </div>
            <div class="d-flex justify-end">
                <v-btn color="error" text class="me-2" @click="close">Annuler</v-btn>
                <v-btn color="primary" text class="me-2" @click="save" :loading="loading">Envoyer</v-btn>
            </div>
        </v-card-text>
    </v-card>
        
    </v-dialog>
  </div>
</template>

<script>
export default {

    props: {
        value: Boolean
    },
    data() {
        return {
            loading: false,
            name: '',
            email:'',
            objet: '',
            message: '',
            errors: {}
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                return this.$emit('input', value)
            }
        }
    },
    methods: {
        close() {
            this.name = ''
            this.email = ''
            this.objet = ''
            this.message = ''
            this.errors = {}
            this.show = false
        },
        save() {
            this.errors = {}
            this.loading = true;
            const data = {
                name: this.name,
                email: this.email,
                objet: this.objet,
                message: this.message
            }


            this.$http.post('auth/besoin-aide', data).then((response) => {
                this.$toast.success({
                    title: 'Besoin d\'aide',
                    message: response.data.message
                })
                this.close()
            }).catch((error) => {
                    if(error.response.data.errors) {
                        this.errors = error.response.data.errors
                    } else {
                        this.$toast.error({
                        title: 'Besoin d\'aide',
                        message: "Une error c'est produite lors de l'envoie de email"
                    })
                    this.loading = false 
                }
            }).finally(() => this.loading = false)
        }
    }

}
</script>

<style>

</style>