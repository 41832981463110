<template>
    <div>
        <v-row align="center" justify="center" v-if="entreprise">
            <v-col cols="12" md="7">
                <v-sheet class="pa-6">
                    <div class="font-4 font-weight-bold text-center">Information entreprise</div>
                    <div class="logo text-center">
                        <span>
                            <img :src="entreprise ? entreprise.logo_url : null" :alt="entreprise.name" width="100"
                                height="100" :style="imgStyle" @click="show = true" />
                            <form enctype="mulipart/form-data">
                                <my-upload field="logo" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess"
                                    @crop-upload-fail="cropUploadFail" v-model="show" :width="200" :height="150"
                                    method="POST" :url="url" :params="params" :headers="headers" langType="fr"
                                    img-format="png"></my-upload>
                            </form>
                        </span>
                        <div class="font-5 font-weight-bold">{{ entreprise.name }}</div>

                    </div>
                    <div class="entreprise-infos">
                        <v-row class="py-2">
                            <v-col class="subtitle-2 font-weight-bold">
                                Email
                            </v-col>
                            <v-col>
                                {{ entreprise.email ? entreprise.email : '-' }}
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row class="py-2">
                            <v-col class="subtitle-2 font-weight-bold">
                                Numéro téléphone
                            </v-col>
                            <v-col>
                                {{ entreprise.phone ? entreprise.phone : '-' }}
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row class="py-2">
                            <v-col class="subtitle-2 font-weight-bold">
                                Adresse
                            </v-col>
                            <v-col>
                                {{ entreprise.address ? entreprise.address : '-' }}
                            </v-col>
                        </v-row>
                        <div class="py-6">
                            <div class="d-flex justify-center">
                                <v-btn color="primary" style="text-transform: none" class="mr-3" outlined rounded
                                    @click="showForm = true">
                                    <v-icon>mdi-pencil</v-icon> Modifier
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </v-sheet>

            </v-col>
        </v-row>
        <entreprise-form :entreprise="entreprise" v-model="showForm" v-if="showForm" @updatedEntreprise="updatedEntreprise"/>
    </div>
</template>

<script>
import myUpload from "vue-image-crop-upload";
import EntrepriseForm from '../../components/Home/entrepriseForm.vue';
export default {
    components: { myUpload,EntrepriseForm },
    data() {
        return {
            show: false,
            showForm: false,
            entreprise: null,
            params: { _method: 'PUT' },

            headers: {
                'Authorization': 'Bearer ' + this.$auth.token
            },
        }

    },
    mounted() {
        this.getEntreprise()
    },
    computed: {
        imgStyle() {
            return {
                width: "200px",
                height: "200px",
                borderRadius: "150px",
                cursor: "pointer",
            };
        },

        url() {
            return `${this.$api.url_base}entreprise/change-logo/${this.entreprise.id}`
        },
    },

    methods: {
        getEntreprise() {
            this.$http.get(`entreprise/${this.$auth.user.entreprise_id}`).then(({ data }) => {
                this.entreprise = data.data
            })
        },
        toggleShow() {
            this.show = !this.show;
        },

        cropSuccess(imgDataUrl) {

            this.imgDataUrl = imgDataUrl;
        },

        cropUploadSuccess(jsonData) {

            this.entreprise.logo_url = jsonData.data.logo_url
            this.$toast.success({
                title: 'Photo profil',
                message: 'Votre logo a bien été modifiée'
            })

        },

        cropUploadFail() {
            this.$toast.error({
                title: 'Photo profil',
                message: 'Impossible de modifier l\'avatar'
            })
        },
        updatedEntreprise(entreprise) {
            this.entreprise = entreprise

        }
    }

}
</script>

<style></style>