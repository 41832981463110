<template>
  <div  class="search-container">
    <v-row
      class="search-content"
      align="center"
      justify="center"
    >
    <div class="item-search">
        <v-autocomplete
          label="Rechercher une spécialité"
          :items="items"
          v-model="searchKey"
          item-text="name"
          item-value="slug"
          solo
          
          hide-details
        ></v-autocomplete>
        <div class="hidden-md-and-up text-center mt-3">
        <v-btn color="primary" small @click="search">
          <v-icon>mdi-magnify</v-icon> Rechercher
        </v-btn>
      </div>
      </div>
      <div class="item-search hidden-sm-and-down">
        
        <v-autocomplete
          label="Lieu"
          :items="villesSenegal"
          v-model="ville"
          autocomplete
          solo
          hide-details
        ></v-autocomplete>
      </div>
     
      <div class="item-search hidden-sm-and-down">
        <v-btn x-large color="#00A19A" dark height="55px" @click="search"
          ><v-icon size="35">mdi-magnify</v-icon></v-btn
        >
      </div>
      
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sw: 1,
      searchKey: null,
      items: [],
      villesSenegal: [ 'Dakar', 'Diourbel','Fatick','Kaffrine','Kaolack','Kédougou','Kolda','Louga',' Matam','Saint-Louis','Sédhiou','Tambacounda','Thiès','Ziguinchor'],
      ville: "",
      structures: ["Ams"],
      medecins: [],
    };
  },
  created() {
    this.fetchMedecins();
  },
  methods: {
    fetchMedecins: function () {
      this.$http.get("auth/departments").then(({ data }) => {
        this.items = data.data;
      });
    },
    search() {
      //const slug = this.searchKey ? this.searchKey: 'medecine-generale'

      const query = {}
      if(this.searchKey) {
        query.department = this.searchKey
      }
     
      if(this.ville ) {
        query.lieu = this.ville
      }

      if(Object.keys(query).length == 0) {
        this.$toast.info({
          title: 'Recherche médecins',
          message: "Veuillez séléctionner les paramètres pour faire la recherche"
        })
      } else {
        this.$router.push({
        path: '/medecins',
        query: query ,
            replace: true
        });
      }
    },
  },
};
</script>

<style scoped>
.search-container {
  background-color: white ;
  /* border-radius: 35px; */
  height: 200px;
  width: 90%;
  border-top-left-radius: 0px;
  padding-top: 20px;
  margin-top: 5%;
  margin-left: 2%;
  }
  /* .btn-search {
    height: 75px; width: 96px;
  } */
  .item-search {
    margin: 60px 20px 0;
   
  }
  /* .search-content {
    
  } */
@media only screen and (max-width: 500px) {
  
  .v-input {
    font-size: 12px;
    max-width: 90%;
    align-items: center;
  }
  .search-container {
    border-top-right-radius: 0px;
    width: 100%;
    height: 120px;
    margin-left: 20px;
  } 
  .item-search {
    margin: 0 0 0 12px;
  }
}
</style>