<template>
    <v-app>
        <navbar />
        <v-main>
            <v-container>
                <div class="text-center primary--text font-4 mb-3" v-if="rooms.length !=0">Téléconsultation sans Rendez-vous</div>
                <card-room-telemedecine v-for="room in rooms" :key="room.id" :room="room" />
                <div v-if="rooms.length ==0">
                  
                  <div class="text-center">
                      <v-icon size="50">mdi-alert-circle-outline</v-icon>
                      <div class="font-6">Aucune salle attente disponible actuellement.</div>
                  </div>
                </div>
               </v-container>
            
        </v-main>
    </v-app>
</template>

<script>
import CardRoomTelemedecine from '../components/doctor/CardRoomTelemedecine.vue'
import navbar from '../components/Home/utilities/navbar.vue'
export default {
  components: { navbar, CardRoomTelemedecine, /*Login, Register*/ },

  data(){
    return {
        rooms: [],
        showDialog: false,
        tab: null,
    }
  },
  mounted(){
    this.getRoomOpen()
    
  },
  methods: {
    getRoomOpen() {
        this.$http.get('auth/rooms/open').then(({data}) => {
            this.rooms = data.data
           

        })
    },
    // patientAuth(){
    //     this.showDialog = true
    // },
    connected(){

    },
    codeValide() {

    },
    
  }

}
</script>

<style>

</style>