<template>
    <v-app class="bg-1">
        <navbar />
        <div id="layout" ref="layoutEl"></div>
        <v-sheet class="chat-container" elevation="1">
            <div id="chat-text">
                <ul id="chat-list"></ul>
            </div>
            <div class="send-chat">
                <v-text-field outlined dense type="text" id="chat-data" placeholder="Ecrire votre message" v-model="chatInput" />
                <v-btn icon color="primary" @click="sendMessage">
                    <v-icon>mdi-send</v-icon>
                </v-btn>
            </div>
        </v-sheet>
        <div class="controls">
            <v-btn icon class="icon" id="audio" @click="publishingAudio">
                <v-icon id="audio-on">{{ audioEnabled ? 'mdi-microphone' : 'mdi-microphone-off'}}</v-icon>
            </v-btn>
            <v-btn icon class="icon" id="video" @click="publishingVideo">
                <v-icon id="video-on">{{ videoEnabled ? 'mdi-video-outline' : 'mdi-video-off-outline'}}</v-icon>
            </v-btn>
            <v-btn icon class="icon" id="share" @click="sharedVideo">
                <v-icon>mdi-monitor</v-icon>
                <!-- <i class="fas fa-desktop fa-2x"></i> -->
            </v-btn>
            <v-btn icon class="icon" id="chat" @click="chated">
                <v-icon>mdi-message-text-outline</v-icon>
                <!-- <i class="fas fa-comment-alt fa-2x"></i> -->
                <div id="message-notification"></div>
            </v-btn>
            <v-btn icon class="icon" id="record" @click="goBack" v-if="$auth.check() && $auth.user.is_doctor">
                <!--  <i class="fas fa-dot-circle fa-2x"></i> -->
                <v-icon>mdi-view-dashboard</v-icon>
            </v-btn>
            <v-btn icon class="icon" id="end" @click="showSubscriberDialog=true" style="color: brown;">
                <v-icon>mdi-phone-hangup</v-icon>
            </v-btn>
            <div v-if="time_start && $auth.user.is_doctor">
                <v-chip color="primary"><v-icon class="mr-2" size="30">mdi-clock-time-three-outline</v-icon><compteur-time :time="time" @isTime="isTime"/>
                </v-chip>
                
            </div>
        </div>
        <v-dialog v-model="showSubscriberDialog"
            width="300px"
        >
            <v-card>
                <v-card-title>Appel terminé</v-card-title>
                <v-card-text>
                <p>Souhaitez-vous vraiment couper l'appel vidéo en cours ?</p>
                </v-card-text>
                <v-card-actions>
                <v-btn color="red" text @click="showSubscriberDialog = false">Non</v-btn>
                <v-btn color="green" text @click="disconnect">Oui</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="addTimeDialog" persistent width="450px" v-if="$auth.user.is_doctor && addTimeDialog">
            <v-card class="pa-6">
                <p>Bonjour {{ $auth.user.name }}</p>
                <p>Votre temps vient de s'ecouler. voulez-vous ajouter 5 minutes supplémentaire ?</p>
                <v-card-actions>
                <v-btn color="red" text @click="disconnect">Non</v-btn>
                <v-btn color="green" text @click="addTime">Oui</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-app>
</template>
  

<script>
import OT from '@opentok/client';
import '../../css/telemedecine/call.css'
import initLayoutContainer from "../../plugins/telemedecine/opentok-layout"
import navbar from '../Home/utilities/navbar.vue';
import CompteurTime from '../Home/utilities/compteurTime.vue';
export default {
  components: { navbar, CompteurTime },
    data() {
        return {
            addTimeDialog: false,
            time_start: false,
            time: 60,
            layout: null,
            publisher: null,
            scPublisher: null,
            session: null,
            sessionId: this.$route.params.sessionId,
            name: this.$route.params.name,
            audioEnabled: localStorage.getItem('audioEnabled') ? localStorage.getItem('audioEnabled') : true,
            videoEnabled: localStorage.getItem('videoEnabled') ? localStorage.getItem('videoEnabled') : true,
            sharing: false,
            recording: false,
            archiveId: null,
            chatVisible: false,
            chatInput: '',
            messages: [],
            messageCount: 0,
            videoSourceId: '',
            showSubscriberDialog: false
        };
    },
    watch: {
        $route: 'getToken',
        //time: this.time_default
    },
    mounted () {
        this.updateLayoutValues()
        this.getToken()
        this.onResize()
    },
    methods: {

        isTime() {
            this.addTimeDialog = true
        },
        updateLayoutValues() {
            const opts = {
                maxRatio: 3 / 2,
                minRatio: 9 / 16,
                fixedRatio: false,
                alignItems: 'center',
                bigPercentage: 0.8,
                bigFixedRatio: false,
                bigMaxRatio: 3 / 2,
                bigMinRatio: 9 / 16,
                bigFirst: true,
                scaleLastRow: true,
                smallMaxWidth: Infinity,
                smallMaxHeight: Infinity,
                bigMaxWidth: Infinity,
                bigMaxHeight: Infinity,
                bigAlignItems: 'center',
                smallAlignItems: 'center',
            };
            this.layout = initLayoutContainer(document.getElementById('layout'), opts);
            this.layout = this.layout.layout
            
        },
        addTime() {
            this.time = 300
            this.addTimeDialog = false
        },
        initializeSession() {
            this.session = OT.initSession(this.apiKey, this.sessionId);
            const pubEl = document.createElement('div');
            //const params = new URLSearchParams(window.location.search);
            this.publisher = OT.initPublisher(
                pubEl,
                {
                    resolution: '1280x720',
                    name: this.name,
                    // style: {buttonDisplayMode: 'off'}
                },
                () => {
                    this.layout();
                },
            );
            this.publisher.publishVideo(this.videoEnabled);
            this.publisher.publishAudio(this.audioEnabled);

            this.videoSourceId = localStorage.getItem('videoSourceId');
            if (this.videoSourceId) {
                this.publisher.setVideoSource(this.videoSourceId);
            }
            this.audioSourceId = localStorage.getItem('audioSourceId');
            if (this.audioSourceId) {
                this.publisher.setVideoSource(this.videoSourceId);
            }

            // const audioEnabled = localStorage.getItem('audioEnabled');
            // if (audioEnabled) {
            //     this.audioEnabled = audioEnabled
            //     this.publisher.publishAudio(JSON.parse(this.audioEnabled));
            // }

            // const videoEnabled = localStorage.getItem('videoEnabled');
            // if (videoEnabled) {
            //     this.videoEnabled = videoEnabled
            //     this.publisher.publishVideo(JSON.parse(this.videoEnabled));
            // }

            this.session.connect(this.token, (error) => {
                if (error) {
                    alert(error);
                } else {
                    this.session.publish(this.publisher);
                }
            });
            this.session.on('streamCreated', (event) => {
                this.time_start = true;
                const subEl = document.createElement('div');
                const isScreenShare = event.stream.videoType === 'screen';
                if (isScreenShare) {
                    subEl.classList.add('OT_big');
                }
                this.session.subscribe(
                    event.stream,
                    subEl,
                    {
                    resolution: '1280x720',
                    },
                    (err) => {
                        if (!err) {
                            //this.showSubscriberDialog = true;
                            document.getElementById("layout").appendChild(subEl);
                        }
                        this.layout();
                    }
                );
            });
            // this.session.on('streamCreated', (event) => {
            //     //confirm("Voulez vous acceptez")
            //     const subEl = document.createElement('div');
            //     const isScreenShare = event.stream.videoType === 'screen';
            //     if (isScreenShare) {
            //         subEl.classList.add('OT_big');
            //     }
            //     this.session.subscribe(
            //         event.stream,
            //         subEl,
            //         {
            //             resolution: '1280x720',
            //         },
            //         (err) => {
            //             if (!err) {
            //                 document.getElementById("layout").appendChild(subEl);
            //             }
            //             this.layout();
            //         },
            //     );
            // });

            this.session.on('streamDestroyed', () => {
                console.log('stream destroyed');
                this.time_start = false
                setTimeout(() => {
                    this.layout();
                }, 200);
            });

            this.session.on('archiveStarted', (event) => {
                console.log('archive started');
                this.archiveId = event.id;
                this.recording = true;
                this.recordButton.style.color = 'red';
            });

            this.session.on('archiveStopped', () => {
                console.log('archive stopped');
                this.archiveId = null;
                this.recording = false;
                this.recordButton.style.color = 'black';
            });

            this.session.on('signal:text', (event) => {
                const message = JSON.parse(event.data);
                const listEl = document.createElement('li');
                const isMe = event.from.connectionId === this.session.connection.id ? 'me' : 'other';
                if (isMe === 'other' && this.chatVisible === false) {
                    this.messageCount += 1;
                    document.getElementById('message-notification').innerHTML = this.messageCount;
                    document.getElementById('message-notification').style.display = 'flex';
                }
                listEl.classList.add(isMe);
                listEl.innerHTML = `<div class="bubble">
                    <span class="user">${message.name}</span>
                    <span class="time">${message.timestamp}</span>
                    <p class="message">${message.data}</p>
                    </div>`;
                    document.getElementById('chat-list').appendChild(listEl);
            });

            document.getElementById("layout").appendChild(pubEl);
            this.layout();
        },
        getToken() {
            this.$http.post('auth/tokens?sessionId='+this.sessionId).then((response) => {
                this.token = response.data.token,
                this.apiKey = response.data.apiKey

                this.initializeSession()
            })
        },
        sharedVideo() {
            const pubEl = document.createElement('div');

            if (!this.sharing) {
                OT.checkScreenSharingCapability((response) => {
                    if (!response.supported || response.extensionRegistered === false) {
                        alert('screen share not supported by browser');
                        return false;
                    }

                    if (response.extensionInstalled === false) {
                        alert('install the extension');
                        return false;
                    }
                    return true;
                });
                const ssEl = document.getElementById("layout").querySelector('.OT_big');
                if (ssEl) {
                    alert('screen is being shared');
                    return false;
                }

                pubEl.classList.add('OT_big');
                this.scPublisher = OT.initPublisher(
                    pubEl,
                    {
                        videoSource: 'screen',
                    },
                    () => this.layout(),
                );

                this.scPublisher.on('mediaStopped', () => {
                    this.scPublisher.destroy();
                    this.layout();
                });

                document.getElementById("layout").appendChild(pubEl);
                this.session.publish(this.scPublisher);
                this.shareButton.style.color = 'green';
                this.sharing = true;
                return this.layout();
            }
            // this is the case when we are screen sharing
            //
            this.scPublisher.destroy();
            this.shareButton.style.color = 'black';
            this.sharing = false;
            return this.layout();
        },
        goBack() {
            this.$router.replace('/dashboard/doctor/home')
            this.$router.go(0)
        },
        chated() {
            if (this.chatVisible) {
                this.chatVisible = false;
                document.getElementById("layout").style.width = '100%';
                document.querySelector('.chat-container').style.display = 'none';
            } else {
                document.getElementById('message-notification').innerHTML = '';
                document.getElementById('message-notification').style.display = 'none';
                this.chatVisible = true;
                document.getElementById("layout").style.width = '75%';
                document.querySelector('.chat-container').style.display = 'block';
            }

            this.layout();
        },
        sendMessage() {
            const chatInputValue = this.chatInput;
            const time = new Date().toLocaleTimeString();
            if (chatInputValue && chatInputValue !== '') {
                this.session.signal(
                {
                    type: 'text',
                    data: JSON.stringify({
                    data: chatInputValue,
                    timestamp: time,
                    name: this.name,
                    }),
                },
                (error) => {
                    if (error) {
                    console.log(error);
                    } else {
                    console.log('signal sent');
                    }
                },
                );
                this.chatInput = '';
            }
        
        },
        onResize() {
            let resizeTimeout;
            window.addEventListener('resize', () => {
                clearTimeout(resizeTimeout);
                resizeTimeout = setTimeout(() => {
                this.layout();
                }, 20);
            });
        },
        publishingAudio() {
            const audio = this.publisher.getAudioSource()
            this.audioEnabled = !audio.enabled
            this.publisher.publishAudio(this.audioEnabled)
            localStorage.setItem('audioEnabled', JSON.stringify(this.audioEnabled));
        },
        publishingVideo() {
            const video = this.publisher.getVideoSource()
            video.track ? this.videoEnabled = false : this.videoEnabled = true ;
            this.publisher.publishVideo(this.videoEnabled)
            localStorage.setItem('videoEnabled', JSON.stringify(this.videoEnabled))
        },
        disconnect() {
            if (this.publisher) {
                this.publisher.destroy();
            }
            if (this.scPublisher) {
                this.scPublisher.destroy();
            }

            setTimeout(() => {
                this.$router.go(-1)
            }, 200);
        }
    
    }
}
</script>

<style scoped>

</style>