<template>
  <div>
    <v-tabs v-model="tab" @change="handleTabChange()">
        <v-tab>Factures Impayées</v-tab>
        <v-tab>Factures Payées </v-tab>
    </v-tabs>
    <v-tabs-items>
        <table-factures 
            :factures="factures" 
            :headers="hearders" 
            :loading="loading"
            :page="current_page"
            :page-count="last_page"
            :items-per-page="per_page"
            @pagination="getFactures"
        /> 
    </v-tabs-items>
  </div>
</template>

<script>
import TableFactures from '../../components/facture/TableFactures.vue'
export default {
  components: { TableFactures },
    data(){
       return {
            tab: 0,
            doctor_id: this.$auth.user.doctor_id,
            factures: [],
            loading: false,
            current_page: 1,
            last_page:1,
            per_page: 10,
            hearders: [
                {
                    text: "Créée le",
                    align: "start",
                    value: "created_at",
                },
                {
                    text: "Patient",
                    align: "start",
                    filterable: true,
                    value: "patient",
                },
                {
                    text: "Montant",
                    align: "start",
                    value: "montant"

                },
                {
                    text: "Motif",
                    align: "start",
                    filterable: false,
                    value: "motif",
                },
                {
                    text: "Actions",
                    align: "start",
                    filterable: false,
                    value: "actions",
                },
            ]
            
        }
    },
    mounted() {
        this.getFactures()
    },
    methods: {
        handleTabChange() {
            // Réinitialiser la page à 1 lorsque l'onglet change
            this.current_page = 1;
            this.getFactures();
        },
        getFactures(page) {
            this.loading = true
            this.$http.get('factures', {params: {doctor_id: this.doctor_id, payee: this.tab, page: page}}).then(({ data }) => {
                this.factures = data.data
                this.current_page = data.meta.current_page
                this.last_page = data.meta.last_page
                this.per_page = data.meta.per_page
            }).catch(() => {
                this.$toast.error({
                    title: 'Erreur',
                    message: "Impossible de recuperer les factures car une erreur est survenue"
                })
            }).finally(() => this.loading = false)
        },
    }
}
</script>

<style>

</style>