<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="400"
      persistent
    >
      <v-card>
        <v-card-title class="text-h6">
          {{ title }}
        </v-card-title>

        <v-card-text>
            {{ message }}
            <!-- Êtes-vous sûr de vouloir supprimer <strong>{{proche.prenom}} {{ proche.nom}}</strong> de vos proches? -->
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            text
            @click="dialog = false"
          >
            Annuler
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="confirmDelete"
          >
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true
        },
        message: {
            type: String,
            required: true
        },
        title: {
            type:  String,
            required: true
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                return this.$emit('input', value)
            }
        }
    },
    data(){ return {} },
    methods: {
        confirmDelete() {
            this.$emit('confirmDelete')
            this.dialog = false
        }
    }
}
</script>

<style>

</style>