<template>
  <div>
    <v-sheet class="pa-3 mb-6">
        <v-row justify-space-between>
            <v-col>
               <!--  <v-btn text class="font-7" style="text-transform: none; padding: 0%;" link color="primary">
                    {{ field.label }}
                </v-btn> -->
                <div class="font-7" @click="viewField=true">
                    <router-link :to="'#'" style="text-decoration: none;">
                        {{ field.label }}
                    </router-link>
                   
                </div>
            </v-col>
            <v-col cols="12" md="4">
                <div class="font-7">{{ field.type }}</div>
            </v-col>
            <v-col cols="12" md="3">
                <div class="actions">
                    <v-btn icon class="mx-3" color="#025397" outlined small @click="updated">
                        <v-icon size="20" >mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon color="error" outlined small @click="dialogDelete=true">
                        <v-icon size="20" >mdi-delete</v-icon>
                    </v-btn>
                </div>
            </v-col>


        </v-row>
    </v-sheet>
    <!-- Dialog de Suppression de champs -->
    <delete-dialog 
        v-if="dialogDelete" 
        :message="'Êtes vous sûr de vouloir supprimer ce champs de formulaire'" 
        :title="'Suppression champs formulaire'" 
        v-model="dialogDelete"
        @confirmDelete="deleteField"
    />
    <!-- Dialog voir champs formulaire -->
    <field-view 
        v-if="viewField"
        v-model="viewField"
        :field="field"
    />
  </div>
</template>

<script>
import DeleteDialog from '../Home/utilities/DeleteDialog.vue'
import FieldView from '../Home/utilities/FieldView.vue'
export default {
  components: { DeleteDialog, FieldView },
    props: { 
        field: Object 
    },
    data() {
        return {
            dialogDelete: false,
            viewField: false
        }
    },
    methods: {
        updated() {
            this.$emit('updatedField', this.field)
        },
        deleteField() {
            this.$http.delete(`field/${this.field.id}`).then(() => {
            this.$toast.success({
                title: 'Suppression champs de formulaire',
                message: 'Votre champs de formulaire a été supprimer avec succès !'
            })
            this.$emit('deletedField', this.field)
        }).catch(() => {
            this.$toast.error({
                title: 'Suppression champs',
                message: 'Erreur impossible de supprimer le formulaire !'
            })

        })
            
        }
    }
}
</script>

<style scoped>
.v-sheet {
    border : 1px solid lightgrey;
}

</style>