<template>
  <v-card>
    <v-card-title>
        <v-spacer></v-spacer>
        
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Rechercher"
                single-line
                hide-details
            />
      
    </v-card-title>
    <v-data-table 
      :headers="headers" 
      :items="appointments" 
      :page.sync="current_page"
      :items-per-page="itemsPerPage"
      :loading="loading"
      class="elevation-0"
      item-key="id"
      hide-default-footer
      >
      <template #[`item.medecin`]="{ item }">
        <div class="d-flex py-3">
          <v-avatar size="50" color="white" class="mr-2">
            <img :src="item.doctor.user.avatar_url" alt="alt" />
          </v-avatar>
          <div class="medecin-name" v-if="item.doctor">
            <div class="font-weight-bold">{{ item.doctor.name }}</div>
            <span class="caption">{{ item.doctor.department.name }}</span>
          </div>
        </div>
      </template>
      <template #[`item.patient`]="{ item }">
        <div class="font-weight-bold">
          {{ item.proche ? item.proche.name : $auth.user.name + " (moi)" }}
        </div>
      </template>
      <template #[`item.delay_status`]="{ item }">
      <v-chip
      class="ma-2"
      :color="tab === 'comming' ? 'primary' : 'pink'"
      label
      text-color="white"
      >
      <v-icon left>
        mdi-av-timer
      </v-icon>
     {{ item.delay_status }}
    </v-chip>
      </template>
      <template #[`item.view`]="{ item }">
      <router-link
          :to="{
            name: $auth.user.is_patient ? 'compte.appointment.detail' : 'intervenant.appointment.detail' ,
            params: { appointment: item.id },
          }"
          style="text-decoration: none"
        >
          <v-icon :color="tab === 'past' ? 'orange accent-3' : 'primary'" class="mr-1">mdi-eye</v-icon></router-link
        >
      </template>
      <template #[`item.rejoindre`]="{ item }">
        <div v-if="item.sessionId">
          <router-link :to="{name: 'telemedecine.join', params: {sessionId: item.sessionId}}" style="text-decoration: none;" >Rejoindre l'appel</router-link>
        </div>
      </template>
      <template #no-data>
        <div class="py-3">
            Vous n'avez pas encore de rendez-vous <br />
            
        </div>
        
      </template>
    </v-data-table>
    <div class="text-center pt-2" v-if="pageCount > 1">
        <v-pagination
            v-model="current_page"
            :length="pageCount"
            @input="paginate"
        />
    </div>
  </v-card>
</template>
  <script>
export default {
  props: {
    appointments: {
      type: Array,
    },
    tab: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      required: true
    },
    page: {
      type: Number,
      default: () => 1
    },
    pageCount: {
      type: Number
    },
    itemsPerPage: {
      type: Number , 
      default: () => 20 
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      search: "",
      current_page: this.page,
    };
  },
  methods: {
    paginate() {
      this.$emit('pagination',this.current_page)
    }
  }
};
</script>
<style scoped>

</style>