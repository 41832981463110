<template>
    <div>
        <v-dialog v-model="show" persistent width="500">
            <v-card>
                <v-card-title primary-title>
                    {{ formTitle }}
                    <v-spacer></v-spacer>
                    <v-btn color="error" icon @click="show = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-6">
                    <v-form>

                        <v-text-field v-model="secretaire.first_name" name="first_name" label="Prénom " outlined :error-messages="errors.first_name"></v-text-field>

                        <v-text-field v-model="secretaire.last_name" name="last_name" label="Nom " outlined :error-messages="errors.last_name"></v-text-field>

                        <v-text-field v-model="secretaire.email" name="email" label="Email" outlined :error-messages="errors.email"></v-text-field>

                        <vue-tel-input-vuetify class="form" v-model="secretaire.phone" name="phone" placeholder="Entrer votre N° téléphone" outlined
                            label="Entrer votre N° téléphone"
                            mode="international" :error="errorPhone" ref="telInput" @change="verifyNumber"
                            :error-messages="errors.phone">
                        </vue-tel-input-vuetify>

                        <div class="d-flex">
                            <v-btn style="text-transform: none; margin-right: 20px;" color="primary" :loading="loading"
                                elevation="0" @click="save">Enregistrer</v-btn>
                            <v-btn style="text-transform: none;" color="error" outlined elevation="0" @click="show=false"> Annuler</v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: { secretaire: Object, value: Boolean },
    data() {
        return {
            errorPhone: false,
            loading: false,
            errors: {}
        }
    },
  computed: {
    show: {
        get() {
            return this.value
        },
        set(value) {
            return this.$emit('input', value)
        }
    },
    formTitle() {
      if(!this.secretaire.id){
        return 'Ajouter un secrétaire'
      }else {
        return 'Modifier secrétaire'
      }
    }
  },
    methods: {
        save() {
            this.secretaire.phone = this.secretaire.phone.match(/\S+/g).join("")
            this.loading = true;
            if(this.secretaire.id) {
                    this.$http.put(`secretaire/${this.secretaire.user_id}`,this.secretaire).then(({ data }) => {
                        this.$toast.success({
                        title: 'ModificationSecretaire',
                        message: 'Secretaire a été modifié avec succès !'
                    })
                    this.$emit('updatedSecretaire', data.data)
                    this.show = false
                    }).catch((error) => {
                       
                        if(error.response.data.abonnement) {
                    this.$toast.info({
                        title: 'Abonnement',
                        message: "Abonnez-vous pour pouvoir utiliser ces resources"
                    })
                    const timer = setInterval(() => {
                        this.$router.push({name: 'abonnement', params: {type: 'premium'}})
                        //this.$router.push('/abonnement')
                        clearInterval(timer)
                    }, 3000);
                }
                        this.errors = error.response.data.errors
                    }).finally(() => this.loading = false)
            } else {
                this.$http.post('secretaire/register', this.secretaire).then(({ data }) => {
                    this.$toast.success({
                        title: 'Ajout Secretaire',
                        message: 'Secretaire a été créé avec succès !'
                    })
                    this.$emit('savedSecretaire', data.data)
                    this.show = false
                }).catch((error) => {
                    if(error.response.data.abonnement) {
                        console.log('premium abonnement')
                    this.$toast.info({
                        title: 'Abonnement',
                        message: "Abonnez-vous pour pouvoir utiliser ces resources"
                    })
                    const timer = setInterval(() => {
                        this.$router.push('/abonnement?type=premium')
                        clearInterval(timer)
                    }, 3000);
                }
                        this.errors = error.response.data.errors
                    }).finally(() => this.loading = false)
            }
            
        },
        verifyNumber() {
            if (!this.$refs.telInput._computedWatchers.phoneObject.value.isValid) {
                this.errorPhone = true;
                this.errors.phone = "Le numéro est incorrecte"
            } else {
                this.errorPhone = false;
                this.errors.phone = null;
            }

        },
    }
}
</script>

<style></style>