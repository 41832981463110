<template>
  <div>
    <v-data-table :headers="headers" :items="plans" sort-by="calories" class="elevation-1" hide-default-footer>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Plans abonnements</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Ajouter
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <div class="form-plan">
                    <v-text-field v-model="editedItem.name" label="Nom du Plan" name="name" outlined dense
                      :error-messages="errors.name" />
                    <v-text-field v-model="editedItem.duration" label="Duréé du plan(mois)" name="duration" outlined
                      dense type="number" :error-messages="errors.duration" />
                    <v-text-field v-model="editedItem.reduction" label="Reduction ou remise" name="reduction" outlined
                      dense type="number" :error-messages="errors.reduction" />
                    <v-tabs v-model="tab" color="primary" grow>
                      <v-tab value="basic">
                        Basic
                      </v-tab>
                      <v-tab value="premium">
                        Prémium
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab" class="mt-6">
                      <v-tab-item>
                        <v-text-field v-model="editedItem.amount_basic" label="Montant basique" name="amount_basic"
                          outlined dense type="number" :error-messages="errors.amount_basic" />
                        <div class="example">
                          <quill-editor class="editor" ref="myTextEditor" :value="editedItem.description_basic"
                            :options="editorOption" @change="onEditorChangeBasic" @blur="onEditorBlur($event)"
                            @focus="onEditorFocus($event)" @ready="onEditorReady($event)" />
                        </div>
                      </v-tab-item>
                      <v-tab-item>
                        <v-text-field v-model="editedItem.amount_premium" label="Montant prémium" name="amount_premium"
                          outlined dense type="number" :error-messages="errors.amount_premium" />
                        <quill-editor class="editor" ref="myTextEditor" :value="editedItem.description_premium"
                          :options="editorOption" @change="onEditorChangePremium" @blur="onEditorBlur($event)"
                          @focus="onEditorFocus($event)" @ready="onEditorReady($event)" />
                      </v-tab-item>
                    </v-tabs-items>
                  </div>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="close">
                  Annuler
                </v-btn>
                <v-btn color="primary" text @click="save">
                  Enregistrer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>

        <div class="text-center">Aucun plan n'est defini pour le moment.</div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import dedent from 'dedent'
import hljs from 'highlight.js'
import debounce from 'lodash/debounce'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
export default {
  components: {
    quillEditor
  },
  data() {
    return {
      tab: null,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Nom plan',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Durée', value: 'duration_format' },
        { text: 'Montant Basique', value: 'amount_basic_format' },
        { text: 'Montant Prémium', value: 'amount_premium_format' },
        { text: 'Réduction', value: 'reduction_format' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      loading: false,
      plans: [],
      editedItem: {
        id: null,
        name: '',
        amount_basic: null,
        amount_premium: null,
        duration: null,
        description_basic: dedent``,
        description_premium: dedent``,
        reduction: null
      },
      defaultItem: {
        id: null,
        name: '',
        amount_basic: null,
        amount_premium: null,
        duration: null,
        description_basic: dedent``,
        description_premium: dedent``,
        reduction: null
      },
      errors: {},
      editedIndex: -1,
      editorOption: {

        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
          ],
          syntax: {
            highlight: text => hljs.highlightAuto(text).value
          }
        }
      },


    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Ajouter un nouveau plan' : 'Modifier un plan'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    onEditorChangeBasic: debounce(function (value) {
      this.editedItem.description_basic = value.html
    }, 466),
    onEditorChangePremium: debounce(function (value) {
      this.editedItem.description_basic = value.html
    }, 466),
    onEditorBlur(editor) {
      console.log('editor blur!', editor)
    },
    onEditorFocus(editor) {
      console.log('editor focus!', editor)
    },
    onEditorReady(editor) {
      console.log('editor ready!', editor)
    },
    init() {
      this.$http.get('plans').then(({ data }) => {
        this.plans = data.data
      }).catch(() => {
        this.$toast.error({
          title: 'Recupérations plans',
          message: "Une erreur c'est produit lors de la recupération des plans aboonements"
        });
      })
    },
    editItem(item) {
      this.editedIndex = this.plans.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.plans.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.plans.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.errors = {}
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },


    save() {
      this.errors = {}
      if (this.editedItem.id) {
        this.$http.put(`plan/${this.editedItem.id}`, this.editedItem).then(({ data }) => {
          this.plans[this.indexOf] = data.data
          this.$toast.success({
            title: "Modification Plan",
            message: "Plan Abonnement modifié avec succès"
          })
          this.close()
        }).catch((error) => {
          if (error.response.data.errors) {
            this.errors = error.response.data.errors
          } else {
            this.$toast.error({
              title: 'Modification Plan',
              message: "Une erreur c'est produit lors de la modification de ce plan"
            })
          }
        })
      } else {
        this.$http.post(`plan`, this.editedItem).then(({ data }) => {
          this.plans.unshift(data.data)
          this.close()
          this.$toast.success({
            title: "Création Plan",
            message: "Plan Abonnement créé avec succès"
          })

        }).catch((error) => {
          if (error.response.data.errors) {
            this.errors = error.response.data.errors
          } else {
            this.$toast.error({
              title: 'Création Plan',
              message: "Une erreur c'est produit lors de la Création de ce plan"
            })
          }
        })
      }
    },
    deletePlan() {
      this.$http.delete(`plan/${this.editedItem.id}`).then(({ data }) => {
        console.log(data)
        this.plans.splice(this.indexOf, 1);
        this.$toast.succes({
          title: "Suppression Plan",
          message: "Plan Abonnement supprimé avec succès"
        })
      }).catch(() => {
        this.$toast.error({
          title: 'Suppression Plan',
          message: "Une erreur c'est produit lors de la Suppression de ce plan"
        })
      })
    },

  }
}
</script>

<style scoped lang="scss">
.example {
  display: flex;
  flex-direction: column;

  .editor {
    height: 15rem;
    overflow: hidden;
  }

  .output {
    width: 100%;
    height: 10rem;
    margin: 0;
    border: 1px solid #ccc;
    overflow-y: auto;
    resize: vertical;

    &.ql-snow {
      border-top: none;
      height: 14rem;
    }
  }
}
</style>