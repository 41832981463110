<!-- eslint-disable vue/no-parsing-error -->
<template>
    <div>
      <span>{{ minutes }}:</span>
      <span>{{ seconds < 10 ? '0' + seconds : seconds }}</span>
    </div>
  </template>
  
  <script>
  export default {
    props: {
        time: {
            type: Number,
            required: true
        }
    },
    data() {
      return {
        timer: null,
        local_time: this.time
        //time: 60, // 5 minutes in seconds
      };
    },
    watch: {
    time(newValue) {
      // Update local_time when the time prop changes
      this.local_time = newValue;
      this.startTimer()
    }
  },
    // watch: {
    //   local_time: () => this.time
    // },
    computed: {
      // local_time() {
      //   return this.time;
      // },
      minutes() {
        return Math.floor(this.local_time / 60);
      },
      seconds() {
        return this.local_time % 60;
      },
    },
    mounted() {
      this.startTimer();
    },
    methods: {
      startTimer() {
        this.timer = setInterval(() => {
          if (this.local_time > 0) {
            this.local_time--;
          } else {
            clearInterval(this.timer);
            this.$emit('isTime')
          }
        }, 1000);
      },
    },
  };
  </script>
  