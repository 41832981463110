<template>
  <div class="prive-rvd">
    <v-tabs v-model="tab" grow @change="goTo(tab)"  :slider-color="tab === 'past' ? 'error' : 'primary'">
      <v-tab href="#comming">Rendez-vous à venir</v-tab>
      <v-tab href="#past" active-class="red--text">Rendez-vous passés</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="comming" class="comming" >
        <appointment-table 
          :appointments="appointments" 
          :tab="tab" 
          :headers="headers" 
          :itemsPerPage="per_page" 
          :page="current_page" 
          :pageCount="last_page"
          :loading="loading"
          @pagination="getInit"
        />
      </v-tab-item>
      <v-tab-item value="past" class="past" >
        <appointment-table 
          :appointments="appointments" 
          :tab="tab" 
          :headers="headers"
          :itemsPerPage="per_page" 
          :page="current_page" 
          :pageCount="last_page"
          :loading="loading"
          @pagination="getPastAppointments"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

import AppointmentTable from '../../components/rv/AppointmentTable.vue';
export default {
  components: { AppointmentTable },
  data() {
    return {
      tab: null,
      page: 1,
      loading: false,
      appointments: [],
      headers: [
        {
          text: "Médecin",
          align: "start",
          sortable: false,
          value: "medecin",
        },
        { text: "Patient", value: "patient" },
        { text: "Date rendez-vous", value: "formated_date" },
        { text: "Délai RDV", value: "delay_status" },
        { text: "Voir plus", value: "view" },
      ],
      current_page: 1,
      last_page:1,
      per_page: 20,
    };
  },
  created() {
    //this.getInit();
  },
  methods: {
    getInit(page) {
      this.loading = true
      const patient_id = this.$auth.user.patient_id;
      this.$http
        .get(`appointments/comming/${patient_id}/patient`, {  params: { page: page } })
        .then(({ data }) => {
          this.appointments = data.data.data;
          this.current_page = data.data.current_page;
          this.last_page = data.data.last_page;
          this.per_page = data.data.per_page;
          this.loading = false
        }).catch(() => {
          this.loading = false
        });
    },
    getPastAppointments(page) {
      this.loading = true
      const patient_id = this.$auth.user.patient_id;
      this.$http
        .get(`appointments/past/${patient_id}/patient`, {  params: { page: page } })
        .then(({ data }) => {
          this.appointments = data.data.data;
          this.current_page = data.data.current_page;
          this.last_page = data.data.last_page;
          this.per_page = data.data.per_page;
          this.loading = false
        }).catch(() => {
          this.loading = false
        });
    },
    deleteAppointment(appointment) {
      this.appointments = this.appointments.filter(
        (rv) => rv.id != appointment.id
      );
      this.$toast.success({
        title: "Annulation RDV",
        message: "Votre rendez-vous a été annulé",
      });
    },
    goTo(value) {
      this.current_page = 1
      this.last_page = 1
      this.last_page = 1
      value == "past" ? this.getPastAppointments() : this.getInit();
    },
  },
};
</script>

<style scoped>
a.past.v-tab.v-tab--active {
  color: red;
  border-bottom: 2px solid red;
}
.prive-rvd {
  padding: 5%;
}
@media screen and (max-width: 600px) {
  .prive-rvd {
    padding: 0;
  }
}
</style>