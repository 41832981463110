<template>
    <v-container>
      <div  
      >
        <div class="d-flex " style ="flex-direction: column;margin:1%;align-items:center" :class="currently.data.is_read ? 'green lighten-5' : 'blue-grey lighten-3'">
          <div class="information" v-if="!onNotification.message">
            <div class="d-flex" style = "margin: 1%;">Inscription d'un nouveau doctor le {{ currently.date}}</div>
            <router-link  style = "text-decoration: none;margin: 0%;" :to="{name: 'admin.userProfile', params: {id: currently.data.data.id}}">
              <div class="my-2" >
                <v-btn  depressed small :class="currently.data.is_read ? 'teal accent-1' : 'blue-grey darken'" @click="notificationRead">Voir Profil</v-btn>
              </div>
          </router-link>
          </div>
          <div class="information" v-else>    
            <div class="d-flex">{{ onNotification.message}}</div>
          </div>
        </div>
      </div>  

      
    </v-container>
  </template>
  <script>
  export default {
    data() {
      return {
        loading: true,
        onNotification: [],
        currently: [],
        created_at: '',
        obj: '',
        data : '',
        alreadyRead : '',

      }
    },
    created () {
      this.currentlyReading(this.$route.params.id);
     
    },
    
    methods: {
      //permet de lire un notification
      notificationRead() {
        let data= {
            notify_id: this.$route.params.id
        };
        this.$http.post('notification/one-read',data)
        .then(data => {
          this.onNotification = data.data;
          this.loading = false
          
        })
        
      },  

      //detail d'un notification de la liste    
      currentlyReading(id) {
        this.$http.get('notification/'+ id)
        .then(data => {
          this.currently = data.data;
          
          this.makRead(id)
        })
        
      },

      //Permet de savoir que l'admin a dejà visualiser la notification sans acceder au compte du doctore
       makRead(id) {
        this.$http.put('notificationmakRead/'+ id)
        .then(data => {
          this.alreadyRead = data.data;
        })
        
      },

    },
      
    
    
  }
  </script>
  <style>
  /* {} */
  .card {
    display: flex;
    flex-direction: column;
    /* border:solid 4px #00a099; */
    height: 100%;
    width: 100%;
    
    
  }
  .information {
    display: flex;
    justify-content:space-between;
    width: 98%; 
    padding: 1%;
  }
  </style>
  