<template>
    <v-sheet class="row justify-space-between pa-3 mb-3 bar-title">
        <div class="font-5 font-weight-bold">
            <v-icon>{{ icon }}</v-icon>
            {{ title }}
        </div>
        <slot></slot>
    </v-sheet>
</template>

<script>
export default {
    props: {title: String, icon: String}

}
</script>

<style soped>
.row {
    margin: 0 !important;
}
@media screen and (max-width: 500px) {
    

}

</style>