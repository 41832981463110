<template>
    <div class="bullettin">
        <v-divider></v-divider>
        <div class="row justify-space-between py-2">
            <div class="col-3">
                {{ bulletin.type }}
            </div>
            <div class="col-4">
                {{ bulletin.diagnostic }}
            </div>
            <div class="col-4">
                {{ bulletin.examen }}
            </div>
            <div class="col-1" >
                <div class="d-flex justify-end text-center">
                    <v-btn v-if="is_from_me" icon class="mx-2" color="#025397" @click="showForm = true" outlined small>
                        <v-icon size="20">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn v-if="$auth.user.is_secretaire || is_from_me" icon class="mr-2" color="warning" outlined small @click="printBulletin">
                        <v-icon size="20">mdi-printer</v-icon>
                    </v-btn>
                    <v-btn v-if="is_from_me" icon class="" color="error" @click="dialogDelete = true" outlined small>
                        <v-icon size="20">mdi-delete</v-icon>
                    </v-btn>
                </div>
            </div>

        </div>
        <form-bulletin v-if="showForm" v-model="showForm" :bulletin="bulletin" @bulletinUpdated="bulletinUpdated" />
        <delete-dialog v-if="dialogDelete" v-model="dialogDelete"
            :message="'Êtes-vous sur de vouloir supprimer ce bulletin'" :title="'Suppression bulletin'"
            @confirmDelete="bulletinDelete" />
    </div>
</template>

<script>
import DeleteDialog from '../Home/utilities/DeleteDialog.vue'
import FormBulletin from './formBulletin.vue'
export default {
  components: { DeleteDialog, FormBulletin },
    props: {
        bulletin: Object,
        is_from_me: Boolean
    },
    data() {
        return {
            showForm: false,
            dialogDelete: false
        }
    },
    methods: {
        bulletinDelete() {
            this.$http.delete(`bulletin/${this.bulletin.id}`).then(() => {
                this.$toast.success({
                    title: 'Bulletin',
                    message: 'Le bulletin a été supprimé avec succès !'
                })
                this.$emit('bulletinDeleted', this.bulletin)
            });
            
        },
        bulletinUpdated(bulletin) {
            this.$toast.success({
                title: 'Bulletin',
                message: 'Le bulletin est à jour !'
            })
            this.bulletin = bulletin
        },
        printBulletin() {
			window.open(`/printer/bulletin/${this.bulletin.id}`,'printWindow','height=800,width=900, align: center')
		}
    }
}
</script>

<style></style>