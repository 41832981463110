<template>
  <v-app>
    <navbar />
    <div class="not-found-container">
  <h3>OOPS! PAGE INTROUVABLE</h3>
  <div class="not-found-container1"><h1 class="not-found-text1">404</h1></div>
  <div class="not-found-container2">
    <h2 class="not-found-text2">
      NOUS SOMMES DÉSOLÉS, MAIS LA PAGE QUE VOUS AVEZ DEMANDÉE EST INTROUVABLE
    </h2>
  </div>
</div>
    
  </v-app>
  
</template>
  
  <script>
import navbar from '../components/Home/utilities/navbar.vue';
  import "../css/fonts.css";
  export default {
  components: { navbar },
    name: "NotFound",
    data() {
      return {
        img_url: ''
      }
      
    }
  };
</script>
<style scoped>
.not-found-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 90vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.not-found-container1 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.not-found-text1 {
  color: rgb(38, 38, 38);
  font-size: 252px;
  margin-top: -20px;
  font-weight: 900;
  margin-bottom: -20px;
  letter-spacing: -20px;
}
.not-found-container2 {
  width: 421px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.not-found-text2 {
  text-align: center;
  font-weight: 400;
}


</style>
  