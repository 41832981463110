<template>
    <div>
      <table-users-log 
          :current_page="current_page" 
          :data="users" 
          :itemsPerPage="itemsPerPage" 
          :loading="loading" 
          :pageCount="pageCount"
          :title="title"
          @paginate="getUsers"
      />
    </div>
  </template>
  
  <script>
  import TableUsersLog from './TableUsersLog.vue'
  export default {
    components: { TableUsersLog },
  
      data() {
          return {
              title: 'Utilisateurs non connectés pendant 30 jours',
              users: [],
              loading: true,
              itemsPerPage: 20,
              current_page: 1,
              pageCount: 0,
          }
      },
      created() {
          this.getUsers()
      },
      methods: {
            getUsers(page) {
              this.$http.get('activity/users/not-connected/month', { params : { page: page }}).then(({ data }) => {
              this.loading = false
              this.users = data.data.data
              this.current_page = data.data.current_page
              this.itemsPerPage = data.data.per_page
              this.pageCount = data.data.last_page
            })
          }
      }
  }
  </script>
  
  <style>
  
  </style>meta