<template>
   <div>
       <v-sheet class="pa-6">
           <div class="d-flex justify-end my-3" v-if="$auth.user.is_doctor">
               <v-btn 
                   color="primary" 
                   class="text-capitalize"
                   link
                   :to="{ 
                    name: 'doctor.visit.medical.patient.create', 
                    params: { 
                       patient: patient_id,
                       auteur: auteur
                    },
                    
                 }"
               > 
                   <v-icon>mdi-plus</v-icon> Nouvelle visite médicale
               </v-btn>
           </div>
           <table-medical-visit  
                :loading="loading" 
                :medical_visits="medical_visits" 
                :headers="headers"
                :page="current_page"
                :page-count="last_page"
                :items-per-page="per_page"
                @pagination="getMedicalVisits"
            />
       </v-sheet>
       </div>
 </template>
 
 <script>
import TableMedicalVisit from '../../../components/visite-medicale/TableMedicalVisit.vue'
 export default {
 components: { TableMedicalVisit },
   data() {
       return {
           loading: false,
           auteur: this.$route.params.auteur,
           patient_id: this.$route.params.patient,
           doctor_id: this.$auth.user.doctor_id,
           medical_visits: [],
            current_page: 1,
            last_page:1,
            per_page: 10,
           tab: null,
           headers: [
                     
                     {
                         text: 'Crée le ',
                         align: 'start',
                         filterable: false,
                         value: 'la_date',
                     },
                     {
                         text: 'Actions',
                         class:'d-flex justify-end',
                         filterable: false,
                         value: 'actions',
                     },
                 ]
       }
   },
   watch: {
       $route: 'getMedicalVisits'
   },
   mounted() {
       this.getMedicalVisits()
   },
   methods: {
       getMedicalVisits(page) {
           this.$http.get(`patient/get/medical_visits`, 
           {
            params: {
                    page: page,
                    auteur: this.auteur,
                    patient_id: this.patient_id,
                    doctor_id: this.doctor_id
                }
           }
           ).then(({ data }) => {
                this.medical_visits = data.data
                this.current_page = data.meta.current_page
                this.last_page = data.meta.last_page
                this.per_page = data.meta.per_page
           }).catch(() => {
               this.$toast.error({
                   title: 'Erreur',
                   message: "Une erreur c'est produite lors de la recuperation du patient"
               })
           })
       }
   }
 }
 </script>
 
 <style>
 
 </style>