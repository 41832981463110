<template>
    <div>
        <v-card class="" color="#181d2c" rounded="xl" width="400px">
            <v-card-text class="white--text">
                <div class="title font-6 font-weight-bold primary--text py-2">{{ plan_abonnement.name }}</div>
                <div class="my-2" v-if="tabs == 0">
                    <div> <span class="font-3">{{ plan_abonnement.amount_basic_format }}</span>
                        <span>/{{ plan_abonnement.duration_format }}</span>
                    </div>
                </div>
                <div class="my-2" v-else>
                    <div> <span class="font-3">{{ plan_abonnement.amount_premium_format }}</span>
                        <span>/{{ plan_abonnement.duration_format }}</span>
                    </div>
                </div>

                <v-tabs grow background-color="blue-grey" class="rounded-xl my-3" slider-color="#181d2c" slider-size="0"
                    v-model="tabs">
                    <v-tab active-class="white" class="white--primary rounded-xl">Basique</v-tab>
                    <v-tab active-class="white" class="white--primary rounded-xl">Prémium</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tabs">
                    <v-tab-item>
                        <v-card color="#181d2c" class="white--text">
                            <div v-html="plan_abonnement.description_basic" />

                        </v-card>

                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat elevation="0" color="#181d2c" class="white--text">
                            <div v-html="plan_abonnement.description_premium" />
                        </v-card>
                    </v-tab-item>

                </v-tabs-items>
                <v-sheet color="grey lighten-5 pa-3 mt-6">
                    <!-- <p class="mb-2">
                            <v-icon color="primary">mdi-check</v-icon> Lorem ipsum dolor sit amet consectetur .
                        </p>
                        <p class="mb-2">
                            <v-icon color="primary">mdi-check</v-icon>  Nobis assumenda aliquid vel veritatis .
                        </p> -->
                    <v-btn block color="primary" dark :loading="loading" @click="pay">Commander</v-btn>
                </v-sheet>


            </v-card-text>

        </v-card>
    </div>
</template>

<script>
export default {

    props: { plan_abonnement: Object },
    data() {
        return {
            loading: false,
            tabs: null
        }
    },
    mounted() {
        if (this.$route.query.type) {
            this.tabs = 1
        }
    },
    methods: {
        pay() {
            const data = {
                name: this.plan_abonnement.name,
                amount: this.tabs === 0 ? this.plan_abonnement.amount_basic : this.plan_abonnement.amount_premium,
                command_name: this.tabs === 0 ? 'Basic' : 'Premium',
                type_payement: 'Abonnement',
                type_abonnement: this.tabs === 0 ? 'STANDART' : 'PREMIUM',
                plan_id: this.plan_abonnement.id
            }
            this.loading = true
            this.$http.post('paytech/abonnement', data).then((response) => {
                this.url_pay = response.data.url;
                window.open(this.url_pay, "_self");
            }).catch((error) => {
                this.loading = false
                console.log(error)
            })
        }
    }
}
</script>

<style scoped>
.theme--light.v-tabs-items {
    background-color: #181d2c;
}
</style>