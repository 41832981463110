<template>
    <v-dialog
        v-model="show"
        persistent
        width="600"
    >
    <v-card>

   
        <v-card-text class="pa-6">

            <v-row>
                <v-col>
                    
                    <div class="font-6 font-weight-bold">Patient</div>
                        <v-autocomplete
                            v-model="patient"
                            :items="patients"
                            item-text="name"
                            :loading="isLoading"
                            :search-input.sync="search"
                            outlined
                            placeholder="Rechercher un patient"
                            :error-messages="errors.patient_id"
                            return-object
                        >
                        <template #no-data>
                            <div class="pa-2 font-7">patient non trouvé <v-btn class="primary" style="text-transform: none;" small @click="showFormPatient=true">Créer un</v-btn></div>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            
            <v-row v-if="$auth.user.is_doctor">
                <v-col>
                    <div class="font-6 font-weight-bold"></div>
                        <v-text-field
                            :value="user.name"
                            outlined
                            placeholder="Rechercher un patient"
                            disabled
                            :error-messages="errors.doctor_id"
                        />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div class="font-6 font-weight-bold">Choisir une prestation</div>
                        <v-autocomplete
                            v-model="motif"
                            :items="motifs"
                            item-text="name"
                            item-value="id"
                            outlined
                            placeholder="Rechercher une prestation"
                            :error-messages="errors.motif_id"
                            return-object
                        />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div class="font-6 font-weight-bold">Choisir une date</div>
                    <v-text-field
                        v-model="date"    
                        outlined
                        placeholder="Rechercher un patient"
                        diseable
                        type="date"
                        :error-messages="errors.date"
                    />
                        
                </v-col>
                <v-col>
                    <div class="font-6 font-weight-bold">Heure </div>
                    <v-text-field
                        v-model="heure"    
                        outlined
                        placeholder="Rechercher un patient"
                        diseable
                        type="time"
                    />
                        
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div class="font-6 font-weight-bold">Descriptions</div>
                        <v-textarea
                            v-model="description"
                            outlined
                            placeholder="description du rendez-vous"
                            :error-messages="errors.description"
                        >
                        </v-textarea>

                </v-col>

            </v-row>
            <v-row>
                <v-col class="d-flex justify-end">
                    <v-btn color="error" style="text-transform: none; margin-right: 15px" outlined @click="show=false">Annuler</v-btn>
                    <v-btn color="primary" style="text-transform: none; margin-right: 15px" @click="save" :loading="loading">Enregistrer</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
    <form-patient :patient="{}" v-model="showFormPatient"  @createPatient="createPatient"/>
    </v-dialog>
   
</template>
<script>
import FormPatient from '../patient/FormPatient.vue';
 export default {
  components: { FormPatient },
    props: { motifs: Array, value: Boolean},
    data() {

        return {
            loading: false,
            showFormPatient:false,
            patient: null,
            user: this.$auth.user,
            date: null,
            heure: '',
            motif: null,
            entries: [],
            isLoading: false,
            search: null,
            description:null,
            errors: {}
        }
    },
    computed: {
        show: {
            get() {
                return this.value;
            },

            set(value) {
                return this.$emit('input', value);
            }
        },
        patients() {
            return this.entries
        }
    },
    watch: {
        search(val) {
            this.isLoading = true
            
            this.$http.get('patients?name='+val).then( res => {
                this.entries = res.data.data
                this.isLoading = false
            }).catch(()=> {
                this.isLoading = false
            })
        }
    },
    methods: {
        save() {
            this.loading = true
           this.date = this.date+'T'+this.heure+':00'
           const data = {
                patient_id: this.patient.auteur == 'patient' ? this.patient.id : this.patient.intervenant_id == null ? this.patient.patient_id : null,
                date: this.date,
                doctor_id: this.$auth.user.doctor_id,
                motif_id: this.motif.id,
                description: this.description ? this.description : this.motif.name,
                proche_id: this.patient.auteur == 'proche' ? this.patient.id: null,
                intervenant_id: this.patient.intervenant_id,
                is_cabinet: this.patient.intervenant_id ? false : true,
                user_id: this.$auth.user.id
            }
           // console.log("data ", data)
        this.$http
        .post("appointment", data)
        .then(({ data }) => {
          this.loading = false;
          this.$toast.success({
            title: 'Prise de RDV',
            message: `Votre rendez-vous a été confirmé pour le ${data.data.formated_date}!`
          })
         this.$emit('appointmenCreated')
         this.show = false
         this.loading = false
          
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          this.loading = false
        })
        },
        createPatient(data){
            this.$toast.success({
                title: 'Ajout patient',
                message: data.name +" est créé avec succès"
            })
        }
    },
   
 }
</script>
<style scoped>
</style>