<template>
  <drawer :links="links" />
</template>

<script>
import Drawer from '../../components/Home/utilities/Drawer.vue'
export default {
    components: { Drawer },
    data() {
      return {
        links: [
        ["mdi-account-child-outline", "Profil", "entreprise.home"],
        ["mdi-account-cog-outline", "Paramètre", 'entreprise.parametre'],
        ["mdi-account-group-outline", "Patients", "entreprise.patients"]

    ]
      }
    }
   



}
</script>

<style>

</style>