<template>
  
    <div class="medecins">
        <div class="information">
          <div class="profile">
              <div class="avatar">
                  <img :src="intervenant.user.avatar_url"/>
              </div>
          </div> 
          <div class="address_cabinet">
            <div class="d-flex">
              <span><strong> Nom intervenant : </strong></span>
            </div>
            <div class="d-flex">
              <span>{{ intervenant.user.name }}</span>  
            </div>
          </div>
          <div class="address_cabinet">
            <div class="d-flex">
              <span><strong> Adresse email : </strong></span>
            </div>
            <div class="d-flex">
              <span>{{ intervenant.user.email }}</span>
            </div>
          </div>
          <div class="address_cabinet">
            <div class="d-flex">
              <span><strong> Télephone : </strong></span>
            </div>
            <div class="d-flex">
              <span>{{ intervenant.tel_port }}</span>
            </div>
          </div>
          <div class="address_cabinet">
            <div class="d-flex">
              <span><strong> Nom Cabinet : </strong></span>
            </div>
            <div class="d-flex">
              <span>{{ intervenant.cabinet_name }}</span>
            </div>
          </div>
  
          <div class="address_cabinet">
            <div class="d-flex">
              <span><strong> Address Cabinet : </strong></span>
            </div>
            <div class="d-flex">
              <span>{{ intervenant.address_cabinet }}</span>
            </div>
          </div>

          <div class="address_cabinet">
            <div class="action">
              <div class="d-flex">
                <span v-if="intervenant.user.actif" ><strong > Etat compte : </strong> <small style="color:green;"> Activé</small></span>
                <span v-else ><strong > Etat compte : </strong><small style="color:red;"> Desactivé</small> </span>
              </div>
              <div class="d-flex" style="padding-bottom: 10px;">
                <v-tooltip bottom >
                    <template v-slot:activator="{ on, attrs }"  >
               <v-btn elevation="0"  style="margin-right: 30px" :loading="loading" 
               v-bind="attrs"
                       v-on="on"> 
                       <span  class="mdi mdi-account-check" style="color:green;font-size:30px ;cursor: pointer;" @click="intervenantActivate()" ></span></v-btn>
              </template>
                 <span>Activer</span>
                 </v-tooltip>
                 <v-tooltip bottom >
                    <template v-slot:activator="{ on, attrs }"  >
               <v-btn elevation="0"  style="margin-right: 30px" 
               v-bind="attrs"
                       v-on="on"> 
               <span  class="mdi mdi-account-check" style="color:goldenrod;font-size:30px ;cursor: pointer;" @click="attentedialogue"></span></v-btn>
              </template>
                 <span>attente</span>
                 </v-tooltip>
  
                 <v-tooltip bottom >
                    <template v-slot:activator="{ on, attrs }"  >
                <v-btn elevation="0"  
                v-bind="attrs"
                       v-on="on"
                       style="margin-right: 30px"
                      > 
                <span  class="mdi mdi-account-remove " style="color:red;font-size:30px;cursor: pointer;" @click="doctorDisabled"></span></v-btn>
              </template>
                 <span>Annuler</span>
                 </v-tooltip>
                 <v-tooltip bottom >
                    <template v-slot:activator="{ on, attrs }"  >
                <v-btn elevation="0"  
                v-bind="attrs"
                       v-on="on"> 
                <span  class="mdi mdi-delete-forever" style="color:red;font-size:30px;cursor: pointer;" @click="deleteIntervenant"></span></v-btn>
              </template>
                 <span>Supprimé son compte</span>
                 </v-tooltip>
              </div>
              
            </div>
          </div>
      </div>
      <div class="fichier" style="display: flex;flex-direction: column;padding: 2%">
        <div class="piece"><h2>Les pièces jointes</h2></div>
        <div style="display: flex; margin-top: 10% ; justify-content: space-between" class="bloc">  
            <div>
              
              <p><span class="mdi mdi-file-document-outline" style="font-size: 20px"></span> &nbsp; Pièce d'identité ou passeport</p>
            </div>
            <div> <span  class="mdi mdi-file-eye" style="font-size: 20px; color: black; cursor: pointer;"  @click="doctorFile(intervenant.cni_file)"></span></div>
        </div>
  
      
  
        <div style="display: flex; margin-top: 10% ; justify-content: space-between">
            <div>
              
              <p><span class="mdi mdi-file-document-outline" style="font-size: 20px"></span> &nbsp; Autorisation d'exercer</p>  
            </div>
            <div> <span  class="mdi mdi-file-eye" style="font-size: 20px; color: black; cursor: pointer;"  @click="doctorFile(intervenant.ordreDesMedcin_file)"></span></div>
        </div>
  
  
      </div>
  
      <template>
        <v-dialog
              v-model="dialog"
              max-width="500px"
            >
                  <v-card>
                  <v-card-title>
                      <span class="text-h5">Rejeter l'inscription du doctor</span>
                  </v-card-title>
      
                  <v-card-text>
                      <v-container>
                      <v-row>
                
                          <v-textarea
                          
                              v-model="motif"
                              :rules="rules.motif"
                              label="Motif de rejet"
                              placeholder="Le motif"
                              outlined
                              required 
                              :error-messages = "motifMessageError"
    
                          >
                        </v-textarea>
                      </v-row>
                      </v-container>
                  </v-card-text>
      
                  <v-card-actions>
                      <v-spacer></v-spacer>
                        <div class="annuler" style="margin-right:3%;" >
                          <v-btn
                          color="#FFCDD2"
                          variant="text"
                          @click="close"
                          >
                          
                          Fermer
                          </v-btn>
                      </div>
    
                          <v-btn
                          color="primary" 
                          variant="text"
                          @click="intervenantActivate()"
                          :loading="loading"
                          >
                          Rejeter
                          </v-btn>
                  
                  </v-card-actions>
                  </v-card>
            </v-dialog>
  
  
  
            <v-dialog
              v-model="attenteDialogue"
              max-width="500px"
            >
                  <v-card>
                  <v-card-title>
                      <span class="text-h5">Mettre en attente le compte du doctor</span>
                  </v-card-title>
      
                  <v-card-text>
                      <v-container>
                      <v-row>
                
                          <v-textarea
                              v-model="attente"
                              :rules="rules.motif"
                              label="Motif de l'attente"
                              placeholder="Le motif"
                              outlined
                              required 
                              :error-messages = "motifMessageError"
    
                          >
                        </v-textarea>
                      </v-row>
                      </v-container>
                  </v-card-text>
      
                  <v-card-actions>
                      <v-spacer></v-spacer>
                        <div class="annuler" style="margin-right:3%;" >
                          <v-btn
                          color="#FFCDD2"
                          variant="text"
                          @click="closeAttentedialogue" 
                          >
                          
                          Fermer
                          </v-btn>
                      </div>
    
                          <v-btn
                          color="primary" 
                          variant="text"
                          @click="intervenantActivate()"
                          :loading="loading"
                          >
                          En attente
                          </v-btn>
                  
                  </v-card-actions>
                  </v-card>
            </v-dialog>
      </template>
    </div>
    
    </template>
    
    <script>
  
    export default {
    
      data() {
        return {
          intervenant: null,
          intervenantActived:'',
          dialog : false,
          attenteDialogue  : false,
          loading : false,
          motifMessageError: '',
          motif:'',
          attente: '',
          rules: {
            motif: [val => (val || '').length > 0 || 'Veillez renseigner le motif'],
          },  
        };
      },
      created() {
          this.ShowIntervenant(this.$route.params.id)
      },
      methods: {
        deleteIntervenant(){
          const id = this.intervenant.id;
          this.$http.delete('intervenant/'+id+'/delete').then(() => {
            this.$toast.success({
              title: 'Suppréssion',
              message: 'Intervenant supprimé avec succès'
            })
            this.$router.go(-1)
          }).catch(() => {
            this.$toast.error({
              title: 'Suppréssion',
              message: 'Une erreur c\'est passée lors de la suppréssion de l\'intervenant'
            })
          })
        },
          ShowIntervenant (id) {
              this.$http.get(`auth/intervenant/`+id).then(({ data }) => {
              this.intervenant = data.data;
              })
          },
  
          intervenantActivate (){    
            this.loading = true
            let data = {
              motif: this.motif,
              attente: this.attente,
            }
            this.$http.post(`auth/ActiveIntervenant/`+this.$route.params.id,data).then( data => { 
              this.intervenant.user.actif = false   
              this.intervenantActived = data.message;
              if(this.motif){
                this.dialog = false;
                this.$toast.success({
                title: "Annulation intervenant",
                message: "L'inscription de intervenant a bien été annuler"
              });
              this.$router.push({ name: 'admin.user' })
              
              ///window.location.reload(); 
              }if(this.attente){
                this.attenteDialogue = false;
                this.$toast.success({
                title: "Attente inscription intervenant",
                message: "L'inscription du intervenant a bien été mise en attente"
              });
              }
              if(this.motif == '' && this.attente == ''){
                this.$toast.success({
                  title: "Activation intervenant",
                  message: "Le docteur a été activer avec succès"
                });
                this.intervenant.user.actif = true
               // window.location.reload(); 
              }
              this.motif = '';  
              this.attente = ''; 
              }).catch((error) => {
                  this.$toast.error({
                  title: 'Gestion compte intervenant',
                  message: error.response.data.message  
                }) 
                this.motifMessageError = 'Veillez renseignez le motif de rejet de l\'inscription de l\'intervenant' 
            }).finally(()=> this.loading = false)
          },
          
          doctorDisabled() {
            this.dialog = true;
            this.motif = '';
            this.motifMessageError = '';
          },
  
          attentedialogue() {
           this.attenteDialogue = true;
            this.motif = '';
            this.motifMessageError = '';
          },
          closeAttentedialogue(){
            this.attenteDialogue = false;
            this.motifMessageError = '';    
            this.rules = '';
          },
  
          close(){
            this.dialog = false;
            this.motifMessageError = '';
            this.rules = '';
          },
  
          doctorFile(nom) {
          
              this.$http.get(`auth/intervenantFile/`+ nom).then(({ data }) => {
           
              window.open(data);
              })
          }
      },
    }
  
  
    </script>
    
    <style scoped>
    .profile {
      display: flex;
  
      /*align-items: center;*/
    }
  
    .information{
      display: flex;
      width: 60%;
      justify-content:center;
      flex-direction: column;
      padding: 5%;
      margin-right: 2%;
      transition: transform ease 0.3s, box-shadow ease 0.3s;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      
    }
    .fichier{
      display: flex;
      width: 40%;
      transition: transform ease 0.3s, box-shadow ease 0.3s;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
     
    }
    .fichier .piece{
      display: flex;
      justify-content: center;
    }
    .medecins {
      display: flex;
      background-color: #fff;
      /*border-top: 2px solid #151616;*/
      /* box-shadow: 0 0 2px rgba(0, 0, 0, 0.5); */
      padding: 2%;
      text-decoration: none;
      color: inherit;
      /* transition: transform ease 0.3s, box-shadow ease 0.3s; */
      /* margin-bottom: 20px; */
    }
     .avatar {
      display:flex;
      width: 100%;
      justify-content: center;
      align-items: center;
   
    }
    .action {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .annuler :hover {
          background-color: red !important;
  }
    .medecins .avatar img {
      display: flex;
      width: 200px;
      height: 200px;
      border-radius: 100px;
    }
  .address_cabinet {
    display: flex;
    justify-content: space-between;
    /* justify-content: center; */
    margin: 2%;
    border-bottom: 1px solid #CFD8DC;
    /* width: %; */
  }
    @media only screen and (max-width: 600px) {
      .profile {
      display: flex;
      align-items: center;
      justify-content: center;
      /*align-items: center;*/
    }
    .info-doc {
      margin-left: 0;
    }
    .bloc {
      border-bottom: 3px solid gray;
    }
    
  
  }
  
    </style>