<template>
    <v-dialog
      v-model="show"
      persistent
      width="500px"
      >
      <v-card>
          <v-card-title>
             {{ formTitle }} 
             <v-spacer></v-spacer>
             <v-btn icon color="error" @click="show=false">
              <v-icon>mdi-close</v-icon>
             </v-btn>
          </v-card-title>
          <v-card-text>
              <v-form>
                  <v-text-field
                      v-model="ordonnance.name"
                      name="name"
                      label="Nom du médicament"
                      outlined
                      :error-messages="errors.name"
                  ></v-text-field>
                  <v-textarea
                      v-model="ordonnance.dosage"
                      name="dosage"
                      label="Dosage"
                      id="dosage"
                      outlined
                      :error-messages="errors.dosage"
                  ></v-textarea>
                  <v-text-field
                      v-model="ordonnance.nombre"
                      name="nombre"
                      label="Nombre de boite"
                      type="number"
                      hide-spin-buttons
                      outlined
                      :error-messages="errors.nombre"
                  ></v-text-field>
                 
                  <div class="d-flex">
                      <v-btn 
                          style="text-transform: none;" 
                          color="primary" elevation="0" 
                          class="mr-3" 
                          @click="save" 
                          :loading="loading"
                      >
                          {{ ordonnance.id ? 'Modifier' : 'Ajouter' }}
                      </v-btn>
                      <v-btn style="text-transform: none;" color="error" outlined @click="show=false"> Annuler</v-btn>
                  </div>
              </v-form>
          </v-card-text>
      </v-card>
  </v-dialog>
  
  </template>
  
  <script>
 
  export default {
      props: {
          value: Boolean,
          ordonnance:{ 
              type: Object,
          }
      },
      data() {
          return {
  
             loading: false,
             errors: {}
          }
      },
      computed: {
          show: {
              get() {
                  return this.value
              },
              set(value) {
                  return this.$emit('input', value)
                 
              }
          },
          formTitle() {
              if(this.ordonnance.id) {
                  return 'Modifier médicament'
              } else {
                  return 'Ajouter un médicament'
              }
          }
         
      },
      methods: {
          save() {
              if(this.ordonnance.id) {
                  this.$http.put(`ordonnance/${this.ordonnance.id}`, this.ordonnance).then(({data}) => {
                      this.$emit('ordonnanceUpdated', data.data)
                      this.show = false
                  }).catch((error) => {
                      this.errors = error.response.data.errors
                  }).finally(()=> this.loading = false)
              } else {
                  this.$http.post(`ordonnance`, this.ordonnance).then(( {data}) => {
                      this.$emit('ordonnanceSaved', data.data)
                      this.show = false
                  }).catch((error) => {
                      this.errors = error.response.data.errors
                  }).finally(()=> this.loading = false)
              }
             
              
          },
         
          
      }
  }
  </script>
  
  <style>
  
  </style>