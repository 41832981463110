<template>
  <v-dialog
    v-model="show"
    max-width="400px"
    transition="dialog-transition"
>
<v-card>
  <v-card-text class="pa-6">
    <v-text-field
        v-model="entrepriseEdited.name"
        name="name"
        label="Nom entreprise"
        outlined
        :error-messages="errors.name"
    ></v-text-field>
    
    <v-text-field
        v-model="entrepriseEdited.email"
        name="email"
        label="Adresse e-mail"
        outlined
        :error-messages="errors.email"
    ></v-text-field>
    <v-text-field
        v-model="entrepriseEdited.phone"
        name="phone"
        label="N° téléphone"
        outlined
        :error-messages="errors.phone"
    ></v-text-field>
    <v-text-field
        v-model="entrepriseEdited.address"
        name="address"
        label="Adresse"
        outlined
        :error-messages="errors.address"
    ></v-text-field>

    <div class="d-flex justify-space-between">
        <v-btn color="error" style="text-transform: none;" outlined @click="show=false">Annuler</v-btn>
        <v-btn color="primary" style="text-transform: none;" outlined @click="save">Modifier</v-btn>
    </div>
  </v-card-text>
</v-card>
</v-dialog>
</template>

<script>
export default {
    props: {
        entreprise: Object,
        value: Boolean
    },
    data() {
        return {
            errors: {},
            entrepriseEdited: null
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                return this.$emit('input', value)
            }
        }
    },
    watch: {
        entreprise: {
            handler: 'updateEntreprise',
            immediate: true
        }
    },
    methods: {
        updateEntreprise() {
            this.entrepriseEdited = JSON.parse(JSON.stringify(this.entreprise))
        },
        save() {
            const data = {
                name: this.entrepriseEdited.name,
                address: this.entrepriseEdited.address,
                email: this.entrepriseEdited.email,
                phone: this.entrepriseEdited.phone
            }

            this.$http.put(`entreprise/${this.entreprise.id}`, data).then(({data}) => {
                this.$emit('updatedEntreprise', data.data)
                this.$toast.success({
                    title: 'Entreprise',
                    message: "Entreprise modifié avec succès"
                })
                this.show = false
            })
        }
    }
}
</script>

<style>

</style>