<template>
  <v-app>
    <navbar />
    <v-main>
        <v-container>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                    <v-row align="center" justify="center">
                        <v-col cols="12" md="8">
                            <v-card class="rounded-xl" elevation="0">
                                <v-card-title class="error text-center">
                                    <div class="white--text">Payement annulé !</div>
                                </v-card-title>
                                <v-divider />
                                <v-card-text>
                                    <p class="text-center">
                                        Vous avez annulé votre paiement.
                                    </p>
                                    <!-- <v-btn color="success" text style="text-decoration: none; text-transform: none;" @click="back">Réessayer </v-btn> -->
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                   
                </v-col>
            </v-row>
        </v-container>
    </v-main>
  </v-app>
</template>

<script>
import navbar from '../../components/Home/utilities/navbar.vue'
export default {
  components: { navbar },
  methods: {
    back() {
        this.$router.go(-1)
    }
  }

}
</script>

<style>

</style>