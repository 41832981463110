<template>
    <div>
      <!-- Logo Cabinet -->
      <card-title :icon="'mdi-upload'" :title="'Logo du Cabinet'"></card-title>
        <logo-cabinet :logo_cabinet_url="doctor.logo_cabinet_url" :logo_cabinet="doctor.logo_cabinet" @saveLogoCabinet="saveLogoCabinet" />
    </div>
  </template>
  
  <script>
import LogoCabinet from '../../../components/doctor/LogoCabinet.vue'
import cardTitle from '../../../components/Home/utilities/cardTitle.vue'
  export default {
  components: { cardTitle, LogoCabinet },
  props: ['doctor'],
  data() {
    return {
      logo_cabinet: this.doctor.logo_cabinet
    }
  },
  methods: {
    saveLogoCabinet(data) {
      this.doctor.logo_cabinet_url = data.url
      this.doctor.logo_cabinet = data.logo_cabinet
    },
  }
  
  }
  </script>
  
  <style>
  
  </style>