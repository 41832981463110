<template>
  <div>
    <div class="d-flex justify-end  font-7 my-12">
      
        <div>Dakar, le {{ today }}</div>
      
     </div>
    <div style="margin-top:40px">
      <h1 class="text-center mb-12 text-uppercase">Bulletin {{ bulletin.type }}</h1>
    </div>
    <table class="font-7">
            <tr>
                <th></th>
                <th>Resultat</th>
            </tr>
            <tr>
                <td>
                    <p>Prenom : {{ bulletin.proche ? bulletin.proche.first_name : bulletin.patient.first_name }}</p>
                    <p>Nom : {{ bulletin.proche ? bulletin.proche.last_name : bulletin.patient.last_name }}</p>
                    <p>Age : {{ bulletin.proche ? bulletin.proche.AGE : bulletin.patient.AGE }} </p>
                    <div>
                        <p>Diagnostic :</p>
                        <p> {{bulletin.diagnostic}}</p>

                    </div>
                    <div>
                        <p>Examen Demandé :</p>
                        <p> {{bulletin.examen}}</p>

                    </div>

                </td>
                <td >
                    <p>{{bulletin.resultat}}</p>

                </td>
            </tr>
        </table>
        <footer-print  :signature_url="doctor.signature_url"/>
  </div>
</template>
  
<script>
import footerPrint from '../../components/Home/utilities/footerPrint.vue';
export default {
  props: ['doctor'],
  components: { footerPrint },
  data() {
    return {
      id: this.$route.params.id,
      bulletin: null
    }
  },
  computed: {
    today() {

            // Créer une instance de la date actuelle
      const date = new Date();

      // Obtenir les informations de la date
      const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

      // Convertir la date en chaîne de caractères
      const dateEnFrancais = date.toLocaleDateString('fr-FR', options);
      return dateEnFrancais;
    }
  },
  mounted() {
    this.getBulletin()
  },
  methods: {
    getBulletin() {
      this.$http.get(`bulletin/${this.id}`).then(({ data }) => {
        this.bulletin = data.data
        setTimeout(() => {
          window.print()
        }, 2000);
      })
    }
  }

}
</script>
  
<style scoped>
  .is-horizontal-center {
    justify-content: center;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid;
    text-align: left;
    padding: 8px;
  }
</style>