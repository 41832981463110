<template>
    <div>

        <v-data-table :items="entreprises" :headers="headers" :page.sync="current_page" class="elevation-0" item-key="id"
            :items-per-page="itemsPerPage" :loading="loading" hide-default-footer>
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Entreprises</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark class="mb-2" style="text-transform: none;" @click="dialog = true">
                        Ajouter un entreprise
                    </v-btn>
                    <!-- <v-btn color="primary" elevation="1" style="text-transform: none;"></v-btn> -->
                </v-toolbar>
            </template>
            <template v-slot:no-data>
                <div class="pa-3">
                    <v-icon size="30">mdi-alert-circle-outline</v-icon>
                    <div class="">La liste des entreprises est vide !</div>
                </div>
            </template>
            <template v-slot:[`item.name`]="{ item }">
                <div class="d-flex py-3">
                    <v-avatar size="50" color="white" class="mr-2">
                        <img :src="item.logo_url" alt="alt" />
                    </v-avatar>
                    <div class="">
                        <div class="font-weight-bold mt-3">{{ item.name }}</div>
                    </div>
                </div>
            </template>
            <template v-slot:[`item.patients`]="{ item }">
                <v-chip> {{ item.patients_count }} </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon color="primary" class="me-3" outlined @click="editedEntreprise(item)">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon color="error" outlined class="me-3" @click="deleteEntreprise(item)">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
                <!-- <v-btn icon color="info" outlined @click="sendSms(item)" v-if="item.patients_count > 0">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn> -->
            </template>
        </v-data-table>
        <div class="text-center pt-2" v-if="last_page > 1">
            <v-pagination v-model="current_page" :length="last_page" @input="getEntreprises"></v-pagination>
        </div>
        <v-dialog v-model="dialog" max-width="500px" persistent>

            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                    <v-spacer></v-spacer>
                    <v-btn icon color="error" @click="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text>
                    <form-entreprise :entreprise="entreprise" @createdEntreprise="createdEntreprise"
                        @updatedEntreprise="updatedEntreprise" />
                </v-card-text>
            </v-card>
        </v-dialog>
        <delete-dialog v-if="dialogDelete" v-model="dialogDelete"
            :message="`Êtes vous sûr de vouloir supprimer l\'entreprise ${entreprise.name}`"
            :title="`Suppréssion entreprise`" @confirmDelete="confirmDelete" />
        <delete-dialog v-if="dialogSend" v-model="dialogSend"
            :message="`Voulez vous envoyer des sms à tous patient de entreprise ${entreprise.name}`"
            :title="`Envoie des sms`" @confirmDelete="confirmSendSms" />
        <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Envoie de sms ...
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import FormEntreprise from '../../components/entreprises/FormEntreprise.vue'
import DeleteDialog from '../../components/Home/utilities/DeleteDialog.vue'
export default {
    components: { FormEntreprise, DeleteDialog },
    data() {
        return {
            dialogLoading: false,
            dialogDelete: false,
            headers: [
                {
                    text: 'Nom entreprise', value: 'name', align: 'start'
                },
                {
                    text: 'Email', value: 'email', align: 'start'
                },
                {
                    text: 'N° téléphone', value: 'phone', align: 'start'
                },
                {
                    text: 'Adresse', value: 'address', align: 'start'
                },
                {
                    text: 'Responsable', value: 'user.name', align: 'start'
                },
                { text: 'Patients', value: 'patients' },
                {
                    text: 'Actions', value: 'actions', align: 'start'
                }
            ],
            entreprises: [],
            entrepriseDefault: {
                id: null,
                name: '',
                email: '',
                phone: '',
                address: '',
                user: {
                    first_name: '',
                    last_name: '',
                    phone: '',
                    email: ''
                }
            },
            entreprise: {
                name: '',
                email: '',
                phone: '',
                address: '',
                user: {
                    first_name: '',
                    last_name: '',
                    phone: '',
                    email: ''
                }
            },
            loading: true,
            dialog: false,
            dialogSend: false,
            current_page: 1,
            last_page: 1,
            itemsPerPage: 15,
        }
    },
    computed: {
        formTitle() {
            if (this.entreprise.id) {
                return 'Modification Entreprise'
            }
            else {
                return 'Création Entreprise'
            }
        }
    },
    created() {
        this.getEntreprises()
    },
    methods: {
        getEntreprises() {
            this.$http.get('entreprises', { params: { page: this.current_page } }).then(({ data }) => {
                this.entreprises = data.data
                this.loading = false
                this.current_page = data.meta.current_page
                this.last_page = data.meta.last_page
                this.itemsPerPage = data.meta.per_page
            }).catch((error) => {
                this.loading = false
                if (error.response.data.message)
                    this.$toast.error({
                        title: 'Error Serveur',
                        message: error.response.data.message
                    })
            })
        },
        editedEntreprise(item) {
            this.entreprise = Object.assign({}, item)
            if (!this.entreprise.user) {
                this.entreprise.user = {
                    first_name: '',
                    last_name: '',
                    phone: '',
                    email: ''
                }
                this.dialog = true

            } else {
                var firstName = this.entreprise.user.name.split(' ').slice(0, -1).join(' ');
                var lastName = this.entreprise.user.name.split(' ').slice(-1).join(' ');
                this.entreprise.user = {
                    id: this.entreprise.user.id,
                    name: this.entreprise.user.name,
                    first_name: firstName,
                    last_name: lastName,
                    phone: this.entreprise.user.phone,
                    email: this.entreprise.user.email
                }
                this.dialog = true
            }
        },
        close() {
            this.entreprise = this.entrepriseDefault
            this.dialog = false;
        },
        deleteEntreprise(item) {
            this.entreprise = Object.assign({}, item)
            this.dialogDelete = true
        },

        createdEntreprise() {
            this.getEntreprises()
            this.close()
        },
        updatedEntreprise() {
            this.getEntreprises()
            this.close()
        },
        confirmDelete() {
            this.$http.delete(`entreprise/${this.entreprise.id}`).then(({ data }) => {
                const index = this.entreprises.findIndex((entreprise) => entreprise.id == data.data.id);
                if (index > -1) {
                    this.entreprises.splice(index, 1)
                }
                this.$toast.success({
                    title: "Suppression Entreprise",
                    message: "Entreprise supprimé avec succès !"
                })
            }).catch((error) => {
                this.$toast.error({
                    title: "Suppression Entreprise",
                    message: error.response.data.message
                })
            })
            this.dialogDelete = false
            this.close()
        },

        sendSms(entreprise) {
            this.entreprise = entreprise
            this.dialogSend = true
        },
        confirmSendSms() {
            this.dialogLoading = true
            this.$http.get('entreprise/send-sms/' + this.entreprise.id).then(() => {
                this.$toast.success({
                    title: 'Envoie de sms',
                    message: "Envoie réussie"
                })
                this.dialogLoading = false
            }).catch(() => {
                this.$toast.error({
                    title: 'Envoie de sms',
                    message: "Une erreur c'est passée lors de l'envoie des sms"
                })
                this.dialogLoading = false
            })
        }
    }
}
</script>

<style></style>