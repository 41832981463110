<template>
  <v-card elevation="1" class="card-search">
    <div class="pa-6">
        <v-row>
          <v-col cols="12" md="4">
            <v-autocomplete
              label="spécialité"
              :items="departments"
              v-model="department"
              outlined
              dense
              item-text="name"
              item-value="slug"
              hide-details
              prepend-inner-icon="mdi-magnify"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              placeholder="Où ?"
              :items="villesSenegal"
              v-model="ville"
              name="ville"
              autocomplete
              outlined
              dense
              hide-details
              prepend-inner-icon="mdi-map-marker-radius-outline"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              v-model="type_rdv"
              :items="types_rdv"
              item-text="title"
              item-value="value"
              placeholder="Type de Rendez-vous"
              outlined
              dense
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="1">
            <div class="text-center">
              <v-btn color="primary" @click="search"  style="text-transform: none;">
                Rechercher
              </v-btn>
            </div>
          </v-col>
          
        </v-row>
    </div>

  </v-card>
</template>

<script>
export default {

  data() {
    return {
      department: null,
      ville: null,
      type_rdv: null,
      types_rdv: [
      {
        title: 'Cabinet',
        value: 'horaire_cabinet'
      },
      {
        title: 'Téléconsultation',
        value: 'horaire_teleconsultation'
      }
    ],
      departments: [],
      villesSenegal: [ 'Dakar', 'Diourbel','Fatick','Kaffrine','Kaolack','Kédougou','Kolda','Louga',' Matam','Saint-Louis','Sédhiou','Tambacounda','Thiès','Ziguinchor'],
    }
  },
  created() {
    this.getDepartments()
    if(this.$auth.check() && this.$auth.user.is_intervenant) {
      this.types_rdv.push({
       title: 'Téléexpertise',
       value: 'teleexpertise_days'
      })
    }
  },
  methods: {
    getDepartments: function () {
      this.$http.get("auth/departments").then(({ data }) => {
        this.departments = data.data;
      });
    },
    search() {
      const query = {}
      if(this.department) {
        query.department = this.department
      }
      if(this.ville) {
        query.lieu = this.ville
      }
      if(this.type_rdv) {
        query.type_rdv = this.type_rdv
      }
      if(Object.keys(query).length == 0) {
        this.$toast.info({
          title: 'Recherche médecins',
          message: "Veuillez saisir au moins un paramètre pour faire la recherche"
        })
      } else {
        this.$router.push({
        path: '/medecins',
        query: query ,
            replace: true
        });
      }
    }
  }
  
}
</script>

<style scoped>

.card-search {
  margin-bottom: 5%;
}
@media screen and (max-width: 500px) {

}

</style>