<template>
  <div v-if="consultation">
    <h2 class="mb-3">Modifier Consultation</h2>
        <v-sheet
            class="pa-6 mb-6"
        >
        <div class="font-6 font-weight-bold mb-3">
            Patient
        </div>
        <v-divider></v-divider>
        <div class="info-patient" >
            <div class="font-3 font-weight-bold">{{ consultation.patient.name }}</div>
            <!-- <div class="text-center">{{ patient.age }}</div> -->
            <div class="d-flex">
                <div class="">{{ consultation.proche ? consultation.patient.phone : consultation.patient.tel_port }}</div>
                <div class=""> / {{ consultation.patient.address }}</div>
            </div>
        </div>
        </v-sheet>
        <v-sheet class="pa-6">
            <div class="font-6 font-weight-bold">
                Consultation N° {{ consultation_id }}
            </div>
        </v-sheet>
        <v-divider></v-divider>
        <form-consultation :forms="consultation.data" :loading="loading" @formSaved="updateConsultation" />
  </div>
</template>

<script>
import formConsultation from '../../../components/consultation/formConsultation.vue'
export default {
    components: { formConsultation },
    data() {
        return {
            consultation_id: this.$route.params.consultation,
            consultation: null,
            loading: false
        }
    },
    mounted() {
        this.getConsultation()
    },


    methods: {
        getConsultation() {
            this.$http.get(`consultation/${this.consultation_id}/show`).then(({ data }) => {
				this.consultation = data
			})
        },
        updateConsultation(forms) {
            
            this.$http.put(`consultation/${this.consultation_id}`, { data: forms}).then(() => {
                this.$toast.success({
                    title: 'Modification Consultation',
                    message: "La consultation a été mise à jour"
                })
                this.$router.go(-1)
            }).catch((error) => {
                if(error.response.data.abonnement) {
                    this.$toast.info({
                        title: 'Abonnement',
                        message: "Abonnez-vous pour pouvoir utiliser ces resources"
                    })
                    const timer = setInterval(() => {
                        this.$router.push('/abonnement')
                        clearInterval(timer)
                    }, 3000);
                }
                // this.$toast.error({
                //     title: 'Modification Consultation',
                //     message: "Impossible de modifier la consultation"
                // })
            })
        }
    }

}
</script>

<style>

</style>