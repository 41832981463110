<template>
    <div v-if="consultation"> 
        <div class="d-flex justify-end  font-7 my-12">
      
      <div>Dakar, le {{ today }}</div>
    
   </div>
  <div style="margin-top:40px">
    <h1 class="text-center mb-12">CERTIFICAT DE VISITE MEDICALE</h1>
    <div class="font-7">
        <div>Nous soussignés, Docteur en {{ doctor.department.name }}, {{ doctor.name }} , certifions avoir examiné ce jour,</div>
        <div>
            {{ consultation.proche ? (consultation.proche.sexe=='homme' ? 'Mr' : 'M(me)') : (consultation.patient.sexe=='homme' ? 'Mr' : 'M(me)') }}  {{ consultation.proche ? consultation.proche.name : consultation.patient.name }}
        </div>
        <div>
            Né(e) le  {{ consultation.proche_id ? consultation.proche.date_naissance : consultation.patient.date_naissance }}
                à {{ consultation.proche_id ? consultation.proche.lieu_naissance : consultation.patient.lieu_naissance }} qu'à ce jour il (elle) ne présente aucune pathologie médicale cliniquement décelable.
        </div>
       
        <div>
            En foi de quoi, le présent certificat lui est délivré pour servir et valoir ce que de droit.
        </div>
        
    </div>
  </div> 
  <footer-print :signature_url="doctor.signature_url" />
    </div>
  </template>
  
  <script>
import footerPrint from '../../components/Home/utilities/footerPrint.vue';
  
  export default {
    props: {doctor: Object},
    components: { footerPrint },
      data() {
          return {
              consultation_id: this.$route.params.consultation,
              consultation: null,
          }
      },
      computed: {
        today() {

                // Créer une instance de la date actuelle
        const date = new Date();

        // Obtenir les informations de la date
        const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

        // Convertir la date en chaîne de caractères
        const dateEnFrancais = date.toLocaleDateString('fr-FR', options);
        return dateEnFrancais;
        }
    },
      async mounted() {
         this.getConsultation()
         
      },
      methods: {
          getConsultation() {
              this.$http.get(`print/consultation/${this.consultation_id}`).then(({data}) => {
                  this.consultation = data
                  setTimeout(() => {
                      window.print()
                  }, 2000);
                  
              })
              
          }
      }
  
  }
  </script>
  
  <style scoped>
  
  </style>