<template>
    <div>
        <v-row class="py-6">
          <v-col cols="12" md="3">
            <card-dashboard  :icon="`mdi-account-multiple`" :title="`Utilisateurs`" :number="users_count" :color="'primary'"/>
          </v-col>
          <v-col cols="12" md="3">
            <card-dashboard  :icon="`mdi-calendar-clock`" :title="`Rendez-vous`" :number="appointments_count" :color="'blue'"/>
          </v-col>
          <v-col cols="12" md="3">
            <card-dashboard  :icon="`mdi-clipboard-pulse`" :title="`Consultations`" :number="consultations_count" :color="'yellow'"/>
          </v-col>
          <v-col cols="12" md="3">
            <card-dashboard  :icon="`mdi-stethoscope`" :title="`Visites Médicales`" :number="medical_visits_count" :color="'orange'"/>
          </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <loading-page v-if="loading1"></loading-page>
                <utlisateurs-chart  :data="usersCount" v-else/>
            </v-col>
            <v-col cols="12" md="6">
                <loading-page v-if="loading2"></loading-page>
                <chart :consultations="consultations" :medical_visits="medical_visits" v-else />
            </v-col>
            <v-col cols="12" md="12">
                <loading-page v-if="loading3"></loading-page>
                <user-create-chart :data="users_created" v-else />
            </v-col>
            

            <!-- <v-col cols="12" md="6">
              
        </v-col> -->
        </v-row>
    </div>
</template>

<script>
import Chart from '../../components/charts/Chart.vue'

//import ConsultationMedicalChart from '../../components/charts/consultation.medical.chart.vue'

import UserCreateChart from '../../components/charts/user.create.chart.vue'
//import UserSemaineChart from '../../components/charts/user.semaine.chart.vue'
import utlisateursChart from '../../components/charts/utlisateurs.chart.vue'
import CardDashboard from '../../components/Home/CardDashboard.vue'
import LoadingPage from '../../components/Home/utilities/LoadingPage.vue'
export default {
    components: { utlisateursChart, UserCreateChart, LoadingPage, Chart, CardDashboard },
        data() {
           return {
            consultations: [],
            medical_visits: [],
            usersCount: [],
            users_created: [],
            users_count: 0,
            appointments_count: 0,
            consultations_count: 0,
            medical_visits_count:0,
            loading1 : true,
            loading2: true,
            loading3: true
        }
    },
    mounted() {
        this.getCounter()
        this.getCountUsersByRole()
        this.getPerMonthConsultationsAndMedicalVisits()
        this.getPerMonthUsersCreated()
    },
    methods: {
        getCountUsersByRole() {
            this.$http.get('dashboard/count/users').then((response) => {
                this.usersCount = response.data
               this.loading1 = false
            })
        },
        getPerMonthConsultationsAndMedicalVisits() {
            this.$http.get('dashboard/per/consultations_medical_visits').then((response) => {
                this.loading2 = false
                this.consultations = response.data.consultations
                this.medical_visits = response.data.medical_visits
            })
        },
        getPerMonthUsersCreated() {
            this.$http.get('dashboard/per/users_create').then((response) => {
                this.users_created = response.data.users_created
               this.loading3 = false
            })
        },
        getCounter() {
            this.$http.get('dashboard/count').then((response) => {
                this.users_count = response.data.users_count
                this.consultations_count = response.data.consultations_count
                this.medical_visits_count = response.data.medical_visits_count
                this.appointments_count = response.data.appointments_count
            })
        }
    }

}
</script>

<style></style>