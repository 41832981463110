<template>
    <drawer :links="links" />
</template>
<script>
    import Drawer from '../../components/Home/utilities/Drawer.vue';
    export default {
        components: {Drawer},
    data: () => ({
      links: [
        ["mdi-view-dashboard", "Dashbord", "admin.home"],
        ["mdi-account-tie", "Mon profil", "admin.profileAdmin"],
        ["mdi-hospital-building", "Départements","admin.departments"],
        //["mdi-cash-multiple", "Moyens de paiement","admin.moyenPaiement"],
        ["mdi-doctor", "Secteur D'activité","admin.SecteteurDactivite"],
        ["mdi-account-supervisor", "Utilisateurs","admin.users"],
        ["mdi-form-select", "Formulaires","admin.formulaires"],
        // ["mdi-tune-variant", "Parametre","admin.home"],
        ["mdi-cash-multiple", "Plans abonnements","admin.plans.abonnements"],
        ["mdi-account-credit-card", 'Payements', 'admin.payements'],
        ["mdi-handshake-outline", "Entreprises", 'admin.entreprises'],
        ["mdi-bell", "Notifications", "admin.notify"],
        ["mdi-account-key-outline", "Logs", "admin.logs"]
      ],
    }),
  };
  </script>