<template>
    <div>
        <v-app-bar color="#025397" style="padding: 0 5%" dark flat app>
            <div class="d-flex align-center">
                <p class="font-5 mb-n1" style="color: white">
                    <router-link to="/" style="text-decoration: none; color: white; font-weight: bolder">
                        <v-avatar size="50" color="white">
                            <img src="../../../assets/logo_sahel.png" alt="logo" />
                        </v-avatar>
                        <span class="px-2 hidden-sm-and-down">SAHEL ASSISTANCE</span>
                    </router-link>
                </p>
            </div>

            <v-spacer></v-spacer>
            <div class="d-none d-sm-flex">
                <div class="d-flex align-center">
                    <v-btn style="text-transform: none" class="font-6 font-weight-bold" text plain link to="/#doctor_register">
                        Vous êtes professionnel de santé
                    </v-btn>
                    <v-btn style="text-transform: none" class="font-6 font-weight-bold" text plain to="/#patient_register">
                        Vous êtes patient
                    </v-btn>
                    <v-btn v-if="!authenticated" style="text-transform: none" class="font-6 font-weight-bold" text plain
                        @click="dialog = true">S'inscrire</v-btn>
                    <v-btn v-if="!authenticated" style="text-transform: none" class="font-6 font-weight-bold" text plain
                        @click="dialog = true">Se connecter</v-btn>
                    <menu-account :user="user" v-if="authenticated" :color="'primary'" />
                    <v-btn style="text-transform: none" class="font-6 font-weight-bold" rounded color="primary"
                        @click="help=true">Besoin d'aide ?</v-btn>
                </div>
            </div>
            <div class="hidden-md-and-up">
                <menu-account :user="$auth.user" :color="'primary'" v-if="authenticated"/>
                <v-btn v-else style="
                
                text-decoration: none;
                color: white !important;
              " small outlined color="primary" @click="dialog=true">
                    s'inscrire | se connecter
                </v-btn>
            </div>
            <!-- <v-app-bar-nav-icon class="hidden-md-and-up" @click.stop="drawer = !drawer" /> -->
        </v-app-bar>

        <v-main>
            <v-container>
                <slot />
                <v-dialog class="text-center" v-model="dialog" max-width="430px">
                    <v-card>
                        <v-tabs v-model="tab" grow>
                            <v-tab> Se connecter </v-tab>
                            <v-tab> S'inscrire </v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <v-card color="basil" flat>
                                    <v-card-text>
                                        <Login @connected="connected" />
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item>
                                <v-card color="basil" flat>
                                    <v-card-text>
                                        <Register @codeValide="codeValide" />
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                </v-dialog>
                <drawer-mobile v-model="drawer" />
            </v-container>
            <besoin-aide v-if="help" v-model="help" />
        </v-main>
    </div>
</template>

<script>
import Login from "../../login.vue";
import Register from "../../register.vue";
import BesoinAide from '../BesoinAide.vue';
import DrawerMobile from "./DrawerMobile.vue";
import MenuAccount from "./MenuAccount.vue";
export default {
    components: { Login, Register, MenuAccount, DrawerMobile, BesoinAide },
    data() {
        return {
            help: false,
            drawer: false,
            dialog: false,
            user: null,
            tab: null,
            authenticated: false,
        };
    },
    mounted() {
        if (this.$auth.check()) {
            this.authenticated = true;
            this.user = this.$auth.user;
        }
    },
    methods: {
        codeValide() {
            this.tab = 0;
        },
        connected() {
            this.dialog = false;
        },
    },
};
</script>

<style scoped>
.v-main {
  padding: 22px 0 !important;
}
</style>