<template>
    <div>
        <home></home>
    </div>
</template>
<script>
import home from '../../../components/formulaire/home.vue'
export default {
    components :  { home },
    setup() {
    },
}
</script>
