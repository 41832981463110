<template>
    <div class="medecins">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: 'medecins-list',

        data() {
            return {
                //
            }
        }
    }
</script>

<style scoped>

</style>