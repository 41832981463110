<template>
  <v-container>
    <v-row align="center" justify="center">
        <v-col cols="12" md="8">
            <v-sheet v-if="payement" class="pa-6">
                <div class="payant text-center">
                    <v-avatar
                        size="150"
                        color="white"
                    >
                        <img :src="payement.user.avatar_url" alt="avatar">
                    </v-avatar>
                    <!-- <div class="font-weight-bold ma-3">Payant(e): {{ payement.user.name }}</div> -->
                </div>
                <div class="d-flex justify-space-between pa-3">
                    <div class="font-weight-bold"> Payant(e)</div>
                    <div class="">{{ payement.user.name }}</div>
                </div>
                <v-divider></v-divider>
                <div class="d-flex justify-space-between pa-3">
                    <div class="font-weight-bold"> Montant</div>
                    <div class="">{{ (payement.amount).toLocaleString('fr-FR') }} fcfa</div>
                </div>
                <v-divider></v-divider>
                <div class="d-flex justify-space-between pa-3">
                    <div class="font-weight-bold"> Date payement</div>
                    <div class="">{{ payement.formated_date ? payement.formated_date : '- - -' }} </div>
                </div>
                <v-divider></v-divider>
                <div class="d-flex justify-space-between pa-3">
                    <div class="font-weight-bold"> Type payement</div>
                    <div class="">{{ payement.type }} </div>
                </div>
                <v-divider></v-divider>
                <div v-if="payement.abonnement">
                    <div class="d-flex justify-space-between pa-3">
                        <div class="font-weight-bold"> Type abonnement</div>
                        <div class="">{{ payement.abonnement.type }} </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="d-flex justify-space-between pa-3">
                        <div class="font-weight-bold"> Date fin abonnement</div>
                        <div class="">{{ payement.abonnement.end_date }} </div>
                    </div>
                    <v-divider></v-divider>
                
                </div>
                <div v-if="payement.receiver">
                    <div class="d-flex justify-space-between pa-3">
                        <div class="font-weight-bold"> Envoyé à</div>
                        <div class="">{{ payement.receiver.name }} </div>
                    </div>
                    <v-divider></v-divider>
                </div>
                <div v-if="payement.appointment">
                    <div class="d-flex justify-space-between pa-3">
                        <div class="font-weight-bold"> Date RV</div>
                        <div class="">{{ payement.appointment.formated_date }} </div>
                    </div>
                    <v-divider></v-divider>
                </div>
                <div class="d-flex justify-space-between pa-3">
                    <div class="font-weight-bold"> Statut</div>
                    <div class="font-weight-bold">
                        <v-chip
                            :color="findColor(payement.status)"
                            label
                            text-color="white"
                            
                        >
                            <v-icon left>
                                mdi-label
                            </v-icon>
                            {{ payement.status }}
                        </v-chip>
                    </div>
                </div>
                <v-divider></v-divider>

            </v-sheet>
            <v-alert type="info" dense v-else>
                <div class="text-center">Payement introuvable.</div>
            </v-alert>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    data() {
        return {
           payement: null, 
        }
    },
    created() {
        this.init()
    },
    watch: {
        $route: 'init'
    },
    methods: {
        init() {
            const id = this.$route.params.id;
            this.$http.get(`paiement/${id}`).then(({ data }) => {
                this.payement = data.data
            }).catch(() => {
                this.$toast.error({
                    title: 'Chargement Payement',
                    message: "Une erreur c'est produite lors du chargement ..."
                })
            })
        },
        findColor(status) {
              if(status == 'Payé') {
                  return 'primary'
              } else if(status == 'En attente') {
                  return 'cyan'
              } else if(status == 'Annuler') {
                  return 'pink'
              } else {
                  return ;
              }
          }
    }
}
</script>

<style>

</style>