import axios from 'axios'
import Api from './api';
import router from '../router'
const api = new Api();
class Auth {
    constructor() {
        this.url_base = 'https://backend.sahelassistance.com/api/'
        this.token = window.localStorage.getItem('token');

        let userData = window.localStorage.getItem('user');
        this.user = userData ? JSON.parse(userData) : null;

        if (this.token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
        }
    }
    login(token, user) {
        window.localStorage.setItem('token', token);
        window.localStorage.setItem('user', JSON.stringify(user));
        window.localStorage.setItem('token_last_refresh', Date.now());

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

        this.token = token;
        this.user = user;
        router.go('')
        
        
            
    }
    check() {
        return !!this.token;
    }

    hasRole(role) {
        if(this.check()) {
            return this.user.role === role ? true : false;
        }
        return false;
    }
    setUser(user) {
        window.localStorage.setItem('user', JSON.stringify(user));
        this.user = user
    }

    getUser() {
        axios.get(`${this.url_base}user`)
            .then(({data}) => {
               return  this.user = data.data;
            })
            .catch(({response}) => {
                if (response.status === 401) {
                    this.logout();
                }
            });
    }

    logout() {
        api.call('post', 'logout').then( () => {
            window.localStorage.clear('token')
            window.localStorage.clear('user')
            window.localStorage.clear('token_last_refresh')
            window.localStorage.clear('session_expired')
            router.push({ name: 'home'})
            router.go(0)
        })
    }
    
    
}

export default Auth;