<template>
    <v-dialog 
      v-model="show"
      persistent
      width="550px"
    >
    <v-card>
      <v-card-title>
          <div class="">{{ title }}</div>
          <v-spacer></v-spacer>
          <v-btn color="error" icon @click="show= false">
              <v-icon>mdi-close</v-icon>
          </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
          <div class="example">
              <quill-editor
                  class="editor"
                  ref="myTextEditor"
                  :value="content"
                  :options="editorOption"
                  @change="onEditorChange"
                  @blur="onEditorBlur($event)"
                  @focus="onEditorFocus($event)"
                  @ready="onEditorReady($event)"
              />
          </div>
         
      </v-card-text>
      <div class="pa-6">
          <div class="d-flex justify-end">
              <v-btn color="error" outlined style="text-transform: none; margin-right: 20px;" @click="show=false">Annuler</v-btn>
              <v-btn color="primary" outlined style="text-transform: none;" @click="save">Enregistrer</v-btn>
          </div>
      </div>
    </v-card>
    </v-dialog>
  </template>
  
  <script>
      import dedent from 'dedent'
      import hljs from 'highlight.js'
      import debounce from 'lodash/debounce'
      import { quillEditor } from 'vue-quill-editor'
      import 'quill/dist/quill.core.css'
      import 'quill/dist/quill.snow.css'
    
  export default {
      props: {
          value: {
              type: Boolean,
              required: true
          },
          contenu: {
              type: String,
              default(){
                  return ''
              }
          },
          title: {
              type: String,
              required: true
          }
      },
      components: {
          quillEditor
        },
        data() {
          return {
            editorOption: {
              
              modules: {
                toolbar: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ 'header': 1 }, { 'header': 2 }],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  [{ 'script': 'sub' }, { 'script': 'super' }],
                  [{ 'indent': '-1' }, { 'indent': '+1' }],
                  [{ 'direction': 'rtl' }],
                  [{ 'size': ['small', false, 'large', 'huge'] }],
                  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                  [{ 'font': [] }],
                  [{ 'color': [] }, { 'background': [] }],
                  [{ 'align': [] }],
                  ['clean'],
                ],
                syntax: {
                  highlight: text => hljs.highlightAuto(text).value
                }
              }
            },
            content: dedent`${this.contenu}`
          }
        },
        methods: {
          onEditorChange: debounce(function(value) {
            this.content = value.html
          }, 466),
          onEditorBlur() {
           
          },
          onEditorFocus() {
           
          },
          onEditorReady() {
           
          },
          save() {
              if(this.$auth.user.is_doctor) {
                  
                  this.$emit("saved", this.content)
                  this.show = false
              }
  
          }
        },
        computed: {
          show: {
              get() {
              return this.value;
          },
          set(value) {
              return this.$emit('input', value)
          },
          },
         
          
          editor() {
            return this.$refs.myTextEditor.quill
          },
          contentCode() {
            return hljs.highlightAuto(this.content).value
          }
        },
        mounted() {
          
        }
  }
  </script>
  
  <style lang="scss" scoped>
  .example {
        display: flex;
        flex-direction: column;
    
        .editor {
          height: 20rem;
          overflow: hidden;
        }
    
        .output {
          width: 100%;
          height: 10rem;
          margin: 0;
          border: 1px solid #ccc;
          overflow-y: auto;
          resize: vertical;
    
          &.ql-snow {
            border-top: none;
            height: 14rem;
          }
        }
      }
  
  </style>