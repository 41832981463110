<template>
  <span>
    <img
      :src="user ? user.avatar_url: null"
      :alt="user.name"
      width="90"
      height="90"
      :style="imgStyle"
      @click="show = true"
    />
    <form enctype="mulipart/form-data">
      <my-upload
        field="avatar"
        @crop-success="cropSuccess"
        @crop-upload-success="cropUploadSuccess"
        @crop-upload-fail="cropUploadFail"
        v-model="show"
        :width="200"
        :height="200"
        method="POST"
        :url="url"
        :params="params"
        :headers="headers"
        langType="fr"
        img-format="png"
      ></my-upload>
    </form>
  </span>
</template>



<script>
import myUpload from "vue-image-crop-upload";

export default {
  name: "update-photo",

  components: { myUpload },

  props: {
    user: Object
  },

  data() {
    return {
      show: false,
      params: { _method: 'PUT' },
      
      headers: { 
        'Authorization': 'Bearer ' + this.$auth.token },
    };
  },

  methods: {
    // vue crop upload

    toggleShow() {
      this.show = !this.show;
    },

    cropSuccess(imgDataUrl) {
      //console.log({ imgDataUrl, field });
       console.log('-------- crop success --------');
      this.imgDataUrl = imgDataUrl;
    },

    cropUploadSuccess(jsonData,) {
      
      this.$props.user = jsonData.user;
      this.$toast.success({
        title: 'Photo profil',
        message: 'Votre photo a bien été modifiée'
      })
      this.$auth.setUser(jsonData.user)
    },

    cropUploadFail() {
      this.$toast.error({
        title: 'Photo profil',
        message: 'Impossible de modifier l\'avatar'
      })
    },
  },

  computed: {
    imgStyle() {
      return {
        width: "150px",
        height: "150px",
        borderRadius: "150px",
        cursor: "pointer",
      };
    },

    url() {
      return `${this.$api.url_base}profile/change-avatar`
    },
  },
};
</script>