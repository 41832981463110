<template>
  <div>
    <!-- Jour(s) de disponibilité télémedecine -->
    <div class="availability_days mb-6">
      <card-title :title="'Jour(s) de disponibilité en téléconsultation'" :icon="'mdi-calendar-check'">
      </card-title>
      <horaire-form @saveHoraire="saveHoraire" :horaire="doctor.horaire_teleconsultation" v-if="doctor" />
    </div>
    <!-- Formulaire horaires -->
  </div>
</template>
  
<script>

import CardTitle from '../../../components/Home/utilities/cardTitle.vue';
import HoraireForm from '../../../components/horaireForm.vue';
export default {
  components: { CardTitle, HoraireForm },
  props: ['doctor'],
  data() {
    return {
      showFormDays: false
    }
  },
  methods: {
    saveHoraire(dayofWeeks) {
      const data = {
        horaire_teleconsultation: dayofWeeks
      }
      this.$http.put(`horaire/update/${this.doctor.id}`, data).then(({ data }) => {
        this.doctor.horaire_teleconsultation = data.horaire_teleconsultation
        this.$toast.success({
          title: 'Horaire téléconsultation',
          message: "Horaire téléconsultation modifié avec succès"
        })
      })

    }
  }


}
</script>
  
<style></style>