<template>
  <div v-if="medical_visit"> 
    <h2 class="font-5 my-6 text-center"> Visite médicale le {{ medical_visit.la_date }}</h2>
    <h3>Information du patient</h3>
    <patient-item 
        :auteur="medical_visit.proche ? 'proche' : 'patient'" 
        :patient="medical_visit.proche ? medical_visit.proche : medical_visit.patient" 
    />

    <data-forms :data="medical_visit.data"  />
    <footer-print :signature_url="doctor.signature_url" />

  </div>
</template>

<script>
import dataForms from '../../components/consultation/dataForms.vue'
import PatientItem from '../../components/consultation/patientItem.vue'
import FooterPrint from '../../components/Home/utilities/footerPrint.vue'
export default {
    props: ['doctor'],
  components: { dataForms, PatientItem, FooterPrint },
    data() {
        return {
            medical_visit_id: this.$route.params.medical_visit,
            medical_visit: null,
        }
    },
    async mounted() {
       this.getConsultation()
       
    },
    methods: {
        getConsultation() {
            this.$http.get(`print/medical_visit/${this.medical_visit_id}`).then(({data}) => {
                this.medical_visit = data
                setTimeout(() => {
                    window.print()
                }, 2000);
                
            })
            
        }
    }

}
</script>

<style scoped>

</style>