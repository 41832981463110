<template>
    <v-app id="inspire">
        <v-navigation-drawer v-model="drawer" :width="widthDrawer" app color="primary">
            <v-sheet color="primary" class="text-center pa-3" v-if="user">
                <v-avatar class="mt-1" color="grey darken-1" size="60">
                    <img :src="$auth.user.avatar_url" />
                </v-avatar>

                <div class="pa-2 white--text font-7"> {{ user.name }} </div>
            </v-sheet>

            <v-divider></v-divider>
            <div class="pa-3" v-if="isVisio">
                <v-card class="" height="350" elevation="0">
                    <admin-call :apiKey="visio.apiKey" :sessionId="visio.sessionId" :token="visio.token" :name="$auth.user.name"
                        @disconnect="disconnect" />
                </v-card>
            <v-divider></v-divider>
            </div>
           
            <v-list>
                <v-list-item v-for="[icon, text, link] in links" :key="icon" link class="white--text" :to="{ name: link }">
                    <v-list-item-icon>
                        <v-badge bordered overlap dot color="red" v-if="(link=='doctor.room') && room_new_patient">
                            <v-icon color="white" size="30">{{ icon }}</v-icon>
                        </v-badge>
                        <v-icon v-else color="white" size="30">{{ icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="subttile-2 font-weight-bold">{{ text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app flat>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title><router-link to="/" style="text-decoration: none">

                    <v-avatar size="50" color="white" class="px-2 hidden-sm-and-down">
                        <img src="../../../assets/logo_sahel.png" alt="logo-sahel-rdv" />
                    </v-avatar>
                    <span class="px-2 hidden-sm-and-down">SAHEL ASSISTANCE</span>
                    <v-avatar size="30" color="white" class="px-2 hidden-sm-and-up">
                        <img src="../../../assets/logo_sahel.png" alt="logo-sahel-rdv" />
                    </v-avatar>
                </router-link></v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-menu
                    auto
                    offset-y
                    bottom
                    v-if="$auth.check()"
                    max-width="300px"
                >
                    <template v-slot:activator="{ on, attrs }"> -->
                        <v-btn
                            v-if="user_profil =='patient' || user_profil == 'admin' || user_profil == 'doctor' || user_profil == 'intervenant'"
                            class="ml-2"
                            min-width="0"
                            text
                            link
                            :to="{name : user_profil +'.notify'}"
                        >
                            <div v-if="notifications.length">
                                <v-badge color="red" overlap>
                                    <span slot="badge">{{notifications.length}}</span>
                                    <v-icon size="30" >mdi-bell-outline </v-icon>
                                </v-badge>
                            </div>
                            <div v-else>
                                <v-icon size="30">mdi-bell-outline </v-icon>
                            </div>
                        </v-btn>
                   
            <v-btn class="white--text mr-3 hidden-sm-and-down" color="#025397" style="text-transform: none" small
                elevation="0" rounded link :to="{ name: 'medecins.rv', params: { type: 'availability_days'} }" v-if=" user && (user.is_patient)">Prendre rendez-vous</v-btn>
                <v-btn class="white--text mr-3 hidden-sm-and-down" color="#025397" style="text-transform: none" small
                elevation="0" rounded link :to="'/medecins/teleexpertise'" v-if=" user && (user.is_intervenant)">Prendre rendez-vous</v-btn>
            <v-btn class="white--text" color="#025397" style="text-transform: none" small @click="logout()" elevation="0"
                rounded>Se déconnecter</v-btn>
        </v-app-bar>

        <v-main>
            <div class="pa-md-6">
                <router-view />
            </div>
        </v-main>
    </v-app>
</template>
<script>
import AdminCall from '../../telemedecine/AdminCall.vue';
import { mapState, mapMutations, mapGetters } from 'vuex'
export default {
    components: { AdminCall },
    props: {
        links: {
            type: Array,
            required: true
        }
    },
    data: () => ({
        user_profil:'patient',
        drawer: null,
        authenticated: false,
        widthDrawer: '350px',
        user: null,
        room_new_patient: false,
        notifications: []
        
    }),
    watch: {
        $route: 'getNotifications'
    },
    computed: {
        ...mapState(['visio']),
        ...mapGetters(['isVisio'])
    },
    methods: {
        ...mapMutations(['setVisio']),
        logout() {
            this.$auth.logout();
        },
        disconnect() {
            
           this.$http.get('disconnect/visio/'+this.visio.sessionId, { params: {type: this.visio.type}}).then(() => {
                const visio = null;
                this.setVisio(visio)
           })
            
            
        },
        async getNotifications(){
            if(this.$route.name=="doctor.room" && this.notifications.length !=0) {
                await this.$http.get("read/all/notify",{ params:{type_notify: 'App\\Notifications\\RoomSubscriberNotify'}});
            }
            this.$http.get(`notifications`).then(({ data }) => {
              this.notifications = data.data; 
              this.verifyNewPatientInRoom(this.notifications);
            });
            
        },
        async verifyNewPatientInRoom(notifications) {
            const index = notifications.findIndex((notification) => notification.type === "App\\Notifications\\RoomSubscriberNotify");
            index >-1 ? this.room_new_patient = true : this.room_new_patient = false
        }
       
    },
    created() {

        // window.Echo.private('channel').listen('', (e) => {
        //     console.log(e)
        //     this.$toast.success({
        //         title: 'Notification',
        //         message: 'Nouvelle patient dans la salle attente'
        //     })

        // })
    },

    mounted() {
        if (this.$auth.check()) {
            this.authenticated = true;
            this.user = this.$auth.user;
            if(this.user.is_doctor) {
                this.user_profil = 'doctor' 
            } else if(this.user.is_admin) {
                this.user_profil = 'admin'
            } else if(this.user.is_secretaire) {
                this.user_profil = 'secretaire'
            }else if(this.user.is_intervenant) {
                this.user_profil = 'intervenant'
            } else {
                this.user_profil = 'patient'
            }
            
        }
        this.getNotifications()
    },
};
</script>