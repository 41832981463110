<template>
  <div>
    <v-row align="center" justify="center">
        <v-col cols="12" md="6" v-if="patient">
            <card-patient :patient="patient" />
        </v-col> 
    </v-row>

  </div>
</template>

<script>
import cardPatient from '../../components/patient/cardPatient.vue'
export default {
  components: { cardPatient },

    data() { 
        return {
            patient: null
        }
    },
    watch: {
        $route: 'showPatient'
    },
    created() {
        this.showPatient()
    },
    methods: {
        showPatient() {
            const id = this.$route.params.id
            this.$http.get(`auth/patient/${id}`).then(({ data }) => {
                this.patient = data.data
            })
        }
    }

}
</script>

<style>

</style>