<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    class="hidden-sm-and-up"
    temporary
    width="100%"
    :color="$auth.check()? 'primary' : 'white'"
  >
   <!--  <div class="d-flex pa-2">
      <v-spacer />
      <v-btn color="red" @click="drawer = false" small class="white--text mr-6" outlined>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-divider></v-divider>
    <div class="d-flex justify-space-between" v-if="!authenticated">
      <v-btn
        style="text-transform: none; text-decoration: none; margin: 0px 10px 10px 10px;"
        small
        outlined
        color="primary"
        @click="showDialogConnect"
      >
        s'inscrire | se connecter
      </v-btn>
      <v-btn
        class=""
        style="text-transform: none; margin: 0px 10px 10px 10px"
        small
        outlined
        color="primary"
      >
        Besoin d'aide ?
      </v-btn>
      
    </div>
    <v-divider /> -->
    <v-sheet color="#025397" class="text-center pa-3" v-if="user">
      <v-avatar class="mt-1" color="grey darken-1" size="60">
        <img :src="user.avatar_url" />
      </v-avatar>

      <div class="pa-2 white--text font-7">{{ user.name }}</div>
    </v-sheet>

    <v-divider></v-divider>
    <v-list v-if="authenticated">
      <v-list-item
        v-for="[icon, text, link] in links"
        :key="icon"
        link
        class="white--text"
        :to="{ name: link }"
      >
        <v-list-item-icon>
          <v-icon color="white">{{ icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="subttile-2 font-weight-bold">{{
            text
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      authenticated: false,
      user: null,
      links: [
        ["mdi-account-circle", "Mon compte", "patient.home"],
        [
          "mdi-calendar-clock",
          "Gérer mes rendez-vous",
          "compte.mes-rendez-vous",
        ],
        ["mdi-account-supervisor", "Mes proches", "compte.mes-proches"],
        ["mdi-send", "Envoyer un fichier", "compte.documents"],
        // ["mdi-bell", "Notifications", "compte.notify"],
      ],
    };
  },
  computed: {
    drawer: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      },
    },
  },
  methods: {
    logout() {
      this.$auth.logout();
    },
    showDialogConnect() {
        this.$emit('showDialogConnect')
    }
  },
  mounted() {
    if (this.$auth.check()) {
      this.authenticated = true;
      this.user = this.$auth.user;
    }
  },
};
</script>

<style scoped>
.close-btn {
  background-color: #025397;
}
.btn-login {
    padding: 20px;
    background-color: #025397;
}
</style>