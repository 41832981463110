<template>
  <v-footer>
    <div class="footer">
      <v-row>
        <v-col cols="12" md="5">
          <div class="contact">
            <div>
              <v-avatar size="40" color="white">
                <img src="../../../assets/logo_sahel.png" alt="logo" />
              </v-avatar>
              SAHEL ASSISTANCE
            </div>
            <div class="pa-3">
              <v-icon>mdi-phone</v-icon> Téléphone : +221 33 824 26 71
            </div>
            <v-divider />
            <!-- <div class="pa-3">
              <v-icon>mdi-whatsapp</v-icon> WhatsApp : +221 33 824 26 71
            </div> -->
            <v-divider />
            <div class="pa-3">
              <v-icon>mdi-email-outline</v-icon> Email :
              info@sahelassistance.org
            </div>
            <v-divider />
            <div class="pa-3">
              <v-icon>mdi-map-marker-outline</v-icon> Adresse : 3, avenue des Ambassadeurs - Fann Résidence 
                 Dakar – Sénégal
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="7">
          <div class="font-7">Trouver un spécialiste <v-icon class="mt-1">mdi-arrow-down-right</v-icon></div>
            <div class="d-flex align-content-start flex-wrap">
                <router-link class="pa-2" style="text-decoration: none" :to=" { name: 'medecins.specialite',query: { department: department.slug } }" v-for="department in departments" :key="department.id">{{ department.name }}</router-link>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="condiction-utilisation text-center white--text">
      <router-link to="/conditions-generales" style="text-decoration: none; color: white">
        Conditions générales d'utilisation
      </router-link>
      <!-- <a href="/conditions-generales" >Conditions générales d'utilisation</a> -->
      <div>Copyright © 2023 sahel assistance - Tous droits réservés</div>

    </div>
  </v-footer>
</template>
<script>
export default {
    data(){
        return {
            departments: []
        }
    },
    created(){
        this.fetchMedecins()
    },
    methods: {
        fetchMedecins: function () {
      this.$http.get("auth/departments").then(({ data }) => {
        this.departments = data.data;
      });
    },
    }
};
</script>
<style scoped>
.v-footer {
  padding: 0px !important;
}
.footer {
  padding: 5%;
}
.contacter {
  display: flex;
  font-size: 14px;
  padding: 10px 0px 10px 0px;
}
.list-department {
    padding-left: 10px;
}
.condiction-utilisation {
    padding: 20px;
    background: #025397;
    width: 100%;
}
</style>
