<template>
    <v-app class="printer" v-if="doctor">
        <!-- <div class="font-5 text-center font-weight-bold mb-6">{{ doctor.cabinet_name }}</div> -->
        <div class="d-flex justify-space-between">
            <div class="avatar">
            <v-avatar
                size="90"
                color="white"
                v-if="doctor.logo_cabinet"
            >
                <img :src="doctor.logo_cabinet_url" alt="logo">
            </v-avatar>
        </div>
        <div class="font-weight-bold" v-if="this.$auth.user.is_doctor">
            <div> 
                <div class="font-7">{{doctor.cabinet_name}}</div>
                <div> <v-icon size="20" color="black">mdi-map-marker</v-icon>{{doctor.address_cabinet}}</div>
                <div> <v-icon size="20" color="black">mdi-email</v-icon> {{  doctor.user.email}}</div>
                <div > <v-icon size="20" color="black">mdi-phone</v-icon> {{doctor.tel_port}} </div>
            </div>
            
            
        </div>
        </div>
       
        <v-main>
            <router-view :doctor="doctor"></router-view>
        </v-main>
        <!-- <v-footer class="d-flex justify-end my-12">
            <div class="font-6 mb-12">
                <div class="text-decoration-underline">Signature et cachet</div>
            </div>

        </v-footer> -->
    </v-app>
  

</template>

<script>
export default {

    data() {
        return {
            doctor_id: this.$auth.user.doctor_id,
            doctor: null,
        }
    },
    mounted(){
        this.$http.get(`auth/doctor/${this.doctor_id}`).then(({ data }) => {
            this.doctor = data.data
        })
    },
    methods: {
        
    }

}
</script>

<style scoped>
    .printer {
        font-size: 10px;
    }

</style>