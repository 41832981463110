<template>
  <span ref="animationSpan">{{ displayValue }}</span>
</template>

<script>
import gsap from "gsap";

export default {
  name: "AnimationNumber",
  props: ["value"],
  data() {
    return {
      displayValue: 0,
      tweenValue: 0,
      observer: null,
    };
  },
  mounted() {
    // Observer l'élément actuel
    this.observer = new IntersectionObserver(this.handleIntersection, {
      threshold: 0.9, // Ajustez le seuil selon vos besoins
    });

    // Observer l'élément du composant actuel
    this.observer.observe(this.$refs.animationSpan);
  },
  beforeDestroy() {
    // Assurez-vous de cesser d'observer lorsque le composant est détruit
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    handleIntersection(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // L'élément est maintenant visible, commencez l'animation
          this.startCounterAnimation();
          // Cessez d'observer une fois que l'animation a commencé (si nécessaire)
          this.observer.disconnect();
        }
      });
    },
    startCounterAnimation() {
      gsap.to(this, {
        duration: 3,
        ease: "power1.inOut",
        tweenValue: this.value,
        onUpdate: () => {
          this.displayValue = Math.ceil(this.tweenValue);
        },
      });
    },
  },
};
</script>

<style>
</style>
