<template>
  <div>
    <v-sheet class="">
          <div class="pa-6">
            <div class="text-center">
              <update-photo :user="secretaire.user" />
            </div>
            <div class="font-weight-bold"> 
              <div class="d-flex justify-space-between">
                <div>Prénom:</div>
                <div>{{ secretaire.first_name }}</div>
              </div>
            </div>
            <v-divider></v-divider>
            <div class="font-weight-bold mt-3"> 
              <div class="d-flex justify-space-between">
                <div>Nom:</div>
                <div>{{ secretaire.last_name }}</div>
              </div>
            </div>
            <v-divider></v-divider>
            <div class="font-weight-bold mt-3"> 
              <div class="d-flex justify-space-between">
                <div>Email:</div>
                <div>{{ secretaire.user.email }}</div>
              </div>
            </div>
            <v-divider></v-divider>
            <div class="font-weight-bold mt-3"> 
              <div class="d-flex justify-space-between">
                <div>N° téléphone:</div>
                <div>{{ secretaire.user.phone }}</div>
              </div>
            </div>
            
            <div class="d-flex justify-center mt-6">
              <div class="mr-2"> 
                <v-btn 
                    small 
                    outlined 
                    class="text-capitalize" 
                    color="info"
                    @click="updated"
                > 
                    <v-icon >mdi-account-edit</v-icon> Modifier
                </v-btn>
              </div>
              <div class="mr-2" v-if="$auth.user.is_doctor"> 
                <v-btn 
                    small 
                    outlined 
                    class="text-capitalize" 
                    :color="secretaire.user.actif ? 'warning' : 'success'"
                    @click="changeStatus"
                > 
                    <v-icon>
                        {{ secretaire.user.actif ? 'mdi-account-remove' : 'mdi-account-check' }}
                    </v-icon> 
                    {{ secretaire.user.actif ? 'Desactiver' : 'Activer' }}
                </v-btn>
              </div>
              <!-- <div class="mr-2" v-if="$auth.user.is_doctor"> 
                <v-btn 
                    small 
                    outlined 
                    class="text-capitalize" 
                    color="error"
                    @click="dialogDelete=true"
                > 
                    <v-icon>mdi-delete</v-icon> Supprimer
                </v-btn>
              </div> -->
            </div>
            
            
          </div>
        </v-sheet>
        <form-secretaire v-if="showForm"  v-model="showForm" :secretaire="secretaire" @updatedSecretaire="updatedSecretaire"/> 
        <delete-dialog 
            v-if="dialogDelete"  
            v-model="dialogDelete" 
            :message="'Êtes -vous sûr de vouloir supprimer le compte secrétaire'"
            :title="'Suppression Compte'"
            @confirmDelete="deleteSecretaire"
        />
  </div>
</template>

<script>
import DeleteDialog from '../Home/utilities/DeleteDialog.vue'
import UpdatePhoto from '../Home/utilities/UpdatePhoto.vue'
import FormSecretaire from './formSecretaire.vue'
export default {
  components: { UpdatePhoto, FormSecretaire, DeleteDialog },
  props: { secretaire: Object },
  data() {
    return {
        showForm: false,
        dialogDelete: false,
    }
  },
  methods: {
    changeStatus() {
        this.$http.get(`user/change-status/${this.secretaire.user_id}`).then(() => {
          this.secretaire.user.actif = !this.secretaire.user.actif;
            this.$toast.success({
                title: 'Statut Secretaire',
                message: `Son compte est ${this.secretaire.user.actif ? 'désactivé' : 'activé'} avec succès !`
            })
            
        }).catch(() => {
            this.$toast.error({
                title: 'Statut Secretaire',
                message: `Impossible de changer statut une erreur est produite`
            })
        })
    },
    updated() {
        this.secretaire.email = this.secretaire.user.email;
        this.secretaire.phone = this.secretaire.user.phone;
        this.showForm = true
    },
    updatedSecretaire(secretaire) {
        this.secretaire = secretaire
    },
    deleteSecretaire() {
        this.$http.delete(`secretaire/${this.secretaire.id}`).then(() => {
            this.$toast.success({
                title: 'Supprimer Secretaire',
                message: `Compte supprimé avec succès !`
            })
            this.$emit('compteDeleted')
        }).catch(() => {
            this.$toast.error({
                title: 'Suppression',
                message: `Impossible de supprimer le compte une erreur est produite`
            })
        })
    }
  }

}
</script>

<style>

</style>