<template>
    <v-dialog v-model="show" persistent width="450px">
        <v-card class="py-3">
            <v-card-title>
                <div class="font-6 font-weight-bold">Dates d'indisponibilité</div>
        <v-spacer></v-spacer>
        <v-btn  icon color="error" @click="show=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="mt-3">
                <template>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date_debut"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="date_debut" label="Date début" outlined  readonly
                                        v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="date_debut" no-title scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="menu = false">
                                        Annuler
                                    </v-btn>
                                    <v-btn text color="primary" @click="$refs.menu.save(date_debut)">
                                        Valider
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="12" sm="12" md="12">
                            <v-dialog ref="dialog" v-model="modal" :return-value.sync="date_fin" persistent width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="date_fin" outlined label="Date fin"
                                        readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="date_fin" scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="modal = false">
                                        Annuler
                                    </v-btn>
                                    <v-btn text color="primary" @click="$refs.dialog.save(date_fin)">
                                        Valider
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-col>
                       
                    </v-row>
                    <v-row>
                        <v-btn color="primary" block style="text-transform: none;" elevation="0" @click="save" :loading="loading">Enregistrer</v-btn>
                    </v-row>
                </template>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true
        },
        unavailability_date_start: {
            type: String,
            default: () => {
                (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            }
        },
        unavailability_date_end: {
            type: String,
            default: () => {
                (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            }
        },
        doctor_id: {
            type: String,
            required: true
        },
    },
    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                return this.$emit('input', value)
            }
        }
    },
    data: () => ({
     // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      loading: false,
      date_debut: '',
      date_fin: '',
      errors: {}
    }),
    mounted() {
        this.date_debut = this.unavailability_date_start
        this.date_fin = this.unavailability_date_end
    },
    methods: {
        save() {
            const data = 
            { 
                unavailability_date_start: this.date_debut, 
                unavailability_date_end: this.date_fin
            }
            this.$http
            .put(`unavailability_days/${this.doctor_id}`, data
            )
            .then(() => {
                this.$emit('saveUnavailabilityDays', data);
                this.$toast.success({
                    title: 'Modification dates',
                    message: 'Les modifications ont été bien prises en charges'
                })
                this.loading = false
                this.show = false
            }).catch((error) => {
                this.loading = false
                this.errors = error.response.data.errors
            }).finally(()=> this.loading = false)
        }
    }
   

}
</script>

<style></style>