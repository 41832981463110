<template>
    <div>
        <div class="patient">
            <v-row>
                <v-col>
                    <div class="d-flex justify-space-between py-3">
                        <div class="prenom">Prénom</div>
                        <div class="font-weight-bold">{{ patient.first_name }}</div>
                    </div>
                    <v-divider></v-divider>
                    <div class="d-flex justify-space-between py-3">
                        <div class="prenom">Nom</div>
                        <div class="font-weight-bold">{{ patient.last_name }}</div>
                    </div>
                    <v-divider></v-divider>
                    <div class="d-flex justify-space-between py-3">
                        <div class="prenom">Sexe</div>
                        <div class="font-weight-bold">{{ patient.sexe }}</div>
                    </div>
                    <v-divider></v-divider>
                    <div class="d-flex justify-space-between py-3">
                        <div class="prenom">Date de Naissance</div>
                        <div class="font-weight-bold">{{ patient.date_naissance }} ( {{ patient.AGE }})</div>
                    </div>
                    <v-divider></v-divider>
                </v-col>
                <v-col>
                    <div class="d-flex justify-space-between py-3">
                        <div class="prenom">Lieu de Naissance</div>
                        <div class="font-weight-bold">{{ patient.lieu_naissance }}</div>
                    </div>

                    <v-divider></v-divider>
                    <div class="d-flex justify-space-between py-3">
                        <div class="prenom">N° telephone</div>
                        <div class="font-weight-bold">{{ auteur == 'patient' ? patient.tel_port : patient.phone }}</div>
                    </div>
                    <v-divider></v-divider>
                    <div class="d-flex justify-space-between py-3">
                        <div class="prenom">Adresse</div>
                        <div class="font-weight-bold">{{ patient.address }}</div>
                    </div>
                    <v-divider></v-divider>
                    <div class="d-flex justify-space-between py-3" v-if="auteur == 'patient'">
                        <div class="prenom">Fonction</div>
                        <div class="font-weight-bold">{{ patient.post }}</div>
                    </div>
                    <v-divider></v-divider>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        auteur: String,
        patient: Object
    }
}
</script>

<style></style>