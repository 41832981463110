<template>
    <div>
        <h2 class="mb-3">Nouvelle Consultation</h2>
        <v-sheet
            class="pa-6 mb-6"
        >
        <div class="font-6 font-weight-bold mb-3">
            Patient
        </div>
        <v-divider></v-divider>
        <div class="info-patient" v-if="patient">
            <div class="font-3 font-weight-bold">{{ patient.name }}</div>
            <!-- <div class="text-center">{{ patient.age }}</div> -->
            <div class="d-flex">
                <div class="">{{ auteur === 'proche' ? patient.phone : patient.tel_port }}</div>
                <div class=""> / {{ patient.address }}</div>
            </div>
        </div>
        </v-sheet>
        <v-sheet class="pa-6">
            <div class="font-6 font-weight-bold">
            Consultation
        </div>
        </v-sheet>
        
        <v-divider></v-divider>
        <form-consultation :forms="forms" :loading="loading" @formSaved="saveForm" @cancelForm="cancelForm"/>

    </div>
</template>
<script>
import formConsultation from '../../../components/consultation/formConsultation.vue'

export default {
  components: { formConsultation },
    data() {
        return {
            auteur: this.$route.params.auteur,
            patient_id: this.$route.params.patient,
            doctor_id: null,
            patient: null,
            loading: false,
            forms: []
        }
    },
    mounted() {
        if(this.$auth.user.doctor_id) {
            this.getPatient(),
            this.doctor_id = this.$auth.user.doctor_id
        }
        this.getFormsConsultations()
    },
    watch: {
        $route: 'getPatient'
    },
    methods: {
        getPatient() {
            this.$http.get(`${this.auteur}/${this.patient_id}`).then(({ data }) => {
                this.patient = data.data
            }).catch(({error}) => {
                this.$toast({
                    title: 'Erreur',
                    message: error.response.error
                })      
            })
        },
        getFormsConsultations() {
            this.$http.get(`consultation_forms/${this.$auth.user.id}`).then(({ data }) => {
                this.forms = data.data
            })
        },
        saveForm(forms) {
            
            this.loading = true;
            const data = {
                doctor_id: this.doctor_id,
                patient_id: this.auteur === 'patient' ? this.patient.id : this.patient.patient_id,
                proche_id: this.auteur === 'proche' ? this.patient.id : null,
                intervenant_id: this.auteur == 'proche' ? this.patient.intervenant_id : null,
                data: forms
            }

            this.$http.post('consultation', data).then( (response )=>{
                
                this.$toast.success({
                        title: 'Nouvelle Consultation',
                        message: 'Nouvelle consultation a été créée avec succès !'
                    })
                this.$router.push({ name: 'doctor.consultation.view', params: {consultation: response.data.id}})
            }).catch((error) => {
                
                if(error.response.data.abonnement) {
                    this.$toast.info({
                        title: 'Abonnement',
                        message: "Abonnez-vous pour pouvoir utiliser ces resources"
                    })
                    const timer = setInterval(() => {
                        this.$router.push('/abonnement')
                        clearInterval(timer)
                    }, 3000);
                }
                if(error.response.error.message) {
                    this.$toast.error({
                        title: 'Nouvelle Consultation',
                        message: error.response.error.message
                    })
                }
            }).finally(()=> this.loading = false)
        },
        cancelForm() {
            this.$router.go(-1)
        }
    }
}
</script>