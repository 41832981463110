<template>
    <div class="repos-medical">
        <v-divider></v-divider>
        <v-row class="py-3">
            <v-col cols="12" md="3">
                <div>{{ repos_medical.number_days }} jours</div>
            </v-col>
            <v-col cols="12" md="3">
                <div>{{ repos_medical.date_reprise_prolongation ? repos_medical.date_reprise_prolongation_formated : '----' }}</div>
            </v-col>
            <v-col cols="12" md="4">
                <div>{{ repos_medical.reprised }}</div>
            </v-col>
            <v-col cols="12" md="2">
                <div class="d-flex justify-end" >
                    <v-btn v-if="is_from_me" icon class="mx-2" color="#025397" @click="showForm = true" outlined small>
                        <v-icon size="20">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn v-if="$auth.user.is_secretaire || is_from_me" icon class="mr-2" color="warning" outlined small @click="printReposMedical">
                        <v-icon size="20">mdi-printer</v-icon>
                    </v-btn>
                    <v-btn v-if="is_from_me" icon class="" color="error" @click="dialogDelete = true" outlined small>
                        <v-icon size="20">mdi-delete</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <form-repos-medical v-if="showForm" v-model="showForm" :repos_medical="repos_medical"
            @reposMedicalUpdated="reposMedicalUpdated" />
        <delete-dialog v-if="dialogDelete" v-model="dialogDelete"
            :message="'Êtes-vous sur de vouloir supprimer ce repos médical'" :title="'Suppression repos médical'"
            @confirmDelete="reposMedicalDelete" />
    </div>
</template>
  
<script>
import DeleteDialog from '../Home/utilities/DeleteDialog.vue';
import formReposMedical from './formReposMedical.vue';
export default {
    components: { formReposMedical, DeleteDialog },
    props: {
        repos_medical: Object,
        is_from_me: Boolean
    },
    data() {
        return {
            showForm: false,
            dialogDelete: false
        }
    },
    methods: {
        reposMedicalDelete() {
            this.$http.delete(`repos_medical/${this.repos_medical.id}`).then(() => {
                this.$toast.success({
                    title: 'Repos medical',
                    message: 'Repos medical a été supprimé avec succès !'
                })
                this.$emit('reposMedicalDeleted', this.repos_medical)
            });

        },
        reposMedicalUpdated(repos_medical) {
            this.repos_medical = repos_medical
        },
        printReposMedical() {
            window.open(`/printer/repos-medical/${this.repos_medical.id}`, 'printWindow', 'height=800,width=900, align: center')
        }
    }
}
</script>
  
<style></style>