<template>
  <v-app>
    <navbar />
    <v-container>
      <div class="reset-password">
          <v-card width="500" min-height="400">
            <v-card-title class="font-6 font-weight-bold">Modifier votre mot de passe</v-card-title>
          <v-divider></v-divider>
            <v-card-text>
              <v-alert type="success" dense v-if="successMsg" outlined>{{ successMsg }} </v-alert>
                <v-alert type="error" dense v-if="errorMsg" dismissible outlined>{{ errorMsg }} </v-alert>
              <div class="font-7 font-weight-bold py-3">Mot de passe</div>
              <v-text-field
                v-model="password"
                name="password"
                placeholder="Veuiller entrer votre mot de passe"
                :error-messages="errors.password"
                outlined
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
              ></v-text-field>
              <div class="font-7 font-weight-bold py-3">
                Confirmer votre mot passe
              </div>
              <v-text-field
                v-model="confirm_password"
                name="confirm_password"
                placeholder="Veuiller confirmer votre mot de passe"
                :error-messages="errors.password_confirmation"
                outlined
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                @click:append="show2 = !show2"
              ></v-text-field>
              <v-btn 
                color="primary" 
                @click="send" 
                :loading="loading"
                style="text-transform:none"
                block
                >Réinitialiser mon mot de passe</v-btn
              >
            </v-card-text>
          </v-card>
        </div>
    </v-container>
  </v-app>
</template>

<script>
import navbar from '../components/Home/utilities/navbar.vue';
export default {
  components: { navbar },
  data() {
    return {
      show1: false,
      show2: false,
      errors: {},
      loading: false,
      successMsg: null,
      errorMsg: null,
      password: "",
      confirm_password: "",
    };
  },
  methods: {
    send() {
      this.errors = {};
      this.loading = true;
      const data = {
        password: this.password,
        password_confirmation: this.confirm_password,
        token: this.$route.params.token,
      };
      this.$http
        .post("auth/reset/now", data)
        .then((res) => {
          this.loading = false;
          this.successMsg = res.data.msg;
          //this.$auth.login(res.data.token, res.data.user);
          if (res.data.status === "success") {
              this.$toast.success({
                title: 'Mot de passe',
                message: 'Votre mot de passe et modifié avec success'
              })
                setTimeout(() => {
                  this.$router.push({name: 'home'})
              }, 3000)
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else {
            this.errorMsg = error.response.data.msg;
          }
        });
    },
  },
};
</script>

<style scoped>
.reset-password {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>