<template>
  <div>
    <form>
        <v-sheet v-for="form in forms" :key="form.id" class="pa-6">
            <div class="font-6 font-weight-bold mb-3">
                {{ form.name }}
            </div>
            <v-row>
                <v-col 
                    cols="12" md="6"
                    v-for="field in form.fields" :key="field.id"
                >
                    <div class="mb-1">{{ field.label }}</div>
                    <v-textarea
                        v-model="field.value"
                        v-if="field.type == 'textarea'"
                        outlined
                        dense
                        :placeholder="field.label"
                        hide-details
                    />
                    <v-checkbox
                        v-else-if="field.type === 'checkbox'"
                        v-for="(box,i) in field.options"
                        :key="i"
                        v-model="field.value"
                        :value="box"
                        :label="box"
                        :placeholder="field.label"
                        multiple
                        dense
                        hide-details
                    />
                    <v-radio-group v-else-if="field.type === 'radio'"
                    v-model="field.value">
                        <v-radio
                        v-for="(item,j) in field.options"
                        :key="j"
                        :label="item"
                        :value="item"
                        dense
                        hide-details
                    />
                    </v-radio-group>
            
            <v-text-field
                v-else
                v-model="field.value"
                :placeholder="field.label"
                outlined
                :type="field.type"
                dense
                hide-details
            ></v-text-field>
                </v-col>
            </v-row>
        </v-sheet>
        <v-sheet class="pa-6">
        <div class="d-flex">
            <v-btn 
                style="text-transform: none;" 
                color="primary" elevation="0"  
                class="mr-6" 
                @click="save" 
                :loading="loading"
            >
                Enregistrer
            </v-btn>
            <v-btn style="text-transform: none;" color="error" outlined @click="$router.go(-1)"> Annuler</v-btn>
        </div>
        </v-sheet>
    </form>
  </div>
</template>

<script>

    export default {

        props: { 
            forms: {
                type: Array,
                required: true
            },
            loading: {
                type: Boolean,
                default() {
                    return false
                }
            },
        },
        methods: {
            save() {
                
                this.$emit('formSaved', this.forms)
            },
            
        }
    }
</script>

<style scoped>
.v-input--selection-controls {
    margin-top: 0px; 
     padding-top: 0px;
}

</style>