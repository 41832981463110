<template>
  <div class="d-flex justify-end mt-12">
    <div>
        <div class=" font-7 text-decoration-underline">Signature et cachet</div>
        <figure class="image is-110x110 has-text-left">
        <img :src="signature_url" alt="" title="signature">
    </figure>
    </div>
    
  </div>
</template>
<script>
export default {
    props: {
      signature_url: String
    }
}
</script>
