<template>
  <div class="formulaire">
    <v-row align-content="space-between">
        <v-col>
            <div>
                <div class="font-6 font-weight-bold mb-6">Ajouter un nouveau formulaire</div>
                <v-form>
                    <div class="font-7 font-weight-bold mb-2">Nom du formulaire</div>
                    <v-text-field
                        v-model="form.name"
                        name="name"
                        placeholder="Saisir le nom du formulaire"
                        id="nam"
                        outlined
                        :error-messages="errors.name"
                    ></v-text-field>
                    <div class="d-flex">
                        <v-btn style="text-transform: none;" color="primary" elevation="0" class="mr-3" @click="save" :loading="loading">{{ title }}</v-btn>
                        <v-btn style="text-transform: none;" color="error" outlined @click="clear"> Annuler</v-btn>
                    </div>
                </v-form>
            </div>
        </v-col>
        <v-col>
            <div class="font-6 font-weight-bold mb-6">Formulaires</div>
            <item-form 
                v-for="formulaire in forms"
                :key="formulaire.id"
                :form="formulaire"
                @updatedForm="updatedForm"
                @deletedForm="deletedForm"
            />
        </v-col>
    </v-row>
  </div>
</template>

<script>
import ItemForm from './ItemForm.vue';
export default {
  components: { ItemForm },
    data() {
        return {
            form:{
                id: null,
                name: ""
            }, 
            title: 'Ajouter',
            forms: [],
            errors: {},
            loading: false
        }
    },
   
    mounted() {
        console.log(this.$auth.user.id)
        this.getForms();
    },

    methods: {
        getForms() {
            this.$http.get('forms').then(({ data }) => {
                this.forms = data.data;
            })
        },
        save() {
            this.loading = true
            if(this.form.id) {
                this.$http.put(`form/${this.form.id}`, { name: this.form.name }).then(({ data }) => {
                    const index = this.forms.findIndex((form) => form.id == this.form.id)
                    this.forms[index].name = data.data.name
                    this.$toast.success({
                        title: 'Modification formulaire',
                        message: 'Votre formulaire a été modifié avec succès !'
                    })
                    this.clear()
                   
                }).catch((error) => {
                    this.errors = error.response.data.errors
                }).finally( () => this.loading = false)
            } else {
                this.$http.post('form', { name: this.form.name, user_id: this.$auth.user.is_doctor ?  this.$auth.user.id : null }).then(({ data }) => {
                    this.forms.unshift(data.data)
                    this.$toast.success({
                        title: 'Création formulaire',
                        message: 'Votre formulaire a été crée avec succès !'
                    })
                    this.clear()
                }).catch((error) => {
                    this.errors = error.response.data.errors
                }).finally( () => this.loading = false)
            }
        },
        clear() {
            this.title = 'Ajouter'
            this.form = { id: null, name: ''}
        },
        updatedForm(data) {
            this.title = 'Modifier'
            this.form = data
        },
        deletedForm(data) {
            this.forms = this.forms.filter((form) => form.id != data.id);
        }
    }
}
</script>

<style>

</style>