<template>
  <v-app>
    <navbar />
        <div class="container">
            <div> 
                <div class="no-auth" v-if="!is_auth">
                    Veuillez vous connectez pour faire la téléconsultation.
                </div>
                <div class="" v-else>
                    <div v-if="appointment">
                        <div v-if="!is_later">
                        <h1 class="mb-6">Votre rendez-vous consultation vidéo est prévu dans</h1>
                            <CompteurRV  :dateRendezVous="appointment.date"  @is_time="isTime"/>
                        </div>
                        <div v-else>
                            <h1>Votre rendez-vous c'etait le {{ appointment.formated_date }}</h1>
                        </div>
                    </div>
                    <div v-else>
                        <h1>Verifier si votre rendez-vous n'est pas été annulé</h1>
                    </div>
                    
                    
                </div>
            </div>
        </div>
  </v-app>
</template>

<script>
import CompteurRV from '../components/Home/utilities/compteurRV.vue';
import navbar from '../components/Home/utilities/navbar.vue'

export default {
  components: { navbar, CompteurRV},
    data(){
        return {
            sessionId: this.$route.params.sessionId,
            appointment: null,
            loading: false,
            is_later: false,
            is_auth: this.$auth.check()
        }
    },
    mounted() {
        if(this.is_auth) {
            this.getAppointment();
        }
        
    },
    watch: {
        $route: 'getAppointment'
    },
    methods: {
        getAppointment(){
            this.loading = true
            this.$http.get(`appointment/telemedecine/${this.sessionId}`).then(({ data }) => {
                this.appointment = data.data;
            }).catch((error) => {
                console.log(error)
            }).finally(() => this.loading = false);
        },
        isTime(value) {
            if(value == true) {
                this.$router.push({name: 'join.meet', params: { sessionId:this.sessionId, name: this.$auth.user.name }})
            } else {
                this.is_later = true
            }
            //console.log("c'est l'heure du rendez-vous")
        }

    }

}
</script>

<style scoped>
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90%;
    }
</style>