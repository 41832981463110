<template>
  <router-view></router-view>
</template>

<script>
import axios from 'axios'
//import * as PusherPushNotifications from "@pusher/push-notifications-web";
export default {
 created() {
  
  if(this.$auth.check()) {
    const lastRefreshTimestamp = localStorage.getItem('token_last_refresh');
if (lastRefreshTimestamp) {
  const currentTime = Date.now();
  const refreshInterval = 58 * 60 * 1000; // 30 minutes en millisecondes
  const timeElapsed = currentTime - lastRefreshTimestamp;
  
  if (timeElapsed < refreshInterval) {
    const timeRemaining = refreshInterval - timeElapsed;
    setTimeout(() => {
    this.refreshToken()
    }, timeRemaining);
  }

 }
  }
 
},
methods: {
  refreshToken() {
  // Effectuez la requête API pour rafraîchir l'access_token
  this.$http.post('/renew-token')
    .then((response) => {
      const token = response.data.token
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      // Stockez le nouvel access_token dans le localStorage
      window.localStorage.setItem('token', token);
      // Stockez la date actuelle pour le suivi du rafraîchissement
      window.localStorage.setItem('token_last_refresh', Date.now());
    })
    .catch(() => {
     
    });
}
}
//   mounted() {
//     const beamsClient = new PusherPushNotifications.Client({
//   instanceId: process.env.VUE_APP_INSTANCE_ID,
// });
// beamsClient
//   .start()
//   .then((beamsClient) => beamsClient.getDeviceId())
//   .then((deviceId) =>
//     console.log("Successfully registered with Beams. Device ID:", deviceId)
//   )
//   .then(() => beamsClient.addDeviceInterest("hello"))
//   .then(() => beamsClient.getDeviceInterests())
//   .then((interests) => console.log("Current interests:", interests))
//   .catch(console.error);
    // const beamsClient = new PusherPushNotifications.Client({
    //   instanceId: process.env.VUE_APP_INSTANCE_ID,
    // });

    // beamsClient
    //   .start()
    //   .then((beamsClient) => beamsClient.getDeviceId())
    //   .then((deviceId) =>
    //     console.log("Successfully registered with Beams. Device ID:", deviceId)
    //   )
    //   .catch(console.error);
    //}
}
</script>

<style>

</style>