<template>
  <v-app class="bg-1">
    <navbar />
    <search-medecins />
    <div>
      <loading-page v-if="loading"></loading-page>
      <medecins-specialite :doctors="doctors" v-else />
    </div>
  </v-app>
</template>

<script>
import MedecinsSpecialite from "../components/Home/medecins.specialite.vue";
import LoadingPage from '../components/Home/utilities/LoadingPage.vue';
//import FormSearchMedecin from "../components/Home/utilities/form.search.medecin.vue";
import navbar from "../components/Home/utilities/navbar.vue";
import SearchMedecins from './search.medecins.vue';
export default {
  components: { MedecinsSpecialite,/* FormSearchMedecin,*/ navbar, SearchMedecins, LoadingPage },
  data() {
    return {
      doctors: [],
      loading: false
      
      
    };
  },
  watch: {
    $route: "init",
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const specialite= this.$route.query.department
      const lieu= this.$route.query.lieu
      const type_rdv = this.$route.query.type_rdv
      this.loading = true
      this.$http.get(`auth/department`,{ params: {lieu: lieu, specialite: specialite, type_rdv: type_rdv }}).then(({ data }) => {
        this.doctors = data.data;
        this.loading = false
      }).catch(() => {
        this.$toast.error({
          title: 'Erreur',
          message: "Impossible de récupérer la liste des médecins"
        })
      });
    },
  },
};
</script>

<style scoped>
/* $ body-background-color: #f5f8fa;  */


@media screen and (max-width: 500px) {
  /*  .form-search {
    margin: 20px 0 20px 0;
  } */
}
</style>