<template>
  <v-dialog v-model="show" persistent width="500px">
      <v-card>
        <v-card-title>
          <div class="">Conditions de paiement de consultation</div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <div class="editor" v-html="content" />
          <div class="d-flex justify-end">
          <v-btn color="error" outlined style="text-transform: none; margin-right: 20px;" @click="show=false">Annuler</v-btn>
            <v-btn color="primary" outlined style="text-transform: none;" @click="confirmer">confirmer</v-btn>
        </div>
        </v-card-text>
        
      </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true
        },
        content: String
    },
    computed: {
        show: {
            get() {
            return this.value;
        },
        set(value) {
            return this.$emit('input', value)
        },
        },
    },
    methods: {
        confirmer() {
            this.show = false
            this.$emit('confirmer')
        }
    }
}
</script>

<style>

</style>