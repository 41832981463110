<template>
    <div>
    <div class="infos" v-if="doctor">
        <div> 
            <v-sheet class="pa-6">
                <v-row>
                    <v-col cols="12" md="4">
                        <div class="img-card">
                            <img src="../../assets/icons/liste-de-choses-a-faire.png" alt="image"  class="img-icon"/>
                        </div>
                        <div class="text-center">
                    <div class="font-5"> 
                        <router-link :to="{name:'doctor.parametre.infos.motifs'}" style="text-decoration: none;">
                            <span class="primary--text font-weight-bold">prestation</span>
                        </router-link>
                    </div>
                </div>
                    </v-col>
                    <v-col cols="12" md="4">
                        <div class="font-6">
                        Pour demarrer Veuillez definir une prestation de rendez-vous
                        Exemple: Consultation generale, 
                        </div>
                          
                    </v-col>
                </v-row>
                
               
            </v-sheet>
        </div>
       
       <div class="font-4 font-weight-bold text-center pa-6 my-6"> CONFIGURATION DES RENDEZ-VOUS </div>
       <v-row>
       
        <v-col cols="12" md="4">
                <div class="float-right">
                    <v-icon :color="doctor.horaire_cabinet.length !=0 ? 'success' : 'error'">{{ doctor.horaire_cabinet.length !=0 ? 'mdi-check-circle' : 'mdi-alert-circle-outline'}}</v-icon>
                </div>
            <v-sheet class="card-sheet pa-6">
                
                <div class="img-card">
                   <img src="../../assets/icons/bouton-daccueil.png" alt="image"  class="img-icon"/>
                </div>
                <div class="text-center">
                    <div class="font-5 font-weight-bold"> 
                        <router-link :to="{name:'doctor.parametre.infos.cabinet'}" style="text-decoration: none;">Cabinet</router-link>
                    </div>
                </div>
            </v-sheet>

        </v-col>
        <v-col cols="12" md="4">
            <div class="float-right">
                    <v-icon :color="doctor.horaire_teleconsultation.length !=0 ? 'success' : 'error'">{{this.doctor.horaire_teleconsultation.length !=0 ? 'mdi-check-circle' : 'mdi-alert-circle-outline'}}</v-icon>
                </div>
            <v-sheet class="card-sheet pa-6">
                <div class="img-card">
                   <img src="../../assets/icons/bouton-jouer.png" alt="image"  class="img-icon"/>
                </div>
                <div class="text-center">
                    <div class="font-5">
                        <router-link :to="{name:'doctor.parametre.infos.teleconsultation'}" style="text-decoration: none;">
                        <span class="primary--text font-weight-bold">téléconsultation</span>
                        </router-link>
                    </div>
                </div>
            </v-sheet>

        </v-col>
        <v-col cols="12" md="4">
            <div class="float-right">
                    <v-icon :color="doctor.horaire_teleexpertise.length !=0 ? 'success' : 'error'">{{doctor.horaire_teleexpertise.length !=0 ? 'mdi-check-circle' : 'mdi-alert-circle-outline'}}</v-icon>
                </div>
            <v-sheet class="card-sheet pa-6">
                <div class="img-card">
                   <img src="../../assets/icons/lecteur-video.png" alt="image"  class="img-icon"/>
                </div>
                <div class="text-center">
                    <div class="font-5"> 
                        <router-link :to="{name:'doctor.parametre.infos.teleexpertise'}" style="text-decoration: none;">
                            <span class="primary--text font-weight-bold">téléexpertise</span>
                        </router-link>
                    </div>
                </div>
            </v-sheet>

        </v-col>
        

       </v-row>
       <div class="font-4 font-weight-bold text-center pa-6 my-6"> CONFIGURATION ENTETE ET SIGNATURE  </div>
       <v-row>
            <v-col cols="12" md="4">
                <div class="float-right">
                    <v-icon :color="doctor.logo_cabinet ? 'success' : 'error'">{{ doctor.logo_cabinet ? 'mdi-check-circle' : 'mdi-alert-circle-outline'}}</v-icon>
                </div>
                <v-sheet class="card-sheet pa-6">
                    <div class="img-card">
                        <img src="../../assets/icons/creation-de-logo.png" alt="image"  class="img-icon"/>
                    </div>
                    <div class="text-center">
                        <router-link :to="{name:'doctor.parametre.infos.logo'}" style="text-decoration: none;">
                            <div class="font-5"><span class="primary--text font-weight-bold">Logo</span></div>
                        </router-link>
                    </div>
                </v-sheet>

            </v-col>
            <v-col cols="12" md="4">
                <div class="float-right">
                    <v-icon :color="doctor.signature ? 'success' : 'error'">{{ doctor.signature ? 'mdi-check-circle' : 'mdi-alert-circle-outline'}}</v-icon>
                </div>
                <v-sheet class="card-sheet pa-6">
                    <div class="img-card">
                        <img src="../../assets/icons/signature.png" alt="image"  class="img-icon"/>
                    </div>
                    <div class="text-center">
                        
                        <div class="font-5">
                            <router-link :to="{name:'doctor.parametre.infos.signature'}" style="text-decoration: none;">
                                <span class="primary--text font-weight-bold">Signature</span>
                            </router-link>  
                        </div>
                    </div>
                </v-sheet>

            </v-col>
            <v-col cols="12" md="4">
                <div class="float-right">
                    <v-icon :color="doctor.availability_langs.length !=0 ? 'success' : 'error'">{{ doctor.availability_langs.length!=0 ? 'mdi-check-circle' : 'mdi-alert-circle-outline'}}</v-icon>
                </div>
                <v-sheet class="card-sheet pa-6">
                    <div class="img-card">
                    <img src="../../assets/icons/linternet.png" alt="image"  class="img-icon"/>
                    </div>
                    <div class="text-center">
                        <div class="font-5"> 
                            <router-link :to="{name:'doctor.parametre.infos.langues'}" style="text-decoration: none;">
                                <span class="primary--text font-weight-bold">Langue(s)</span>
                            </router-link>
                        </div>
                      
                    </div>
                </v-sheet>

            </v-col>
       </v-row>
       <div class="font-4 font-weight-bold text-center pa-6 my-6"> CONFIGURATION AUTRE  </div>
       <v-row>
            <v-col cols="12" md="4">
                <div class="float-right">
                    <v-icon :color="doctor.careers.length!=0 ? 'success' : 'error'">{{ doctor.careers.length!=0 ? 'mdi-check-circle' : 'mdi-alert-circle-outline'}}</v-icon>
                </div>
                    <v-sheet class="card-sheet pa-6">
                        <div class="img-card">
                        <img src="../../assets/icons/diplome-de-fin-detudes.png" alt="image"  class="img-icon"/>
                        </div>
                        <div class="text-center">
                            <div class="font-5"> 
                                <router-link :to="{name:'doctor.parametre.infos.careers'}" style="text-decoration: none;">
                                    <span class="primary--text font-weight-bold">Diplômes et formations</span>
                                </router-link>
                            </div>
                        </div>
                    </v-sheet>

                </v-col>
            <v-col cols="12" md="4">
                <div class="float-right">
                    <v-icon :color="doctor.marker.length !=0 ? 'success' : 'error'">{{ doctor.marker.length !=0 ? 'mdi-check-circle' : 'mdi-alert-circle-outline'}}</v-icon>
                </div>
                <v-sheet class="card-sheet pa-6">
                    <div class="img-card">
                        <img src="../../assets/icons/espace-reserve.png" alt="image"  class="img-icon"/>
                    </div>
                    <div class="text-center">
                        <div class="font-5">
                            <router-link :to="{name:'doctor.parametre.infos.localisation'}" style="text-decoration: none;">
                                <span class="primary--text font-weight-bold">Localisation</span>
                            </router-link>
                        </div>
                    </div>
                </v-sheet>

            </v-col>
            <v-col cols="12" md="4">
                <div class="float-right">
                    <v-icon :color="doctor.condictions_pay ? 'success' : 'error'">{{ doctor.condictions_pay ? 'mdi-check-circle' : 'mdi-alert-circle-outline'}}</v-icon>
                </div>
                <v-sheet class="card-sheet pa-6">
                    <div class="img-card">
                    <img src="../../assets/icons/paiement-en-especes.png" alt="image"  class="img-icon"/>
                    </div>
                    <div class="text-center">
                        <div class="font-5"> 
                            <router-link :to="{name:'doctor.parametre.infos.condition.pay'}" style="text-decoration: none;">
                                <span class="primary--text font-weight-bold">Conditions de paiement de consultation</span>
                            </router-link>
                        </div>
                       
                    </div>
                </v-sheet>

            </v-col>
            <v-col cols="12" md="4">
                <div class="float-right">
                    <v-icon :color="doctor.presentation ? 'success' : 'error'">{{ doctor.presentation ? 'mdi-check-circle' : 'mdi-alert-circle-outline'}}</v-icon>
                </div>
                <v-sheet class="card-sheet pa-6">
                    <div class="img-card">
                    <img src="../../assets/icons/presentation.png" alt="image"  class="img-icon"/>
                    </div>
                    <div class="text-center">
                        <div class="font-5"> 
                            <router-link :to="{ name:'doctor.parametre.infos.presentation' }" style="text-decoration: none;">
                                <span class="primary--text font-weight-bold">Présentation</span>
                            </router-link>
                        </div>
                       
                    </div>
                </v-sheet>

            </v-col>
            <v-col cols="12" md="4">
                <div class="float-right">
                    <v-tooltip
          v-model="show"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
            <v-icon color="info">mdi-information-slab-circle-outline</v-icon>
            </v-btn>
          </template>
          <span class="font-7">Définir ses dates d'indisponibilité</span>
        </v-tooltip>
                    
                    <!-- <v-icon :color="doctor.unavailability_date_start ? 'success' : 'error'">{{ doctor.unavailability_date_start ? 'mdi-check-circle' : 'mdi-alert-circle-outline'}}</v-icon> -->
                </div>
                <v-sheet class="card-sheet pa-6">
                    <div class="img-card">
                    <img src="../../assets/icons/indisponibilite.date.png" alt="image"  class="img-icon"/>
                    </div>
                    <div class="text-center">
                        <div class="font-5"> 
                            <router-link :to="{name:'doctor.parametre.infos.indisponobilite.date'}" style="text-decoration: none;">
                                <span class="primary--text font-weight-bold">Dates d’indisponibilité</span>
                            </router-link>
                        </div>
                       
                    </div>
                </v-sheet>

            </v-col>
            
       </v-row>
       
    </div>

    <v-dialog v-model="showDialog" width="400" v-if="showDialog">
        <v-card>
            <v-card-title>
                <div class="font-7 red--text">Impossible de créer une plage horaire</div>
            </v-card-title>
            <v-divider/>
            <v-card-text class="pa-6">
                <div class="font-7">
                    Veuillez d'abord créer au moins une prestation avant de configurer une nouvelle plage horaire.
                </div>
                <div class="d-flex justify-end mt-3">
                    <v-btn color="error" style="text-transform: none; margin-right: 20px;" @click="showDialog=false" small>Annuler</v-btn>
                    <v-btn color="primary" style="text-transform: none" @click="createMotif" small>Créer</v-btn>

                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</div>
</template>

<script>

    export default {
        props: {
                doctor: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                showFormMotif: false,
                showDialog: false,
                show: false
            }
        },
        methods: {
            createMotif(){
                this.showDialog = false
                this.showFormMotif = true
            },
        }
    }
</script>

<style scoped>
    .infos {
        padding: 5%;
    }
    .card-sheet {
        width: 100%;
        /* height: 250px; */
    }
    .img-card {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
    .img-card img , .motif-card img  {
        width: 80px;
    }
</style>