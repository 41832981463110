<template>
   <div>
      <v-row align="center" justify="center">
          <v-col cols="12" md="8">
            <notifications />
          </v-col>
      </v-row>
  </div>
</template>

<script>
import notifications from '../../components/notifications.vue';
export default {
  components: { notifications },};
</script>

<style>
</style>