<template>
  <div style="position: relative" class="sp-container">
    <div class="sh-container">
      <v-container class="hide-xs">
        <searchSpecialHomeVue />
      </v-container>
    </div>
    <v-container>
      <div class="working" id="patient_register">
        <p class="font-3 weight-600 text-center mb-12 primary--text">
          Vous êtes un patient
        </p>

        <v-row align="center" justify="space-between">
          <v-col
            cols="12"
            md="4"
            sm="4"
            v-for="(item, i) in items"
            :key="i"
            align-self="center"
            class="mb-2"
          >
            <div class="item">
              <div class="title mt-2">
                <div class="primary--text font-4 text-center font-weight-bold">
                  {{ item.title }}
                </div>
                <div
                  v-if="item.subtitle"
                  class="info--text font-4 text-center font-weight-bold"
                >
                  {{ item.subtitle }}
                </div>
              </div>

              <v-img eager :src="item.img" class="text-center" contain> </v-img>
              <div class="pa-6 text-center">
                <v-btn
                  block
                  style="text-transform: none"
                  color="primary"
                  class="font-6 pa-2 font-weight-bold"
                  link
                  :to="item.link"
                >
                  {{ item.actionTitle }}
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="professionnel">
          <p
            class="font-3 weight-600 text-center primary--text my-6"
            id="doctor_register"
          >
            Vous êtes un professionnel de santé
          </p>
          <div class="doc">
            <div class="item-medecin pa-6">
              <div class="font-3 weight-600 primary--text">Médecin</div>
              <div class="font-8">
                <p>
                  - Dès votre inscription, notre plateforme vous référencie
                  auprès de plus de 10000 patients déjà inscrits.
                </p>
                <p>
                  - Un secrétariat médical « virtuel » vous gère les
                  rendez-vous, le paiement de vos prestations, la gestion, le
                  suivi et l’archivage des dossiers de vos patients
                </p>
                <p>
                  - Si vous disposez déjà d’un secrétariat vous pouvez
                  l’inscrire et partager votre activité avec lui.
                </p>
                <p>
                  - Votre espace de travail professionnel vous est propre et
                  vous donne la possibilité d’éditer au format de votre choix
                  les formulaires des dossiers médicaux (feuilles d’observation,
                  ordonnances, bulletins d’examens, certiﬁcats médicaux, etc.)
                </p>
                <p>
                  - Notre plateforme met à votre disposition les outils
                  nécessaires à la téléconsultation avec le patient et à la
                  téléexpertise avec vos collègues.
                </p>
                <p>
                  - Sur votre tableau de bord, vous disposez de statistiques sur
                  votre activité.
                </p>
              </div>
              <div class="btn-register">
                <v-btn
                  class=""
                  style="text-transform: none"
                  color="primary"
                  link
                  :to="{ name: 'doctor.register' }"
                  :disabled="$auth.check()"
                  >S'inscrire maintenant</v-btn
                >
              </div>
            </div>
            <div class="img-doc mr-6">
              <v-img src="../../assets/images/medecin_1.png" alt="doctor" />
            </div>
          </div>
        </div>
        <div class="professionnel">
          <div class="intervenant" id="intervenant_register">
            <div class="img-doc mr-6">
              <v-img src="../../assets/images/pharmaphie.png" alt="doctor" />
            </div>
            <div class="item-medecin pa-6">
              <div class="font-3 weight-600 primary--text">
                Intervenant (pharmacien, poste de santé)
              </div>
              <div class="font-6">
                <p>
                  Vous souhaitez bénéﬁcier de nos services en tant
                  qu’intervenant dans le domaine de la santé ? Inscrivez-vous
                  sur notre espace professionnel et ayez un accès privilégié à
                  nos services :
                </p>
                <p>
                  - Dès votre inscription, notre plateforme vous référencie
                  auprès de plus de 10000 patients déjà inscrits.
                </p>
                <p>
                  - Notre plateforme met à votre disposition les outils
                  nécessaires à la téléconsultation de votre patient et à la
                  téléexpertise avec des médecins.
                </p>
              </div>
              <div class="btn-register">
                <v-btn
                  class=""
                  style="text-transform: none"
                  color="primary"
                  link
                  :to="{ name: 'intervenant.register' }"
                  :disabled="$auth.check()"
                  >S'inscrire maintenant</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-container>
    <p
      class="font-3 weight-600 text-center primary--text"
      id="nos_services"
    >
      Nos Services
    </p>
    <div class="nos-services white--text pa-6">
      <v-container>
        <v-row class="mx-6">
          <v-col cols="12" md="4">
            <div class="mx-3">
              <v-icon size="50" color="white">mdi-calendar-text-outline</v-icon>
              <span class="font-5 font-weight-bold mx-3"
                >Prise de rendez-vous</span
              >
              <div class="font-6 mt-6 text-justify">
                <p>
                  <v-icon color="white"
                    >mdi-arrow-right-bold-circle-outline</v-icon
                  >
                  Sur la plateforme de Sahel Assistance, prenez rendez-vous dès
                  maintenant en ligne pour une consultation auprès d’un médecin,
                  vous recevrez un mail et un SMS de conﬁrmation ou toute autre
                  information concernant votre rendez-vous.
                </p>
                <p>
                  <v-icon color="white"
                    >mdi-arrow-right-bold-circle-outline</v-icon
                  >
                  Vous pouvez choisir plusieurs spécialités à Dakar et en région
                  selon les horaires disponibles en consultant le proﬁl des
                  intervenants.
                </p>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="mx-3">
              <v-icon size="50" color="white">mdi-note-text-outline</v-icon>
              <span class="font-5 font-weight-bold mx-3"
                >Votre espace santé</span
              >
              <div class="font-6 mt-6 text-justify">
                <p>
                  <v-icon color="white"
                    >mdi-arrow-right-bold-circle-outline</v-icon
                  >
                  Via la plateforme, votre médecin possède votre dossier médical
                  complété au fur et à mesure de vos visites ainsi que toutes
                  les informations de rendez-vous eﬀectués chez lui vous
                  concernant.
                </p>
                <p>
                  <v-icon color="white"
                    >mdi-arrow-right-bold-circle-outline</v-icon
                  >
                  Vous pouvez créer et suivre dans votre espace l’historique de
                  vos consultations et actes en santé chez les diﬀérents
                  spécialistes.
                </p>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="mx-3">
              <v-icon size="50" color="white">mdi-video-outline</v-icon>
              <span class="font-5 font-weight-bold mx-3"
                >La téléconsultation</span
              >
              <div class="font-6 mt-6 text-justify">
                <p>
                  Obtenez une téléconsultation dans plusieurs spécialités, avec
                  ou sans rendez-vous, de chez vous ou de chez un professionnel
                  de santé, via notre plateforme ou l’un de nos intervenants.
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import searchSpecialHomeVue from "./search.special.home.vue";
export default {
  data() {
    return {
      items: [
        {
          title: "Téléconsultation",
          subtitle: "sans rendez-vous",
          img: require("../../assets/images/teleconsultation.png"),
          actionTitle: "Démarrer la consultation",
          link: "/telemedecine-sans-rv",
        },
        {
          title: "Téléconsultation",
          subtitle: "avec rendez-vous",
          img: require("../../assets/images/teleconsultation_avec_rv.png"),
          actionTitle: "Prendre rendez-vous",
          link: "/rendez-vous/horaire_teleconsultation",
        },
        {
          title: "Consultation en cabinet",
          img: require("../../assets/images/consultation_cabinet.png"),
          actionTitle: "Prendre rendez-vous",
          link: "/rendez-vous/horaire_cabinet",
        },
      ],
      sw: 1,
    };
  },
  methods: {},
  components: {
    searchSpecialHomeVue,
  },
};
</script>

<style scoped>
.sp-container {
  padding-top: 8px;
  /*margin-bottom: 5%;*/
  /* padding: 6%; */
}
.doc {
  display: flex;
  margin-bottom: 8%;
}
.btn-register {
  display: flex;
  justify-content: flex-end;
}
.intervenant {
  display: flex;
}
.working {
  padding: 6%;
  background-color: #f5f8fa;
  margin-top: 8%;
}
.item-medecin {
  background-color: #ffffff;
  border-radius: 5%;
  width: 100%;
}
.nos-services {
  background-color: #00a19a;
}
.item {
  /*   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
 */
  background-color: #ffffff;
  padding: 2%;
  border-radius: 5%;
  text-align: center;
  /* width: 480px; */
  /* height: 580px; */
}
.item > .title {
  height: 90px;
}
.sh-container {
  position: absolute;
  top: -5%;
  width: 100%;
  /*margin-bottom: 20px;*/
}
@media screen and (max-width: 650px) {
  .doc {
    display: block;
  }
  .btn-register {
    justify-content: center;
  }

  .intervenant {
    display: block;
  }
  .sh-container {
    position: absolute;
    top: -3em;
    width: 90% !important;
  }
  .working {
    margin-top: 42%;
  }
  /* .hide-xs {
    margin-bottom: 20px;
  } */
  /* .hide-xs {
    display: none !important;
  } */
}
</style>
