<template>
  <div v-if="consultation"> 
    <h2 class="font-5 my-6 text-center"> Consultation le {{ consultation.la_date }}</h2>
    <h3>Information du patient</h3>
    <patient-item 
        :auteur="consultation.proche ? 'proche' : 'patient'" 
        :patient="consultation.proche ? consultation.proche : consultation.patient" 
    />

    <data-forms :data="consultation.data"  />
    <footer-print :signature_url="doctor.signature_url"/>

  </div>
</template>

<script>
import dataForms from '../../components/consultation/dataForms.vue'
import PatientItem from '../../components/consultation/patientItem.vue'
import FooterPrint from '../../components/Home/utilities/footerPrint.vue'
export default {
    props: ['doctor'],
  components: { dataForms, PatientItem, FooterPrint },
    data() {
        return {
            consultation_id: this.$route.params.consultation,
            consultation: null,
        }
    },
    async mounted() {
        console.log(this.doctor)
       this.getConsultation()
       
    },
    methods: {
        getConsultation() {
            this.$http.get(`print/consultation/${this.consultation_id}`).then(({data}) => {
                this.consultation = data
                setTimeout(() => {
                    window.print()
                }, 2000);
                
            })
            
        }
    }

}
</script>

<style scoped>

</style>