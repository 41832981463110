<template>
  <div class="">
    <div class="font-5 font-weight-bold text-center py-3 mt-3">Connexion</div>
    
    <div class="form-login pa-6">
      <v-alert v-if="error" dense outlined type="error" class="caption" dismissible>
        {{ message }}
      </v-alert>
      <vue-tel-input-vuetify v-if="isNumeric(email)" v-model="email" @input="handleTelInput" class="form" name="phone" label="Adresse e-mail ou numéro téléphone"
            placeholder="Entrer votre N° téléphone" outlined mode="international" defaultCountry="SN" :error="errorPhone"
            ref="telInput" :error-messages="errors.login"></vue-tel-input-vuetify>

          <!-- Affichez v-text-field si la saisie contient des caractères autres que des chiffres -->
          <v-text-field v-else v-model="email" label="E-mail ou numéro téléphone" placeholder="adresse e-mail ou numéro téléphone" outlined
            :error-messages="errors.login" />
      <v-text-field
        v-model="password"
        label="Mot de passe"
        name="password"
        placeholder="Entrer votre mot de passe"
        outlined
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show ? 'text' : 'password'"
        @click:append="show = !show"
      ></v-text-field>
    </div>
    <div class="d-flex font-7 font-weight-bold float-right blue--text mb-4">
      <router-link :to="{name: 'forgot.password'}" style="text-decoration: none">
        Mot de passe oublié ?
      </router-link>
    </div>
    <div class="mb-3">
      <v-btn
        block
        class="primary white--text p-2"
        style="text-transform: none"
        :loading="loading"
        @click="login"
      >
        Se connecter
      </v-btn>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      error: false,
      errors:{},
      message: "",
      loading: false,
      email: "",
      password: "",
      errorPhone: false,
      show: false
    };
  },
  methods: {
    login() {
      this.error = false;
      this.message = "";
      this.loading = true;
      const data = {
        login: this.email.match(/\S+/g).join(""),
        password: this.password,
      };
      this.$http
        .post("auth/login", data)
        .then((res) => {
          this.loading = false;
          this.$auth.login(res.data.token, res.data.user);
          this.email = "";
          this.password = "";
          this.$emit("connected");
        })
        .catch(({ response }) => {
          this.loading = false;
          this.error = true;
          if(response.data.message) {
            this.message = response.data.message;
          }
         
        });
    },
    verifyNumber() {
      if (!this.$refs.telInput._computedWatchers.phoneObject.value.isValid) {
        this.errorPhone = true;
        this.errors.phone = "Le numéro est incorrecte"
      } else {
        this.errorPhone = false;
        this.errors.phone = null;
      }

    },
    handleTelInput() {
      // Définir le focus sur le champ d'entrée lorsque l'utilisateur saisit quelque chose
      this.$refs.telInput.focus();
    },
    isNumeric(value) {
      // Utilisez une expression régulière pour vérifier si la saisie ne contient que des chiffres
      const numericPattern = /^\d+$/;
      return numericPattern.test(value);
    },
  },
};
</script>
  
  <style scoped>
  @media screen and (max-width: 430px) {
     .btn {
      max-width: 120px;
    } 
  }
  </style>