<template>
    <div>
      <!-- Langues parlés -->
    <div class="langues mb-6">
        <card-title :title="'Langue(s) parlée(s)'" :icon="'mdi-translate'" >
            <v-btn style="text-transform:none" color="primary" elevation="0" outlined @click="showFormLangs=true">
              {{doctor.availability_langs.length !=0 ? 'Modifier' : 'Ajouter' }}
            </v-btn>
        </card-title>
        <v-sheet class="pa-6" v-if="doctor.availability_langs.length !=0">
            <v-chip
                v-for="(lang, i) in doctor.availability_langs"
                :key="i"
                class="ma-2"
                close
                color="#025397"
                outlined
                @click:close="DeleteLang(i)"
            >
            {{ lang }}
            </v-chip>

        </v-sheet>
    </div>
     <!-- Formulaire langs -->
     <availability-langs-form
        v-if="showFormLangs"
        v-model="showFormLangs"
        :availability_langs="doctor.availability_langs"
        :doctor_id="doctor.id"
        @saveLangs="saveLangs"
    />
    </div>
  </template>
  
  <script>
import AvailabilityLangsForm from '../../../components/doctor/AvailabilityLangsForm.vue'
import cardTitle from '../../../components/Home/utilities/cardTitle.vue'
  export default {
  components: { cardTitle, AvailabilityLangsForm },
  props: ['doctor'],
  data(){
    return {
      showFormLangs: false
    }
  },
  methods: {
    saveLangs(data) {
                this.doctor.availability_langs = data.availability_langs
                //this.$router.go()
            },
            DeleteLang(index) {
                this.doctor.availability_langs.splice(index, 1);
                this.showFormLangs = true
            },
  }
  
  }
  </script>
  
  <style>
  
  </style>