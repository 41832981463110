<template>
  <div>
    <v-row justify="space-between">
      <v-col cols="12" md="6" v-if="secretaire">
        <profile-secretaire :secretaire="secretaire"  />
      </v-col>
      <v-col cols="12" md="4">
        <v-sheet class="pa-6 mb-3">
          <h4>Modifier votre mot de passe</h4>
        </v-sheet>
        <v-sheet class="pa-3">
          <v-text-field
            v-model="password_old"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            label="Ancien mot de passe"
            outlined
            dense
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
            :error-messages="errors.current_password"
          ></v-text-field>
          <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            label="Nouveau mot de passe"
            outlined
            dense
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            :error-messages="errors.password"
          ></v-text-field>
          <v-text-field
            v-model="password_confirm"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            label="Confirmer votre mot de passe"
            outlined
            dense
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
            :error-messages="errors.password_confirmation"
          ></v-text-field>
          <v-btn block color="primary" dark outlined class="my-3" @click="changePassword">Enregistrer</v-btn>
          <v-btn block color="error" dark outlined @click="close">Annuler</v-btn>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProfileSecretaire from '../../components/secretaires/ProfileSecretaire.vue'
export default {
  components: { ProfileSecretaire },
 data() {
    return {
        secretaire :null,
        password_old: null,
        password: null,
        password_confirm: null,
        errors:{},
        show: false,
        show1: false,
        show2: false,
    }
 },
 mounted() {
    this.getSecretaire()
 },
 methods: {
    getSecretaire() {
      this.$http.get('secretaire').then(({ data }) => {
        this.secretaire = data.data
      }).catch((error) => {
        this.$toast.error({
          title: 'Error',
          message: error.response.data.message
        })
      })
    },
    changePassword() {
      this.errors = {};
      const data = {
        current_password: this.password_old,
        password: this.password,
        password_confirmation: this.password_confirm
      }
      this.$http.post('profile/change-password', data).then(() => {
        this.$toast.success({
        title:'Modification Mot de Passe',
        message:'Votre mot de passe a été mise à jour avec succès'
      })
        this.close()
      }).catch((error) => {
        if(error.response.data.errors) {
          this.errors = error.response.data.errors;
        } else {
          this.$toast.error({
            title: 'Erreur',
            message: 'Une erreur est survenue lors du modification de votre mot de passe'
          })
        }
       
      })
    },
    close() {
      this.password = '';
      this.password_old = '';
      this.password_confirm = '';
      this.errors = {};
    }
 }
}
</script>

<style>

</style>