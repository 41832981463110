<template>
    <div>
        <v-sheet class="item-type pa-4 mb-3">
        <div class="d-flex justify-space-between">
            <div class="font-7 font-weight-bold">
                <router-link v-if="user.is_doctor" :to="{name: 'doctor.parametre.visit.medical.assign.form', params: { id: type.id }}" style="text-decoration: none" > {{ type.name }} </router-link>
                <router-link v-else :to="{name: 'admin.parametre.visit.medical.assign.form', params: { id: type.id }}" style="text-decoration: none" > {{ type.name }} </router-link>
            </div>
            <div class="actions">
                <v-btn icon class="mx-2" color="#025397" outlined small @click="updatedType" :disabled="!type.is_from_me">
                    <v-icon size="20" >mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon class="" color="error" outlined small :disabled="!type.is_from_me">
                    <v-icon size="20" @click="dialogDelete=true">mdi-delete</v-icon>
                </v-btn>
            </div>
    
        </div>
      </v-sheet>
        <!--  Dialog suppression de type de visite médicale -->
        <delete-dialog
            v-if="dialogDelete"
            v-model="dialogDelete"
            :message="'Etes-vous sûr de voulaire supprimer ce type de visite. Une fois supprimé tous champs attachés à ce type de visite serons supprimés'"
            :title="'Suppression type de visite médicale'"
            @confirmDelete="typeDelete"
        />
    </div>
    
    </template>
    
    <script>
        import DeleteDialog from '../Home/utilities/DeleteDialog.vue';
    export default {
      components: { DeleteDialog },
        props: {type: Object},
        data() {
            return {
                dialogDelete: false,
                user:this.$auth.user,
            }
        },
        created() {
            console.log(this.user.is_doctor)
            console.log('teste')
        },
        methods: {
            updatedType(){
                this.$emit('updatedType', this.type);
            },
            typeDelete() {
            this.$http.delete(`medical_visit_types/${this.type.id}`).then(() => {
                this.$toast.success({
                    title: 'Suppression type de visite médicale',
                    message: 'Votre type de visite médicale a été supprimer avec succès !'
                })
                this.$emit('deletedType', this.type)
            }).catch(() => {
                this.$toast.error({
                    title: 'Suppression type de visite médicale',
                    message: 'Erreur impossible de supprimer le type de visite médicale !'
                })
    
            })
    
        }
        },
        
    
    
    }
    </script>
    
    <style scoped>
     /* .item-type {
        background-color: aliceblue;
     } */
    
    </style>