<template>
    <div>
      <div v-if="loading">
        <loading-page />
      </div>
      <div v-else>
        <!-- Dashboard patients,rendez-vous, consultation et visites médicales -->
        <v-row>
          <v-col cols="12" md="3">
            <card-dashboard  :icon="`mdi-account-multiple`" :title="`Patients`" :number="patients_count" :color="'primary'"/>
          </v-col>
          <v-col cols="12" md="3">
            <card-dashboard  :icon="`mdi-calendar-clock`" :title="`Rendez-vous`" :number="appointments_count" :color="'blue'"/>
          </v-col>
          <v-col cols="12" md="3">
            <card-dashboard  :icon="`mdi-clipboard-pulse`" :title="`Consultations`" :number="consultations_count" :color="'yellow'"/>
          </v-col>
          <v-col cols="12" md="3">
            <card-dashboard  :icon="`mdi-stethoscope`" :title="`Visites Médicales`" :number="medical_visits_count" :color="'orange'"/>
          </v-col>
        </v-row>
        <!-- Liste des 5 rendez-vous à venir -->
        <v-row>
            <v-col cols="12" md="4" justify-center> 
                <v-sheet class="pa-6" height="auto">
                    <h4> 
                        <v-icon color="primary">mdi-calendar-month</v-icon>
                        Liste des cinq (5) rendez-vous à venir
                    </h4>
                    <div class="" v-if="appointments.length">
                        <v-timeline
                            align-top
                            dense
                            v-for="appointment in appointments"
                            :key="appointment.id"
                        >
                            <v-timeline-item
                                color="teal lighten-3"
                                small
                            >
                                <v-row class="pt-1">
                                    <v-col cols="12" md="12">
                                        <div class="font-weight-normal info--text">
                                            <router-link :to="`rendez-vous/${appointment.id}/detail`" style="text-decoration: none;">
                                                <strong>{{ appointment.formated_date }}</strong>
                                            </router-link>
                                        </div>
                                        <div class="font-7 font-weight-bold my-2"> {{  appointment.description }}</div>
                                        <div class="font-7">
                                            <v-avatar size="35">
                                                <v-img :src="appointment.proche ? appointment.proche.avatar_url : appointment.patient.user.avatar_url" />
                                            </v-avatar>
                                            {{ appointment.proche ? appointment.proche.name : appointment.patient.name }}
                                        </div>
                                    </v-col>
                                   
                                </v-row>
                            </v-timeline-item>
                        </v-timeline>
                    </div>
                    <div class="" v-else>
                        <div class="pa-6 text-center">
                            <v-icon size="40">mdi-alert-circle-outline</v-icon>
                            <div class="">Vous n'avez pas encore de rendez-vous à venir</div>
                        </div>
                    </div>
                </v-sheet>
            </v-col>
            <v-col cols="12" md="8">
                <v-sheet class="pa-6">
                    <chart-example />
                </v-sheet>
            </v-col>
        </v-row>
      </div>
    </div>
  </template>
  
  <script>
import ChartExample from '../../components/charts/ChartExample.vue'
import CardDashboard from '../../components/Home/CardDashboard.vue'
import LoadingPage from '../../components/Home/utilities/LoadingPage.vue'
  export default {
  components: { LoadingPage, CardDashboard, ChartExample },
    data() {
        return {
            loading: false,
            doctor_id: this.$auth.user.doctor_id,
            patients_count:0,
            appointments_count:0,
            consultations_count:0,
            medical_visits_count:0,
            appointments: []
            
        }
    },
    mounted() {
      this.getCounts()
      this.getAppointments()
    },
    methods: {
        getCounts() {
            this.loading = true
            this.$http.get('dashboard/doctor/'+this.doctor_id).then(({ data }) => {
                this.patients_count       = data.patients_count
                this.appointments_count   = data.appointments_count
                this.consultations_count  = data.consultations_count
                this.medical_visits_count = data.medical_visits_count
            }).catch((error) => {
                this.$toast.error({
                    title: 'Erreur Serveur',
                    message: error.response.data.message
                })
            }).finally(() => this.loading = false)
        },
        getAppointments() {
            this.loading
            this.$http.get('appointments/laster/'+this.doctor_id).then(({ data}) => {
                this.appointments = data.data
            }).catch(() => {
                this.$toast.error({
                    title: 'Erreur du serveur',
                    message: "Impossible de recuperer les rendez-vous"
                })
            }).finally(() => this.loading = false)
        }
    }
  }
  </script>
  
  <style>
  
  </style>