<template>
    <drawer :links="links" />
  </template>
<script>
    import Drawer from '../../components/Home/utilities/Drawer.vue';
    export default {
        components: {Drawer},
    data: () => ({
      links: [
        [ "mdi-view-dashboard","Tableau de bord", "secretaire.home"],
        [ "mdi-doctor", "Mon Profil","secretaire.compte" ],
        [ "mdi-calendar-clock", "Rendez-vous","secretaire.appointments" ],
        [ "mdi-account-multiple", "Patients","secretaire.patients" ],
        ["mdi-file-table", "Factures", "secretaire.factures"]
      ],
    }),
   
  };
  </script>