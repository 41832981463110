<template>
  <div class="countdown caption">
    <div class="countdown-item">
      <span class="countdown-number" v-text="days"></span>
      <span class="countdown-label">Jours</span>
    </div>
    <div class="countdown-item">
      <span class="countdown-number" v-text="hours"></span>
      <span class="countdown-label">Heures</span>
    </div>
    <div class="countdown-item">
      <span class="countdown-number" v-text="minutes"></span>
      <span class="countdown-label">Minutes</span>
    </div>
    <div class="countdown-item">
      <span class="countdown-number" v-text="seconds"></span>
      <span class="countdown-label">Secondes</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dateRendezVous: String
  },
  data() {
    return {
      //countdownDate: new Date('2023-04-22 09:00:00'), // Date de votre rendez-vous
      countdownDate: new Date(this.dateRendezVous),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  },
  mounted() {
    setInterval(() => {
      const now = new Date().getTime();
      const distance = this.countdownDate - now;
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (this.days < 0 && this.hours < 0) {
        if (this.days === -1 && this.hours === -1 && this.minutes >= -30) {
          this.$emit('is_time', true)
        } else {
          this.$emit('is_time', false)
        }

      }
    }, 1000);
  }
}
</script>

<style scoped>
.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdown-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

.countdown-number {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.countdown-label {
  font-weight: bold;
  font-size: 1.2rem;
}
</style>