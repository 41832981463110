<template>
  <v-container>
    <div class="form-patient mt-12" v-if="patient">

      <v-row justify="space-between">
        <v-col cols="12" md="12">
          <v-sheet class="pa-3">
            <div class="text-center pa-6">
              <div class="profile">
                <div class="avatar" v-if="patient.user">
                  <img :src="patient.user.avatar_url" />
                </div>
              </div>
              <div class="pt-3 font-weight-bolder">{{ patient.name }}</div>
            </div>
            <div class=" ifoPatient">
              <div class="patient">

                <div class="address_cabinet">
                  <div class="d-flex">
                    <span><strong> Profession : </strong></span>
                  </div>
                  <div class="d-flex">
                    <span>{{ patient.post ? patient.post : '-' }}</span>
                  </div>
                </div>


                <div class="address_cabinet">
                  <div class="d-flex">
                    <span><strong> Adresse email : </strong></span>
                  </div>
                  <div class="d-flex">
                    <span>{{ patient.user.email ? patient.user.email : '-' }}</span>
                  </div>
                </div>

                <div class="address_cabinet">
                  <div class="d-flex">
                    <span><strong> Adresse : </strong></span>
                  </div>
                  <div class="d-flex">
                    <span>{{ patient.address ? patient.address : '-' }}</span>
                  </div>
                </div>


                <div class="address_cabinet">
                  <div class="d-flex">
                    <span><strong> N° téléphone : </strong></span>
                  </div>
                  <div class="d-flex">
                    <span>{{ patient.tel_port ? patient.tel_port : '-' }}</span>
                  </div>
                </div>

                <div class="address_cabinet">
                  <div class="d-flex">
                    <span><strong> N° tel dom : </strong></span>
                  </div>
                  <div class="d-flex">
                    <span>{{ patient.tel_dom ? patient.tel_dom : '-' }}</span>
                  </div>
                </div>

                <div class="address_cabinet">
                  <div class="d-flex">
                    <span><strong> Date de naissance : </strong></span>
                  </div>
                  <div class="d-flex">
                    <span>{{ patient.formatted_date_naissance ? patient.formatted_date_naissance : '-' }}</span>
                  </div>
                </div>



                <div class="address_cabinet">
                  <div class="d-flex">
                    <span><strong> Lieu de naissance: </strong></span>
                  </div>
                  <div class="d-flex">
                    <span>{{ patient.lieu_naissance ? patient.lieu_naissance : '-' }}</span>
                  </div>
                </div>



                <div class="address_cabinet">
                  <div class="d-flex">
                    <span><strong> AGE : </strong></span>
                  </div>
                  <div class="d-flex">
                    <span>{{ patient.age != 0 ? patient.AGE : '-' }}</span>
                  </div>
                </div>
                <div class="address_cabinet">
                  <div class="d-flex">
                      <span><strong>Entreprise : </strong></span>
                  </div>
                  <div class="d-flex">
                      <template v-if="patient.entreprise">
                          <v-chip color="primary" label small>{{ patient.entreprise.name }}</v-chip>
                      </template>
                      <template v-else>
                          <span>-</span>
                      </template>
                  </div>
                </div>
                <div class="address_cabinet">
                  <div class="d-flex">
                    <span><strong> Ajouté : </strong></span>
                  </div>
                  <div class="d-flex">
                    <span>{{ patient.created_at ? patient.created_at : '-' }}</span>
                  </div>
                </div>


                <div class="py-6" v-if="patient.user">
                  <div class="d-flex justify-center">
                    <v-tooltip bottom v-if="patient.user.actif == false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn elevation="0" style="margin: 5px;" v-bind="attrs" v-on="on"
                          :loading="loading_refresh"><span class="mdi mdi-refresh-circle"
                            style="color:rgb(210, 177, 9);font-size:40px;cursor: pointer;"
                            @click="reminberPatient"></span></v-btn>
                      </template>
                      <span>rappel</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="patient.user.actif == true">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn elevation="0" style="margin: 5px;" v-bind="attrs" v-on="on" :loading="loading"><span
                            class="mdi mdi-account-remove " style="color:red;font-size:30px;cursor: pointer;"
                            @click="patientActivate"></span></v-btn>
                      </template>
                      <span>Désactiver</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="patient.user.actif == false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn elevation="0" style="margin: 5px;" v-bind="attrs" v-on="on" :loading="loading"> <span
                            class="mdi mdi-account-check" style="color:green;font-size:30px ;cursor: pointer;"
                            @click="patientActivate"></span></v-btn>
                      </template>
                      <span>Activer</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn elevation="0" style="margin: 5px;" v-bind="attrs" v-on="on" :loading="loading"> <span
                            class="mdi mdi-delete-circle-outline" style="color:red;font-size:30px ;cursor: pointer;"
                            @click="patientDelete"></span></v-btn>
                      </template>
                      <span>Supprimer son compte</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </v-sheet>
        </v-col>
      </v-row>

    </div>
  </v-container>
</template>
<script>

export default {
  data() {
    return {
      show: false,
      show1: false,
      show2: false,
      errors: {},
      showForm: false,
      patient: null,
      password_old: null,
      password: null,
      password_confirm: null,
      pActivate: null,
      errorMessage: '',
      user_id: null,
      loading: false,
      loading_refresh: false,
    };
  },
  watch: {
    $route: 'showPatient'
  },
  created() {
    this.showPatient()
  },
  methods: {
    showPatient() {
      const id = this.$route.params.id
      this.$http.get(`auth/patient/` + id).then(({ data }) => {

        this.patient = data.data;
        this.user_id = this.patient.user.id

      });
    },

    patientActivate() {
      this.loading = true;
      this.$http.post(`auth/patientactived/` + this.$route.params.id).then(data => {

        this.pActivate = data.message;
        this.$toast.success({
          title: "Desactivation  Patient",
          message: "Le compte du patient à été désactiver avec sucées"
        });

        this.showPatient();

      }).catch((error) => {
        this.$toast.error({
          title: 'Ce patient n\'existe pas',
          message: error.response.data.message
        })
      }).finally(() => this.loading = false)
    },

    reminberPatient() {
      this.loading_refresh = true
      this.$http.post(`auth/patient/AccountActivationReminder/` + this.user_id).then(() => {

        this.$toast.success({
          title: "Relence  Patient",
          message: "Le patient à bien été relencer"
        });

      }).catch((error) => {
        this.$toast.error({
          title: 'Ce patient n\'existe pas',
          message: error.response.data.message
        })
      }).finally(() => this.loading_refresh = false)
    },
    patientDelete() {
      this.$http.delete(`auth/patient/destroyPatient/${this.user_id}`).then(() => {
        this.$toast.success({
          title: 'Compte Patient',
          message: "Compte patient supprimé avec succès"
        })
        this.$router.go(-1)
      }).catch(() => {
        this.$toast.error({
          title: 'Suppréssion compte patient',
          message: "Impossible de supprimer le compte patient, car erreur c'est produite lors de la suppréssion"
        })
      })
    },


    close() {
      this.password = '';
      this.password_old = '';
      this.password_confirm = '';
      this.errors = {};
    }
  },
};
</script>
<style scoped>
.form-patient {
  display: flex;
  padding: 2px;
  transition: transform ease 0.3s, box-shadow ease 0.3s;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.profile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  display: flex;
  width: 150px;
  height: 150px;
  border-radius: 100px;
}

.ifoPatient {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.patient {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
}

.address_cabinet {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  /* justify-content: center; */
  margin-bottom: 1.3%;
  border-bottom: 1px solid #CFD8DC;
  /* width: %; */
}
</style>
