import axios from 'axios' 
class Api {
    constructor () {
        //this.url_base = 'http://localhost:8000/api/';
        process.env.VUE_APP_ENV == 'local' ?  this.url_base = 'http://localhost:8000/api/' :  this.url_base = 'https://backend.sahelassistance.com/api/';
    }
    csrf = () => {
        this.url_base
          .get('/sanity/get/csrf', { credentials: 'same-origin' })
          .then((res) => {
            this.url_base.defaults.headers.post['x-csrf-token'] = res.data.csrf_token;
          })
          .catch((err) => {
            console.log('err = ', err);
          });
      };
      

    call (requestType, url, data = null) {
        return new Promise((resolve, reject) => {
            axios[requestType](this.url_base+url, data)
                .then(response => {
                    resolve(response);
                })
                .catch(({response}) => {
                    if (response.status === 401) {
                        this.$auth.logout();
                    }
    
                    reject(response);
                });
        });
    }

}

export default Api;