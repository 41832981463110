<template>
  <router-view :doctor="doctor" />
</template>

<script>
export default {
    props: ['doctor']

}
</script>

<style>

</style>