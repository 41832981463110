<template>
  <div v-if="ordonnance" class="mt-12">
    <div class="d-flex justify-end my-12">
      <div class="font-7">
        <div>Dakar, le {{ today }}</div>
        <div class="">{{ ordonnance.proche ? ordonnance.proche.name : ordonnance.patient.name }}</div>
        <div class="">{{ ordonnance.proche ? ordonnance.proche.AGE : ordonnance.patient.AGE }}</div>
      </div>

    </div>
    <h1 class="text-center mb-12"> Ordonnance</h1>
  

    <div v-for="(medicament, i) in ordonnance.ordonnances" :key="medicament.id">
      <div class="d-flex align-stretch justify-space-between font-7">
        <div class="col-5">
          {{ i + 1 }} . {{ medicament.name }}
        </div>
        <div class="col-4"> 
          <div class="d-flex justify-center">
            {{ medicament.dosage }}
          </div>
          
        </div>
        <div class="col-3">
          <div class="d-flex justify-end"> 
            {{ medicament.nombre }}
          </div>
          
        </div>
      </div>
    </div>
    <footer-print :signature_url="doctor.signature_url"/>
  </div>
</template>
  
<script>
import footerPrint from '../../components/Home/utilities/footerPrint.vue';
export default {
  props: ['doctor'],
  components: { footerPrint },
  name: `ordonnance`,
  data() {
    return {
      id: this.$route.params.id,
      ordonnance: null,
      type: this.$route.params.type,
    }
  },
  computed: {
    today() {

            // Créer une instance de la date actuelle
      const date = new Date();

      // Obtenir les informations de la date
      const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

      // Convertir la date en chaîne de caractères
      const dateEnFrancais = date.toLocaleDateString('fr-FR', options);
      return dateEnFrancais;
    }
  },

  mounted() {
    this.getOrdonnance()
  },
  methods: {
    getOrdonnance() {
      this.$http.get(`print/ordonnance?id=${this.id}&type=${this.type}`).then(({ data }) => {
        this.ordonnance = data
        setTimeout(() => {
                    window.print()
                }, 1000);
                
      })
    }
  },

}
</script>
  
<style></style>