<template>
  
  

        <div class="form-patient mt-12" v-if="user">

        <v-row justify="space-between">
            <v-col cols="12" md="8" >
            <v-sheet class="pa-3">
                    <div class="text-center pa-6">
                        <div class="profile">
                            <div class="avatar">
                                <img :src="user.avatar_url"/>
                            </div>
                        </div>
                    <div class="pt-3 font-weight-bolder">{{ user.name }}</div>
                    </div>
        <div class=" ifoPatient">
                <div class="patient">
                

                
                <div class="address_cabinet">
                    <div class="d-flex">
                        <span><strong> Adresse email : </strong></span>
                    </div>
                    <div class="d-flex">
                        <span>{{ user.email }}</span>
                    </div>
                </div>

                
                
                <div class="address_cabinet">
                    <div class="d-flex">
                        <span><strong>  N° téléphone : </strong></span>
                    </div>
                    <div class="d-flex">
                        <span>{{ user.phone ? user.phone: '-' }}</span>
                    </div>
                </div>
                <div class="address_cabinet">
                    <div class="d-flex">
                        <span><strong>  Crée le : </strong></span>
                    </div>
                    <div class="d-flex">
                        <span>{{ user.created_at ? user.created_at : '-' }}</span>
                    </div>
                </div>
                
                <div class="address_cabinet">
                    <div class="d-flex">
                        <span><strong>  Modifié le : </strong></span>
                    </div>
                    <div class="d-flex">
                        <span>{{ user.updated_at ? user.updated_at : '-' }}</span>
                    </div>
                </div>

        
                </div>
                <div style="display: flex;justify-content:center;align-items:center">
            <v-btn color="primary" style="text-transform: none" class="mr-3" outlined rounded @click="showModal()">
                  <v-icon>mdi-pencil</v-icon> <!-- Modifier -->
                </v-btn>
            </div>
            </div>
            </v-sheet>
           
            </v-col>

        
            <v-col cols="12" md="4"
            style="background-color: aliceblue;"
            >
        <v-sheet class="pa-6 mb-3">
          <h4>Modifier votre mot de passe</h4>
        </v-sheet>
        <v-sheet class="pa-3">
          <v-text-field
            v-model="password_old"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            label="Ancien mot de passe"
            outlined
            dense
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
            :error-messages="errors.current_password"
            
          ></v-text-field>
          <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            label="Nouveau mot de passe"
            outlined
            dense
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            :error-messages="errors.password"
        
          ></v-text-field>
          <v-text-field
            v-model="password_confirm"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            label="Confirmer votre mot de passe"
            outlined
            dense
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
             :error-messages="errors.password_confirmation"
        
          ></v-text-field>
          <v-btn block color="primary" dark outlined class="my-3" @click="changePassword">Enregistrer</v-btn>
          <v-btn block color="error" dark outlined @click="close">Annuler</v-btn>
        </v-sheet>
      </v-col>
        </v-row>




<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
     

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Modifications de l'admin
        </v-card-title>

        <v-card-text>
            <template>
  <v-form>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="name"
            label="Nom Complet"
            outlined
            required
            :error-messages="errors.name"
          ></v-text-field>
        </v-col>

      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="phone"
            label="Téléphone"
            outlined
            required
            :error-messages="errors.phone"
          ></v-text-field>
        </v-col> 
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="email"
            label="Adresse email"
            outlined
            required
            :error-messages="errors.email"
          ></v-text-field>
        </v-col> 
      </v-row>
    </v-container>
  </v-form>
  <div style="color:red" v-if="this.erorrMessage">{{ this.erorrMessage }}</div>
</template>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#F44336"
            text
            @click="closeDialogueUpdate()"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="UpdateAdmin"
          >
            Modifier
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
        </div>


        

  </template>
  

<script>

export default {
       data() {
         return {
            show: false,
            show1: false,
            show2: false,
            errors: {},
           user : null,
           patient: null,
            password_old: null,
            password: null,
            password_confirm: null,
            dialog: false,
            name: null,
            phone: null,
            email: null,
            erorrMessage: null,
            userConnect: null
         }
       },
       mounted() {
         this.user = this.$auth.user
         console.log(this.user);
     },
     methods: { 
        changePassword() {
        this.errors = {};
        const data = {
            current_password: this.password_old,
            password: this.password,
            password_confirmation: this.password_confirm
        }
        this.$http.post('profile/change-password', data).then(() => {
            this.$toast.success({
            title:'Modification Mot de Passe',
            message:'Votre mot de passe a été mise à jour avec succès'
        })
            this.close()
        }).catch((error) => {
            if(error.response.data.errors) {
            this.errors = error.response.data.errors;
            } else {
            this.$toast.error({
                title: 'Erreur',
                message: 'Une erreur est survenue lors du modification de votre mot de passe'
            })
            }
        
        })
        },
        close() {
      this.password = '';
      this.password_old = '';
      this.password_confirm = '';
      this.errors = {};
    },
    getAdmin() { 
        this.name = this.user.name;
        this.phone = this.user.phone;
        this.email = this.user.email;
    },
    showModal() {
       this.getAdmin(); 
       this.dialog = true;
    },
    UpdateAdmin () {
      this.errors = {}
        let data = { 
            name: this.name,
            phone: this.phone.match(/\S+/g).join(""),
            email: this.email
        };
        this.$http.post(`updateAdmin`,data).then(data => {
            this.erorrMessage = data.data.error
            if(this.erorrMessage){
                this.erorrMessage 
            }else {
            this.dialog = false;
            this.userConnect = data.data
            this.$toast.success({
            title:'Modification compte Admin',
            message:'Votre compte a été mise à jour avec succès'
            })

        }
        }).catch((error) => {
            if(error.response.data.errors) {
            this.errors = error.response.data.errors;
            } else {
            this.$toast.error({
                title: 'Erreur',
                message: 'Une erreur est survenue lors du modification des informations'
            })
            }
        
        })
    },
    closeDialogueUpdate(){
        this.erorrMessage = "";
        this.dialog = false;
    },
}

}
</script>
<style scoped>

.form-patient   { 
    display: flex;
    padding: 2%;
    width: 100%;
    /* height: 50vh; */
}     
.profile {  
    display: flex;
    justify-content: center;
    align-items: center;
} 
.avatar  {
    display: flex;
    width: 150px;
    height: 150px;
    border-radius: 100px;
} 
.ifoPatient { 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.patient {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.address_cabinet {
  display: flex;
  justify-content: space-between;
  /* justify-content: center; */
  margin-bottom: 5%;
  align-items: center;
  border-bottom: 1px solid #CFD8DC;
  /* width: %; */
}
  </style>