<template>
    <div>
        <v-row>
            <v-col cols="12" md="4" v-if="patient">
                <v-sheet class="pa-3">
                    <div class="text-center pa-6">
                        <div class="avatar">
                            <v-avatar size="150" color="white">
                                <img :src="auteur == 'patient' ? patient.user.avatar_url : patient.avatar_url"
                                    :alt="patient.name" />
                            </v-avatar>
                        </div>
                        <div class="pt-3 font-weight-bold font-6">{{ patient.name }}</div>
                    </div>
                    <div class="patient">
                        <v-row class="py-2">
                            <v-col class="subtitle-2 font-weight-bold">
                                Profession
                            </v-col>
                            <v-col>
                                {{ patient.post ? patient.post : '--' }}
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row class="py-2">
                            <v-col class="subtitle-2 font-weight-bold">
                                Email
                            </v-col>
                            <v-col>
                                {{ auteur == 'patient' ? patient.user.email : '--' }}
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row class="py-2">
                            <v-col class="subtitle-2 font-weight-bold">
                                Adresse
                            </v-col>
                            <v-col>
                                {{ patient.address ? patient.address : '--' }}
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row class="py-2">
                            <v-col class="subtitle-2 font-weight-bold">
                                N° tel port
                            </v-col>
                            <v-col>
                                {{ auteur === 'proche' ? patient.phone : patient.tel_port }}
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row class="py-2">
                            <v-col class="subtitle-2 font-weight-bold">
                                N° tel dom
                            </v-col>
                            <v-col>
                                {{ patient.tel_dom ? patient.tel_dom : '-' }}
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row class="py-2">
                            <v-col class="subtitle-2 font-weight-bold">
                                Date de naissance
                            </v-col>
                            <v-col>
                                {{ patient.formatted_date_naissance ? patient.formatted_date_naissance : '-' }}
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row class="py-2">
                            <v-col class="subtitle-2 font-weight-bold">
                                Lieu de naissance
                            </v-col>
                            <v-col>
                                {{ patient.lieu_naissance ? patient.lieu_naissance : '-' }}
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row class="py-2">
                            <v-col class="subtitle-2 font-weight-bold">
                                AGE
                            </v-col>
                            <v-col>
                                {{ patient.AGE ? patient.AGE : '-' }}
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row class="py-2">
                        <v-col class="subtitle-2 font-weight-bold">
                            Entreprise
                        </v-col>
                        <v-col>
                            {{ auteur=='patient' && patient.entreprise ? patient.entreprise.name : '-' }}
                        </v-col>
                        </v-row>
                        <v-divider />
                        <v-row class="py-2">
                            <v-col class="subtitle-2 font-weight-bold">
                                Ajouté
                            </v-col>
                            <v-col>
                                {{ patient.created_at ? patient.created_at : '-' }}
                            </v-col>
                        </v-row>
                        <div class="text-center mt-2" v-if="auteur == 'patient'">
                            <v-btn color="primary" outlined style="text-transform: none;" @click="showForm = true" rounded>Modifier</v-btn>
                        </div>
                    </div>
                </v-sheet>

            </v-col>
            <v-col cols="12" md="8">
                <v-tabs v-model="tab" grow>
                    <v-tab 
                        link 
                        :to="{ 
                            name: $auth.user.is_secretaire ? 
                            'secretaire.patient.consultations' : 'doctor.patient.consultations'
                        } "
                    >
                        <v-icon class="mr-1">mdi-clipboard-pulse</v-icon>
                        Consultations
                    </v-tab>
                    <v-tab
                        link 
                        :to="{ 
                            name: $auth.user.is_secretaire ? 
                            'secretaire.patient.medical.visits' : 'doctor.patient.medical.visits'
                        } "
                    >
                        <v-icon class="mr-1">mdi-stethoscope</v-icon>
                        Visites Médicales
                    </v-tab>
                    <v-tab
                        link 
                        :to="{ 
                            name: $auth.user.is_secretaire ? 
                            'secretaire.patient.appointments' : 'doctor.patient.appointments'
                        } "
                    >
                        <v-icon class="mr-1">mdi-calendar-clock</v-icon>
                        Rendez-vous
                    </v-tab>
                    <v-tab 
                        v-if="$auth.user.is_doctor"
                        link
                        :to="{name: 'doctor.patient.files'}"
                    >
                        <v-icon class="mr-1">mdi-paperclip</v-icon>
                        Fichiers
                    </v-tab>
                </v-tabs>
                <v-divider></v-divider>
                <v-tabs-items v-model="tab">
                    <router-view :patient="patient"/>
                </v-tabs-items>
            </v-col>
        </v-row>
        <form-patient :patient="patient" v-model="showForm" v-if="showForm"  @updatedPatient="updatedPatient"/>
    </div>
</template>

<script>
import FormPatient from '../../../components/patient/FormPatient.vue'
export default {
  components: { FormPatient },
    data() {
        return {
            showForm: false,
            auteur: this.$route.params.auteur,
            patient_id: this.$route.params.patient,
            patient: null,
            tab: null,
        }
    },
    watch: {
        $route: 'getPatient'
    },
    mounted() {
        this.getPatient()
    },
    methods: {
        getPatient() {
            this.$http.get(`patient?auteur=${this.auteur}&id=${this.patient_id}`).then(({ data }) => {
                this.patient = data.data
            }).catch(() => {
                this.$toast.error({
                    title: 'Erreur',
                    message: "Une erreur c'est produite lors de la recuperation du patient"
                })
            })
        },
        updatedPatient(patient) {
            this.$toast.success({
                title: 'Modification patient',
                message: "Patient modifié avec succès !"
            })
            this.patient = patient
        }
    }
}
</script>

<style></style>