<template>
    <div class="files">
        <v-row justify="space-between">
            <v-col cols="12" md="3" class="font-weight-bold"> Nom du fichier </v-col>
            <v-col cols="12" md="3" class="font-weight-bold"> Date de téléversement </v-col>
            <v-col cols="12" md="3" class="font-weight-bold"> Propriétaire </v-col>
            <v-col cols="12" md="3" class="font-weight-bold">
                <div class="d-flex justify-end font-weight-bold">
                    Actions
                </div>
            </v-col>
        </v-row>
        
        <div class="list mt-3" v-for="file in files" :key="file.id">
            <v-divider></v-divider>
            <v-row class="py-3">
                <v-col cols="12" md="3"> {{ file.filename }} </v-col>
                <v-col cols="12" md="3"> {{ file.uploaded_at }} </v-col>
                <v-col cols="12" md="3"> {{ file.is_mine ? 'Moi' : file.creator.name }} </v-col>
                <v-col cols="12" md="3">
                    <div class="d-flex justify-end">
                        <v-btn icon class="mx-2" color="#025397" @click="showFile(file)" outlined small>
                            <v-icon size="20">mdi-eye</v-icon>
                        </v-btn>
                        <v-btn icon class="" color="error" @click="showDialogDelete(file)" outlined small v-if="file.is_mine">
                            <v-icon size="20">mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </v-col>

            </v-row>

        </div>
        <div class="text-center mt-6" v-if="files.length == 0">
            <v-alert
            color="info"
            border="left"
            elevation="0"
            colored-border
            icon="mdi-file-document"
            >
             Vous n'avez reçu aucun partage de fichiers pour le moment.
            </v-alert>
        </div>
        <view-file v-if="showDialog" v-model="showDialog" :file="file" />
        <delete-dialog 
            v-if="dialogDelete"
            v-model="dialogDelete"
            :message="'Ëtes vous sur de vouloir supprimer ce fichier'" 
            :title="'Suppression de Fichier'"
            @confirmDelete="deleteFile"
        />
    </div>
</template>

<script>
import ViewFile from '../file/ViewFile.vue'
import DeleteDialog from '../Home/utilities/DeleteDialog.vue'
export default {
    components: { ViewFile, DeleteDialog },
    props: {
        files: Array
    },
    data() {
        return {
            file: null,
            showDialog: false,
            dialogDelete: false
        }
    },
    methods: {
        showDialogDelete(file) {
            this.file = file
            this.dialogDelete = true
        },
        showFile(file) {
            this.file = file;
            this.showDialog = true;
        },
        
        deleteFile() {
            if(this.file.id) {
                this.$http.delete(`file/${this.file.id}`).then(() => {
                    this.$toast.success({
                        title: 'Suppression fichier',
                        message: 'Votre fichier a été supprimer avec succès !'
                    })
                    this.$emit('fileDeleted', this.file)
                }).catch(() => {
                    this.$toast.error({
                        title: 'Suppression fichier',
                        message: "Impossible de supprimer ce ficier"
                    })
                })
            }
        }
    }


}
</script>

<style></style>