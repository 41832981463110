<template>
    <v-card elevation="1">
        <v-card-title class="green--text grey lighten-4 subtitle-1">
           Ajouter des Photos
           <v-spacer></v-spacer>
                    <div>
                        <v-btn text @click.stop="showFormGalerie=true" x-small>
                            <v-icon color="green" >
                                mdi-plus
                            </v-icon>
                            <div v-if="showFormGalerie">
                                <form-galerie v-model="showFormGalerie" v-if="showFormGalerie" @savedPhoto="savedPhoto"></form-galerie>
                            </div>
                        </v-btn>
                    </div>
        </v-card-title>
        <v-card-text>
            <div><span>Vous pouvez ajouter jusqu'a 4 photos ({{4- (photos ? 4-photos.length : 0)}}) photos restantes</span></div>
            <div v-if="photos">
                <v-row
                    class="fill-height"
                    align="center"
                    justify="center"
                >
                   
                        <v-col
                        cols="12"
                        md="6"
                        v-for="(item,index) in photos" :key="index"
                        >
                   
                        <v-hover v-slot="{ hover }">
                            <v-card
                            :elevation="hover ? 12 : 2"
                            :class="{ 'on-hover': hover }"
                            >
                            <v-img
                                :src="item"
                                aspect-ratio="1"
                               
                            >
                                <v-card-title class="title white--text">
                                <v-row
                                    class="fill-height flex-column"
                                    justify="space-between"
                                >
                                <div class="align-self-center">
                                    <v-btn
                                        :class="{ 'show-btns': hover }"
                                        :color="transparent"
                                        icon
                                        @click="deletePhoto(index)"
                                    >
                                        <v-icon
                                        :class="{ 'show-btns': hover }"
                                        :color="transparent"
                                        >
                                         mdi-delete
                                    </v-icon>
                                    </v-btn>
                                </div>
                            </v-row>
                        </v-card-title>
                    </v-img>
                </v-card>
            </v-hover>
        </v-col>
    </v-row>
               
            </div>
            <div v-else>
                <div >Veuillez ajouter des photos</div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import FormGalerie from './FormGalerie.vue'
export default {
    components: { FormGalerie },
    props: {
        photos: Array
    },
    name:'galerie-doctor',
    data() {
        return {
            showFormGalerie: false,
            transparent: 'rgba(255, 255, 255, 0)'
        }
    },
    methods: {
        savedPhoto(file) {
            //this.photos.push(file)
            this.$emit('savedPhoto', file)
        },
        // viewPhotos(item) {

        // },
        deletePhoto(index) {
            const item = this.photos[index]
            this.photos.splice(index, 1)
            const data ={
                photos: this.photos,
                url_photo: item
            }
            this.$emit('deletePhoto', data)
        }
    }
}
</script>
<style scoped>
.v-card {
  transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
 }

.show-btns {
  color: rgb(221, 39, 39) !important;
}
</style>