<template>
    <div>
        <v-sheet class="pa-6">
            <div class="d-flex justify-end my-3" v-if="$auth.user.is_doctor">
                <v-btn 
                    color="primary" 
                    class="text-capitalize"
                    link
                    :to="{ 
							name: 'doctor.consultation.patient.create', 
							params: { 
								patient: patient_id,
								auteur: auteur
							},
							
						}"
                > 
                    <v-icon>mdi-plus</v-icon> Nouvelle consultation
                </v-btn>
            </div>
            <table-consultations  
                :loading="loading" 
                :consultations="consultations" 
                :headers="headers" 
                :page="current_page"
                :page-count="last_page"
                :items-per-page="per_page"
                @pagination="getConsultations" 
            />
        </v-sheet>
        </div>
  </template>
  
  <script>
import tableConsultations from '../../../components/consultation/tableConsultations.vue'
  export default {
  components: { tableConsultations },
    data() {
        return {
            loading: false,
            auteur: this.$route.params.auteur,
            patient_id: this.$route.params.patient,
            doctor_id: this.$auth.user.doctor_id,
            consultations: [],
            current_page: 1,
            last_page:1,
            per_page: 15,
            tab: null,
            headers: [
                      
                      {
                          text: 'Crée le ',
                          align: 'start',
                          filterable: false,
                          value: 'la_date',
                      },
                      {
                          text: 'Actions',
                          filterable: false,
                          value: 'actions',
                      },
                  ]
        }
    },
    watch: {
        $route: 'getConsultations'
    },
    mounted() {
        this.getConsultations()
    },
    methods: {
        getConsultations(page) {
            this.$http.get(`patient/get/consultations`, 
            {
                params: {
                    page: page,
                    auteur: this.auteur,
                    patient_id: this.patient_id,
                    doctor_id: this.doctor_id
                }
            }).then(({ data }) => {
                this.consultations = data.data
                this.current_page = data.meta.current_page
                this.last_page = data.meta.last_page
                this.per_page = data.meta.per_page
            }).catch(() => {
                this.$toast.error({
                    title: 'Erreur',
                    message: "Une erreur c'est produite lors de la recuperation du patient"
                })
            })
        }
    }
  }
  </script>
  
  <style>
  
  </style>