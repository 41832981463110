<template>
  <v-container>
    <loading-page v-if="loading" />
    <v-row align="center" justify="center" v-else>
      
      <v-col cols="12" md="8" v-if="appointment">
       <!--  <v-card color="#f5f8fa" elevation="0"> -->
          <v-sheet class="mb-3">
            <div class="pa-3 primary white--text" v-if="appointment.formated_date">
              <span>
                <v-icon color="white">mdi-calendar</v-icon>
                {{ appointment.formated_date }}
              </span>
              <span>
                <v-icon size="20" color="white" class="px-2">mdi-alarm</v-icon>
                {{ appointment.formated_time }}
              </span>
              <span class="float-right">
                <v-icon size="20" color="white" class="">mdi-av-timer</v-icon> {{ appointment.delay_status }}
               
              </span>
             
            </div>

            <div class="d-flex" v-if="appointment.doctor">
              <div class="profile-photo">
                <img :src="appointment.doctor.user.avatar_url" alt="avatar" />
              </div>
              <div class="profile-info">
                <div>
                  <strong>{{ appointment.doctor.name }}</strong>
                </div>
                <div>
                  Spécialiste en
                  {{ appointment.doctor.department.name.toLowerCase() }}
                </div>
              </div>
            </div>
            <v-divider />
            <div class="d-flex justify-space-around mb-3">
              <v-btn
                color="primary"
                text
                style="text-transform: none"
                @click="dialogModify = true"
                :disabled="appointment.rvpasse"
              >
                <v-icon> mdi-calendar-edit</v-icon>
                Déplacer le RDV
              </v-btn>
              <v-btn
                color="error"
                text
                style="text-transform: none"
                @click="dialogDelete = true"
              >
                <v-icon> mdi-calendar-remove</v-icon>
                Annuler le RDV
              </v-btn>
            </div>
          </v-sheet>
          <v-sheet class="pa-3 mb-3">
            <div>
              <p><strong>Préparer le rendez-vous</strong></p>
              <v-btn
                color="primary"
                text
                style="text-transform: none"
                @click="sendFile = true"
              >
                <v-icon>mdi-note-plus</v-icon>
                Envoyer des documents
              </v-btn>
            </div>
          </v-sheet>
          <v-sheet class="pa-3 mb-3" v-if="appointment.files">
            <div>
              <p><strong>Documents envoyés</strong></p>
              <div>
                <file-item
                  v-for="file in appointment.files"
                  :file="file"
                  :appointment_id="appointment.id"
                  :doctor_id="appointment.doctor.user.id"
                  :key="file.id"
                  @deleteShareSuccess="deleteShareSuccess"
                />
              </div>
            </div>
          </v-sheet>
          <v-sheet class="pa-6">
            <div class="detail-rendez">
              <!-- <p><strong>Rendez-vous</strong></p> -->
              <div class="d-flex justify-space-between my-3">
                <div><strong>Patient </strong> </div>
                <div>{{ appointment.proche ? appointment.proche.name : $auth.user.name }}</div>
              </div>
              <v-divider></v-divider>
              <div class="d-flex justify-space-between my-3">
                <div><strong>Prestation consultation </strong> </div>
                <div>{{appointment.description }}</div>
              </div>
              <v-divider></v-divider>
              <div class="d-flex justify-space-between my-3" v-if="appointment.motif">
                <div><strong>Montant </strong> </div>
                <div>{{appointment.motif.montant_formated }}</div>
              </div>
              <v-divider></v-divider>
              <div class="d-flex justify-space-between my-3" v-if="appointment.is_cabinet">
                <div><strong>Adresse Cabinet </strong> </div>
                <div>{{appointment.doctor.address_cabinet }}</div>
              </div>
              <div class="d-flex justify-space-between my-3"  v-if="appointment.sessionId">
                <div><strong>Consultation en video</strong> </div>
                <div>
                  <router-link :to="{name: 'telemedecine.join', params: {sessionId: appointment.sessionId}}" style="text-decoration: none;" >Rejoindre l'appel</router-link>
                </div>
              </div>
              <!-- <div class="no-pay text-center" v-if="!appointment.is_pay">
                <v-alert color="error" dense>
                  Paiement non confirmé
                </v-alert>
               
              </div> -->

            </div>
          </v-sheet>
        <delete-dialog 
            :message="` Êtes-vous sûr de vouloir supprimer le rendez-vous du ${appointment.formated_date} avec ${appointment.doctor.name} ?`" 
            :title="`Suppression de rendez-vous`" 
            v-model="dialogDelete"
            @confirmDelete="confirmDelete"
        />
        <v-dialog
          fullscreen
          class="text-center"
          persistent
          v-model="dialogModify"
        >
          <v-card>
            <v-card-subtitle class="d-flex justify-center">
              <div>Modifier le date de votre rendez-vous</div>
              <v-spacer />
              <v-btn icon color="error" @click="dialogModify = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-subtitle>
            <div>
              <meeting-selector
                :doctor="appointment.doctor"
                @dateSelected="dateSelected"
              />
            </div>
          </v-card>
        </v-dialog>
        <v-dialog width="400" v-model="dialogDate" persistent>
          <v-card>
            <v-card-title class="text-h6">
              Veuillez confirmer la date.
            </v-card-title>

            <v-card-text>
              Êtes-vous sûr de vouloir deplacer la date de votre rendez-vous le
              <strong>
                {{ date_formatting }} à {{ heures }}h{{ minutes }} </strong
              >?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="error" text @click="dialogDate = false">
                Annuler
              </v-btn>

              <v-btn color="primary" text @click="confirmDate">
                Confirmer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <send-file-dialog :appointment="appointment" :files="appointment.files" v-model="sendFile"  @fileSendingSuccessfuly="fileSendingSuccessfuly" v-if="sendFile" />
  </v-container>
</template>

<script>
import FileItem from '../../components/file/FileItem.vue';
import SendFileDialog from '../../components/file/SendFileDialog.vue';
import DeleteDialog from '../../components/Home/utilities/DeleteDialog.vue';
import meetingSelector from '../../components/Home/utilities/meeting.selector.vue';
import LoadingPage from '../../components/Home/utilities/LoadingPage.vue'
export default {
  components: { meetingSelector, FileItem, DeleteDialog, SendFileDialog, LoadingPage },
  data() {
    return {
        appointment: {},
        sendFile: false,
        loading: true,
        //files:[],
        dialogDelete: false,
        dialogModify: false,
        dialogDate: false,
        date_selected: null,
        date_formatting: null,
        heures: null,
        minutes: null,
        options: { year: "numeric", month: "long", day: "numeric" },
        opt_weekday: { weekday: "long" },
    };
  },
  created() {
    this.init()
  },
  watch: {
    $route: "init",
  },
  methods: {
    init() {
      const appointment = this.$route.params.appointment;
      this.$http.get(`appointment/${appointment}`).then(({ data }) => {
        this.appointment = data.data;
        this.loading = false;
      });
    },
    confirmDelete() {
      this.$http
        .delete(`appointment/${this.appointment.id}`)
        .then(() => {
          this.$toast.success({
          title: 'Annulation  RDV',
          message: 'Votre rendez-vous a été annulé avec succès.'
        })
          this.dialogDelete = false;
          this.$router.push({name: this.$auth.user.is_patient ? 'compte.mes-rendez-vous' : 'intervenant.mes-rendez-vous'})
          //this.$emit("deleteAppointment", data.data);
        })
        .catch(() => {
          this.$toast.error({
          title: 'Erreur',
          message: 'Une erreur est survenue lors de l\'annulation du rende-vous'
        })
        });
    },
    dateSelected(data) {
      const myDate = new Date(data.date);
      const weekday = this.toTitleCase(
        myDate.toLocaleDateString("fr-FR", this.opt_weekday)
      );
      const date_day =
        weekday + " " + myDate.toLocaleDateString("fr-FR", this.options);
      this.date_formatting = date_day;
      this.heures = myDate.getHours();
      this.minutes = myDate.getMinutes();
      this.date_selected = data.date;
      this.dialogDate = true;
    },
    confirmDate() {
      this.$http.put(`appointment/${this.appointment.id}/date`, {date: new Date(this.date_selected)}).then(({ data }) => {
        this.dialogDate = false ;
        this.dialogModify = false;
        this.appointment = data.data;
        this.$toast.success({
          title: 'Modification Date RV',
          message: 'Votre rendez-vous a été deplacé avec succès.'
        })
        //this.$emit('dateModify', data.data);
      }).catch(() => {
        this.$toast.error({
          title: 'Erreur',
          message: 'Une erreur est survenue lors de la mise à jour de la date du rende-vous'
        })
      })
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    deleteShareSuccess(file) {
      this.appointment.files = this.appointment.files.filter(doc => doc.id != file.id);
      //this.appointement.files = this.files;
    },
    fileSendingSuccessfuly(data) {
      this.appointment.files = data;
      this.$toast.success({
        title: 'Ajout Document',
        message: 'Le document a été ajouté au rendez-vous avec succès.'
      })
    }
  },
};
</script>

<style scoped>
.profile-photo img {
  width: 70px;
  height: 70px;
  border-radius: 70px;
}
.profile-photo {
 padding: 10px 0 10px 10px;
  margin-right: 20px;
}
.profile-info {
  margin-top: 20px;
  line-height: 1.5;
  font-size: 0.9em;
}
.close-icon {
  display: inline;
}
/* v-dialog {
  background-color: #f5f8fa !important;
} */
.appointement-detail {
  background-image: #f5f8fa;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
@media screen and (max-width: 600px) {
  .profile-photo{
    margin-right: 50px;
  }
}
</style>