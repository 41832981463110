<template>
  <div>
    <v-row align="center" justify="start" class="hero" no-gutters>
      <!--  <v-col cols="12" xl="2" lg="2" /> -->
      <div
        class="deep-orange lighten-4 pa-6"
        style="width: 100%"
        v-if="dataCookies"
      >
        <div class="row-cookies">
          <div class="">
            <h3>Attention votre rendez-vous n'est pas encore confirmé</h3>
            <p>
              Vous devez finir votre processus de reservation pour qu'il soit
              effectif.
            </p>
            <div class="bouton">
              <v-btn
                color="primary"
                class="btn"
                elevation="0"
                link
                :to="dataCookies.url"
                style="text-decoration: none"
                >Confirmer ce RV</v-btn
              >
              <v-btn
                color="red lighten-2"
                class="btn white--text"
                elevation="0"
                @click="annulerRV"
                >Annuler ce RV</v-btn
              >
            </div>
          </div>
          <div class="card-cookie">
            <v-card class="rounded">
              <v-card-text>
                <div class="mb-3">
                  <div class="font-weight-bold">
                    {{ dataCookies.date_formatting }}
                    {{ dataCookies.heures }}h{{ dataCookies.minutes }}
                  </div>
                  <div>{{ dataCookies.medecin.adresse }}</div>
                </div>

                <v-divider />
                <div class="d-flex avatar-medecin mt-3">
                  <v-avatar size="50" color="white">
                    <img :src="dataCookies.medecin.avatar_url" alt="alt" />
                  </v-avatar>
                  <div class="mx-3">
                    <h4>{{ dataCookies.medecin.name }}</h4>
                    <div>{{ dataCookies.medecin.specialite }}</div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>
      <v-col cols="12" xl="6" lg="6" class="slogan">
        <v-container>
          <div class="font-2 white--text font-title">
            Prenez rendez-vous avec des professionnels de santé
          </div>
          <div class="white--text font-title font-3">En un clic 24H/24 - 7J/7</div>
          <div class="compteur-patient" v-if="patients_number > 0"> 
            <v-avatar size="50">
                    <span class="font-1 font-weight-bold white--text text">+</span>
                </v-avatar>
                <v-avatar size="100" color="white"  class="avatar-compteur">
                    <div class="font-4 font-weight-bold primary--text">
                        <animation-number :value="patients_number" />
                    </div>
                </v-avatar>
                <v-avatar size="180" class="avatar-name">
                    <span class="font-3 font-weight-bold white--text text">Patients </span>
                </v-avatar>
          </div>
         
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AnimationNumber from '../AnimationNumber.vue';
export default {
  name: "Hero",

  components: {AnimationNumber},
  data() {
    return {
      dataCookies: null,
      patients_number: 0
    };
  },
  created() {
    this.getComputer()
    if (this.$cookies.get("appointment")) {
      this.dataCookies = this.$cookies.get("appointment");
    }
  },
  methods: {
    annulerRV() {
      this.$cookies.remove("appointment");
      this.dataCookies = null;
      this.$router.go("");
    },
    getComputer() {
      this.$http.get('auth/count/patients').then(({data}) => {
        this.patients_number = data
      })
    }
  },
};
</script>
<style>
.hero {
  height: 750px;
  background: url("../../assets/images/banniere.png");
  background-size: cover;
}

.bouton {
    display: flex;
    justify-content: space-between;
  }
.font-title {
  font-weight: 500;
  margin: 0 5px 0 100px;
  /*text-align: center;*/
}
.font-desc {
  font-weight: 500;
  text-align: center;
}
.row-cookies {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.card-cookie {
  margin-left: 50px;
}
.compteur-patient {
  margin: 0 5px 0 100px;
}


@media screen and (max-width: 650px) {
  .hero {
    height: 30vh;
    background: url("../../assets/images/banniere.png");
    background-size: cover;
    padding: 0;
    /* align-self: start; */
    /* align-content: flex-start; */
  }
  .font-title {
    text-align: left;
    margin: 0 0 0 10px;
    width: 50%;
    font-size: small !important;
    /* line-height: 1.2;
  }

  .font-desc {
    font-weight: 500;
    text-align: left;
  }
  .row-cookies {
    display: block;
    /* justify-content: start; */
  }
 /*  .bouton {
    display: flex;
  } */
  .v-btn.v-size--default {
    font-size: 0.8rem;
  }
  .card-cookie {
    margin-left: 0px;
    margin-top: 24px;
  }
  .compteur-patient {
    margin: 10px 0px;
  }
  .avatar-name {
  width: 100px !important;
  height: 100px !important;
  min-width: 80px !important;
}
.avatar-compteur {
  width: 50px !important;
  height: 50px !important;
  min-width: 50px !important;
}
}
</style>
