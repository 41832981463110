import { render, staticRenderFns } from "./medecins.list.vue?vue&type=template&id=b1fcb8ec&scoped=true&"
import script from "./medecins.list.vue?vue&type=script&lang=js&"
export * from "./medecins.list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1fcb8ec",
  null
  
)

export default component.exports