<template>
    <v-app class="bg-1" style="display:flex;width:100%">
            <navbar />
    <container>
    <div class="allcontent" style="margin:5px;display:flex;align-items:center;width: 100%;justify-content:center;margin-top:0%">
        <div style="display:flex;flex-direction:column;width:65%;margin-top:5%">
            <p style="text-align:center;"><strong>CONDITIONS GENERALES D’UTILISATION DE LA PLATEFORME DE TELEMEDECINE DE SAHEL ASSISTANCE </strong></p>
            <p style="text-align:center;margin:5px"><strong>Préambule</strong></p>
            <p class="p">La télésanté est devenue un outil incontournable et le Sénégal s’est doté d’un plan stratégique de santé digitale 2018-2023 coordonné par le Ministère de la santé et de l’Action Sociale</p>
            <p class="p">C’est dans ce contexte que la plateforme sante.sahelassistance.com  a été créée par des acteurs de la santé.</p>
            <p class="p">Sante.sahelassistance.com  est une plateforme numérique de télémédecine accessible via le net qui a pour objectif d'améliorer  durablement la couverture sanitaire universelle des populations et d’assurer une prise de décision des acteurs basés sur des informations sécurisées au Sénégal.</p>
            <p ><strong>C'est dans ce cadre que ce projet de télémédecine a été mis en place pour:</strong></p>
            <div>
            <ul>
                <li style="text-decoration: dotted">Faciliter l'accès aux soins de santé pour les populations vivant dans les zones éloignées;</li>
                <li>Réduire les coûts liés aux déplacements des patients et des professionnels de santé;</li>
                <li>Permettre aux patients d'avoir accès à des spécialistes qui ne sont pas forcément présents dans leur région;</li>
                <li>Améliorer la qualité des soins en permettant aux professionnels de santé d'avoir accès à des outils de diagnostic performants</li>
            </ul>
            </div>
            <p>Les présentes Conditions Générales d’utilisation ont pour objet de définir les modalités et conditions dans lesquels sante.sahelassistance.com permet aux  utilisateurs d’accéder et de bénéficier des services disponibles; </p>
            <h3>I- DISPOSITIONS GENERALES</h3>
            <h4>A- PRESENTATION DE LA PLATEFOREME.</h4>
            <p>La plateforme est éditée par des acteurs de la santé individuels et collectifs. Sa gestion est assurée par la société Sahel Assistance en collaboration avec CREATIC STUDIO qui assure la maintenance informatique de la plateforme ainsi que la sécurisation et confidentialité des données. </p>
            <h4>B- DEFINITIONS</h4>
            <p>Pour les besoins des présentes conditions générales d’utilisation, certains termes sont définis comme suit:CGU : conditions générales d’utilisations de la plateforme souscrites par l’utilisateur;</p>
            <p><strong>Données personnelles:</strong> toute information relative à une personne physique ou morale identifiée ou identifiable directement ou indirectement, conformément à la définition qui en est donnée par les lois sur la protection des données;</p>
            <p><strong> Droit de propriété intellectuelle</strong> toute protection octroyée par la loi notamment aux inventions, découvertes, secrets de fabrication, savoir-faire, logiciels, sur les brevets et les droits d’auteur et sur toute demande relative à la propriété intellectuelle;</p>
            <p><strong> Lois sur la protection des données:</strong> désigne l’ensemble des lois sénégalaises existantes en la matière;</p>
            <ul>
                <li><strong>Professionnels de santé :</strong> désigne les professionnels de santé ayant accepté d’être référencés au sein de la plateforme; </li>
                <li><strong>Editeur :</strong> Désigne la société éditrice, exploitante et fournisseuse du service aux utilisateurs de la plateforme; </li>
                <li><strong>Utilisateur :</strong> Désigne à la fois le professionnel utilisateur et le non professionnel ayant accepté les présentes CGU;</li>
                <li><strong>Utilisateur non professionnel :</strong> désigne toute personne physique ou morale qui accède et utilise les services de la plateforme depuis tout support informatique connecté à Internet;</li>
                <li><strong>Utilisateur professionnel:</strong>établissement, association entreprise ou professionnel de santé ayant souscrit aux présentes CGU, accepté d’être référencés sur la plateforme et qui déclare pouvoir contribuer aux services;</li>
            </ul>
            <h4>C- CHAMP D’APPLICATION</h4>
            <p>L’accès et l’utilisation de la plateforme sont soumis à l’acceptation et au respect des présentes conditions générales d’utilisation ci-après CGU que l’utilisateur reconnait avoir lu et compris;</p>
            <p>Les présentes CGU s’appliquent à tout accès à et/ou utilisation de la plateforme et/ou des services;</p>
            <p>Tout utilisateur étranger accepte expressément l’application de la loi sénégalaise en accédant et ou  utilisant le service;
            Lors de la création de son compte utilisateur, l’utilisateur doit prendre connaissance des CGU et cocher l’acceptation des conditions d’utilisation de la plateforme prévue à cet effet dans le formulaire d’inscription;</p>
            <p>En cochant cette case, l’utilisateur reconnait qu’il accepte l’ensemble des clauses des CGU;</p>
            <h3>II- DESCRIPTION DU SERVICE</h3>
        <h4>A- OBJECTIFS</h4>
        <p>Télémédecine .com est une plateforme internet sur laquelle l’Utilisateur se connecte et accède à ses services par l’intermédiaire d’un navigateur WEB.</p>
        <p>Les présentes CGU ont pour objet de définir les conditions et modalités d’utilisations de la plateforme Télémédecine.com:</p>
        <p> D’une part la plateforme Télémédecine ci-après dénommée Editeur met à la disposition de ses utilisateurs le site, et les services disponibles sur le site et d’autre part la manière par laquelle l’utilisateur accède au site et utilise ses services;</p>
        <h4>B- SERVICES</h4>
        <p>il s’agit de la mise en place  de services qui permettent aux patients de prendre rendez-vous en ligne avec des médecins et des spécialistes de la santé, de consulter à distance et de recevoir des prescriptions médicales électroniques;</p>
        <p>Les professionnels de santé pourront également suivre leurs patients à distance, recevoir des alertes en cas de besoin et échanger avec d’autres professionnels de santé pour discuter des cas complexes;</p>
        <h4>C- COMPTE UTILISATEUR</h4>
        <p>La plateforme numérique de Télémédecine reposera sur des outils de traitement de données électroniques performants garantissant la confidentialité et la sécurité des données personnelles; </p>
        <p>L’utilisateur reconnait que tout accès et utilisation de la plateforme et des services via son compte  sont effectués sous sa responsabilité exclusive sauf à démontrer une utilisation frauduleuse ne résultant d’aucune faute ou négligence de sa part. A cet effet  il est tenu d’informer l’Editeur dans les meilleures délais de tout détournement ou de toute utilisation frauduleuse de son compte ;</p>
        <h4><strong>utilisateur professionnel</strong></h4>
        <p>Pour accéder et utiliser la plateforme télémédecine, chaque utilisateur doit se connecter au compte utilisateur qu’il va créer préalablement;</p>
        <p>la création d’un compte utilisateur s’effectue depuis la page d’accueil de la plateforme en saisissant son adresse mail valide dans l’emplacement prévu à cet effet et après confirmation de cette adresse, l’utilisateur recevra un code personnel adressé à l’adresse de courrier électronique saisie afin de valider définitivement la création:</p>
        <p>Une fois le code unique associé à l’adresse de courrier électronique saisi, l’utilisateur est invité à compléter la fiche de renseignement en indiquant certaines informations personnelles:</p>
        <ul>
            <li>
               - Son identité incluant nom, prénom, sexe, date de naissance (photo de profil sil y’a lieu)
            </li>
            <li>
                - ses coordonnées professionnelles et sa spécialité
            </li>
            <li>
                l’utilisateur s’engage à fournir, lors de la création et de l’utilisation du compte utilisateur, des informations justes et fiables;
            </li>
        </ul>
        <p>L’utilisateur s’engage a à ne pas créer ou utiliser d’autres comptes que celui initialement crée;</p>
        <p> Une fois la prise de contact établi, l’utilisateur crée son espace privé dans les conditions des présentes CGU;</p>
        <p>Il doit être précisé que selon les Services auxquels il a souscrit par voie d’abonnement  dans la présente plateforme, les informations de l’utilisateur professionnel pourront être rendus disponibles via le moteur et doivent être plus ou moins détaillées et précises;</p>
        <p>Les utilisateurs professionnels référencés sont responsables des informations qu’ils déclarent et qu’ils mettent à disposition de l’utilisateur de la présente plateforme</p>
        <p>L’Editeur ne s’engage à aucune exhaustivité sur les informations présentées et disponibles depuis le moteur de recherche </p>
        <h4>l’utilisateur non professionnel</h4>
        <p><stron>locally flywheel</stron></p>
        <p>L’utilisateur non professionnel peut avoir accès via le moteur de recherche aux informations référencées par la plateforme en précisant au préalable certaines informations personnelles le concernant relatives à:</p>
        <ul>
            <li>son identité (nom, prénom, Age, sexe)</li>
            <li>La prise en charge médicale souhaitée</li>
            <li>La localisation géographique de la prise en charge souhaitée;</li>
        </ul>
        <p>Les données ainsi récoltées sont traitées dans le respect des lois sur la protection des données par l’Editeur;</p>
        <h3>1.c MODIFICATION DU COMPTE UTILISATEUR</h3>
        <p>A tout moment, l’utilisateur peut mettre à jour l’ensemble des informations de son compte utilisateur au niveau de la plateforme à l’exception de l’adresse électronique associé au compte utilisateur. Pour toute modification, l’utilisateur adresse sa demande a télémédecine à l’adresse électronique indiquée en préambule des présentes CGU.</p>
        <h3>DUREE DE VALIDITE DU COMPTE</h3>
        <h4>UTILISATEUR</h4>
        <p>Le compte utilisateur demeure valide pour une durée indéterminée néanmoins l’utilisateur peut mettre fin à son compte à tout moment en effectuant la demande à Télémédecine</p>
        <p>Dans ce cas précis, l’utilisateur s’engage et garantit avoir informé individuellement chacun de ses patients sans nuire de quelque procédé que ce soit, l’utilisation des Services via la plateforme  sur la qualité ni la sécurité et l’utilisateur n’aura plus accès à la plateforme et aux services en ce compris son compte;</p>
        <p>En cas  d’inactivité du compte pendant plus de 24 mois Télémédecine se réserve la possibilité de suspendre l’utilisateur après l’avoir avisé préalablement;</p>
        <p>En cas de manquement de l’utilisateur Télémédecine se réserve le droit, en fonction de la gravité et de la durée du manquement  de suspendre ou de clôturer le compte utilisateur </p>
        <h3>III - CONDITIONS D’ACCES AU SERVICE</h3>
        <p>Tout utilisateur a accès au service dès lors qu’il accepte et reconnait satisfaire aux conditions suivantes:</p>
        <h4>1- conditions générales d’accès au service</h4>
        <p>L’utilisateur doit être majeur et être doté de la capacité juridique à souscrire aux présentes CGU. L’utilisateur peut en sa qualité de représentant légal d’une personne légalement incapable ou déclarée comme telle par une autorité judiciaire ( mineur, incapable majeur sous tutelle) souscrire aux présentes CGU dès lors qu’il démontre y être autorisé par la personne qu’il représente ou par les autorités judiciaires.</p>
        <p>L’utilisateur prend à ce titre, la responsabilité des informations qu’il transmet à Télémédecine;</p>
        <p>L’Utilisateur doit reconnaitre avoir lu et compris l’intégralité des présentes CGU et les accepter sans restriction ni réserve;</p>
        <p>L’utilisateur doit consentir à la collecte et au traitement de données personnelles afin d’assurer le bon fonctionnement du service, et ce dans le strict respect des lois et règlements sur la protection des données;</p>
        <p>Pour l’utilisateur professionnel: activer son compte personnel et fournir les informations nécessaires au bon fonctionnement du service</p>
        <p>Accéder au service en utilisant sa véritable identité et s’engage à renseigner des informations authentiques</p>
        <h4>2- conditions particulieres d’accès aux services</h4>
        <p>Pour accéder aux services l’utilisateur professionnel doit </p>
        <p>-initialiser un espace personnel en s’inscrivant via le site www   </p>
        <p>Accepter les conditions générales d’utilisation de la présente plateforme</p>
        <p>L’inscription nécessaire des données suivantes:</p>
        <ul>
            <li>
                nom prénom  adresse
            </li>
            <li>
                Nom de l’établissement auquel il est attaché ainsi que les missions qu’il y exerce;
            </li>
            <li>Date  de naissance</li>
            <li>Téléphone mobile pour y recevoir le code</li>
            <li>l’adresse mail</li>
        </ul>
            <p>Les données personnelles ainsi enregistrées permettent d’envoyer par mail, le lien internet sur lequel l’utilisateur professionnel doit cliquer pour activer son espace personnel qui est sécurisé par un identifiant et un mot de passe généré par lui et dont il sera le seul responsable. Le mot de passe est crypté et sécurisé</p>
            <p>Les données ainsi récoltées sont traitées dans le respect des lois sur la protection des données personnelles:</p>
            <p>Les identifiants et mots de passe permettent à l’utilisateur professionnel de s’identifier  et de se connaitre aux services. Ils sont personnels et confidentiels et ne peuvent être modifiés que sur demande ou par l’utilisateur professionnel en cas de perte.</p>
            <p>Par ailleurs l’utilisateur professionnel peut, s’il le souhaite, modifier son mot de passe à tout moment en se connectant à son compte ou en le réinitialisant en cas d’oubli;</p>
            <p>Chaque utilisateur professionnel est entièrement responsable de l’utilisation de ses identifiants qu’il s’engage à conserver secrets et à ne pas divulguer sous quelque forme que ce soit à des tiers. En cas de perte, de vol ou de toute utilisation frauduleuse, l’utilisateur professionnel devra immédiatement en informer l’Editeur</p>
            <p>Chaque utilisateur professionnel est en conséquent seul responsable de l’utilisation des services faite sous son identifiant. Toute connexion ou transmission de données effectuée en utilisant les identifiants sera réputée avoir été effectuée par l’utilisateur et sous sa responsabilité exclusive;</p>
            <p>En conséquence l’utilisateur reconnait que télémédecine ne peut être tenue en aucun cas responsable de l’utilisation frauduleuse des identifiants de l’utilisateur par un tiers;</p>
            <h4>3- limite et suspension des services</h4>
            <p>L’Editeur se réserve le droit de suspendre l’accès des services en cas de perte, vol et ou utilisation des données d’identification de l’utilisateur professionnel dont ce dernier l’a informé en cas d’utilisation non conforme aux dispositions des présentes, en cas d’opération de maintenance.la responsabilité de l’Editeur ne peut être engagée en raison de ces interruptions et des conséquences  qui peuvent en découler pour l’utilisateur;</p>
            <h3>OBLIGATIONS </h3>
            <h4>OBLIGATIONS DE L’UTILISATEUR </h4>
            <p>- L’utilisateur s’engage à ne pas accéder et utiliser les services de la plateforme à des fins illicites ou dans le but de causer un préjudice à la réputation et l’image de Télémédecine ou plus généralement à porter atteinte aux droits, notamment propriété intellectuelle de l’Editeur</p>
            <p>- L’utilisateur s’engage à ne pas utiliser le service dans le but d’affecter ou de tenter d’affecter son bon fonctionnement ou encore  d’extraire ou de modifier tout ou partie de la plateforme;</p>
            <p>- L’utilisateur s’engage a accéder et à utiliser le service en toute bonne foi, de manière raisonnable, non contraire aux termes des CGU et pour une utilisation strictement personnelle;</p>
            <p>- En cas de manquement, à l’une de ses obligations et sans que cette liste ne soit limitative, l’utilisateur reconnait et accepte que l’Editeur a la faculté de lui refuser unilatéralement et sans notification préalable , l’accès à tout ou partie du service et qu’il se réservera le droit d’agir en justice pour engager les actions judiciaires contre l’utilisateur fautif;</p>
            <p>- L’utilisateur est seul responsable de l’utilisation qu’il fait du service auquel il accède
                L’Editeur ne pourra en aucun cas être tenue responsable dans le cadre d’une procédure introduite à l’encontre de l’utilisateur qui se serait rendu coupable d’une utilisation non conforme du service qu’il procure;</p>
            <p>L’utilisateur reconnait et accepte à cet égard qu’il fera son affaire personnelle  toute réclamation ou procédure formée contre l’Editeur du fait de l’utilisation non conforme par lui du service;</p>
            <h3>OBLIGATIONS DE L’EDITEUR</h3>
            <p> L’Editeur fera tout son possible afin de rendre son service disponible 24 heure sur 24 et 7 jours  sur 7, à fournir un accès à la plateforme et aux services dans le respect des présentes CGU;  d’agir avec diligence pour remédier à tout disfonctionnement portée à sa connaissance</p>
            <p> Cependant compte tenu des spécificités du réseau internet, l’Editeur n’offre aucune garantie de continuité du service, n’étant tenue à cet égard que d’une obligation de moyen;</p>
            <h3>V RESPONSABILTE</h3>
            <p>Chacune des parties s’engage à garder confidentiel toute information ou document dont elle aura connaissance dans le cadre de l’exécution des présentes CGU.</p>
            <p>A ce titre les parties s’engagent à ne divulguer aucune information confidentielle  sauf nécessité ou obligation légale eu égard au respect du secret médical;</p>
            <h4>RESPONSABILITES DE L’Utilisateur</h4>
            <p>L’utilisateur s’engage à ne pas accéder et ou utiliser le service à des fins illicites ou dans le but de causer un tort à la réputation et l’image de l’Editeur ou plus généralement à porter atteinte aux droits de propriétés intellectuelles de l’Editeur</p>
            <p>L’utilisateur s’engage à accéder et  à utiliser les services en toute bonne foi de manière raisonnable non contraire aux présentes CGU </p>
            <p>Il s’engage à ne pas utiliser le service dans le but d’affecter ou de tenter d’affecter son bon fonctionnement ou encore d’extraire ou de modifier tout ou partie de la plateforme(ce qui l’exposerait aux remboursements des frais de réparation sans préjudice des poursuites judiciaires adéquates);</p>
            <p>Il est seul responsable du préjudice qu’il pourrait subir en cas de manquement ou d’inexécution des présentes CGU et l’Editeur ne peut en aucune manière, être tenue responsable des informations communiquées par l’utilisateur </p>
            <p>L’utilisateur est seul responsable de l’utilisation qu’il fait du service auquel il accède</p>
            <p>L’Editeur ne pourra en aucun cas être tenue responsable dans le cadre d’une procédure introduite à l’encontre de l’utilisateur qui se serait rendu coupable d’une utilisation non conforme du service qu’il procure;</p>
            <p>L’utilisateur reconnait et accepte à cet égard qu’il fera son affaire personnelle  toute réclamation ou procédure formée contre l’Editeur du fait de l’utilisation non conforme par lui du service</p>
            <h4>Responsabilités de l’EDITEUR</h4>
            <p>La responsabilité de l’Editeur envers l’utilisateur ne peut être engagée que pour un préjudice direct causé à l’Utilisateur;</p>
            <p> L’Editeur ne peut être tenu pour responsable des informations déclarées et affichées par l’utilisateur professionnel ou toute autre information à destination de l’utilisateur non professionnel qui ne lui permettrait pas de jouir des services proposés par l’Editeur</p>
            <p>L’Editeur ne peut être tenue responsable de la non-exécution ou de la mauvaise exécution de la prise en charge proposée par l’utilisateur professionnel et ne peut être redevable des frais éventuels engagés par l’utilisateur non professionnel dans ce cadre;</p>
            <p>L’Editeur ne peut être tenu responsable  des dommages liés à l’impossibilité temporaires d’accéder à l’un des services proposés,  aux difficultés d’accès ou impossibilités d’accès aux services proposés, encore moins aux perturbations du réseau de télécommunication, à un piratage des serveurs de ses sous-traitants et plus généralement à toute attaque informatique dirigée contre lui par un tiers et les suspensions d’accès pendant les périodes de maintenance;</p>
            <p>L’Editeur ne peut être tenu responsable pour les manquements de l’utilisateur résultant d’une mauvaise utilisation de la plateforme ou d’une utilisation illégal de la plateforme et ou des services ou manquement de l’utilisateur a ses obligations légales, réglementaires, conventionnelles ou déontologiques</p>
            <h3>VI PROTECTIONS DES DONNEES  A CARACTERE PERSONNEL ;</h3>
            <p>Les responsables des données est la personne qui détermine les moyens et les finalités du traitement, c’est-à-dire l’utilisateur professionnel. Le sous-traitant des données à caractère personnel pour le compte du responsable du traitement. L’Editeur agit sous l’autorité du responsable du traitement et sur instruction de celui-ci</p>
            <p>- Les données sont récoltés sur la base du consentement exprimé par l’utilisateur qui reconnait avoir pris connaissance des présentes CGU et de ses implications relatives aux traitements des données;</p>
            <h4>1 Natures des données collectées</h4>
            <p>Dans le cadre de la mise en œuvre du service, l’Utilisateur professionnel sera amené à renseigner des données personnelles le concernant pour les créations précitées</p>
            <h3> FINALITES DES DONNEES COLLECTES</h3>
            <p>Aucune données personnelle n’est cédée ou communiquée par l’Editeur à des personnes physiques ou morales tierces non préablement autorisées par l’utilisateur dument informé</p>
            <p>Les données récoltées sont stockées sur des serveurs sécurisés et agréés par les autorités compétentes</p>
            <h4>Durée de conservation des données</h4>
            <p>Les données sont stockées sur les serveurs pour une durée proportionnelle à la durée de l’abonnement souscrit par l’utilisateur professionnel, en l’absence de souscription d’abonnement les données personnelles de l’utilisateur sont supprimés définitivement au terme d’un délai de 5 ans à compter de leur récolte.</p>
            <h4>Accès aux informations</h4>
            <p>L’Editeur interdit tout accès aux données personnelles de l’utilisateur à l’exception de l’administrateur de la plateforme qui est tenue d’une obligation de confidentialité. L’administrateur de la plateforme est en charge de la gestion des évènements rendant difficile voire impossible l’utilisation des services proposés par l’Editeur et de sa maintenance;</p>
            <p>L’Editeur s’engage à ne transmettre ces données à caractère personnel et ou sensible a aucun autre tiers non autorisé;</p>
            <p>Seul l’Editeur et ses équipes techniques sous-traitant ont accès aux informations collectées dans le cadre de l’utilisation du service, et de la gestion de la plateforme précisément l’Editeur accède aux données personnelles de l’utilisateur afin de garantir l’exécution de ses droits;</p>
            <p>L’Editeur s’engage à ne transmettre ses données à caractère personnel à aucun autre tiers non autorisé</p>
            <p>L’utilisation des services peut donner lieu à une commande de prestation facturée </p>
            <p>Le prix peut être soldé </p>
            <p>- en ligne, par carte bancaire</p>
            <p>- par virement</p>
            <p>En cas de payement en ligne ou par carte bancaire, l’utilisateur devra se conformer aux conditions générales d’utilisations prévues proposées par l’éditeur de cette structure;</p>
            <p>Conformément aux lois en vigueur sur la protection des données, l’utilisateur bénéficie d’un droit d’accès, de rectification, d’opposition, d’effacement, de suppression de ses données personnelles, ainsi qu’un droit à la limitation du traitement de ses données et à leurs portabilités.</p>
            <h3>VII PROPRIETE INTELLECTUELLE</h3>
            <p>Le contenu de la plateforme et l’utilisation des services protégés par la loi et notamment le code de propriété intellectuelle, sont exclusivement destinés à l’information et l’usage personnel de l’utilisateur qui bénéficie d’un droit d’usage privé, non collectif et non exclusif</p>
            <p>L’éditeur est propriétaire des droits de propriété intellectuelle de la plate télémedecine.com tant de la structure que de son contenu (tous  textes, graphiques, images logos, nom,  marque, dénomination, sons photos vidéos dessins données logiciels)</p>
            <p>Dès lors toute représentation, reproduction, modification, dénaturation et ou exploitation totale ou partielle de son contenu ou du service par quelque procédé que ce soit et sur quelque support que ce soit, sans l’autorisation expresse et préalable d l’Editeur est prohibée et constitue des actes de contrefaçon de droits d’auteur</p>
            <h4>Protections des signes distinctifs</h4>
            <p>Les marque, logos, dénominations sociales, sigles, noms commerciaux, enseignes et nom de domaine de l’éditeur permettant l’accès au service constituent des signes distinctifs insusceptibles d’utilisation sans l’accord expresse écrite et préalable de l’Editeur;</p>
            <p>L’utilisateur reconnait que la plateforme et tous les éléments qui la composent  et (sans que la liste soit exhaustive)  tous  textes, graphiques, images logos, nom,  marque, dénomination, sons photos vidéos dessins données logiciels sont et demeurent la propriété exclusive de Télémédecine;</p>
            <p>Télémédecine se réserve le droit de modifier, à tout moment sans préavis tout ou partie, les services ainsi que des présentes CGU notamment pour les adapter aux évolutions du services par la mise en place de nouvelles fonctionnalités ou la suppression ou la modification de fonctionnalité existantes  par l’édition d’une fenêtre d’informations   au moins ( 3) jours avant la date d’entrée en vigueur de la modification sans droit à des indemnités;</p>
            <p>L’utilisateur qui continue d’utiliser les services après la date d’entrée en vigueur, sera réputé avoir accepté la modification;</p>
            <h3>VIII FORCE MAJEUR</h3>
            <p>La responsabilité de l’éditeur ne pourra être engagée si l’exécution de l’une de ses obligations est empêchée ou retardée en raison d’une force majeure tel que définie juridiquement</p>
            <h3>IX RECLAMATION ET Résiliations</h3>
            <p> Une ou plusieurs stipulations des présentes CGU venaient à être déclarées nulles par une autorité judiciaire en vertu d’une disposition légale ou réglementaire, elle serait réputée non écrite mais n’entrainerait pas la nullité des autres stipulations;</p>
            <p>Chaque utilisateur peut à tout moment résilier son compte personnel en contactant l’Editeur ainsi que toute réclamation</p>
            <h3>X LOI APPLICABLE ET JURIDICTION COMPETENTE</h3>
            <p>Tout désaccord sur l’interprétation ou l’application des présentes CGU relèvera de la compétence des juridictions sénégalaises et du droit sénégalais à l’exclusion de toute autre législation;</p>
            <p>En cas de rédaction des présentes conditions générales en plusieurs langue ou de traduction seule la version française fera foi;</p>
        </div>

    </div>
</container>
</v-app>
</template>

<script>
import navbar from "../components/Home/utilities/navbar.vue";
export default {
  components: { navbar },

};
</script>
<style>
ul {
  list-style-type: disc;
  margin-left: 20px;
}
h3{
    text-align: center;
    margin: 5px;
}
h4{
    text-align: center;
    margin: 5px;
}
p { line-height: 2; }
li { line-height: 2; }
</style>