<template>
  <div>
    <div class="font-5 font-weight-bold text-center py-5">Créer un compte patient</div>
  <div v-if="activation" class="activation-code pa-6">
    <div class="form-activation">
      <v-alert
      v-if="errorCode"
      dense
      outlined
      type="error"
      dismissible
    >
      {{message}}
    </v-alert>
      <v-text-field
        v-model="code"
        class="form"
        name="code"
        placeholder="Entrer votre code d'activation"
        outlined
      ></v-text-field>
      
      <v-row class="mb-2">
        <v-col>
          <v-btn
            class="text-center red darken-2 white--text"
            style="text-transform: none"
            min-width="160px"
            @click="reset"
          >
            <v-icon class="mr-2">mdi-close-circle</v-icon> Annuler
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            class="text-center primary white--text"
            style="text-transform: none"
            min-width="160px"
            :loading="loading"
            @click="actived()"
          >
            <v-icon class="mr-2">mdi-check-circle</v-icon> Activer
          </v-btn>
        </v-col>
    </v-row>
    </div>
  </div>
  <div class="pa-3" v-else>
    
    <div class="form-login">
      <v-text-field
        v-model="prenom"
        label="Prénom"
        class="form"
        name="prenom"
        placeholder="Entrer votre Prénom"
        outlined
        required
        :error-messages="errors.first_name"
      ></v-text-field>
      <v-text-field
        v-model="nom"
        label="Nom"
        class="form"
        name="nom"
        placeholder="Entrer votre Nom"
        required
        outlined
        :error-messages="errors.last_name"
      ></v-text-field>
      <vue-tel-input-vuetify 
        v-model="phone"
        class="form"
        name="phone"
        placeholder="Entrer votre N° téléphone"
        label="Numéro téléphone"
        hint="Un code va vous être envoyé sur ce numéro pour valider votre compte."
        outlined
        mode="international"
        :error-messages="errors.phone"
       >
      </vue-tel-input-vuetify>
     
      <v-text-field
        v-model="email"
        label="Adresse e-mail"
        class="form"
        name="email"
        placeholder="Entrer votre Email"
        outlined
        :error-messages="errors.email"
      ></v-text-field>
      <v-text-field
        v-model="password"
        class="form"
        name="password"
        label="Mot de passe"
        placeholder="Votre mot de passe"
        outlined
       
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show ? 'text' : 'password'"
        @click:append="show = !show"
        :error-messages="errors.password"
      ></v-text-field>
    </div>
    <v-checkbox v-model="checkbox" dense :error-messages="errors.checkbox">
      <template v-slot:label>
        <div class="caption">
          J'accepte les
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <a
                target="_blank"
                href="/conditions-generales"
                @click.stop
                v-on="on"
              >
                Conditions d'utilisation de sahel assistance
              </a>
            </template>
          </v-tooltip>
        </div>
      </template>
    </v-checkbox>
    <div class="mb-2">
      <v-btn
        block
        class="primary white--text mt-2"
        style="text-transform: none"
        :loading="loading"
        @click="save"
      >
        S'inscrire
      </v-btn>
    </div>
  </div>
  <v-dialog
    v-model="dialogCompteExiste"
    max-width="500px"
  >
  <v-card  class="font-6 pa-3" min-height="150">
    <v-card-text class="mt-3">
      <div>Il semble que votre compte existe déjà.</div>
         <div class="mb-3">
          Vous pouvez réinitialiser votre mot de passe pour récupérer votre compte.
          <router-link :to="{name: 'forgot.password'}" style="text-decoration: none; font-weight: bold;">
            Réinitialiser le mot de passe ?
          </router-link>
        </div>
      <div class="float-right">
          <v-btn color="info" style="text-transform: none;" @click="dialogCompteExiste = false" elevation="0" small>Fermer</v-btn>
      </div>
    
  </v-card-text>
  </v-card>
 
    
  </v-dialog>
</div>
</template>
  
  <script>
  
export default {
  data() {
    return {
      errorPhone: false,
      loading: false,
      errorCode: false,
      message: "",
      activation: false,
      prenom: "",
      nom: "",
      phone: "",
      email: "",
      password: "",
      checkbox: false,
      code: "",
      errors: {},
      show: false,
      dialogCompteExiste: false,
      validPhone: false,
    };
  },
  methods: {
    
    save() {
      this.errors = {}
      // if (!this.validPhone) {
      //   this.errors.phone = ["Le numéro de téléphone est incorrect."];
      //   return;
      // }
      
      if(this.checkbox) {
        this.errors = {}
        if(!this.errorPhone) {
        this.loading = true
        const data = {
          first_name: this.prenom,
          last_name: this.nom,
          phone: this.phone ? this.phone.match(/\S+/g).join("") : null,
          email: this.email,
          password: this.password
        };
        const baseURI = 'auth/patient/register';
        this.$http.post(baseURI, data)
        .then(() => {
          this.activation = true;
          this.loading = false
        }).catch((error) => {
          this.loading = false
          this.errors = error.response.data.errors
          if(error.response.data.unique_error) {
            this.dialogCompteExiste = true
          }
        })
        }
      
      } else {
        this.errors.checkbox = ["Veuillez cocher les condictions d'utilisation"]
      }
     
    },
    actived() {
      this.loading = true
      this.errorCode = false
      this.message = ""
      const data = {
        code: this.code
      };
      const baseURI = 'auth/patient/compte/verify';
      this.$http.post(baseURI, data)
      .then((result) => {
        this.message = result.data.message
        this.activation = false
        this.loading = false
        this.reset()
        this.$emit('codeValide');
      }).catch(error => {
        this.errorCode = true
        this.message = error.response.data.message
        this.loading = false
      })
    },
    reset() {
      this.loading = false
      this.errorCode = false
      this.message = ""
      this.activation = false
      this.phone = ""
      this.email = ""
      this.password = ""
      this.checkbox = ""
      this.code = ""
      this.prenom = ""
      this.nom = ""
      this.errors = {}
    },
    verifyNumber() {
      if(!this.$refs.telInput._computedWatchers.phoneObject.value.isValid) {
        this.errorPhone = true;
        this.errors.phone = "Le numéro est incorrecte"
      } else {
        this.errorPhone = false;
        this.errors.phone = null;
      }
      
    },
    
  },
};
</script>
  
<style scoped>

</style>