<template>
    <v-app class="animate__animated animate__fadeIn animate__slow">
        <v-app-bar :color="color" class="my-app" fixed style="padding: 0 5%" dark flat id="nav-bar">
            <div class="d-flex align-center">
                <p class="font-5 mb-n1" :style="`color:${colo}`">
                    <v-avatar size="40" color="white">
                        <img src="../assets/logo_sahel.png" alt="alt" />
                    </v-avatar>
                    <span class="px-2 hidden-sm-and-down">SAHEL ASSISTANCE</span>
                </p>
            </div>

            <v-spacer></v-spacer>
            <div class="d-none d-sm-flex">
                <div class="d-flex align-center">
                    <v-btn  class="font-6 font-weight-bold" text plain to="#doctor_register">
                        Vous êtes professionnel de santé
                    </v-btn>
                    <v-btn class="font-6 font-weight-bold" text plain link to="#patient_register">
                        Vous êtes patient
                    </v-btn>
                    
                    <v-btn v-if="!authenticated"  class="font-6 font-weight-bold" text plain
                        @click="singUp">S'inscrire</v-btn>
                    <v-btn v-if="!authenticated"  class="font-6 font-weight-bold" text plain
                        @click="singIn">Se connecter</v-btn>
                    <menu-account :user="user" v-if="authenticated" :color="btnColor" />

                    <v-btn class="font-6 font-weight-bold" :color="btnColor" rounded
                        @click="help=true">Besoin d'aide ?</v-btn>
                </div>
            </div>
            <div class="hidden-md-and-up">
                <menu-account :user="$auth.user" :color="btnColor" v-if="authenticated"/>
                <v-btn v-else style="
                
                text-decoration: none;
                color: white !important;
              " small outlined color="primary" @click="showDialogConnect">
                    s'inscrire | se connecter
                </v-btn>
            </div>
            <!-- <v-app-bar-nav-icon class="d-flex d-sm-none" /> -->
        </v-app-bar>
        <hero-home />
        
        <!-- <drawer-mobile v-model="drawer" @showDialogConnect="showDialogConnect" /> -->
        <v-main>
            <router-view />
        </v-main>
        <Footer />
        <v-dialog class="text-center" v-model="dialog" max-width="430px">
            <v-card>
                <v-tabs v-model="tab" grow>
                    <v-tab class=""> Se connecter </v-tab>
                    <v-tab class=""> S'inscrire </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <login @connected="connected" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <register @codeValide="codeValide" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-dialog>
        <besoin-aide v-if="help" v-model="help" />
    </v-app>
</template>
  
<script>
import login from "../components/login.vue";
import Register from "../components/register.vue";
//import registerDoctor from "../components/registerDoctor.vue";
import "../css/fonts.css";
import HeroHome from "../components/Home/hero.home.vue";
import MenuAccount from "../components/Home/utilities/MenuAccount.vue";
//import DrawerMobile from "../components/Home/utilities/DrawerMobile.vue";
import Footer from "../components/Home/utilities/footer.vue";
import BesoinAide from '../components/Home/BesoinAide.vue';
export default {
    components: { login, Register, HeroHome, MenuAccount,/* DrawerMobile,*/ Footer, BesoinAide },
    name: "Home",
    data: () => ({
        help: false,
        drawer: false,
        user: null,
        authenticated: false,
        color: "transparent",
        colo: "white",
        btnColor: "#025397",
        dialog: false,
        dialog1: false,
        tab: 0,
        medecins: [],
    }),
    methods: {
        singUp() {
            this.tab = 1
            this.dialog = true
        },
        singIn() {
            this.tab = 0
            this.dialog = true
        },
        showDialogConnect() {
            this.dialog = true;
            //this.dialog1 = true;
        },
        codeValide() {
            this.$toast.success({
                title: "Validation code",
                message: "Votre compte vient d'être activé avec succès",
            });
            this.tab = 0;
        },
        connected() {
            this.dialog = false;
            this.$router.go();
            //this.$router.go()
        },
    },
    mounted() {
        this.$nextTick(() => {
            const navbar = document.getElementById("nav-bar");
            if(navbar) {
                window.addEventListener("scroll", () => {
                if (window.scrollY > 20) {
                    this.color = "#025397";
                    this.colo = "whitesmoke";
                    this.btnColor = "#00A19A";
                    navbar.classList.add("scale-in-center");
                } else {
                    this.color = "transparent";
                    this.colo = "white";
                    this.btnColor = "#025397";
                    navbar.classList.remove("scale-in-center");
                    navbar.classList.add("scale-out-center");
                }
            });
            }
           
       })
        
        if (this.$auth.check()) {
            this.authenticated = true;
            this.user = this.$auth.user;
        }
    },
};
</script>
<style>
.v-application--wrap {
    background-color: #f5f8fa;
}

@media screen and (max-width: 500px) {
    .footer {
        padding: 10%;
    }

    .v-toolbar__content,
    .v-toolbar__extension {
        padding: 4px 0 4px 2px;
    }

    .my-app {
        padding: 0 5px 0 5px !important;
    }

    .textpraticien {
        margin-top: 2rem;

    }
    .vue-image-crop-upload .vicp-wrap {
  width: 350px !important;
  height: 450px !important;
 /* max-height: 600px !important; */
}
}
</style>
  