<template>
    <div>
         <!-- Signature -->
    <div class="signature mb-6">
        <card-title :icon="'mdi-upload'" :title="'Signature'"></card-title>
        <signature :url_signature="doctor.signature_url" :signature="doctor.signature" @saveSignature="saveSignature"></signature>
    </div>
    </div>
  </template>
  
  <script>
import Signature from '../../../components/doctor/Signature.vue'
import CardTitle from '../../../components/Home/utilities/cardTitle.vue'
  export default {
  components: { Signature, CardTitle },
  props: ['doctor'],
  
  methods: {
    saveSignature(data) {
                this.doctor.signature_url = data.url
                this.doctor.signature = data.signature
            },
  }
  }
  </script>
  
  <style>
  
  </style>