<template>
    <div v-if="medical_visit">
        <h2 class="mb-3">Modifier viste médicale</h2>
        <v-sheet class="pa-6 mb-6">
            <div class="font-6 font-weight-bold mb-3">
                Patient
            </div>
            <v-divider></v-divider>
            <div class="info-patient">
                <div class="font-3 font-weight-bold">{{ medical_visit.proche ? medical_visit.proche.name :
                    medical_visit.patient.name }}</div>
                <!-- <div class="text-center">{{ patient.age }}</div> -->
                <div class="d-flex">
                    <div class="">{{ medical_visit.proche ? medical_visit.patient.phone : medical_visit.patient.tel_port }}
                    </div>
                    <div class=""> / {{ medical_visit.proche ? medical_visit.proche.address : medical_visit.patient.address
                    }}</div>
                </div>
            </div>
        </v-sheet>
        <v-sheet class="pa-6">
            <div class="font-6 font-weight-bold">
                Visite médicale N° {{ medical_visit_id }}
            </div>
            <div class="row justify-space-between mt-6">
                <div class="col-md-6">
                    <div class="font-6 font-weight-bold">Date Examen</div>
                    <v-text-field v-model="medical_visit.date_examen" name="date" placeholder="Date examen" outlined dense
                        type="date"></v-text-field>
                </div>
                <div class="col-md-6">
                    <div class="font-6 font-weight-bold">Nom du medecin</div>
                    <v-text-field v-model="medical_visit.owner.name" name="nom_medecin" outlined dense
                        disabled></v-text-field>
                </div>

            </div>
            <v-divider></v-divider>
            <div class="font-6 font-weight-bold mt-3">Conclusion</div>
            <div class="row justify-space-between mt-3">
                <div class="col-md-3">
                    <div class="font-6 font-weight-bold">Aptitude</div>
                    <v-select v-model="medical_visit.aptitude" :items="etats" outlined dense/>
                </div>
                <div class="col-md-3">
                    <div class="font-6 font-weight-bold">Date limite</div>
                    <v-text-field v-model="medical_visit.date_limite" name="date_limite" outlined dense type="date"
                    ></v-text-field>
                </div>
                <div class="col-md-6">
                    <div class="font-6 font-weight-bold">Note</div>
                    <v-textarea v-model="medical_visit.note" name="note" outlined dense
                    ></v-textarea>
                </div>

            </div>
        </v-sheet>
        <v-divider></v-divider>

        <form-consultation :forms="medical_visit.data" :loading="loading" @formSaved="updateMedicalVisit" />
    </div>
</template>
  
<script>
import formConsultation from '../../../components/consultation/formConsultation.vue'
export default {
    components: { formConsultation },
    data() {
        return {
            medical_visit_id: this.$route.params.medical_visit_id,
            medical_visit: null,
            loading: false,
            etats: ['Apte', 'Inapte', 'Restriction']
        }
    },
    mounted() {
        this.getMedicalVisit()
    },


    methods: {
        getMedicalVisit() {
            this.$http.get(`medical_visit/${this.medical_visit_id}/show`).then(({ data }) => {
                this.medical_visit = data
            })
        },
        updateMedicalVisit(forms) {
            const donnees = {
                data: forms,
                note: this.medical_visit.note,
                aptitude: this.medical_visit.aptitude,
                date_limite: this.medical_visit.date_limite,
                date_examen: this.medical_visit.date_examen
            }

            this.$http.put(`medical_visit/${this.medical_visit_id}`, donnees).then(() => {
                this.$toast.success({
                    title: 'Modification visite médicale',
                    message: "La visite médicale a été mise à jour"
                })
                this.$router.go(-1)
            }).catch(() => {
                this.$toast.error({
                    title: 'Modification visite médicale',
                    message: "Impossible de modifier la visite médicale"
                })
            })
        }
    }

}
</script>
  
<style></style>