<template>
    <div  class="activation-code pa-6">
    <p>Veuillez cliquer <span v-on:click="destroyPatientAccount" class="lien-suppression">ici</span> pour supprimer votre compte et vous réinscrire , ou <span v-on:click="save()" class="lien-suppression">ici</span> pour entrer votre code d'activation reçu par SMS.</p>
    <div class="popup" v-if="activation">
        <v-container class="ctainer" >
                <div  class="activation-code pa-6 z-index: 999">
                <div class="form-activation">
                        <v-alert
                            v-if="errorCode"
                            dense
                            outlined
                            type="error"
                            dismissible
                            class="red-text-alert"
                            >
                        {{message}}
                        </v-alert>

                        <v-text-field
                            v-model="code"
                            class="form"
                            name="code"
                            placeholder="Entrer votre code d'activation"
                            outlined
                        ></v-text-field>
                        
                        <v-row class="mb-2">
                                <v-col>
                                    <v-btn
                                    class="text-center"
                                    style="text-transform: none"
                                    min-width="160px"
                                    :loading="loading"
                                    @click="actived()"
                                    >
                                    <v-icon class="mr-2">mdi-check-circle</v-icon> Activer
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn
                                    class="text-center red darken-2 white--text"
                                    style="text-transform: none"
                                    min-width="160px"
                                    @click="reset"
                                    >
                                    <v-icon class="mr-2">mdi-close-circle</v-icon> Annuler
                                    </v-btn>
                                </v-col>
                        </v-row>
                    </div>
                </div>
        </v-container> 
    </div>
    </div>
     

</template>

<script>


export default ({
    data() {
    
      return {
        id: '',
        activation: false,
        errorCode: '',
        code : '',
        loading : false,
        errors : false,
      };
    },
    mounted() {
        this.id = this.$route.params.id;
    },

    methods: {
       
        destroyPatientAccount(){
            // alert("Votre compte sera supprimé !");
            this.$http.delete(`auth/patient/destroyPatient/${this.id}`).then ( data => {
                console.log(data.response.message);
            });
            this.$router.push({ name: 'home' });
        },

        save() {
            this.activation = true;
        },
        actived() {
            this.loading = true
            this.errorCode = false
            this.message = ""
            const data = {
                code: this.code
            };
            const baseURI = 'auth/patient/compte/verify';
            this.$http.post(baseURI, data)
            .then((result) => {
                this.message = result.data.message
                this.activation = false
                this.loading = false
                this.reset()
                this.$emit('codeValide');
            }).catch(error => {
                this.errorCode = true
                this.message = error.response.data.message
                this.loading = false
            })
        },

        reset() {
        this.loading = false
        this.errorCode = false
        this.message = ""
        this.activation = false
        this.code = ""
        this.errors = {}
        },

    },
})
</script>

<style>
/* app.css */
.lien-suppression {
  color: blue; /* Couleur du lien */
  cursor: pointer; /* Curseur de la souris en forme de main pour indiquer que c'est cliquable */
}
.lien-suppression:hover {
  text-decoration: underline; /* Souligner le lien lorsque la souris est dessus */
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;

}
.ctainer { 
    /* background-color: white;  */
    display: flex;
    justify-content: center;
    align-items: center;
}
.red-text-alert .v-alert__content,
.red-text-alert .v-alert__icon {
  color: red; /* Couleur du texte et de l'icône en rouge */
}

</style>
