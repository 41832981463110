<template>
  <div>
    <card-title :title="'Définir mes dates d\'indisponibilité'" :icon="'mdi-calendar-remove'">
            <v-btn style="text-transform:none" color="primary" elevation="0" outlined @click="showForm=true">Modifier</v-btn>
        </card-title>
        <v-sheet class="pa-6">
            <div class="" v-if="doctor.unavailability_date_start"> Vous avez défini les dates d'indisponibilité du <v-chip color="error"> {{ formatDate(doctor.unavailability_date_start) }} </v-chip> au <v-chip color="error">{{ formatDate(doctor.unavailability_date_end) }}</v-chip></div>
            <div v-else> Vous n'avez pas encore défini de dates d'indisponibilité</div>
        </v-sheet>
        
        <!-- Formulaire de dates d'indisponobilitées -->
    <unavailability-day-form 
        v-model="showForm"
        v-if="showForm"
        :doctor_id="doctor.id"
        :unavailability_date_end="doctor.unavailability_date_end" 
        :unavailability_date_start="doctor.unavailability_date_start" 
        @saveUnavailabilityDays="saveUnavailabilityDays"
    />
  </div>
</template>

<script>
import UnavailabilityDayForm from '../../../components/doctor/UnavailabilityDayForm.vue'
import cardTitle from '../../../components/Home/utilities/cardTitle.vue'
export default {
  components: { cardTitle, UnavailabilityDayForm },
  props:['doctor'],
    data() {
       
        return {
            showForm: false
        }
    },
    methods: {
        saveUnavailabilityDays(data) {
            this.doctor.unavailability_date_start = data.unavailability_date_start
            this.doctor.unavailability_date_end = data.unavailability_date_end
        },
        formatDate(dateString) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(dateString).toLocaleDateString('fr-FR', options);
        }
    }
}
</script>

<style>

</style>