<template>
  <div style="width: 100%; height: 500px;"></div>
</template>
<script>
import * as echarts from 'echarts';

export default {
  name: 'Chart',
  props: {
    consultations: Array,
    medical_visits: Array
  },
  mounted() {
    //this.getStats();
    this.renderChart();
  },
  data() {
    return {
      colors: ['#5470C6', '#EE6666'],
    }
  },
  methods: {
    
    renderChart() {
      const chart = echarts.init(this.$el);
      chart.setOption(this.getOption());
    },
    getOption() {
      return {
        color: this.colors,
  tooltip: {
    trigger: 'none',
    axisPointer: {
      type: 'cross'
    }
  },
  legend: {},
  grid: {
    top: 70,
    bottom: 50
  },
  xAxis: [
    {
      type: 'category',
      axisTick: {
        alignWithLabel: true
      },
      axisLine: {
        onZero: false,
        lineStyle: {
          color: this.colors[1]
        }
      },
      axisPointer: {
        label: {
          formatter: function (params) {
            return (
              params.value +
              (params.seriesData.length ? '：' + params.seriesData[0].data : '')
            );
          }
        }
      },
      // prettier-ignore
      data: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre']
    },
    {
      type: 'category',
      axisTick: {
        alignWithLabel: true
      },
      axisLine: {
        onZero: false,
        lineStyle: {
          color: this.colors[0]
        }
      },
      axisPointer: {
        label: {
          formatter: function (params) {
            return (
              params.value +
              (params.seriesData.length ? '：' + params.seriesData[0].data : '')
            );
          }
        }
      },
      // prettier-ignore
      data: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre']
    }
  ],
  yAxis: [
    {
      type: 'value'
    }
  ],
  series: [
    {
      name: 'Consultations(2023)',
      type: 'line',
      xAxisIndex: 1,
      smooth: true,
      emphasis: {
        focus: 'series'
      },
      data: this.consultations
    },
    {
      name: 'Visites médicales(2023)',
      type: 'line',
      smooth: true,
      emphasis: {
        focus: 'series'
      },
      data: this.medical_visits
    }
  ]
      }
    }
  }
}

</script>

<style>

</style>