<template>
  <v-app>
    <navbar>
      <div class="prise_rv">
        <step-prive-rv :doctor="doctor" :date-select="dateSelect" />
      </div>
    </navbar>
  </v-app>
</template>

<script>
import Navbar from "../components/Home/utilities/navbar.vue";
import StepPriveRv from "../components/StepPriveRv.vue";
export default {
  components: { StepPriveRv, Navbar },
  data() {
    return {
      step: 1,
      doctor: null,
      dateSelect: null,
    };
  },
  watch: {
    $route: "getDoctor",
  },
  created() {
    /* if(this.$auth.check()) {
      if(this.$auth.user.is_doctor) {
        this.$router.push({ name: 'doctor.home'})
      } 
      else if(this.$auth.user.is_secretaire) {
        this.$router.push({ name: 'secretaire.home'})
      } 
      else if(this.$auth.user.is_admin) {
        this.$router.push({ name: 'secretaire.home'})
      }
      else {
        this.$router.push({ name: 'home'})
      }
    } */
    this.getDoctor();
  },
  methods: {
    getDoctor() {
     
      let doctor_id = this.$route.params.id;
      this.dateSelect = this.$route.params.date
        ? this.$route.params.date
        : null;
      this.$http.get(`auth/doctor/${doctor_id}`).then(({ data }) => {
        this.doctor = data.data;
      });
    },
  },
};
</script>

<style scoped>
.prise_rv {
  margin-top: 5%;
}
</style>