<template>
    <drawer :links="links" />
  </template>
  <script>
    import Drawer from '../../components/Home/utilities/Drawer.vue';
    export default {
        components: {Drawer},
    data: () => ({
      links: [
        ["mdi-account-circle", "Mon compte", "intervenant.home"],
        ["mdi-calendar-clock", "Gérer mes rendez-vous","intervenant.mes-rendez-vous"],
        ["mdi-account-supervisor", "Patients", "intervenant.patients"],
        ["mdi-file-document", "Mes documents","intervenant.documents"],
        ["mdi-sofa", "Salle Attente téléexpertise","intervenant.room.waitings"],
        ["mdi-bell", "Notifications", "intervenant.notify"]
        
      ],
    }),
   
  };
  </script>