<template>
  <v-dialog
    v-model="show"
    persistent
    width="500px"
    eager
    >
    <v-card>
        <v-card-title>
            <div class="font-6 font-weight-bold">{{ title }}</div>
            <v-spacer></v-spacer>
            <v-btn  icon color="error" @click="show=false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-6">
            <v-form>
                <div class="font-7 font-weight-bold mb-2">Nom de la prestation</div>
                <v-text-field
                    v-model="local_motif.name"
                    name="name"
                    placeholder="Nom de la prestation"
                    id="name"
                    outlined
                    dense
                    :error-messages="errors.name"
                ></v-text-field>
                <div class="font-7 font-weight-bold mb-2">Montant de la prestation</div>
                <v-text-field
                    v-model="local_motif.montant"
                    name="montant"
                    placeholder="Montant de la prestation"
                    id="montant"
                    type="number"
                    outlined
                    dense
                    :error-messages="errors.montant"
                ></v-text-field>
                <div class="font-7 font-weight-bold mb-2">Durée de la prestation</div>
                <v-text-field
                    v-model="local_motif.duree"
                    name="montant"
                    placeholder="Durée de la prestation en minutes"
                    id="montant"
                    type="number"
                    outlined
                    dense
                    :error-messages="errors.duree"
                ></v-text-field>
                <div class="font-7 font-weight-bold mb-2">Description de la prestation</div>
                <v-textarea
                    v-model="local_motif.description"
                    placeholder="Description de la prestation"
                    name="description"
                    id="description"
                    outlined
                    dense
                    :error-messages="errors.description"
                />
                <div class="d-flex justify-end">
                    <v-btn color="error" style="text-transform: none; margin-right: 20px;" elevation="0" outlined @click="show=false">
                       Annuler
                    </v-btn>
                    <v-btn 
                        color="primary" 
                        style="text-transform: none;" 
                        elevation="0" 
                        :loading="loading"
                        @click="save"
                    >
                        Enregistrer
                    </v-btn>
                   
                </div>
            </v-form>
        </v-card-text>
    </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true
        },
        motif: {
            type: Object,
            default(){
                return {}
            }
        }
    },
    data() {
        return {
            errors: {},
            loading: false,
            local_motif: this.motif
        }
    },
    computed: {
        show: {
        get() {
            return this.value;
        },
        set(value) {
            return this.$emit('input', value)
        }
        },
        title() {
            return this.motif.id ? 'Modification prestation de rendez-vous' : 'Ajouter une nouvelle prestation de rendez-vous';
        }
    },
    methods: {
        save() {
            this.loading = true
            if(this.motif.id){
                this.$http.put(`motif/${this.motif.id}`, this.local_motif).then(({ data }) => {
                    this.$emit('updatedMotif', data.data)
                    this.show =false
                    this.$toast.success({
                        title: 'Modification Motif',
                        message: 'Prestation de rendez-vous modifier avec succès !'
                    })
                }).catch((error) => {
                    this.errors = error.response.data.errors
                }).finally(() => {
                    this.loading = false
                })
            } else {
                this.$http.post('motif', this.local_motif).then(({ data }) => {
                    this.$emit('createdMotif', data.data)
                    this.show = false
                }).catch((error) => {
                    this.errors = error.response.data.errors
                }).finally(() => {
                    this.loading = false
                })
            }
        }
    }
}
</script>

<style>

</style>