<template>
  <div>
      <form-search-medecin />
  </div>
</template>

<script>
import formSearchMedecin from "./utilities/form.search.medecin.vue";
export default {
  components: { formSearchMedecin },
  data() {
    return {
      dataCookies: null,
    };
  },
  created() {
    if(this.$cookies.get('appointment')) {
      this.dataCookies = this.$cookies.get('appointment');
    }
  },
  methods: {},
};
</script>

<style scoped>
.bar-search {
  height: 90px;
  width: 300px;
  /* border-radius: 35px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 0px; */
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: white;
  padding: 0 10px 0 10px;
}
/* .search-container {
  border-radius: 35px;
  height: 200px;
  width: 90%;
  border-top-left-radius: 0px;
} */

@media screen and (max-width: 500px) {
  .bar-search {
    width: 100%;
    height: 60px;
  }
}
</style>