<template>
  <v-dialog
    v-model="show"
    max-width="400px"
    transition="dialog-transition"
  >
    <v-card>
        <v-card-title>
            <div class="font-6 font-weight-bold">Champs {{ field.label }}</div>
            <v-spacer></v-spacer>
            <!-- <v-btn  icon color="error" @click="show=false" outlined small>
                <v-icon >mdi-close</v-icon>
            </v-btn> -->
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-3">
            <v-textarea
                v-if="field.type === 'textarea'"
                :placeholder="field.label"
                outlined
            />
            <v-checkbox
                v-else-if="field.type === 'checkbox'"
                v-for="(box,i) in field.options"
                :key="i"
                :value="box"
                :label="box"
                :placeholder="field.label"
            />
            <v-radio-group v-else-if="field.type === 'radio'">
                <v-radio
                v-for="(item,j) in field.options"
                :key="j"
                :label="item"
                :value="item"
            />
            </v-radio-group>
            
            <v-text-field
                v-else
                :placeholder="field.label"
                outlined
                :type="field.type"
            ></v-text-field>

            <div>
                <v-btn color="primary" outlined style="text-transform: none;" elevation="0" @click="show=false" class="mt-3">fermer</v-btn>
            </div>
        </v-card-text>
        
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    props: {
        value: Boolean,
        field: Object
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                return this.$emit('input',value)
            }
        }
    }
}
</script>

<style>

</style>