<template>
  <div>
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
          style="font-weight: bold;"
          elevation="0"
          rounded
          :color="color"
          class="mx-2"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="font-weight-bold mr-2"> mdi-account </v-icon> Mon Espace
        </v-btn>
      </template>
      <v-list min-width="150px">
        <v-list-item
          v-for="(item, i) in menus"
          :key="i"
          dense
          link
          :to="{ name: item.link }"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title>
              <v-btn
                small
                style="
                  text-transform: none;
                  padding: 0px;
                  background-color: white;
                "
                @click="logout"
              >
                Se déconnecter
              </v-btn>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
    props: {user: Object, color: String },
  data() {
    return {
      menus: [],
      menusPatient: [
        {
          icon: "mdi-account-circle",
          title: "Mon profil",
          link: "patient.home",
        },
        {
          icon: "mdi-calendar-clock",
          title: "Gerer mes rendez-vous",
          link: "compte.mes-rendez-vous",
        },
        {
          icon: "mdi-account-supervisor",
          title: "Mes proches",
          link: "compte.mes-proches",
        },
        {
          icon: "mdi-file-outline",
          title: "Mes documents",
          link: "compte.documents",
        },
      ],
      menusDoctor: [
        {
          icon: "mdi-view-dashboard",
          title: "Tableau de bord",
          link: "doctor.home",
        },
        {
          icon: "mdi-account-circle",
          title: "Mon profil",
          link: "doctor.profil"
        },
        {
          icon: "mdi-doctor",
          title: "Mes Paramètres",
          link: "doctor.parametre",
        },
        {
          icon: "mdi-calendar-clock",
          title: "Rendez-vous",
          link: "doctor.appointments",
        },
        {
          icon: "mdi-clipboard-pulse",
          title: "Consultations",
          link: "doctor.consultations",
        },
        {
          icon: "mdi-stethoscope",
          title: "Visites Médicales",
          link: "doctor.visite-medicales",
        },
        {
          icon: "mdi-account-tie-woman",
          title: "Secrétaire",
          link: "doctor.secretaria",
        },
        {
          icon: "mdi-account-multiple",
          title: "Patients",
          link: "doctor.patients",
        },
      ],
      menusSecretaire: [
        {
          icon: "mdi-view-dashboard",
          title: "Tableau de bord",
          link: "secretaire.home",
        },
        {
          icon: "mdi-doctor",
          title: "Mon Profil",
          link: "secretaire.compte",
        },
        {
          icon: "mdi-calendar-clock",
          title: "Rendez-vous",
          link: "secretaire.appointments",
        },
        {
          icon: "mdi-file-table",
          title: "Factures",
          link: "secretaire.factures",
        },
      ],
      menusIntervenant: [
      {
          icon: "mdi-account-circle",
          title: "Mon profil",
          link: "intervenant.home",
        },
        {
          icon: "mdi-calendar-clock",
          title: "Gerer mes rendez-vous",
          link: "intervenant.mes-rendez-vous",
        },
        {
          icon: "mdi-sofa",
          title: "Salle Attente téléexpertise",
          link: "intervenant.room.waitings"
        },
      ],
      menusEntreprise: [
        {
          icon: 'mdi-account-child-outline',
          title: 'Profil',
          link: 'entreprise.home'
        },
        {
          icon: 'mdi-account-cog-outline',
          title: 'Paramètre',
          link: 'entreprise.parametre'
        },
        {
          icon: 'mdi-account-group-outline',
          title: 'Patients',
          link: 'entreprise.patients'
        }
      ]
    };
  },
  mounted() {
    if(this.$auth.user.is_patient) {
      this.menus = this.menusPatient
    } else if( this.$auth.user.is_doctor) {
      this.menus = this.menusDoctor
    } else if( this.$auth.user.is_secretaire) {
      this.menus = this.menusSecretaire
    } else if(this.$auth.user.is_intervenant) {
      this.menus = this.menusIntervenant
    } else if(this.$auth.user.is_entreprise) {
      this.menus = this.menusEntreprise
    }
    else {
      return ;
    }
  },
  methods: {
    logout() {
      this.authenticated = false;
      this.$auth.logout();
      //this.$router.go("/");
    },
  },
};
</script>

<style>
</style>