<template>
    <div>
        <v-dialog v-model="show" persistent :overlay="false" max-width="450px" transition="dialog-transition">
            <v-card>
                <v-card-subtitle class="py-6">
                    <div class="d-flex justify-center">
                        <h2>Ajouter un nouveau document</h2>
                    </div>
                </v-card-subtitle>
                <v-card-text>
                    <v-text-field v-model="filename" name="filename" label="Nom du document" outlined dense
                        :error-messages="errors.filename"></v-text-field>
                    <v-file-input ref="file-input" prepend-icon="" label="Parcourir" v-model="file" outlined dense
                        :error-messages="errors.file" />
                    <div>
                        <v-progress-linear v-if="uploading" v-model="uploadPercentage" height="25">
                            <strong>{{ uploadPercentage }}%</strong>
                        </v-progress-linear>
                    </div>
                    <div class="d-flex justify-end py-3">
                        <v-btn color="error" @click="close" class="mr-2">Annuler</v-btn>
                        <v-btn color="primary"  @click="submitFile">Enregistrer</v-btn>
                        
                    </div>

                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

export default {
    props: {
        value: Boolean,
        patient_id: {
            type: String,
            required: true
        },
        intervenant_id: {
            type: String
        },
        proche_id: {
            type: String,
            default() {
                return null;
            }
        },
        type_url: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        }
        
    },
    data() {
        return {
            errors: {},
            filename: "",
            uploading: false,
            uploadPercentage: 0,
            file:null,
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                return this.$emit('input', value)
            }
        }
    },
    methods: {
        submitFile() {
            
            this.uploading = true;

            const config = {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                    this.uploadPercentage = parseInt(
                        Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    );
                },
            };

            const data = new FormData();

            if(this.patient_id)
            {
                data.append("patient_id", this.patient_id);
            }
            if(this.proche_id) {
                data.append("proche_id", this.proche_id);
            }
            
            data.append("file", this.file);
            data.append("filename", this.filename);
            data.append("intervenant_id", this.intervenant_id);
            
            if(this.type_url == 'consultations')
            {
                data.append('consultation_id', this.id)
            } else {
                data.append('medical_visit_id', this.id)
            }
           
            this.$http.post(`file`, data, config).then(({ data }) => {
                this.$toast.success({
                    title: 'Création Fichier',
                    message: 'Le fichier a été crée avec succès !'
                })
                this.$emit("createdFile", data.data);
                this.close();
                //this.$handleMessage(`Le fichier a bien été téléversé.`, 'success')
            })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                })
                .finally(() => {
                    this.uploading = false;
                    //this.close()
                });
        },
        close() {
            this.show = false;

        }
    }
}
</script>

<style></style>