<template>
    <div style="width: 100%; height: 500px;"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'utilisateur-chart',
    props: {
        data: {
            type: Array,
            required: true
        }
    },
    mounted() {
        this.renderChart();
    },
    methods: {
        renderChart() {
            const chart = echarts.init(this.$el);
            chart.setOption(this.getOption());
        },
        getOption() {
            return {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                series: [
                    {
                        name: 'Utilisateurs',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 40,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: this.data
                    }
                ]
            };
            // option 2
            // return {
            //     tooltip: {
            //         trigger: 'item'
            //     },
            //     legend: {
            //         top: '5%',
            //         left: 'center',
            //         // doesn't perfectly work with our tricks, disable it
            //         selectedMode: false
            //     },
            //     series: [
            //         {
            //             name: 'Access From',
            //             type: 'pie',
            //             radius: ['40%', '70%'],
            //             center: ['50%', '70%'],
            //             // adjust the start angle
            //             startAngle: 180,
            //             label: {
            //                 show: true,
            //                 formatter(param) {
            //                     // correct the percentage
            //                     return param.name + ' (' + param.percent * 2 + '%)';
            //                 }
            //             },
            //             data: [
            //                 { value: 1048, name: 'Search Engine' },
            //                 { value: 735, name: 'Direct' },
            //                 { value: 580, name: 'Email' },
            //                 { value: 484, name: 'Union Ads' },
            //                 { value: 300, name: 'Video Ads' },
            //                 {
            //                     // make an record to fill the bottom 50%
            //                     value: 1048 + 735 + 580 + 484 + 300,
            //                     itemStyle: {
            //                         // stop the chart from rendering this piece
            //                         color: 'none',
            //                         decal: {
            //                             symbol: 'none'
            //                         }
            //                     },
            //                     label: {
            //                         show: false
            //                     }
            //                 }
            //             ]
            //         }
            //     ]
            // };
        }
    }
}
</script>

<style></style>