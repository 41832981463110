<template>
   <v-dialog
      v-model="show"
      persistent
      max-width="650px"
      transition="dialog-transition"
      hide-overlay
    >  
    <v-card>
        <div class="d-flex justify-space-between py-3">
        <v-btn icon>
            <v-icon size="30" @click="retour">mdi-arrow-left</v-icon>
        </v-btn>
        <div class="mt-2 title">Envoyer document</div>
        <v-btn icon @click="show = false ">
            <v-icon size="30">mdi-close</v-icon>
        </v-btn>
        </div>
        <v-divider />
        <v-card-text class="py-6">
        <embed :src="file.file_url" width="100%" />
        </v-card-text>
        <v-card-actions style="align-content: flex-end">
        <v-btn block color="primary" style="text-transform: none" @click="sendFile" :loading="loading">Envoyer</v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
</template>
  
  <script>
export default {
  props: {
    file: { type: Object },
    value: { type: Boolean },
    appointment_id: { type: String }
  },
  data() {
    return { loading: false };
  },
  computed: {
    show: {
        get() {
            return this.value
        },
        set(value) {
            return this.$emit('input', value)
        }
    }
  },
  methods: {
    retour() {
      this.$emit("prevSelect", 1);
    },
    sendFile() {
        this.loading = true;
        const data = {
            file_id : this.file.id,
            appointment_id: this.appointment_id
        }
        this.$http.post('files/attach-appointment', data).then(({ data }) => {
            this.loading = false;
            this.$emit('fileSendingSuccessfuly',data.data)
            
        }).catch(() => {
          this.loading = false
        })
    }
    
  },
};
</script>
  
  <style>
</style>