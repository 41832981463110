<template>
    <div>
      <div v-if="loading">
        <loading-page />
      </div>
      <chart :consultations="consultations" :medical_visits="medical_visits" v-else />
    </div>
  </template>
  
  <script>
  import LoadingPage from '../Home/utilities/LoadingPage.vue';
  import Chart from './Chart.vue';
  
  export default {
    name: 'App',
    components: {
      Chart,
        LoadingPage
    },
    data() {
      return {
        doctor_id: this.$auth.user.doctor_id,
        loading: false,
        consultations:  [],
        medical_visits: [],
      }
    },
    mounted() {
      this.getStats()
    },
    methods: {
      getStats() {
      this.loading = true
      this.$http.get(`dashboard/per/consultations/${this.doctor_id}`).then(( {data} ) => {
        this.consultations = data.consultations
        this.medical_visits = data.medical_visits
      }).catch(() => {
        this.$toast.error({
          title: 'erreur',
          message: "Impossible de recuperer les stats"
        })
      }).finally(() => this.loading = false)
    },
    }
  }
  </script>
  