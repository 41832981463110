<template>
  <v-dialog
    v-model="show"
    persistent :overlay="false"
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card>
        <v-card-title><div>Annulation de salle d'attente</div></v-card-title>
        <v-divider></v-divider>
        <v-sheet class="pa-6">
            <p>
                Votre téléconsultation en direct va être annulée, veuillez vous rapprocher du cabinet pour le remboursement.
            </p>

            <div class="d-flex">
                <v-btn elevation="0" style="text-transform: none;margin-right: 15px;" color="red" outlined @click="show=false">Annuler</v-btn>
                <v-btn elevation="0" style="text-transform: none;" color="primary" @click="confirm">J'accepte</v-btn>
               
            </div>
        </v-sheet>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
    props: {value: Boolean},
    data(){return {}},
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        confirm() {
            this.show =false
            this.$emit('confirmed');
        }
    }
}
</script>

<style>

</style>