<template>
  <div>
    <v-row align-content="center" justify="center">
      <v-col cols="12" md="10">
        <v-sheet class="pa-6">
          <h2 class="text-center">Secrétaire</h2>
          <div class="" v-if="secretaire.id">
            <div class="d-flex justify-center">
              <div class="col-6">
                <profile-secretaire :secretaire="secretaire" @compteDeleted="compteDeleted"/>
              </div>
              
            </div>
          </div>
          <div class="text-center pa-4 grey--text" v-else>
            <v-icon size="50">mdi-alert-circle-outline</v-icon>
            <div class="d-flex justify-center">
              <div class="col-6">Créer un secretaire pour prendre en charge certaines tâches administratives telles que la gestion des rendez-vous, la prise de notes, la gestion des factures, la gestion des dossiers patients </div>
            </div>
            <div> 
              <v-btn class="text-lowercase mt-2" outlined color="primary" @click="showForm=true">créer un secretaire</v-btn>
            </div>
            

          </div>
        </v-sheet>
      </v-col>

    </v-row>

    <form-secretaire v-if="showForm" :secretaire="secretaire" v-model="showForm"  @savedSecretaire="savedSecretaire"></form-secretaire>
  </div>
</template>

<script>
import formSecretaire from '../../components/secretaires/formSecretaire.vue';
import ProfileSecretaire from '../../components/secretaires/ProfileSecretaire.vue';
export default {
  components: { formSecretaire, ProfileSecretaire },
  data(){
    return {
      showForm: false,
      secretaire: {
        
      },
    }
  },
  mounted(){
    this.getSecretaire()
  },
  methods: {
    getSecretaire() {
      this.$http.get('secretaire').then(({ data }) => {
        if(data.data) {
          this.secretaire = data.data
        }
        
      }).catch((error) => {
        this.$toast.error({
          title: 'Error',
          message: error.response.data.message
        })
      })
    },
    verifyNumber() {
      if(!this.$refs.telInput._computedWatchers.phoneObject.value.isValid) {
        this.errorPhone = true;
        this.errors.phone = "Le numéro est incorrecte"
      } else {
        this.errorPhone = false;
        this.errors.phone = null;
      }
      
    },
    savedSecretaire() {
     
      this.getSecretaire()
      //this.secretaire = secretaire
      this.showForm = false
    },
    compteDeleted(){
      this.secretaire = {}
    }
  }
}
</script>

<style>

</style>