<template>
  <div>
    <div class="data-consultation">
				<div class="" v-for="form in data" :key="form.id">
					<div class="font-weight-bold pt-6">{{ form.name }}</div>
					<v-divider dark style="border-bottom: 1px solid black ;"></v-divider>
					<v-row class="">
						<v-col cols="12" md="6" v-for="field in form.fields" :key="field.id">
							<div class="d-flex justify-space-between py-2">
								<div class="prenom">{{ field.label }}</div>
								<div class="d-flex" v-if="field.type === 'checkbox'">
									<div class="mr-1" v-for="(item, i) in field.value" :key="i">
										<v-chip>
											{{ item }}
										</v-chip>

									</div>
								</div>
								<div class="font-weight-bold" v-else>{{ field.value }}</div>
							</div>
							<v-divider></v-divider>
						</v-col>

					</v-row>
				</div>

			</div>
  </div>
</template>

<script>
export default {
    props: {
        data: {
          type: Array,
          required: true
        }
    }

}
</script>

<style>

</style>