var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"items":_vm.factures,"headers":_vm.headers,"item-key":"id","loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"pa-4"},[_c('v-icon',{attrs:{"size":"40"}},[_vm._v("mdi-alert-circle-outline")]),_c('div',{},[_vm._v("Aucune facture pour le moment")])],1)]},proxy:true},{key:"item.patient",fn:function({item}){return [_c('div',{},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
                            name: _vm.$auth.user.is_secretaire ? 'secretaire.patient.detail' : 'doctor.patient.detail', 
                            params:{ 
                                auteur: item.patient.patient_id ? 'proche' : 'patient',
                                patient: item.patient.id
                            } 
                        }}},[_c('span',{},[_vm._v(_vm._s(item.patient.name))])])],1)]}},{key:"item.motif",fn:function({item}){return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":item.is_consultation ? 'success' : 'info',"label":"","text-color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-label ")]),_c('div',{staticClass:"text-captitalize"},[_vm._v(" "+_vm._s(item.is_consultation ? 'Consultation' : 'Visite Médicale')+" ")])],1)]}},{key:"item.actions",fn:function({item}){return [(!item.payee)?_c('v-btn',{staticClass:"text-lowercase mr-3",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.showFacture(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v(" Modifier")],1):_c('v-btn',{staticClass:"text-lowercase",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.printFacture(item)}}},[_c('v-icon',[_vm._v("mdi-printer")]),_vm._v(" Imprimer")],1)]}}])}),(_vm.pageCount>1)?_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},on:{"input":_vm.paginate},model:{value:(_vm.current_page),callback:function ($$v) {_vm.current_page=$$v},expression:"current_page"}})],1):_vm._e(),(_vm.showForm)?_c('form-facture',{attrs:{"facture":_vm.facture},on:{"printFacture":_vm.printFacture},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }