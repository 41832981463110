<template>
  <div>
    <v-layout row justify-center>
      <v-dialog v-model="show" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="subtitle-2">{{ formTitle }}</span>
            <v-spacer />
            <v-btn icon color="error" @click="close">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="font-weight-bold">Genre</div>
            <v-radio-group v-model="proche.sexe" row dense :error-messages="errors.sexe">
              <v-radio label="Femme" value="femme"></v-radio>
              <v-radio label="Homme" value="homme"></v-radio>
            </v-radio-group>
            <v-text-field v-model="proche.first_name" name="first_name" label="Prénom" outlined dense
              :error-messages="errors.first_name" />

            <v-text-field v-model="proche.last_name" name="last_name" label="Nom" outlined dense
              :error-messages="errors.last_name" />
            <div>
              <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="proche.date_naissance" label="Date de Naissance" readonly v-bind="attrs"
                    v-on="on" dense outlined :error-messages="errors.date_naissance"></v-text-field>
                </template>
                <v-date-picker v-model="proche.date_naissance" :active-picker.sync="activePicker" :max="new Date(
                  Date.now() - new Date().getTimezoneOffset() * 60000
                )
                    .toISOString()
                    .substr(0, 10)
                  " min="1950-01-01" @change="save"></v-date-picker>
              </v-menu>
            </div>
            <v-text-field v-model="proche.lieu_naissance" name="lieu_naissance" label="Lieu de Naissance" outlined dense
              :error-messages="errors.lieu_naissance" />
            <vue-tel-input-vuetify v-model="proche.phone" label="Numéro de téléphone" outlined mode="international"
              ref="telInput" required :error="errorPhone" @change="verifyNumber"
              :error-messages="errors.phone"></vue-tel-input-vuetify>
            <!-- <v-text-field
              v-model="proche.phone"
              name="phone"
              label="Numéro de téléphone"
              outlined
              dense
              :error-messages="errors.phone"
            /> -->
            <v-text-field v-model="proche.address" name="address" label="Adresse" outlined dense
              :error-messages="errors.address" />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.native="close">Annuler</v-btn>
            <v-btn color="primary" text @click="saveProche" :loading="loading">Enregistrer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    proche: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      errors: {},
      activePicker: null,
      menu: false,
      errorPhone: false,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    formTitle() {

      if (this.proche.id != null) {
        return "Modifier un proche";
      } else {
        return "Ajouter un nouveau proche";
      }
    },
  },

  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    verifyNumber() {
      if (!this.$refs.telInput._computedWatchers.phoneObject.value.isValid) {
        this.errorPhone = true;
        this.errors.phone = "Le numéro est incorrecte"
      } else {
        this.errorPhone = false;
        this.errors.phone = null;
      }

    },
    saveProche() {
      this.errors = {};
      if (!this.errorPhone) {
        
        this.loading = true;
        if (this.proche.phone) {
          this.proche.phone = this.proche.phone.match(/\S+/g).join("")
        }
        if (!this.proche.id) {
          //this.proche.patient_id = this.$auth.user.patient_id;
          this.$http
            .post("proche/store", this.proche)
            .then(({ data }) => {
              this.$emit("createProche", data.data);
              this.loading = false;
              this.close();
            })
            .catch((err) => {
              this.loading = false;
              if (err.response.data.errors) {
                this.loading = false;
                this.errors = err.response.data.errors;
              }
            });
        } else {
          this.$http
            .put(`proche/${this.proche.id}`, this.proche)
            .then((data) => {
              this.$emit("updateProche", data.data.data);
              this.loading = false;
              this.close();
            })
            .catch((err) => {
              if (err.response.data.errors) {
                this.loading = false;
                this.errors = err.response.data.errors;
              } else {
                alert('Erreur serveur')
                this.close();
              }

            });
        }
      }

    },
    close() {
      this.errors = {};
      this.proche = {};
      this.show = false;
    },
  },
};
</script>

<style></style>