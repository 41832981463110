<template>
    <div v-if="appointment">
      <v-row align="center" justify="center">
        <v-col class="" cols="12" md="12">
          <v-row >
            <v-col cols="12" md="8">
              <div class="mx-3">
                <v-sheet class="mb-6">
                <div :class="appointment.rvpasse ? 'error pa-3 white--text' : 'primary pa-3 white--text'" v-if="appointment.formated_date">
                    <span>
                        <v-icon color="white">mdi-calendar</v-icon>
                        {{ appointment.formated_date }}
                    </span>
                    <span>
                        <v-icon size="20" color="white" class="px-2">mdi-alarm</v-icon>
                        {{ appointment.formated_time }}
                    </span>
                    <span class="float-right">
                        <v-icon size="20" color="white" class="">mdi-av-timer</v-icon> {{ appointment.delay_status }}
                    
                    </span>
                </div>
                <div class="info-patient text-center">
                    <div>
                        <v-avatar
                            size="50"
                            color="white"
                        >
                            <v-icon size="50">mdi-account-circle</v-icon>
                        </v-avatar>
                    </div>
					<div class="font-6 font-weight-bold" v-if="appointment.intervenant">
                        {{ appointment.proche ? appointment.proche.name : appointment.intervenant.first_name+ ' '+ appointment.intervenant.last_name}}
                    </div>
                    <div class="font-6 font-weight-bold" v-if="appointment.patient_id">
                        {{ appointment.proche ? appointment.proche.name : appointment.patient.name }}
                    </div>
                    <!-- <div>
                        {{ appointment.proche ? appointment.proche.address : appointment.patient.address }}
                    </div> -->
                    <div v-if="appointment.patient_id">
                        {{ appointment.proche ? appointment.proche.AGE : appointment.patient.AGE }}
                    </div>
					<div class="font-weight-thin"> 
						{{ appointment.proche ? 'Proche' : (appointment.patient ? 'Patient' : 'Intervenant') }}
					</div>
                    <div class="my-3">
						<v-btn 
							color="primary" 
							small 
							style="text-transform: none;margin-bottom: 10px;"
							link
							:to="{ 
								name: $auth.user.is_secretaire ? 'secretaire.patient.detail' : 'doctor.patient.detail',
								params: { 
									auteur: this.appointment.proche ? 'proche' : 'patient', 
									patient: appointment.proche ? appointment.proche.id : appointment.patient.id
								}
							}"
							v-if="appointment.patient_id || appointment.proche"
						>
							Voir profil
						</v-btn>
                    </div>
                    <v-divider></v-divider>
                    <div>
                      <v-row no-gutters>
                        <v-col cols="12" md="6" sm="6">
                          <v-btn color="success" text block style="margin: 0%; padding: 24px" @click="showDialogModifyDate">Deplacer RDV</v-btn>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="12" md="6" sm="6">
                          <v-btn color="error" text block style="margin: 0%; padding: 24px" @click="dialogDelete=true">Annuler RDV</v-btn>
                        </v-col>
                      </v-row>
                    </div>
                </div>
              </v-sheet>
              <v-sheet class="pa-3 mb-6" v-if="$auth.user.is_doctor">
                <div class="font-6 font-weight-bold mb-2">Preparer le rendez-vous</div>
                <v-btn color="primary" text style="text-transform: none;margin-bottom: 10px; padding-left: 0%;" @click="(dialogNote = true) && (note_doctor=appointment.note_doctor)">
                  <v-icon>mdi-file-plus-outline</v-icon>
                  {{ appointment.note_doctor ==null ? 'Ajouter' : 'Modifier'}} une note
                </v-btn>
				<div class="caption" v-if="appointment.note_doctor">
					<span class="font-weight-bold">Note:</span> {{ appointment.note_doctor }}
				</div>
				
              </v-sheet>
              <v-sheet class="pa-3 mb-6" v-if="$auth.user.is_doctor">
                <div class="font-6 font-weight-bold">Les documents envoyés</div>
                <div v-if="appointment.files.length">
                  <div class="my-3">
                    <file-item
                      v-for="file in appointment.files"
                      :file="file"
                      :appointment_id="appointment.id"
                      :doctor_id="appointment.doctor.user.id"
                      :key="file.id"
                      @deleteShareSuccess="deleteShareSuccess"
                    />
                  </div>
                </div>
                <div v-else class="text-center">
                      <v-icon>mdi-file-remove-outline</v-icon>
                    <div class="font-7"> Pas de documents</div>
                </div>
              </v-sheet>
              <v-sheet
                class="pa-3"
              >
                <div class="font-6 font-weight-bold mb-3">Details du rendez-vous</div>
				<div class="my-2" v-if="appointment.proche">
					<v-divider />
					<div class="d-flex justify-space-between my-2" v-if="appointment.intervenant">
						<div>Intervenant</div>
						<div class="font-weight-bold">{{ appointment.intervenant.name }}</div>
					</div>
					<div class="d-flex justify-space-between my-2" v-else>
						<div>Patient</div>
						<div class="font-weight-bold">{{ appointment.patient.name }}</div>
					</div>
					<v-divider />
				</div>
				<div class="d-flex justify-space-between my-2">
                  <div class="">Statut rendez-vous</div>
                  <div>
					<v-switch
						v-model="appointment.status"
						color="primary"
						:error="!appointment.status"
						style="margin-top: 0%; padding-top: 0%;"
						@change="changeStatus"
					></v-switch>
					</div>
                </div>
				<v-divider />
				<div class="d-flex justify-space-between my-2">
                  <div class="">Paiement</div>
                  <div>
					<v-switch
						v-model="appointment.is_pay"
						color="primary"
						:error="!appointment.is_pay"
						style="margin-top: 0%; padding-top: 0%;"
						@change="changePay"
					></v-switch>
					</div>
                </div>
				<v-divider />
                <div class="d-flex justify-space-between my-2" v-if="appointment.motif">
                  <div>{{ appointment.motif.name }}</div>
                  <div class="font-weight-bold">{{ appointment.motif.montant.toLocaleString('fr-FR') }} Fcfa</div>
                </div>
				
				
              </v-sheet>
              </div>
            </v-col>
            <v-divider vertical inset></v-divider>
            <v-col cols="12" md="4">
                <v-sheet class="pa-12 mx-3" v-if="$auth.user.is_doctor">
					<div class="mb-6" v-if="!appointment.is_cabinet">
						<v-btn color="primary" outlined @click="getSession" :loading="loadVisio">
						<v-icon class="mr-2">mdi-video-outline</v-icon>
						Demarrer une video conférence
						</v-btn>
                    </div>
                    <div class="mb-6">
                    <v-btn color="primary" outlined link 
						:to="{ 
							name: 'doctor.visit.medical.patient.create', 
							params: {
								patient: this.appointment.proche ? this.appointment.proche.id : this.appointment.patient.id,
								auteur: this.appointment.proche ? 'proche' : 'patient'
							}
						}"
						v-if="appointment.patient_id || (appointment.intervenant && appointment.proche)"
					>
                      <v-icon class="mr-2">mdi-stethoscope</v-icon>
                      Demarrer une Visite Médicale
                    </v-btn>
                  </div>
                  <div class="mb-6">
                    <v-btn color="primary" outlined
					:to="{ 
							name: 'doctor.consultation.patient.create', 
							params: { 
								patient: this.appointment.proche ? this.appointment.proche.id : this.appointment.patient.id,
								auteur: this.appointment.proche ? 'proche' : 'patient'
							},
							
						}"
						v-if="appointment.patient_id || (appointment.intervenant && appointment.proche)"
					>
                      <v-icon class="mr-2">mdi-clipboard-pulse</v-icon>
                      Demarrer une consultation
                    </v-btn>
                  </div>
                  
                </v-sheet>
            </v-col>
      </v-row>
        </v-col>
    </v-row>
		<!-- Dialog Suppression RDV -->
		<delete-dialog 
			v-if="dialogDelete"
			:message="` Êtes-vous sûr de vouloir supprimer le rendez-vous du ${appointment.formated_date} ?`" 
			:title="`Suppression de rendez-vous`" 
			v-model="dialogDelete"
			@confirmDelete="confirmDelete"
		/>
		<!-- Form Deplacer Date -->
		<v-dialog
			v-if="dialogModifyDate"
			v-model="dialogModifyDate"
			persistent
			width="400"
		>
			<v-card>
				<v-card-title>
					<div class="font-6 font-weight-bold">Modifier la date du rendez-vous</div>
					<v-spacer></v-spacer>
					<v-btn icon color="error" small @click="dialogModifyDate=false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-form>
						<div class="font-7 font-weight-bold">Choisir une date</div>
						<v-text-field
							v-model="date"
							name="date"
							placeholder="Choisir une date"
							outlined
							type="date"
							:error-messages="errors.date"
						></v-text-field>
						<div class="font-7 font-weight-bold">Choisir une heure</div>
						<v-text-field
							v-model="heure"
							name="heure"
							placeholder="Choisir une heure"
							outlined
							type="time"
						></v-text-field>
					</v-form>
					<div class="d-flex">
						<v-btn style="text-transform: none;margin-right: 15px;" color="primary" elevation="0" @click="save" :loading="loading">Modifier</v-btn>
						<v-btn style="text-transform: none;" color="error" outlined elevation-0 @click="dialogModifyDate=false"> Annuler</v-btn>
                    </div>
				</v-card-text>
			</v-card>
		</v-dialog>
		<!-- Dialog Formulaire de note -->
		<v-dialog 
			v-if="dialogNote"
			v-model="dialogNote"
			width="400px"
		>
		<v-card>
				<v-card-title>
					<div class="font-6 font-weight-bold">{{ appointment.note_doctor ==null ? 'Ajouter' : 'Modifier'}} la note du rendez-vous</div>
					<v-spacer></v-spacer>
					<v-btn icon color="error" small @click="dialogNote=false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-form>
						<div class="font-7 font-weight-bold">Saisir note du rendez-vous</div>
						<v-textarea
							v-model="note_doctor"
							name="note_doctor"
							placeholder="Veuillez saisir une note sur ce rendez-vous"
							outlined
							:error-messages="errors.note_doctor"
						></v-textarea>
					</v-form>
					<div class="d-flex">
						<v-btn style="text-transform: none;margin-right: 15px;" color="primary" elevation="0" @click="saveNote" :loading="loading">{{ appointment.note_doctor ==null ? 'Ajouter' : 'Modifier'}}</v-btn>
						<v-btn style="text-transform: none;" color="error" outlined elevation-0 @click="dialogNote=false"> Annuler</v-btn>
                    </div>
				</v-card-text>
			</v-card>
		</v-dialog>
    </div>
</template>
  
<script>
import FileItem from '../../components/file/FileItem.vue';
import DeleteDialog from '../../components/Home/utilities/DeleteDialog.vue';
import { mapActions} from 'vuex'
  export default {
    
    components: { FileItem, DeleteDialog },
    data() {
      return {
        appointment: null,
		dialogDelete: false,
		dialogModifyDate: false,
		dialogNote: false,
		note_doctor: '',
		date: null,
		heure: null,
		loading: false,
		loadVisio: false,
		errors: {},
		
      }
    },
	computed: {},
    watch: {
      $route: 'getAppointment'
    },
    mounted() {
      this.getAppointment()
    },
    methods: {
		getAppointment() {
			const appointment = this.$route.params.appointment
			this.$http.get('appointment/'+appointment).then(({ data }) => {
				this.appointment = data.data
			})
		},
		changePay() {
			const appointment = this.$route.params.appointment
			this.$http.get(`appointment/${appointment}/change_is_pay`).then(({ data }) => {
				this.$toast.success({
					title: 'Paiement Rendez-vous',
					message: data.message
				})
			}).catch(() => {
				this.$toast.error({
					title: 'Paiement Rendez-vous',
					message: "Une erreur c'est produite lors du changement du paiement."
				})
				this.appointment.is_pay = !this.appointment.is_pay
			})
		},
		changeStatus() {
			const appointment = this.$route.params.appointment
			this.$http.get(`appointment/${appointment}/change_status`).then(({ data }) => {
				this.$toast.success({
					title: 'Statut Rendez-vous',
					message: data.message
				})
			}).catch(() => {
				this.$toast.error({
					title: 'Statut Rendez-vous',
					message: "Le statut n'est pas pu etre changé"
				})
				this.appointment.status = !this.appointment.status
			})
		},
		
		deleteShareSuccess(file) {
			this.$toast.success({
				title: 'Partage Fichier',
				message: 'Le partage du fichier est annulé avec succès !'
			})
			this.appointment.files = this.appointment.files.filter(doc => doc.id != file.id);
		},
		showDialogModifyDate() {
			this.date = this.appointment.date.substring(0, 10),
			this.heure = this.appointment.date.substring(11,)
			this.dialogModifyDate = true

		},
		save() {
		this.loading = true
		this.$http.put(`appointment/${this.appointment.id}/date`, {date: this.date+'T'+this.heure}).then(({ data }) => {
        this.dialogModifyDate = false;
        this.appointment = data.data;
        this.$toast.success({
          title: 'Modification Date RV',
          message: 'Votre rendez-vous a été deplacé avec succès.'
        })
        //this.$emit('dateModify', data.data);
      }).catch((error) => {
		if(error.response.data.errors) {
			this.errors = error.response.data.errors
		}
		else {
			this.$toast.error({
          title: 'Erreur',
          message: 'Une erreur est survenue lors de la mise à jour de la date du rende-vous'
        })
		}
        
      }).finally(() => this.loading = false)
		},
		saveNote() {
			const appointment = this.$route.params.appointment;

			this.$http.put(`appointment/${appointment}/note_doctor`, {note_doctor: this.note_doctor}).then((data) => {
				this.$toast.success({
					title: 'Note Rendez-vous',
					message: data.data.message
				})
				this.appointment.note_doctor = this.note_doctor
				this.note_doctor = ''
				this.dialogNote = false
			})
		},
		confirmDelete() {
			this.$http.delete(`appointment/${this.appointment.id}`)
				.then(() => {
					this.$toast.success({
						title: 'Annulation  RDV',
						message: 'Votre rendez-vous a été annulé avec succès.'
					})
				this.dialogDelete = false;
          this.$router.push({name: 'doctor.appointments'})
          //this.$emit("deleteAppointment", data.data);
			}).catch(() => {
				this.$toast.error({
					title: 'Erreur',
					message: 'Une erreur est survenue lors de l\'annulation du rende-vous'
					})
			});
		},
		...mapActions(['getVisio']),
		getSession() {
			
			this.getVisio({ sessionId: this.appointment.sessionId, type: 'appointment'})
		}
    }
  }
  </script>
  
  <style>
  
  </style>