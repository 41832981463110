<template>
  <div>
		<div v-if="loading">
			<loading-page />
		</div>
	<div v-if="medical_visit">
		<v-sheet class="d-flex pa-6 mb-3">
			<div class="font-3 font-weight-bold">Visite Médicale N° {{ medical_visit_id }}</div>
			<v-spacer></v-spacer>
			<v-btn color="primary" outlined style="text-transform: none; margin-top: 10px" @click="$router.go(-1)">Retour</v-btn>
		</v-sheet>
		<v-divider></v-divider>
		<!-- Patient -->
		<v-sheet class="pa-6">
			<div class="patient">
				<div class="font-4 font-weight-bold">Patient</div>
				<patient-item 
					:auteur="medical_visit.proche ? 'proche' : 'patient'" 
					:patient="medical_visit.proche ? medical_visit.proche : medical_visit.patient" 
				/>
			</div>
		</v-sheet>
		<!-- Consultatin data -->
		<v-sheet class="pa-6 mb-3">
			<div class="d-flex">
				<div class="font-3 font-weight-bold mb-1">Visite médicale</div>
				<v-spacer></v-spacer>
				<div class="d-flex">
					<v-tooltip bottom v-if="medical_visit.is_from_me">
					<template #activator="{on, attr}">
						<v-btn  
							icon 
							color="info" 
							outlined 
							class="mr-3" 
							v-on="on" 
							v-bind="attr"
							link
							:to="{ name: 'doctor.visit.medical.update', params: { medical_visit_id: medical_visit_id } }"
						>
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					
					</template>
					<span>Modifier</span>
				</v-tooltip>
				<v-tooltip bottom v-if="medical_visit.is_from_me">
					<template #activator="{on, attr}">
						<v-btn  
							icon 
							color="warning" 
							class="mr-2" 
							outlined 
							v-on="on" 
							v-bind="attr"
							@click="printMedicalVisit"
						>
							<v-icon>mdi-printer</v-icon>
						</v-btn>
					
					</template>
					<span>Imprimer</span>
				</v-tooltip>
				<v-tooltip bottom v-if="medical_visit.is_from_me|| $auth.user.is_secretaire">
					<template #activator="{on, attr}">
						<v-btn  
							color="blue-grey darken-4" 
							class="mr-2" 
							outlined 
							v-on="on" 
							v-bind="attr"
							style="text-transform: none;"
							@click="printCertificat"
						>
							<v-icon>mdi-printer</v-icon> Certificat
						</v-btn>
					
					</template>
					<span>Imprimer Certificat</span>
				</v-tooltip>
				<v-tooltip bottom v-if="medical_visit.is_from_me">
					<template #activator="{on, attr}">
						<v-btn  
							icon 
							color="error" 
							outlined 
							v-on="on" 
							v-bind="attr"
							@click="dialogDelete=true"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					
					</template>
					<span>Supprimer</span>
				</v-tooltip>
				</div>
			</div>
			<!-- Forms data -->
			<div class="" v-if="$auth.user.is_doctor">
				<data-forms :data="medical_visit.data" />
				<div class="mt-6" >
					<div class="font-6 font-weight-bold text-decoration-underline">Conclusion</div>
					<v-row class="mt-3">
						<v-col cols="12" md="6">
							<div class="d-flex justify-space-between">
								<div>Medecin:</div>
								<div class="font-weight-bold">{{ medical_visit.owner.name }}</div>
							</div>
							<v-divider></v-divider>
						</v-col>
						<v-col cols="12" md="6">
							<div class="d-flex justify-space-between">
								<div>Date Examen:</div>
								<div class="font-weight-bold">{{ medical_visit.la_date ?  medical_visit.la_date : '---'}}</div>
							</div>
							<v-divider></v-divider>
						</v-col>
						
					</v-row>
					<div class="pa-4 mt-3 conclusion rounded-lg">
						<div class="d-flex justify-space-between font-6">
							<div>
								<div class="font-weight-bold">Etat</div>
								<div>{{ medical_visit.aptitude ? medical_visit.aptitude :'---' }}</div>
							</div>
							<div>
								<div class="font-weight-bold">Note</div>
								<div class="d-flex justify-start">{{ medical_visit.note ?  medical_visit.note : '---' }}</div>
							</div>
							<div class="">
								<div class="font-weight-bold">Date limite</div>
								<div>{{ medical_visit.date_limite ? medical_visit.date_limite : '---' }}</div>
							</div>

							
						</div>

					</div>
					
				</div>
			</div>
		</v-sheet>
		<!-- ordonnance -->
		<v-sheet class="pa-6 mb-3">
			<div class="d-flex justify-space-between">
				<div class="font-4 font-weight-bold">
					<v-icon color="black">mdi-file-document-outline</v-icon>
					Ordonnance
				</div>
				<div class="d-flex">
					<v-tooltip bottom v-if="medical_visit.is_from_me">
						<template #activator="{ on, attr }">
							<v-btn icon color="primary" outlined small class="mr-2" v-on="on" v-bind="attr"
								@click="addMedicament">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</template>
						<span>Ajouter un médicament</span>
					</v-tooltip>
					<v-tooltip bottom v-if="(medical_visit.is_from_me || $auth.user.is_secretaire) && medical_visit.ordonnances.length">
						<template #activator="{ on, attr }">
							<v-btn icon color="info" outlined small v-on="on" v-bind="attr" @click="printOrdonnance">
								<v-icon>mdi-printer</v-icon>
							</v-btn>
						</template>
						<span>Imprimer ordonnance</span>
					</v-tooltip>
				</div>
			</div>
			<v-divider></v-divider>
			<div class="my-6">
				<div v-if="medical_visit.ordonnances.length">
					<div class="row justify-space-between">
						<div class="font-weight-bold col-md-3">Nom médicament</div>
						<div class="font-weight-bold col-md-4">Dosage</div>
						<div class="font-weight-bold col-md-3">Nombre</div>
						<div class="font-weight-bold col-md-2 d-flex justify-end" v-if="medical_visit.is_from_me">Actions</div>
					</div>
					<item-medicament 
						v-for="medicament in medical_visit.ordonnances" 
						:key="medicament.id"
						:ordonnance="medicament" 
						@medicamentDeleted="medicamentDeleted" 
						:is_from_me="medical_visit.is_from_me"
					/>
				</div>
				<div v-else class="text-center">
					<div>
						<v-icon size="50">mdi-alert-circle-outline</v-icon>
						<div class="">Pas encore d'ordonnonce</div>
					</div>

				</div>

			</div>

		</v-sheet>
		<!-- Fichiers -->
		<v-sheet class="pa-6 mb-3" v-if="$auth.user.is_doctor">
			<div class="d-flex justify-space-between">
				<div class="font-4 font-weight-bold">
					<v-icon color="black">mdi-paperclip</v-icon>
					Fichiers
				</div>
				<div class="d-flex" v-if="medical_visit.is_from_me">
					<v-tooltip bottom>
						<template #activator="{ on, attr }">
							<v-btn icon color="primary" outlined small class="mr-2" v-on="on" v-bind="attr"
								@click="addFile">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</template>
						<span>Ajouter un fichier</span>
					</v-tooltip>
				</div>
			</div>
			<v-divider></v-divider>
			<div class="my-6">
				<div v-if="medical_visit.files.length">
					<list-files :files="medical_visit.files" @fileDeleted="fileDeleted" />
				</div>
				<div v-else class="text-center">
					<div>
						<v-icon size="50">mdi-alert-circle-outline</v-icon>
						<div class="">Pas encore fichiers</div>
					</div>
				</div>
			</div>


		</v-sheet>
		<!-- Bulletins -->
		<v-sheet class="pa-6 mb-3">
			<div class="d-flex justify-space-between">
				<div class="font-4 font-weight-bold">
					<v-icon color="black">mdi-paperclip</v-icon>
					Bulletins
				</div>
				<div class="d-flex" v-if="medical_visit.is_from_me">
					<v-tooltip bottom>
						<template #activator="{ on, attr }">
							<v-btn icon color="primary" outlined small class="mr-2" v-on="on" v-bind="attr"
								@click="addBulletin">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</template>
						<span>Ajout bulletin</span>
					</v-tooltip>
				</div>
			</div>
			<v-divider></v-divider>
			<div class="my-6">
				<div v-if="medical_visit.bulletins.length">
					<div class="row justify-space-between">
						<div class="font-weight-bold col-md-3">Type Examen</div>
						<div class="font-weight-bold col-md-4">Diagnostic</div>
						<div class="font-weight-bold col-md-4">Examen</div>
						<div class="font-weight-bold col-md-1 d-flex justify-end" v-if="medical_visit.is_from_me || $auth.user.is_secretaire">Actions</div>
					</div>
					<item-bulletin 
						v-for="bulletin in medical_visit.bulletins" 
						:key="bulletin.id" :bulletin="bulletin"
						@bulletinDeleted="bulletinDeleted"
						:is_from_me="medical_visit.is_from_me"
					/>
				</div>
				<div v-else class="text-center">
					<div>
						<v-icon size="50">mdi-alert-circle-outline</v-icon>
						<div class="">Pas encore bulletins</div>
					</div>

				</div>

			</div>


		</v-sheet>
		<!-- formulaire Ordonnance -->
		<form-ordonnance v-if="showMedicament" v-model="showMedicament" :ordonnance="ordonnance"
			@ordonnanceSaved="ordonnanceSaved" />
		<!-- formulaire creation fichier -->
		<create-file 
			v-if="showFile" 
			v-model="showFile" 
			:id="medical_visit_id" 
			:type_url="type"
			:proche_id="medical_visit.proche_id" 
			:patient_id="medical_visit.patient_id" 
			@createdFile="fileCreated" 
			:intervenant_id="medical_visit.intervenant_id" 
			/>
		<!-- formulaire Bulletin -->
		<form-bulletin v-if="showBulletin" v-model="showBulletin" :bulletin="bulletin" @bulletinSaved="bulletinSaved" />
		<delete-dialog 
			v-if="dialogDelete"
			v-model="dialogDelete"
			:message="'Êtes vous sûr de vouloir supprimer la visite médicale'" 
			:title="'Suppression visite médicale'"
			@confirmDelete="deleteMedicalVisit"
		/>

	</div>
	</div>
</template>

<script>
import FormBulletin from '../../../components/bulletins/formBulletin.vue'
import ItemBulletin from '../../../components/bulletins/itemBulletin.vue'
import CreateFile from '../../../components/consultation/createFile.vue'
import DataForms from '../../../components/consultation/dataForms.vue'
import ListFiles from '../../../components/consultation/listFiles.vue'
import PatientItem from '../../../components/consultation/patientItem.vue'
import DeleteDialog from '../../../components/Home/utilities/DeleteDialog.vue'
import LoadingPage from '../../../components/Home/utilities/LoadingPage.vue'
import FormOrdonnance from '../../../components/ordonnances/formOrdonnance.vue'
import ItemMedicament from '../../../components/ordonnances/itemMedicament.vue'
export default {
  components: { LoadingPage, PatientItem, DataForms, ListFiles, ItemMedicament, ItemBulletin, DeleteDialog, CreateFile, FormOrdonnance, FormBulletin},
    data() {
        return {
			loading:false,
			medical_visit_id: this.$route.params.medical_visit_id,
			medical_visit: null,
			showDate: false,
			patient_id:null,
			intervenant_id: null,
			proche_id: null,
			ordonnance: null,
			showFile:false,
			dialogDelete: false,
			showBulletin:false,
			showMedicament:false,
			bulletin: null,
			type: 'medical_visit'
        }
    },
    mounted() {
      this.getMedicalVisit()
    },
    watch: {
      $route: 'getMedicalVisit'
    },
    methods: {
		getMedicalVisit() {
			this.$http.get('medical_visit/'+this.medical_visit_id).then(({ data }) => {
			this.medical_visit = data
			}).catch(() => {
			this.$toast.error({
				title: 'Medical Visit Detail',
				message: 'Impossible de liste le visite médicale, une erreur est survenue'
			})
			})
		},
		printMedicalVisit(){
			window.open(`/printer/medical-visit/${this.medical_visit_id}`,'printWindow','height=800,width=900, align: center')
		},
		printCertificat(){
			window.open(`/printer/medical-visit/certificat/${this.medical_visit_id}`,'printWindow','height=800,width=900, align: center')
		},
		addMedicament(){
			this.ordonnance = {
				id: null,
				medical_visit_id: this.medical_visit_id
			}
			this.showMedicament = true
		},
		fileCreated(file) {
			this.medical_visit.files.unshift(file)
		},
		medicamentDeleted(medicament){
			this.medical_visit.ordonnances = this.medical_visit.ordonnances.filter( (value) => value.id != medicament.id)
		},
		addFile(){
			
			// if (this.medical_visit.proche) {
			// 	this.proche_id = this.medical_visit.proche.id
			// }
			// this.patient_id = this.medical_visit.patient.id
			this.showFile = true
		},
		addBulletin(){
			this.bulletin = {
				id: null,
				type: 'medical_visit',
				patient_id: this.medical_visit.patient_id,
				intervenant_id: this.medical_visit.intervenant_id,
				proche_id: this.medical_visit.proche ? this.medical_visit.proche.id : null,
				medical_visit_id: this.medical_visit_id
			}
			this.showBulletin = true
		},
		bulletinSaved(bulletin) {
			this.$toast.success({
				title: 'Bulletin',
				message: 'Le bulletion a été crée avec succès !'
			})
			this.medical_visit.bulletins.unshift(bulletin)
		},
		bulletinDeleted(bulletin){
			this.medical_visit.bulletins = this.medical_visit.bulletins.filter((value) => value.id != bulletin.id)
		},
		deleteMedicalVisit(){
			this.$http.delete(`medical_visit/${this.medical_visit_id}`).then(() => {
				this.$toast.success({
					title: 'Suppression',
					message: 'Suppression réussie'
				})
				this.$router.push({ name: 'doctor.visite-medicales'})
			}).catch(() => {
				this.$toast.error({
					title: 'Suppression',
					message: "Impossible de Suppression une visite médicale , une erreur c'est produit" 
				})
			})
		},
		ordonnanceSaved(medicament) {
			this.medical_visit.ordonnances.unshift(medicament)
			this.$toast.success({
				title: 'ordonnance',
				message: 'Un nouveaumédicament a été ajouté avec succès !'
			})
		},
		fileDeleted(file) {
			this.medical_visit.files = this.medical_visit.files.filter((fichier) => fichier.id != file.id)
		},
		printOrdonnance() {
			window.open(`/printer/ordonnance/medical_visit/${this.medical_visit_id}`,'printWindow','height=800,width=900, align: center')
		},
    }
}
</script>

<style scoped>
.conclusion {
	border: 1px solid #b6b2b2;
}

</style>