<template>
  <div>
    <v-card class="file" elevation="1">
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <v-icon size="30">mdi-file-outline </v-icon>
          <div class="px-2">
            <span class="subtitle-2">{{ file.filename }}</span
            ><br />
            <span class="caption font-weight-thin"
              >Créé par
              {{ file.creator.patient ? "vous" : file.creator.name }} le
              {{ file.last_update }}</span
            >
          </div>
        </div>
        <div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(action, index) in actions"
                :key="index"
                dense
              >
                <v-list-item-title>
                  <v-btn
                    text
                    small
                    style="text-transform: none"
                    :color="action.action === 'delete-share' ? 'error' : 'blue'"
                    @click="actionFile(action.action)"
                  >
                    <v-icon class="pr-2" size="15">
                      {{ action.icon }}
                    </v-icon>
                    {{ action.title }}
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-card>
    <view-file :file="file" v-model="viewDialog" v-if="viewDialog" />
  </div>
</template>

<script>
import ViewFile from "./ViewFile.vue";
export default {
  components: { ViewFile },
  props: {
    file: {
      type: Object,
      required: true,
    },
    appointment_id: String,
  },
  data() {
    return {
      viewDialog: false,
      actions: [
        {
          title: "Consulter",
          icon: "mdi-eye",
          action: "view",
        },
        {
          title: "Télécharger",
          icon: "mdi-file-upload-outline",
          action: "upload",
        },

        {
          title: "Annuler le partage",
          icon: "mdi-paperclip-off",
          action: "delete-share",
        },
      ],
    };
  },
  methods: {
    actionFile(action) {
      if (action === "view") {
        this.viewDialog = true;
      } else if (action === "delete-share") {
        this.deteleShare();
      } else {
        const extentionFile = this.file.filetype.toLowerCase();
        this.downloadFile(
          `files/${this.file.id}/download`,
          `${this.file.filename}.${extentionFile}`
        );
      }
    },
    deteleShare() {
    
      this.$http
        .put(`file/${this.file.id}/delete-share`, { appointment_id: this.appointment_id })
        .then(() => {
          this.$emit("deleteShareSuccess", this.file);
        })
        .then(() => {});
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
    downloadFile(url, title) {
      this.$http
        .get(url, { responseType: "arraybuffer" })
        .then((response) => {
          this.forceFileDownload(response, title);
        })
        .catch(() => console.log("error occured"));
    },
  },
};
</script>

<style scoped>
.file {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid rgb(206, 199, 199);
  border-radius: 10px;
}
</style>