<template>
    <v-sheet class="pa-6 mt-6">
      <div class="font-6 font-weight-bold">
        <v-icon class="mr-2">mdi-list-box</v-icon> <span class="mt-2">Ajout de formulaire </span> 
      </div>
      <div class="btn">
        <v-btn color="primary" style="text-transform: none; margin-top: 20px;" @click="showAddForm()">
          <div class="font-7">Ajouter un formulaire</div>
        </v-btn>
      </div>

      <v-card class="mt-12">
    <v-card-title class="indigo white--text text-h5">
      Formulaire(s) ajouté(s)
    </v-card-title>
    <v-row
      class="pa-4"
      justify="space-between"
    >
      <v-col cols="6">
        <div class="forms my-6">
        <!-- <div class="font-6 font-weight-bold"> Formulaire(s) ajouté(s)</div> -->
        <v-treeview
          :items="forms" 
          item-children="fields"
          :open.sync="open"
          :active.sync="active"
          open-on-click
          activatable
          transition
          return-object
          color="primary"
        >
          <template v-slot:label="{item}">
            <div class="d-flex justify-space-between" v-if="item.name">
              <div class="font-6 ma-2">
                <router-link 
                  :to="{ name: 'admin.create.fields.form', params: { id: item.id }}"
                  style="text-decoration: none"
                >
                  {{ item.name }}
                </router-link>
              </div>
              
              <div >
                <v-btn  icon color="error" @click="showDialogDelete(item)" :disabled="!item.is_me_add">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
              </div>
              
            </div>
            
          </template>
          <template v-slot:prepend="{ item, open }">
            <v-icon v-if="item.type ==='textarea'">mdi-text-long</v-icon>
            <v-icon v-else-if="item.type ==='date'">mdi-calendar</v-icon>
            <v-icon v-else-if="item.type ==='radio'">mdi-radiobox-marked</v-icon>
            <v-icon v-else-if="item.type ==='checkbox'">mdi-format-list-checkbox</v-icon>
            <v-icon v-else-if="item.type === 'text'">mdi-text-short</v-icon>
            <v-icon v-else>{{ open ? 'mdi-folder-open' : 'mdi-folder' }}</v-icon>
            
            {{ item.label }}
          </template>
        </v-treeview>

      </div>
      </v-col>
      <v-divider vertical></v-divider>

      <v-col
        align-self="center"
        class="d-flex justify-center"
      >
        <div v-if="selected">
          <div class="font-weight-bold">{{ selected.label }}</div>
          <v-textarea
                v-if="selected.type === 'textarea'"
                :placeholder="selected.label"
                outlined
            />
            <v-checkbox
                v-else-if="selected.type === 'checkbox'"
                v-for="(box,i) in selected.options"
                :key="i"
                :value="box"
                :label="box"
                :placeholder="selected.label"
            />
            <v-radio-group v-else-if="selected.type === 'radio'">
                <v-radio
                v-for="(item,j) in selected.options"
                :key="j"
                :label="item"
                :value="item"
            />
            </v-radio-group>
            
            <v-text-field
                v-else
                :placeholder="selected.label"
                outlined
                :type="selected.type"
            ></v-text-field>
        </div>
      </v-col>
      </v-row>
  </v-card>
      <formulaire 
        v-if="dialogAddForm" 
        v-model="dialogAddForm"
        @formAdd="addForm"
        :items="forms_not_assign"
      />
      <!-- Dialog Confirm Delete -->
      <delete-dialog
        v-if="dialogDelete"
        v-model="dialogDelete"
        :title="'Suppression formulaire'"
        :message="'Êtes-vous sûr de vouloir supprimer ce formulaire ?'"
        @confirmDelete="deleteForm"
    />
    </v-sheet >
</template>
  
<script>
  import Formulaire from '../../components/doctor/formulaire.vue'
import DeleteDialog from '../../components/Home/utilities/DeleteDialog.vue';
  export default {
    props: {
        doctor: Object
    },
    data() {
        return {
            dialogDelete: false,
            dialogAddForm: false,
            active: [],
            form: null,
            forms: [],
            open: [],
            forms_not_assign: []
        };
    },
    computed: {
        // eslint-disable-next-line vue/return-in-computed-property
        selected() {
            if (!this.active.length)
                return undefined;
            return this.active[0];
        }
    },
    mounted() {
        this.getConsultationForms();
    },
    methods: {
        getConsultationForms() {
            const id = this.$route.params.id;
            this.$http.get(`assignated-forms/${id}`).then(({ data }) => {
                this.forms = data.data;
            });
        },
        deleteForm() {
          const id = this.$route.params.id;
            this.$http.delete(`remove-form/${id}/${this.form.id}`).then(() => {
                //this.forms = this.forms.filter((formulaire) => formulaire.id = this.form.id);
                this.getConsultationForms();
                this.$toast.success({
                    title: "Suppression formulaire",
                    message: "Votre formulaire a été supprimé avec succès !"
                });
                this.form = null
            }).catch(() => {
            });
        },
        addForm(form_id) {
            const data = {
                form_id: form_id,
            }
            const id = this.$route.params.id;
            this.$http.post(`assign-new-form/${id}`, data).then(() => {
                this.getConsultationForms()
                this.$toast.success({
                    title: "Ajout formulaire",
                    message: "Votre formulaire a été ajouté avec succès !"
                });
            })
          //this.forms.unshift(data)
        },
        getFormsNotAssign() {
            const id = this.$route.params.id;
            this.$http.get(`not-assignated-forms/${id}`).then(({ data }) => {
                this.forms_not_assign = data.data
            })
        },
        showDialogDelete(form) {
          this.form = form
          this.open = []
          this.dialogDelete = true
        },
        showAddForm() {
          this.getFormsNotAssign()
          this.dialogAddForm = true
        }
    },
    components: { Formulaire, DeleteDialog }
}
  </script>
  
  <style>
  
  </style>