<template>
  <div>
      <div class="d-flex justify-space-between py-6 mx-2">
        <div></div>
        <v-btn color="primary" @click="showForm = true">
          <v-icon>mdi-note-plus</v-icon> Créer une note
        </v-btn>
      </div>
      <hr />
    <v-data-table
        :headers="headers"
        :items="notes"
        class="elevation-1"
        item-key="id"
        :loading="loading"
        hide-default-footer
        single-select
        tag="button"
        @click:row="viewNote"
        :search="search"
    >
    <template #top>
        <div class="d-flex justify-end my-3">
        <div class="col-6">
            <v-text-field
                v-model="search"
                name="search"
                placeholder="Rechercher une note par titre ou date"
                solo
                append-icon="mdi-magnify"
            ></v-text-field>
        </div>
    </div>
      </template>
    <template #no-data>
        <div class="pa-4">
            <v-icon size="40">mdi-alert-circle-outline</v-icon>
            <div class="">Vous n'avez pas encore de notes</div>
        </div>
    </template>
    <template #[`item.title`]="{ item }">
        <v-avatar color="orange lighten-5" size="30" rounded class="mr-2">
            <v-icon color="#FFCC80">mdi-note-outline</v-icon>
        </v-avatar>
         {{ item.title }}
    </template>
    <template #[`item.actions`]="{ item }">
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
            </template>
              <v-list>
                <v-list-item
                  v-for="(action, index) in actions"
                  :key="index"
                  dense
                >
                  <v-list-item-title>
                    <v-btn
                      text
                      small
                      style="text-transform: none"
                      :color="action.action === 'delete' ? 'error' : 'blue'"
                      @click="actionFile(action.action, item)"
                    >
                      <v-icon class="pr-2" size="15">
                        {{ action.icon }}
                      </v-icon>
                      {{ action.title }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
        </v-menu>
    </template>
        
    </v-data-table>
    <delete-dialog 
        v-if="deleteDialog" 
        v-model="deleteDialog" 
        :title="'Supprimer une note'" 
        :message="`Etês vous sûr de vouloir supprimer la note. Cette action est irréversible.`" 
        @confirmDelete="deleteNote"
    />
    <form-note-patient 
        v-if="showForm" 
        v-model="showForm" 
        :note="note" 
        @createdNote="createdNote" 
        @updatedNote="updatedNote"
        @closeForm="closeForm"
    />
  </div>
</template>

<script>
import DeleteDialog from '../../components/Home/utilities/DeleteDialog.vue'
import formNotePatient from '../../components/note/formNotePatient.vue'
export default {
  components: { formNotePatient, DeleteDialog },
    data() {
        return {
            search: '',
            patient_id: this.$auth.user.patient_id,
            note: {
                id: null,
                title: '',
                content: '',
                patient_id: this.$auth.user.patient_id,
                
            },
            noteDefault: {
                id: null,
                title: '',
                content: '',
                patient_id: this.$auth.user.patient_id,
            },
            deleteDialog: false,
            headers: [
                {
                    text: 'Note',
                    value: 'title',
                    align: 'start'
                },
                {
                    text: 'Créée le',
                    value: 'created_at',
                    align: 'start'
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    filterable: false,
                    align: 'start'
                }
            ],
            notes: [],
            loading: false,
            showForm: false,
            actions: [
                {
                    title: "Visualiser",
                    icon: "mdi-eye",
                    action: "view",
                },
                
                {
                    title: "Modifier",
                    icon: "mdi-pencil",
                    action: "edited",
                },
                {
                    title: "Supprimer",
                    icon: "mdi-delete",
                    action: "delete",
                },
            ],
        }
    },
    mounted() {
        this.getNotes()
    },
    methods: {
        getNotes() {
            this.loading = true
            this.$http.get('notes/'+this.patient_id).then(({ data }) => {
                this.notes = data.data
            }).catch((error) => {
                this.$toast.error({
                    title: "Une erreur est surveneue",
                    message: error.response.data.message
                })
            }).finally(() => this.loading = false)
        },
        updatedNote(note) {
            const index = this.notes.findIndex((item) => item.id = note.id);
            this.notes[index] = note
            
            this.$toast.success({
                title: 'Modification Note',
                message: "Votre note est modifiée avec succès !"
            })
            this.closeForm()
        },
        createdNote(note) {
            this.notes.unshift(note)
            this.$toast.success({
                title: 'Création Note',
                message: "Votre note est créée avec succès !"
            })
            this.closeForm()
        },
        viewNote(note) {
            this.note = note
            this.showForm = true
        },
        actionFile(action, item) {
            if(action == 'delete') {
                this.note = item
                this.deleteDialog = true
               
            } else {
                this.viewNote(item)
            }
        },
        deleteNote() {
            this.$http.delete(`note/${this.note.id}`).then(({data}) => {
                this.notes = this.notes.filter((note) => note.id != data.data.id)
                this.$toast.success({
                    title: 'Suppression Note',
                    message: "Votre note est supprimée avec succès !"
                })
                this.note = Object.assign({}, this.noteDefault)
            }).catch((error) => {

                this.$toast.error({
                    title: 'Suppression Note',
                    message: error.response.data.error
                })
            })
        },
        closeForm() {
            this.note = Object.assign({}, this.noteDefault)
            this.showForm = false
        }

       
    }
}
</script>

<style>

</style>