<template>
    <div>
       <v-sheet class="pa-6">
         <div class="d-flex justify-end mb-3">
               <v-btn color="primary" style="text-transform: none;" @click="showFormFile=true">
                  <v-icon>mdi-file-plus</v-icon> Ajouter un fichier
               </v-btn>
         </div>
         <div class="" v-if="loading">
            <loading-page />
         </div>
         <div class="" v-else>
            <list-files :files="files" @fileDeleted="fileDeleted" v-if="files.length" />
            <div v-else class="text-center font-weight-thin">
					<div>
						<v-icon size="40">mdi-alert-circle-outline</v-icon>
						<div class="">Pas encore de fichiers pour ce patient</div>
					</div>
				</div>
         </div>
       </v-sheet>
       <v-dialog v-model="showFormFile" persistent :overlay="false" max-width="450px" transition="dialog-transition">
            <v-card>
                <v-card-subtitle class="">
                    <div class="d-flex justify-center pt-3">
                        <h3><v-icon>mdi-file-plus</v-icon> Ajouter un nouveau document</h3>
                    </div>
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-text class="pa-3">
                    <v-text-field v-model="filename" name="filename" label="Nom du document" outlined dense
                        :error-messages="errors.filename"></v-text-field>
                    <v-file-input ref="file-input" prepend-icon="" label="Parcourir" v-model="file" outlined dense
                        :error-messages="errors.file" />
                    <div>
                        <v-progress-linear v-if="uploading" v-model="uploadPercentage" height="25">
                            <strong>{{ uploadPercentage }}%</strong>
                        </v-progress-linear>
                    </div>
                    <div class="d-flex justify-start py-3">
                        <v-btn color="primary" class="mr-2 text-capitalize" @click="submitFile">Enregistrer</v-btn>
                        <v-btn color="error" @click="close" outlined class="text-capitalize">Annuler</v-btn>
                    </div>

                </v-card-text>
            </v-card>
        </v-dialog>
   </div>
  </template>
  
  <script>

   import listFiles from '../../../components/consultation/listFiles.vue'
import LoadingPage from '../../../components/Home/utilities/LoadingPage.vue'
  export default {
  components: { listFiles, LoadingPage },
  props: {patient: Object },
   data() {
      
      return {
         loading: false,
         auteur: this.$route.params.auteur,
         patient_id: this.$route.params.patient,
         files:[],
         showFormFile: false,
         errors: {},
         filename: "",
         uploading: false,
         uploadPercentage: 0,
         file: null
      }
   },
   mounted(){
      this.getPatientFiles()
   },
   methods: {

      getPatientFiles() {
         this.loading = true
         this.$http.get(`patient/get/files?auteur=${this.auteur}&patient_id=${this.patient_id}`).then(({ data }) => {
                this.files = data.data
            }).catch(() => {
                this.$toast.error({
                    title: 'Erreur',
                    message: "Une erreur c'est produite lors de la recuperation du patient"
                })
            }).finally(() => this.loading = false)
      },
      submitFile() {
            
            this.uploading = true;

            const config = {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                    this.uploadPercentage = parseInt(
                        Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    );
                },
            };

            const data = new FormData();
            const id =  this.auteur == 'patient' ? this.patient_id : this.patient.patient_id
            if(id) {
                data.append("patient_id", id);
            }
            
            data.append("file", this.file);
            data.append("filename", this.filename);
            if(this.auteur === 'proche') {
               data.append("proche_id", this.patient_id)
               if(this.patient.intervenant_id) {
                data.append("intervenant_id", this.patient.intervenant_id)
               }
            }
            
            
            this.$http.post(`file`, data, config).then(({ data }) => {
                this.$toast.success({
                    title: 'Création Fichier',
                    message: 'Le fichier a été crée avec succès !'
                })
                this.files.unshift(data.data)
                this.close()
               
            })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                })
                .finally(() => {
                    this.uploading = false;
                    //this.close()
                });
        },
        fileDeleted(file) {
			this.files = this.files.filter((fichier) => fichier.id != file.id)
		},
        close() {
            this.showFormFile = false
            this.filename = "",
            this.file = null
        }
   }
  
  }
  </script>
  
  <style>
  
  </style>