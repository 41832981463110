<template>
    <div>
       <!-- Localisation du cabinet -->
    <div class="mb-6 mt-6" v-if="doctor">
        <card-title :title="'Localisation du cabinet'" :icon="'mdi-google-maps'"></card-title>
        <maps-doctor :marker_doctor="doctor.marker" @markerSaved="markerSaved" />
    </div>
    </div>
  </template>
  
  <script>
import MapsDoctor from '../../../components/doctor/MapsDoctor.vue'
import cardTitle from '../../../components/Home/utilities/cardTitle.vue'
  export default {
  components: { cardTitle, MapsDoctor },
  props: ['doctor'],
  methods: {
    markerSaved(marker) {
                this.doctor.marker = marker
            },
  }
  
  }
  </script>
  
  <style>
  
  </style>