<template>
  <div class="center">
      <v-dialog v-model="show" width="400" persistent>
          <v-card>
              <v-card-title class="title">Ajouter une Photo </v-card-title>
              <v-card-text>
                  <div class="large-12 medium-12 small-12 cell">
                      <label>
                          <input type="file" id="files" ref="files" accept="image/*"  v-on:change="handleFileUploads()" required />
                      </label>
                  </div>
              </v-card-text>
              <v-card-actions>
                  <v-btn text color="green" @click="show=false">Annuler</v-btn>
                  <v-btn text color="green" @click="submitFiles()">Enregistrer</v-btn>
              </v-card-actions>
          </v-card>

      </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
      value: Boolean,
  },
  name:'form-galerie',
  data(){
      return {
          files:'',
      }
  },
  computed: {
      show: {
          get() {
              return this.value;
          },
          set(value) {
              return this.$emit('input',value);
          }
      },
  },
  methods: {
      handleFileUploads(){
          this.files = this.$refs.files.files;
          //console.log(this.files);
      },
      /*
          Submits all of the files to the server
      */
      submitFiles(){
          /*
          Initialize the form data
          */
          let formData = new FormData();

          formData.append('file', this.files[0]);

          this.$emit('savedPhoto',formData);

          /*
          Iteate over any file sent over appending the files
          to the form data.
          */
          // for( var i = 0; i < this.files.length; i++ ){
          //     let file = this.files[i];

          //     formData.append('files[' + i + ']', file);
          // }

          /*
          Make the request to the POST /multiple-files URL
          */
          /* axios.post( '/multiple-files',
          formData,
          {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          }
          )*/
          // this.$http({
          //     url:'auth/multiple-files',
          //     method:'POST',
          //     data: formData,
          //     headers: {
          //         headers: {
          //             'Content-Type': 'multipart/form-data'
          //         }
          //     }
          // }).then(function(){
              
          //     console.log('SUCCESS!!');

          // })
          // .catch(function(){
          //     console.log('FAILURE!!');
          // });
          
           this.show = false;
      },
  },
}
</script>