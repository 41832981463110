<template>
  <div>
    <v-sheet class="d-flex justify-space-between mb-6">
        <div class="info-medecine">
          <div class="avatar">
            
            <v-avatar
              size="100"
              color="white"
            >
              <img :src="room.doctor.user.avatar_url" alt="avatar">
            </v-avatar>
            <v-avatar color="green" class="online" size="15"></v-avatar>
          </div>
         
          <div class="">
            <div>
              <router-link :to="{name: 'medecin.show', params: {id: room.doctor.id }}" style="text-decoration: none;">{{ room.doctor.user.name }} </router-link>
            </div>
            <div class="caption">{{ room.doctor.department.name }}</div>
            <div class="caption">{{ room.doctor.address_cabinet }}</div>

          </div>
        </div>
        <div class="btn-room text-center" @click="getRoomStep">
          <div>Fermeture {{ room.time_closing }}</div>
          <div class="my-2" v-if="room.attente ==0">
            Salle d'attente vide
          </div>
          <div class="my-2" v-else>
            Attente: <strong>{{room.attente}}</strong> Personne(s)
          </div>
          <div> 
            <v-btn icon color="white" class="text-center" :loading="loading">
              <v-icon size="30">mdi-play-circle</v-icon>
            </v-btn>
          </div>
        </div>
    </v-sheet>
    <conditions-pay v-model="showDialog" v-if="showDialog && room.doctor.condictions_pay" :content="room.doctor.condictions_pay" @confirmer="addPatient" />
  </div>
</template>

<script>
import ConditionsPay from './ConditionsPay.vue'
export default {
  components: { ConditionsPay },
 
    props: {
      room: Object
    },
    data(){
      return {
        showDialog:false,
        loading: false
      }
    },
    methods: {
      verify() {
        if(this.room.doctor.condictions_pay) {
          this.showDialog = true
        } else {
          this.addPatient()
        }
      },
      getRoomStep() {
        this.$router.push({ name: 'teleconsultation.direct.step', params: { room: this.room.id}})
      },
      addPatient() {
        if(this.$auth.check()){
          this.loading = true
        const data = {
          room_id: this.room.id,
          patient_id: this.$auth.user.patient_id
        }
        this.$http.post('room_waiting', data).then((response) => {
            this.$toast.success({
              title: "Ajout Salle attente",
              message: response.data.message
            })
            this.$router.push({name: `${(this.$auth.user.role).toLowerCase()}.room.waitings`})
            //this.$router.push({name: 'patient.room.waitings'})
        }).catch((error) => {
          this.$toast.error({
              title: "Ajout Salle attente",
              message: error.response.data.message
            })
        }).finally(() => this.loading = false)
        } else {
          this.$emit('patientAuth')
        }
       //this.$emit('addPatient',this.room.id)
      },
      
    }
}
</script>

<style scoped>
  .btn-room {
    background-color: #025397;
    color: white;
    padding: 12px;
    width: 200px;
  }
  .info-medecine {
    padding: 12px;
    display: flex;
  }
  .online{
    /*position: absolute;*/
    left: -23px;
    bottom: 40px;
    
  }
</style>