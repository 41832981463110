<template>
  <div>
    <v-sheet width="100%" height="250px" class="mt-3">
      <l-map :zoom="11" :center="center" v-if="center">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-marker v-if="marker" :lat-lng="marker"></l-marker>
      </l-map>
    </v-sheet>
  </div>
</template>

<script>
export default {
    props: {
        marker: Array

    },
    data() {
        return {
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            attribution:'&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
            center: this.marker
        }
    }
}
</script>

<style >
.leaflet-container {
    z-index: 1;
  }
</style>