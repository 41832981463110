<template>
  <!-- Jour(s) de disponibilité télémedecine -->
  <div class="teleexpertise_days mb-6">
    <card-title :title="'Jour(s) de disponibilité en téléexpertise'" :icon="'mdi-calendar-check'">
      <v-btn style="text-transform:none" color="primary" elevation="0" outlined
        @click=" showFormTeleexpertise = true">Modifier</v-btn>
    </card-title>
    <horaire-form @saveHoraire="saveHoraire" :horaire="doctor.horaire_teleexpertise" v-if="doctor" />
  </div>
</template>
  
<script>

import CardTitle from '../../../components/Home/utilities/cardTitle.vue';
import HoraireForm from '../../../components/horaireForm.vue';
export default {
  components: { CardTitle, HoraireForm },
  props: ['doctor'],
  data() {
    return {
      showFormTeleexpertise: false
    }
  },
  methods: {
    saveHoraire(dayofWeeks) {
      const data = {
        horaire_teleexpertise: dayofWeeks
      }
      this.$http.put(`horaire/update/${this.doctor.id}`, data).then(({ data }) => {
        this.doctor.horaire_teleexpertise = data.horaire_teleexpertise
        this.$toast.success({
          title: 'Horaire téléexpertise',
          message: "Horaire téléexpertise modifié avec succès"
        })
      })
    }
  }

}
</script>
  
<style></style>