<template>
  <v-sheet class="pa-3">
    <v-form @submit.prevent="submitAvailability" class="mb-6">
      <div v-for="(day, index) in daysOfWeek" :key="index">
        <v-row class="mt-8">
          <v-col cols="12" md="2" sm="2">
            <div class="font-7"> {{ day.day }} : </div>
          </v-col>
          <v-col cols="12" md="10" sm="10" order-xl="10">
            <div v-if="day.end_time">
              <v-row>
                <v-text-field 
                  class="mr-2" 
                  v-model="day.start_time" 
                  name="start_time" 
                  label="Heure début"
                  id="start_time" 
                  dense outlined 
                  type="time" 
                  :error-messages="errors.start_time" 
                />
                <v-text-field 
                  class="mr-2"
                  v-model="day.end_time" 
                  name="end_time" 
                  label="Heure fin" 
                  id="end_time" 
                  dense
                  outlined 
                  type="time" 
                  :error-messages="errors.end_time" 
                  />
                  <div class="font-7 ma-3">Pause</div>
                  <v-text-field 
                  class="mr-2" 
                  v-model="day.break_start_time" 
                  name="start_time" 
                  label="Début pause"
                  id="start_time" 
                  dense outlined 
                  type="time" 
                  :error-messages="errors.break_start_time" 
                />
                <v-text-field 
                  v-model="day.break_end_time" 
                  name="end_time" 
                  label="Fin pause" 
                  id="end_time" 
                  dense
                  outlined 
                  type="time" 
                  :error-messages="errors.break_end_time" 
                  />
                <v-btn small class="mx-2 mt-1" text @click="deleteDay(index)">
                  <v-icon color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-row>
            </div>
            <div v-else class="mt-3">
              <v-row>
                <div class="font-7 warning--text mr-3">
                  Aucune disponibilité
                </div>
                <div><v-btn small elevation="1" @click="addDay(index)">
                    <v-icon size="12">mdi-plus</v-icon>
                  </v-btn></div>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-form>
    <div class="">
      <v-divider />
      <div class="d-flex justify-start mt-6 mb-3">
        <v-btn color="primary" class="" style="text-transform: none;" @click="submitAvailability">Valider</v-btn>
        <!-- <v-btn style="text-transform: none;" small>Annuler</v-btn> -->
      </div>
    </div>

  </v-sheet>
</template>
  
<script>
export default {
  props: ['horaire'],
  data() {
    return {
      daysOfWeek: [
        { day: 'Lundi', start_time: null, end_time: null, break_start_time: null,  break_end_time: null },
        { day: 'Mardi', start_time: null, end_time: null, break_start_time: null,  break_end_time: null },
        { day: 'Mercredi', start_time: null, end_time: null, break_start_time: null,  break_end_time: null },
        { day: 'Jeudi', start_time: null, end_time: null, break_start_time: null,  break_end_time: null },
        { day: 'Vendredi', start_time: null, end_time: null, break_start_time: null,  break_end_time: null },
        { day: 'Samedi', start_time: null, end_time: null, break_start_time: null,  break_end_time: null },
        { day: 'Dimanche', start_time: null, end_time: null, break_start_time: null,  break_end_time: null }
      ],
      selectedDays: [],
      errors: [],
      isDirty: false,
    };
  },
  
  created() {
    this.filledDay()
  },

  methods: {
    filledDay() {
      if (this.horaire.length) {
        this.daysOfWeek = Array.from(this.horaire); // ou [...this.horaire]
      }
    },
    submitAvailability() {
      const data = this.isCheckDirty() ? this.daysOfWeek : [];
      this.$emit('saveHoraire', data)
    },
    deleteDay(index) {
     
      this.daysOfWeek[index].start_time = null
      this.daysOfWeek[index].end_time = null
      this.daysOfWeek[index].break_start_time = null
      this.daysOfWeek[index].break_end_time = null
      this.submitAvailability()
      // if(this.horaire.length) {
      //   this.submitAvailability()
      // }
      

    },
    addDay(index) {
      this.daysOfWeek[index].start_time = '08:00'
      this.daysOfWeek[index].end_time = '16:00'
    },
    isCheckDirty() {
      return this.daysOfWeek.some(day => day.start_time !== null || day.end_time !== null);
    }
  }
};
</script>
  