<template>
  <div>
    <v-dialog v-model="show" persistent width="600px">
      <v-card>
        <v-card-title class="">
          <div class="font-6 font-weight-bold">{{ formTitle }}</div>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template #activator="{on, attrs}">
                <v-btn icon v-on="on" v-bind="attrs" @click="show=false" color="error">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
            <span>Fermer</span>
          </v-tooltip>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <v-autocomplete 
                v-model="doctors_share" 
                :items="doctors_not_shared" 
                item-text="name" 
                multiple 
                chips
                deletable-chips 
                return-object 
                hide-selected
                label="Partager avec.." 
                placeholder="Partager avec..." 
                outlined
                class="mt-3"
                :error-messages="errors.doctors"
            >

          </v-autocomplete>
          <div class="my-3 d-flex justify-space-between">
            <v-btn color="primary" style="text-transform: none;" @click="share" :loading="loading">Partager</v-btn>
            <v-btn color="error" style="text-transform: none;" outlined @click="show=false">Annuler</v-btn>
          </div>
          <v-divider></v-divider>
          <v-sheet class="pa-3" min-height="400" >
            <div class="font-weight-bold">
              <v-icon>mdi-account-group</v-icon>
              Médecins ayant accès à ce dossier
            </div>
            <div class="mt-3" v-if="doctors_shared.length">
              
                <v-card v-for="(doctor,i) in doctors_shared" :key="i" class="pa-2 mb-3 grey lighten-3">
                  <div class="d-flex justify-space-between">
                    <div class="font-weight-bold">
                      {{ doctor.name }}
                    </div>
                    <div>
                      <v-btn color="error" elevation="1" class="white--text" style="text-transform: none;" small @click="cancelShare(doctor)">Retirer</v-btn>
                    </div>

                  </div>
                </v-card>
              

            </div>
            <div v-else class="text-center mt-3">
                <div class="font-weight-thin">
                    <div> 
                        <v-icon size="30">mdi-alert-circle-outline</v-icon>
                    </div>
                    
                    Aucun médecin pour le moment
                </div>

            </div>

          </v-sheet>

        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {

  props: {
    value: Boolean,
    type: String,
    id: String
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit('input', value)
      }
    },
    
    formTitle() {
      if (this.type === 'consultation') {
        return 'Partager une consultation'
      } else {
        return 'Partager une visite médicale'
      }
    }
  },
  data() {
    return {
      errors: {},
      doctors_not_shared: [],
      doctors_shared: [],
      doctors_share: [],
      loading: false
    }
  },
  mounted() {
    this.notShared()
    this.shared()
  },
  methods: {
    notShared() {
      this.$http.get(`doctors/not_shared?id=${this.id}&type=${this.type}`).then(({ data}) => {
        this.doctors_not_shared = data.doctors
      })
    },
    shared() {
      this.$http.get(`doctors/shared?id=${this.id}&type=${this.type}`).then(({ data}) => {
        this.doctors_shared = data.doctors
      })
    },
    share() {
        this.loading = true
        this.errors = {}
      this.$http.post('doctors/share', { id: this.id, type: this.type, doctors: this.doctors_share })
        .then(() => {
          this.notShared()
          this.shared()
          this.doctors_share = []
          this.$toast.success({
            title: 'Partage',
            message: 'Partage réussie !'
          })
        }).catch((error) => {
          if(error.response.data.errors) {
            this.errors = error.response.data.errors
          } else {
              this.$toast.error({
              title: 'Partage',
              message: 'Impossible de faire le partage car une erreure est survenu'
            })
          }
          
        }).finally(() => this.loading = false)
    },
    cancelShare(doctor) {
        this.$http.put(`doctors/${doctor.id}/cancel_share`, { params: { id: this.id, type: this.type}})
        .then(({ data }) => {
            this.doctors_shared = this.doctors_shared.filter((item) => item.id != data.data.id)
            this.$toast.success({
                title: 'Retrait Docteur',
                message: doctor.name+" est rétiré du dossier avec succès !"
            })
        }).catch(() => {
            this.$toast.error({
                title: 'Retrait Docteur',
                message: " Impossible de rétiré $doctor.name du dossier car une erreur est survenue!"
            })
        })
    }

    
  }

}
</script>

<style></style>