<template>
  <div> 
    <v-tabs
        v-model="tab"
        color="primary"
        slider-color="blue"
    >
    <v-tab>
        <h3>Mes Fichiers</h3>
    </v-tab>
    <v-tab>
        <h3>Mes Notes</h3>
    </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
        <v-tab-item>
            <send-file 
              :files="files" 
              :headers="headers"
              :page="current_page"
              :pageCount="last_page"
              @pagination="getFiles"
              :itemsPerPage="per_page"
              :loading="loading"
              @deleteFile="confirmDelete"
            />
        </v-tab-item>
        <v-tab-item>
            <note-table />
        </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import NoteTable from './NoteTable.vue'
import SendFile from './SendFile.vue'
export default {
  components: { SendFile, NoteTable },
    data() {
        return {
          tab: 0,
          files : [],
          loading: false,
          headers: [
              {
                text: "Nom du document",
                align: "start",
                sortable: false,
                value: "filename",
              },
              { text: "Patient concerné", value: "patient" },
              { text: "Crée le", value: "uploaded_at" },
              { text: "Crée par", value: "creator.name" },
              { text: "Actions", value: "actions", sortable: false },
          ],
          current_page: 1,
          last_page:1,
          per_page: 20,

        }
    },
    created() {
        this.getFiles()
    },
    methods: {
        getFiles(page) {
        this.loading = true
        this.$http.get(`patient/${this.$auth.user.patient_id}/files`, {  params: { page: page } }).then(({ data }) => {
            this.files = data.data;
            this.current_page = data.meta.current_page
            this.last_page = data.meta.last_page
            this.per_page = data.meta.per_page
          }).catch(() => {
            this.$toast.error({
              title: 'Error du serveur',
              message: "Impossible d'accéder à vos fichiers car une erreur c'est produite"
            })
          }).finally(() => this.loading = false);
      },
      confirmDelete(file) {
        
        this.$http
        .delete(`file/${file.id}`)
        .then(() => {
          this.files = this.files.filter(
            (fichier) => fichier.id != file.id
          );
          this.$toast.success({
            title: "Suppression document",
            message: "Votre document a été supprimé avec succès",
          });
        })
        .catch(() => {
          this.$toast.error({
            title: "Suppression document",
            message: "La suppression du document a échoué",
          });
        });
      }
    }
}
</script>

<style>

</style>