<template>
    <div>
        <!-- Condiction de paiement de consultation -->
    <div class="mb-6">
        <card-title :title="'Conditions de paiement de consultation'" :icon="'mdi-text'">
            <v-btn style="text-transform:none" color="primary" elevation="0" outlined @click="showFormCondictionPay=true">Modifier</v-btn>
        </card-title>
        <v-sheet class="pa-6">
            <div class="ql-editor" v-html="doctor.condictions_pay" />
        </v-sheet>
       
    </div>
    <form-editor
        v-if="showFormCondictionPay"
        v-model="showFormCondictionPay" 
        :contenu="doctor.condictions_pay" 
        :title="'Conditions de paiement de consultation'"
        @saved="saveCondictionPay" 
    />
    </div>
</template>

<script>
import FormEditor from '../../../components/FormEditor.vue'
import CardTitle from '../../../components/Home/utilities/cardTitle.vue'
export default {
  components: { CardTitle, FormEditor },
    props: ['doctor'],
    data(){
        return{ showFormCondictionPay : false }
    },
    methods: {
        saveCondictionPay( content) {
            if(this.$auth.user.is_doctor) {
                this.$http.put(`condiction_pay/${this.$auth.user.doctor_id}`,{ condictions_pay: content }).then((res ) => {
                this.$toast.success({
                    title: 'Conditions de paiement',
                    message: res.data.message
                })
                this.doctor.condictions_pay = content
            }).catch(() => {
                this.$toast.error({
                  title: 'error',
                  message: "Une erreur c'est produite lors de l'enregistrement de condition de payement"
                })
            })
            } else {
                this.$toast.info({
                    title: 'Conditions de paiement',
                    message: "Vous n'avez accés à cette resource"
                })
            }
        }
    }
}
</script>

<style></style>