<template>
    <div>
        <v-sheet class="pa-6" v-if="!room.id">
            <h3>Bienvenue dans la salle d'attente</h3>
            <div class="text-center">
                <!-- <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestias expedita, doloremque, voluptatibus temporibus quisquam numquam quam voluptate quis soluta libero facilis quasi?</p> -->
            <div class="mt-3"> 
                <v-btn class="primary" @click="showForm=true" style="text-transform: none;">
                    Ouvrir un salon de téléconsultation.
                </v-btn>
            </div>
                
            </div>
        </v-sheet>
        <div class="pa-6" v-else>
            <v-sheet class="d-flex justify-space-between pa-3" color="primary" >
                <div class="font-5 white--text"> Salle d'attente <span :class="!room.visible ? 'red--text':''">{{ room.visible ? 'ouverte' : 'fermée'}}</span> </div>
                <div v-if="room.visible">
                    {{ compteurFermuture() }}
                    <div class="d-flex">
                        
                        <v-chip>
                            <span class="mr-1">Fermeture dans</span>
                            <span class="mr-1" v-if="day!=0">{{ day }}J</span>
                            <span class="mr-1">{{ hours }}h</span>
                            <span class="mr-1">{{ minutes }}mn</span>
                            <span class="">{{ seconds }}s</span>
                        </v-chip>
                        <v-tooltip bottom>
                            <template #activator="{on, attr}">
                                <v-btn 
                                    icon 
                                    color="warning" 
                                    @click="showForm=true"
                                    v-on="on"
                                    v-bind="attr"
                                >
                            <v-icon size="30">mdi-pencil-box</v-icon>
                        </v-btn>
                            </template>
                            <span>Modifier date fermuture</span>
                        </v-tooltip>
                        
                    </div>
                </div>
                <div><v-btn class="error" style="text-transform: none;" text @click="dialogDeleteShow=true">Fermer le salon</v-btn></div>
            </v-sheet>
            <v-divider></v-divider>
            <div class="mt-6" v-if="room.attente !=0">
                <item-patient 
                    v-for="(room_waiting, index) in room.room_waitings" 
                    :key="room_waiting.id"
                    :room="room_waiting"
                    :index="index"
                    @retirerPatient="retirerPatient"
                />
            </div>
            <div class="text-center pa-6" v-else>
                <div>
                    <v-icon size="50">mdi-alert-circle-outline</v-icon>
                    <div class="font-6">Salle d'attente vide</div>
                </div>
            </div>
        </div>
        <delete-dialog 
            v-if="dialogDeleteShow" 
            v-model="dialogDeleteShow" 
            :message="'Êtes vous sûr de fermé le salon ?'" 
            :title="'Fermeture de la salle attente'" 
            @confirmDelete="deleteRoom"
        />
        <v-dialog
            ref="dialog"
            v-model="showForm"
            :return-value.sync="room.time_closing"
            persistent
            width="290px"
        >
        <v-time-picker
          v-if="showForm"
          v-model="room.time_closing"
          format="24hr"
          full-width
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="error"
            @click="showForm = false"
          >
            Annuler
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="saveRoom"
          >
            Valider
          </v-btn>
        </v-time-picker>
        </v-dialog>
    </div>
</template>

<script>
import Pusher from 'pusher-js'
import DeleteDialog from '../../../components/Home/utilities/DeleteDialog.vue'
import ItemPatient from './itemPatient.vue'
export default {
  components: { DeleteDialog, ItemPatient },
    data() {
        return {
            countdownDate: null,
            dialogDeleteShow: false,
            day:0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            defaultRoom: {
                id: null,
                doctor_id: this.$auth.user.doctor_id,
                date_closing: null,
                time_closing: null,
                visible: false,
                room_waitings: []
            },
            room: {
                id: null,
                doctor_id: this.$auth.user.doctor_id,
                date_closing: null,
                time_closing: null,
                visible: false,
                room_waitings: []
            },
            showForm: false
        }
    },
    mounted() {
        const url_auth = process.env.VUE_APP_ENV == 'local'  ? 'http://localhost:8000/api/broadcasting/auth ' : 'https://backend.sahelassistance.com/api/broadcasting/auth'
        var pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
            cluster: process.env.VUE_APP_PUSHER_CLUSTER,
            auth: {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + window.localStorage.getItem('token'), // Assurez-vous d'inclure le jeton d'authentification si nécessaire
                },
            },
            authEndpoint: url_auth
        });
        let channel = pusher.subscribe('private-room-waiting.'+this.$auth.user.id);
        channel.bind('room-waiting-event', () => {
            this.$toast.info({
                title: 'Notification',
                message: 'Nouvelle patient dans la salle attente',
                showDuration: 5000,
                timeOut: 5000,
                closeButton: true
            })
            this.getRoom()
        });
    },
    created() {
        this.getRoom()
        
    },
    methods: {
        lancerEvent(){

        },
        lanceNotify() {
            this.$toast.info({
                    title: 'Notification',
                    message: 'Nouvelle patient dans la salle attente',
                    showDuration: 5000,
                    timeOut: 5000,
                    closeButton: true
                })
        },
        saveRoom(){
            if(this.room.id){
                this.$http.put(`room/${this.room.id}`,this.room).then((response) => {
                   
                    this.getRoom()
                    this.showForm = false
                    this.$toast.success({
                    title: 'Heure de fermeture modifié',
                    message: response.data.message
                })
                }).catch((error) => {
                    if(error.response.data.abonnement) {
                    this.$toast.info({
                        title: 'Abonnement',
                        message: "Abonnez-vous pour pouvoir utiliser ces resources"
                    })
                    const timer = setInterval(() => {
                        this.$router.push('/abonnement')
                        clearInterval(timer)
                    }, 3000);
                }
                if(error.response.error.message) {
                    this.$toast.error({
                        title: 'Nouvelle Consultation',
                        message: error.response.error.message
                    })
                }
                    this.$toast.error({
                            title: 'Modification heure de fermuture',
                            message: error.response.data.message
                        })
                })
            } else {
                this.createRoom()
                
            }
        },
        compteurFermuture(){
            if(this.room.visible) {

                this.countdownDate = new Date(this.room.date_closing);
                setInterval(() => {
                    const now = new Date().getTime();
                    const distance = this.countdownDate - now;
                    //this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    if(this.room.id && this.minutes < 0){
                        this.$http.get(`room/closing/${this.room.id}`).then(() => {
                            this.$router.go('')
                            //this.getRoom()
                        })
                    }
                },1000);
            }
            
        },
        createRoom() {
           this.$http.post('room/create',this.room).then((response) => {
                this.getRoom()
                this.$toast.success({
                    title: 'Salle attente créée',
                    message: response.data.message
                })
                this.showForm = false
           }).catch((error) => {
            if(error.response.data.abonnement) {
                    this.$toast.info({
                        title: 'Abonnement',
                        message: "Abonnez-vous pour pouvoir utiliser ces resources"
                    })
                    const timer = setInterval(() => {
                        this.$router.push('/abonnement')
                        clearInterval(timer)
                    }, 3000);
                }
                if(error.response.error.message) {
                    this.$toast.error({
                        title: 'Nouvelle Consultation',
                        message: error.response.error.message
                    })
                }
            this.$toast.error({
                    title: 'Salle attente',
                    message: error.response.data.message
                })
           })
        },
        getRoom() {
            this.$http.get(`room/${this.room.doctor_id}`).then(({ data }) => {
                if(data.data) {
                    this.room = data.data
                }else {
                    this.room = Object.assign({}, this.defaultRoom)
                }
               
            })
        },
        deleteRoom() {
            this.$http.delete(`room/${this.room.id}`).then(() => {
                this.$toast.success({
                    title: "Fermure sallle d'attente",
                    message: "Salon fermé avec succès !"
                })
                this.room = Object.assign({}, this.defaultRoom)
            }).catch((error) => {
                this.$toast.error({
                    title: 'Salle Attente',
                    message: error.response.data.message
                })
            })
        },
        retirerPatient(data) {
            console.log(data)
            this.getRoom()
        },
       
    }
}
</script>

<style>

</style>