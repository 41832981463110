<template>
<div>
  <div class="proche">
    <div class="info-proche">
      <div class="font-weight-bold">{{ proche.name }}</div>
      <div class="">Adresse : <span class="font-weight-bold">{{ proche.address ? proche.address : '---' }}</span></div>
      <!-- <div>{{ proche.age }}</div>
      <div v-if="proche.lieu_naissance">À {{ proche.lieu_naissance }}</div>
      <div v-else>Lieu de naissance : inconnu</div> -->
      <div class="mb-2"> N° tel : <span class="font-weight-bold">{{ proche.phone ? proche.phone : auth.user.phone }}</span></div>
    </div>
    <div class="d-flex d-flex justify-space-between mb-2">
        <v-btn color="error" small style="text-transform: none" @click.prevent="dialog =true" elevation="0" class="mr-3">
          <v-icon>mdi-delete</v-icon> Supprimer
        </v-btn>
        <v-btn color="primary" small style="text-transform: none" @click.prevent="showDialog = true" elevation="0">
          <v-icon>mdi-pencil</v-icon> Modifier
        </v-btn>
    </div>
  </div>
  <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h6">
          Supprimer un proche
        </v-card-title>

        <v-card-text>
            Êtes-vous sûr de vouloir supprimer <strong>{{proche.prenom}} {{ proche.nom}}</strong> de vos proches?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            text
            @click="dialog = false"
          >
            Annuler
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="confirmDelete"
          >
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <proche-form :proche="proche" v-model="showDialog" @updateProche="updateProche" v-if="showDialog" />
</div>
</template>

<script>
import ProcheForm from './ProcheForm.vue';
export default {
  components: { ProcheForm },
  props: { proche: Object },
  data() {
    return {
        showDialog: false,
        dialog: false,
    }
  },
  methods: {
    confirmDelete() {
        this.$http.delete(`proche/${this.proche.id}`).then(({data}) => {
            this.$emit('deleteProche', data.data);
            this.dialog = false
        }).catch(() => {
            this.dialog = false
            this.$toast.error({
              title: 'Suppression Proche',
              message: 'Impossible de supprimer un proche s\'il est rataché à un RDV. Veuillez annuler le RDV'
            })
            //alert('Error du serveur',JSON.stringify(error))
        })
    },
     updateProche(proche) {
        this.proche = proche
        this.$toast.success({
        title:'Modification proche',
        message:'Proche modifié avec succès'
      })
    }
  }

};
</script>

<style scoped>
.proches .proche {
  padding: 20px 0;
  border-top: 2px solid #00A19A;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  padding: 5px;
  text-decoration: none;
  color: inherit;
  transition: transform ease 0.3s, box-shadow ease 0.3s;
  margin-bottom: 30px;
  
}
.info-proche {
    line-height: 2;
    font-size: .9em;
}
@media screen and (max-width: 600px) {
 /*  .proches .proche {
    width: 100% !important;
  } */
  }

 /* .proches .proche .actions {
  display: flex;
  align-items: center;
  justify-content: flex-end; 
} */
</style>