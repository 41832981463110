<template>
    <v-app>
        <navbar />
        <v-container>
            <v-row>
                <v-col cols="12" md="4"  v-for="plan in plans_abonnements" :key="plan.id">
                    <card-abonnement :plan_abonnement="plan" />
                </v-col>
                
            </v-row>
            
        </v-container>
        
    </v-app>
  
</template>

<script>
import navbar from '../../components/Home/utilities/navbar.vue'
import CardAbonnement from './cardAbonnement.vue'
export default {
  components: { navbar, CardAbonnement },
    data: () => ( {
       
        plans_abonnements: []
    }),
    created() {
        this.getAbonnements()
    },
    methods: {
        getAbonnements() {
            this.$http.get('plans').then(({ data }) => {
                this.plans_abonnements = data.data
            })
            this.plans_abonnements 
        },
        
    }
}
</script>

<style>

</style>