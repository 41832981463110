<template>
<div>
    <v-row justify="space-between">
        <v-col cols="12" md="6" sm="6">
            <div class="">{{ motif.name }}</div>
        </v-col>
        <v-col cols="12" md="5" sm="5">
            <div class="">
                {{ motif.montant_formated }}
            </div>
        </v-col>
        <v-col cols="12" md="1" sm="1">
            <div class="d-flex align-content-end">

                <v-btn icon class="mx-2" color="#025397" @click="showForm=true" outlined small>
                <v-icon size="20">mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon class="" color="error" @click="dialogDelete=true" outlined small>
                    <v-icon size="20" >mdi-delete</v-icon>
                </v-btn>
            </div>
            
        </v-col>
    </v-row>
   
    <!-- Formulaire motif --> 
    <form-motif 
        v-if="showForm" 
        :motif="motif" 
        v-model="showForm"
        @updatedMotif="updatedMotif"
    />
    <delete-dialog
        v-if="dialogDelete"
        v-model="dialogDelete"
        :message="'Êtes vous sûr de vouloir ce supprimer ce prestation'" 
        :title="'Suppression prestation'"
        @confirmDelete="deleteMotif"

    />
</div>
</template>

<script>
    import FormMotif from './FormMotif.vue'
    import DeleteDialog from '../Home/utilities/DeleteDialog.vue'

    export default {
  components: { FormMotif, DeleteDialog},
        props: { motif: Object },
        data() {
            return {
                showForm: false,
                dialogDelete: false
            }
        },
        methods: {
            updatedMotif(data) {
                this.motif = data
            },
            deleteMotif() {
                this.$http.delete(`motif/${this.motif.id}`).then(({data}) => {
                    this.$emit('deletedMotif', data.data)
                    this.$toast.success({
                        title: 'Suppression prestation',
                        message: 'Suppression de la prestation effectuée avec succès'
                    })
                }).catch(() => {
                    this.$toast.error({
                        title: 'Error',
                        message: "Impossible de supprimer la prestation, une erreur c'est produite lors de la suppression"
                    })
                })
            }
        }
    }
</script>

<style>

</style>