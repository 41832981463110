<template>
    <v-app>
        <Navbar />
        <v-container>
            <v-row align="start" justify="space-between" v-if="room">
                <!-- Les etapes de prise de rendez-vous -->
                <v-col cols="12" md="8">
                    <v-stepper v-model="step" vertical elevation="0">
                        <v-stepper-step :complete="step > 1" step="1">
                            Identification
                        </v-stepper-step>
                        <v-stepper-content step="1">
                           
                            <div class="form-login text-center" v-if="!($auth.user)">
                                <v-btn color="primary" outlined style="text-transform: none" @click="dialog = true">Se
                                    connecter | S'inscrire</v-btn>

                            </div>
                            <div class="form-login text-center" v-else>
                                <v-btn color="success" style="text-transform: none" @click="step = 2">continuer</v-btn>
                            </div>

                        </v-stepper-content>
                        <v-stepper-step :complete="step > 2" step="2">
                            Informations patient
                        </v-stepper-step>
                        <v-stepper-content step="2">
                            <div class="list-proches" v-if="patient">
                                <div class="row">
                                    <v-col cols="12" md="6" class="pa-6">
                                        <v-text-field v-model="first_name" placeholder="Rechercher par prénom" outlined clearable dense @input="search" />
                                    </v-col>
                                    <v-col cols="12" md="6" class="pa-6">
                                        <v-text-field v-model="last_name" placeholder="Rechercher par nom" outlined clearable dense @input="search" />
                                    </v-col>
                                </div>
                            <v-radio-group v-model="patient_selected" column>
                            <v-card class="mb-3 pa-3 grey lighten-5">
                                <v-radio :value="patient">
                                <template #label>
                                    <v-avatar
                                    size="25"
                                    color="primary"
                                    class="white--text font-weight-bold mx-6 pa-1"
                                    >
                                    {{ (patient.name).substring(0, 1)
                                    }}{{ (patient.name).substring((patient.name.indexOf(" ")+1),(patient.name.indexOf(" ")) +2) }}
                                    </v-avatar>
                                    <div>
                                    {{ patient.name }} (<strong>Moi</strong>)
                                    </div>
                                </template>
                                </v-radio>
                            </v-card>
                            <v-card
                                v-for="proche in proches"
                                :key="proche.id"
                                class="mb-3 pa-3 grey lighten-4"
                            >
                                <v-radio :value="proche">
                                <template #label>
                                    <v-avatar
                                    size="25"
                                    color="warning"
                                    class="white--text font-weight-bold mx-6 pa-1"
                                    >
                                    {{ proche.first_name.substring(0, 1)
                                    }}{{ proche.last_name.substring(0, 1) }}
                                    </v-avatar>
                                    <div>{{ proche.name }}</div>
                                </template>
                                </v-radio>
                            </v-card>
                            </v-radio-group>
                            </div>
                            
                            <div class="add-proche mb-3">
                            <v-btn
                                text
                                color="blue"
                                class=""
                                style="text-transform: none"
                                @click="showFormProche = true"
                            >
                                <v-icon>mdi-account-plus</v-icon> Ajouter un nouveau proche
                            </v-btn>
                            </div>
                            <div class="btn-group">
                            <!-- <v-spacer></v-spacer> -->
                            <v-btn 
                                class="btn"
                                outlined
                                rounded
                                color="error" 
                                @click="annuler"
                            >
                                Annuler 
                            </v-btn>
                            <v-btn
                                class="btn"
                                color="primary"
                                @click="verify"
                                style="text-transform: none"
                                outlined
                                rounded
                                :loading="loading"
                            >
                                Confirmer
                            </v-btn>
                            
                            </div>
                        </v-stepper-content>
                    </v-stepper>
                </v-col>
                <v-col cols="12" md="4">
                    <v-sheet>

                        <div class="d-flex">
                            <div class="profile-photo" v-if="room.doctor.user.avatar_url">
                                <img :src="room.doctor.user.avatar_url" alt="avatar" />
                            </div>
                            <div class="profile-info">
                                <div>
                                    <strong>{{ room.doctor.name }}</strong>
                                </div>
                                <div>
                                    Spécialiste en {{ room.doctor.department.name.toLowerCase() }}
                                </div>
                            </div>
                        </div>
                        <v-divider />
                        <div class="detail-rv pa-5">
                            <div class="mb-2"> <v-icon>mdi-account-multiple</v-icon> Attente : {{ room.attente }}</div>
                            <div  class="mb-2"><v-icon>mdi-clock</v-icon> Fermuture : {{ room.time_closing }}</div>
                            <div class="lieu mb-2" v-if="patient_selected">
                                <v-icon>mdi-account</v-icon> {{ patient_selected.name }}
                            </div>
                        </div>

                    </v-sheet>
                </v-col>
            </v-row>
            <v-dialog class="text-center" v-model="dialog" max-width="430px">
                <v-card>
                    <v-tabs v-model="tab" grow>
                        <v-tab> Se connecter </v-tab>
                        <v-tab> S'inscrire </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <v-card color="basil" flat>
                                <v-card-text>
                                    <login @connected="connected" />
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card color="basil" flat>
                                <v-card-text>
                                    <register @codeValide="codeValide" />
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-dialog>
            <conditions-pay v-model="showDialog" v-if="showDialog && room.doctor.condictions_pay"
                :content="room.doctor.condictions_pay" @confirmer="confirmer" />
                <proche-form v-model="showFormProche" @createProche="createProche" />
        </v-container>

    </v-app>
</template>

<script>
//import Pusher from 'pusher-js'
import ConditionsPay from '../components/doctor/ConditionsPay.vue';
import Navbar from '../components/Home/utilities/navbar.vue';
import Login from '../components/login.vue';
import ProcheForm from '../components/proche/ProcheForm.vue';
import Register from '../components/register.vue';

export default {
    data() {
        return {
            tab: null,
            room: null,
            step: null,
            showDialog: false,
            dialog: false,
            patient: null,
            patient_selected: null,
            loading: false,
            showFormProche: false,
            proches: [],
            first_name: '',
            last_name: ''
        };
    },
    created() {
        this.getInit();
        if(this.$auth.user) {
            this.step = 2
            this.getpatient()
            this.getProches()
        }
    },
    watch: {
        $route: 'getInit'
    },
    methods: {
        getInit() {
            const room_id = this.$route.params.room;
            this.$http.get(`auth/room/${room_id}`).then(({ data }) => {
                this.room = data.data;
            }).catch((error) => {
                if(error.response.data.message)
                    this.$toast.error({
                        title: "Salle attante",
                        message: "Désolé, la salle n'est plus disponible !!!"
                    });
            });
        },
          verify(){
      if(this.room.doctor.condictions_pay) {
        this.showDialog = true
      } else {
        this.confirmer()
      }
    },
        confirmer() {
            const data = {
                proche_id:  this.patient_selected.isMe ? null : this.patient_selected.id,
                room_id: this.room.id,
            }
            this.$http.post('room_waiting', data).then((response) => {
                    this.$toast.success({
                    title: "Ajout Salle attente",
                    message: response.data.message
                })
            

            this.$router.push({name: `${(this.$auth.user.role).toLowerCase()}.room.waitings`})
            //this.$router.push({name: 'patient.room.waitings'})
        }).catch((error) => {
            if(error.response.data.message){
                this.$toast.error({
                    title: "Ajout Salle attente",
                    message: error.response.data.message
                })
            }
                
        }).finally(() => this.loading = false)
        },
        async connected() {
            this.dialog = false;
            this.$router.go()
        },
        codeValide() {
            this.tab = 1;
        },
        getProches() {
            this.$http
                .get(`proches`)
                .then(({ data }) => {
                    this.proches = data.data;
                });
            this.getpatient();
        },
        getpatient() {
            this.patient = this.$auth.user;
        },
        createProche(proche) {
            this.proches.push(proche);
        },
        annuler() {
            this.$router.go(-1)
        },
        search(key) {
            if (key) {
                this.$http.get('proches/search',{ params: {first_name: this.first_name, last_name: this.last_name}}).then(res => {
                this.proches = res.data.data
                }).catch(() => {

                })
            } else {
            this.getProches()
            //this.$refs.loader.$emit('$InfiniteLoading:loaded')
            }
        }
    },
    components: { Navbar, Login, Register, ConditionsPay, ProcheForm }
}
</script>

<style scoped>
.btn-group {
    display: flex;
    justify-content: flex-end;

}

.btn {
    margin-right: 12px;
}

.profile-photo img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.profile-photo {
    padding: 10px;
    margin-right: 20px;
}

.profile-info {
    /*padding: 10px;*/
    margin: 10px 0px 0px 17px;
    /*text-align: center;*/
    line-height: 1.5;
    font-size: 0.9em;
}

.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
    margin-bottom: 30px;
}

@media screen and (max-width: 600px) {
    .v-application--is-ltr .v-stepper--vertical .v-stepper__content {
        margin: 0;
    }

    .btn {
        font-size: 12px;
    }

    .v-application--is-ltr .v-stepper--vertical .v-stepper__content {
        padding: 0 0 0 12px;
    }

    .btn-group {
        justify-content: space-around;
    }

}</style>