<template>
  <div class="ordonnance">
    <v-divider></v-divider>
    <div class="row justify-space-between my-1">
        <div class="font-7 col-md-3">{{ ordonnance.name }}</div>
        <div class="font-7 col-md-4">{{ ordonnance.dosage }}</div>
        <div class="font-7 col-md-3">{{ ordonnance.nombre }}</div>
        <div class="d-flex justify-end col-md-2" v-if="is_from_me">
            <v-btn icon class="mx-2" color="#025397" @click="showForm=true" outlined small>
                <v-icon size="20" >mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon class="" color="error" @click="dialogDelete=true" outlined small>
                <v-icon size="20" >mdi-delete</v-icon>
            </v-btn>
        </div>
    </div>
    <delete-dialog 
        v-if="dialogDelete" 
        v-model="dialogDelete" 
        :message="'Êtes-vous sur de vouloir supprimer ce médicament'" 
        :title="'Suppression médicament'"
        @confirmDelete="ordonnanceDelete"
    />
    <form-ordonnance v-if="showForm"  v-model="showForm" :ordonnance="ordonnance" @ordonnanceUpdated="ordonnanceUpdated"   />
  </div>
</template>

<script>
import DeleteDialog from '../Home/utilities/DeleteDialog.vue'
import formOrdonnance from './formOrdonnance.vue'
export default {
  components: { formOrdonnance, DeleteDialog },
    props: {
        ordonnance: Object,
        is_from_me: Boolean
    },
    data() {
        return {
            showForm: false,
            dialogDelete: false
        }
    },
    methods: {
        ordonnanceUpdated(ordonnance) {
            this.$toast.success({
                title: 'Médicament',
                message: 'Le médicament a été mis à jour avec succès !'
            })
            this.ordonnance = ordonnance
        },
        ordonnanceDelete() {
            this.$http.delete(`ordonnance/${this.ordonnance.id}`).then(() => {
                this.$toast.success({
                    title: 'Médicament',
                    message: 'Le médicament a été supprimé avec succès !'
                })
                this.$emit('medicamentDeleted', this.ordonnance)
            }).catch(() => {
                this.$toast.error({
                    title: 'Médicament',
                    message: "Le médicament n'est pas pu être supprimé"
                })
            })
            
        }
        
    }

}
</script>

<style>

</style>