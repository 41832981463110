<template>
 
 <iframe :src="url" frameborder="0" style="overflow:hidden;height:100%;width:100%" height="100%" width="100%"></iframe>
</template>

<script>


export default {
    props: {
        url: String
    },
  data() {
    return {
      
    };
  },
  computed: {
   
  },
  mounted() {
    
  },
  beforeDestroy() {
   
  },
  methods: {
  },
};
</script>

<style>
</style>