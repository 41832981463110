<template>
  
  <div style="overflow: hidden">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="650px"
      transition="dialog-transition"
      hide-overlay
    >
      <v-card height="800px">
        <v-btn icon color="error" @click="dialog = false" class="float-right">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="white pa-3">
          <div class="d-flex title justify-center">Envoyer un document</div>
          <!--  -->
        </div>
        <v-divider />
        <v-card-text>
          <div class="d-flex pa-3" v-if="appointment.medecin">
            <v-avatar size="50" color="white">
              <img :src="appointment.medecin.avatar_url" alt="photo-medecin" />
            </v-avatar>
            <div class="subtitle px-3">
              Envoyer vos documents à
              <strong>{{ appointment.medecin.name }}</strong> pour faciliter la
              consultation
            </div>
          </div>
          <div>
            <file-item
              v-for="file in files"
              :file="file"
              :key="file.id"
              :appointment_id="appointment.id"
              @deleteShareSuccess="deleteShareSuccess"
            >
            </file-item>
          </div>
          <div class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on" outlined>
                  Ajouter un document
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-btn color="primary" text @click="pickFile()"
                    >Depuis mon ordinateur</v-btn
                  >
                </v-list-item>
                <v-list-item v-if="showBtn">
                  <v-btn color="primary" text @click="(showListFiles=true)"
                    >Depuis mes documents sahel assistance</v-btn
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-card-text>
        <input
          ref="galerie"
          type="file"
          style="display: none"
          @change="uploadImage"
        />
      </v-card>
    </v-dialog>
    <div v-if="showListFiles">
      <select-file
      v-model="showListFiles"
      :files="filesPatient"
      @prevListFile="prevListFile"
      @fileSelected="fileSelected"
    />
    </div>
   
    <file-selected
      v-model="showSelectedFile"
      v-if="file_selected"
      :file="file_selected"
      :appointment_id="appointment.id"
      @fileSendingSuccessfuly="fileSendingSuccessfuly"
      @prevSelect="prevSelect"
    />
    <file-form
      v-if="file"
      v-model="showFormFile"
      :file="file"
      :modify="true"
      :patient="patient"
      :patient_id="appointment.patient_id"
      :intervenant_id="appointment.intervenant_id"
      :appointment="appointment"
      @createFile="createFile"
    />
  </div>
  
</template>

<script>
import FileForm from "./FileForm.vue";
import FileItem from "./FileItem.vue";
import FileSelected from "./FileSelected.vue";
import SelectFile from "./SelectFile.vue";
export default {
  components: { FileForm, FileItem, SelectFile, FileSelected },
  props: {
    value: {
      type: Boolean,
    },
    appointment: {
      type: Object,
      required: true,
    },
    files: {
      type: Array,
    },
  },
  data() {
    return {
      file_selected: null,
      showBtn: false,
      filesPatient: [],
      file: null,
      patient: null,
      showFormFile: false,
      showListFiles: false,
      showSelectedFile: false
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      },
    },
  },
  mounted() {
    this.getFiles();
  },

  methods: {
    pickFile() {
      this.$refs.galerie.click();
    },
    deleteShareSuccess(file) {
      this.files = this.files.filter((doc) => doc.id != file.id);
      this.appointment.files = this.files;
    },
    createFile(file) {
      this.files.unshift(file);
      this.$toast.success({
        title: 'Ajout Document',
        message: 'Le document a été ajouté au rendez-vous avec succès.'
      })
    },
    prevSelect() {
      this.showListFiles = true;
      this.showSelectedFile = false;
    },
    prevListFile() {
      this.dialog = true;
      this.showListFiles = false;
    },
    fileSelected(file) {
      this.file_selected = file;
      this.showListFiles = false;
      this.showSelectedFile = true;
    },
    getFiles() {
      const appointement_id = this.$route.params.appointment;
      
          this.$http
          .get(
            `files/${appointement_id}/not-selected`
          )
          .then(({ data }) => {
            this.filesPatient = data.data;
            if (this.filesPatient.length !=0) {
              this.showBtn = true;
            }
          });
      
      
    },
    uploadImage(event) {
      this.file = event.target.files[0];
      this.patient = this.appointment.proche
        ? this.appointment.proche
        : this.appointment.patient ? this.appointment.patient : this.appointment.intervenant;
      this.showFormFile = true;
    },
    listingFile() {
      this.showListFiles = true;
      this.dialog = false;
      
    },
    fileSendingSuccessfuly(data) {
      this.$emit('fileSendingSuccessfuly',data)
      this.showSelectedFile = false;
      this.dialog = false;
    }
  },
};
</script>

<style>
</style>