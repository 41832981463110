<template>
    <div>
        <v-data-table :headers="headers" :items="data" :page.sync="current_page" class="elevation-0"
            item-key="id" :items-per-page="itemsPerPage" :loading="loading" hide-default-footer>
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>{{ title }}</v-toolbar-title>
                </v-toolbar>
            </template>
            <template v-slot:[`item.name`]="{ item }">
                <div class="d-flex py-3" v-if="item">
                    <v-badge bordered top :color="item.is_online ? 'green' : 'red'" dot offset-x="10" offset-y="10">
                        <v-avatar size="50" color="white" class="mr-2">
                            <img :src="item.avatar_url" alt="alt" />
                        </v-avatar>
                    </v-badge>
                    <div class="mt-3">
                        <div class="font-weight-bold font-7">{{ item.name }}</div>
                    </div>
                </div>
            </template>
        </v-data-table>
        <div class="text-center pt-6" v-if="pageCount > 1">
            <v-pagination v-model="current_page" :length="pageCount" @input="paginate"></v-pagination>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Array,
        title: String,
        loading: Boolean,
        page: {
            type: Number,
            default: () => 1
        },
        //current_page: Number,
        pageCount: Number,
        itemsPerPage: Number

    },
    data () {
        return {
            current_page: this.page,
            headers: [
                {
                text: 'Nom et Prénom',
                align: 'start',
                sortable: false,
                
                value: 'name',
                },
                {
                text: 'N° téléphone', value: 'phone'
                },
                { text: 'Profil', value: 'role' },
                { text: 'Derniére connexion', value: 'last_login_at' },
            ],
        }
    },
    methods: {
        paginate() {
            this.$emit('paginate', this.current_page)
        }
    }
}
</script>

<style></style>