<template>
<div>
    <v-row class="py-6">
      <v-col cols="12" md="3">
        <router-link :to="{name: 'admin.logs.home'}" style="text-decoration: none;">
            <card-dashboard :icon="`mdi-account-key-outline`" :title="`Users connectés aujourd'hui`"
          :number="users_connected_today" :color="'primary'" :minHeight="180"/>
        </router-link>
       
      </v-col>
      <v-col cols="12" md="3">
        <router-link :to="{name: 'admin.users.logs.week'}" style="text-decoration: none;">
            <card-dashboard :icon="`mdi-account-key-outline`" :title="`Users connectés 7 derniers jours`"
          :number="users_connected_last_7_days" :color="'blue'" :minHeight="180" />
        </router-link>
        
      </v-col>
      <v-col cols="12" md="3">
        <router-link :to="{name: 'admin.users.logs.month'}" style="text-decoration: none;">
            <card-dashboard :icon="`mdi-account-key-outline`" :title="`Users connectés au cours des 30 derniers jours`"
          :number="users_connected_last_30_days" :color="'yellow darken-1'" :minHeight="180" />
        </router-link>
       
      </v-col>
      <v-col cols="12" md="3">
        <router-link :to="{name: 'admin.users.not-connected.logs'}" style="text-decoration: none;">
            <card-dashboard :icon="`mdi-account-key-outline`" :title="`Users non connectés pendant 30 jours`"
          :number="users_not_connected_last_30_days" :color="'red'"  :minHeight="180" />
        </router-link>
      </v-col>
    </v-row>
    <div class="">
        <router-view></router-view>
    </div>
</div>
</template>

<script>
import CardDashboard from '../../../components/Home/CardDashboard.vue'
export default {
  components: { CardDashboard },
    data() {
        return {
            users_connected_today: 0,
            users_connected_last_7_days: 0,
            users_connected_last_30_days: 0,
            users_not_connected_last_30_days: 0,
        }
    },
    created() {
        this.getConnectedUsersStats()
    },
    methods: {
        getConnectedUsersStats() {
        this.$http.get('activity/stats').then(({ data }) => {

            this.users_connected_today = data.users_connected_today
            this.users_connected_last_7_days = data.users_connected_last_7_days
            this.users_connected_last_30_days = data.users_connected_last_30_days
            this.users_not_connected_last_30_days = data.users_not_connected_last_30_days
        })
        }, 
    }
}
</script>

<style>

</style>