<template>
  <div>
    <v-dialog
        v-model="show"
        persistent
        width="500"
    >
    <v-card>
        <v-card-title>
            <div class="font-6 font-weight-bold">Modifier mes infos</div>
            <v-spacer></v-spacer>
            <v-btn icon small color="red" @click="show = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-6">
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model="doctor.user.name"
                        name="name"
                        label="Nom Complet"
                        outlined
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model="doctor.user.email"
                        name="email"
                        label="Adresse e-mail"
                        outlined
                        disabled
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <vue-tel-input-vuetify 
                        v-model="doctor.tel_port"
                        class="form"
                        name="phone"
                        placeholder="Entrer votre N° téléphone"
                       
                        outlined
                        mode="international"
                        :error="errorPhone"
                        ref="telInput"
                        @change="verifyNumber"
                        :error-messages="errors.tel_port"
                    >
                    </vue-tel-input-vuetify>
                    <!-- <v-text-field
                        v-model="doctor.tel_port"
                        name="phone"
                        label="N° téléphone"
                        outlined
                        :error-messages="errors.tel_port"
                    ></v-text-field> -->
                </v-col>
                <v-col cols="12" md="6">
                    <v-autocomplete
                        v-model="doctor.ville"
                        :items="villesSenegal"
                        name="ville"
                        label="Ville"
                        outlined
                        :error-messages="errors.ville"
                        disabled
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model="doctor.cabinet_name"
                        name="cabinet_name"
                        label="Nom du Cabinet"
                        outlined
                        :error-messages="errors.cabinet_name"
                        
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model="doctor.address_cabinet"
                        name="address_cabinet"
                        label="Adresse du cabinet"
                        outlined
                        :error-messages="errors.address_cabinet"
                        disabled
                    ></v-text-field>
                </v-col>
            </v-row>
            <div class="d-flex">
            <v-btn style="text-transform: none;" color="primary" elevation="0" @click="updated" class="mr-3">Enregistrer</v-btn>
            <v-btn style="text-transform: none;" color="error" outlined @click="show=false" elevation="0"> Annuler</v-btn>
        </div>
        </v-card-text>
    </v-card>

    </v-dialog>
  </div>
</template>

<script>
export default {
    props: {
        doctor: Object,
        value: Boolean
    },
    data() {
        return {
            errorPhone: false,
            loading: false,
            errors: {},
            villesSenegal: [ 'Dakar', 'Diourbel','Fatick','Kaffrine','Kaolack','Kédougou','Kolda','Louga',' Matam','Saint-Louis','Sédhiou','Tambacounda','Thiès','Ziguinchor'],
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                return this.$emit('input', value)
            }
        }
    },
    methods: {
        verifyNumber() {
            if(!this.$refs.telInput._computedWatchers.phoneObject.value.isValid) {
                this.errorPhone = true;
                this.errors.tel_port = "Le numéro est incorrecte"
            } else {
                this.errorPhone = false;
                this.errors.tel_port = null;
            }
      
    },
        updated() {
            const data = {
                ville: this.doctor.ville,
                address_cabinet: this.doctor.address_cabinet,
                cabinet_name: this.doctor.cabinet_name,
                tel_port: this.doctor.tel_port.match(/\S+/g).join("")
            }
            this.$http.put(`doctor/${this.doctor.id}`, data).then((response) => {
                this.$toast.success({
                    title: 'Modification',
                    message: response.data.message
                })
                this.show = false
            }).catch((error) => {
               
                if(error.response.data.errors) {
                    this.errors = error.response.data.errors
                    
                } else {
                    this.$toast.error({
                        title: 'Echec',
                        message: error.response.data.message
                    })
                }
                
            })
        }
    }
}
</script>

<style>

</style>