<template>
    <div>
        <v-data-table 
            :headers="headers" 
            :items="medical_visits" 
            class="elevation-0" 
            item-key="id" 
            :loading="loading"
            hide-default-footer
            :items-per-page="itemsPerPage"
        >
            <template #no-data>
                <div class="pa-4">
					<v-icon size="40">mdi-alert-circle-outline</v-icon>
					<div class="">Aucune visite médicale pour le moment</div>
				</div>
            </template>
            <template #item.owner="{ item }">
                <div class="d-flex py-3">
                    <v-avatar size="50" color="white" class="mr-2">
                        <img :src="item.owner.avatar_url" alt="alt" />
                    </v-avatar>
                    <div class="medecin-name">
                        <div class="font-weight-bold">{{ item.owner.name }}</div>
                        <span class="caption">Médecine générale</span>
                    </div>
                </div>
            </template>
            <template #item.patient="{ item }">
                <div class="">
                    <router-link 
                        style="text-decoration: none;"
                        :to="{
                            name: $auth.user.is_secretaire ? 'secretaire.patient.detail' : 'doctor.patient.detail', 
                            params:{ 
                                auteur: item.proche ? 'proche' : 'patient',
                                patient: item.proche ? item.proche.id : item.patient.id
                            } 
                        }" 
                    >
                        <span class="">{{ item.proche ? item.proche.name : item.patient.name }}</span>
                    </router-link>

                </div>
            </template>
            <template  #item.actions="{ item }">
                <div class="d-flex justify-end"> 
                    <div class="d-flex">
                    <v-tooltip bottom v-if="item.is_from_me">
                        <template #activator="{ on, attr }">
                            <v-btn icon color="primary" class="mr-2" v-on="on" v-bind="attr" @click="shared(item)">
                                <v-icon>mdi-share</v-icon>
                            </v-btn>
                        </template>
                        <span>Partager</span>
                    </v-tooltip>
                </div>
                <div class="d-flex">
                    <v-tooltip bottom>
                        <template #activator="{ on, attr }">
                            <v-btn icon color="primary" class="mr-2" v-on="on" v-bind="attr" link
                                :to="{ name: $auth.user.is_doctor ? 'doctor.visit.medical.view' : 'secretaire.medical.visit.view', params: { medical_visit_id: item.id } }">
                                <v-icon>mdi-eye</v-icon>
                            </v-btn>
                        </template>
                        <span>Voir</span>
                    </v-tooltip>
                </div>
                <div class="d-flex">
                
                    <v-tooltip bottom v-if="item.is_from_me || $auth.user.is_secretaire">
                        <template #activator="{ on, attr }">
                            <v-btn icon color="primary" class="mr-2" v-on="on" v-bind="attr" @click="factureShow(item)">
                                <v-icon>mdi-file-document-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Facture</span>
                    </v-tooltip>
                </div>
                </div>
                
            </template>


        </v-data-table>
        <div class="text-center pt-2" v-if="pageCount > 1">
            <v-pagination
                v-model="current_page"
                :length="pageCount"
                @input="paginate"
            />
        </div>
        <form-shared v-if="showshared" v-model="showshared" :id="shared_id" :type="'medical_visit'" />
        <form-facture v-if="showFacture" v-model="showFacture" :facture="facture" @factureSaved="factureSaved"
            @printFacture="printFacture" />
    </div>
</template>
  
<script>
import FormFacture from '../facture/formFacture.vue'
import FormShared from '../doctor/formPartage.vue'
export default {
    components: { FormFacture, FormShared },
    props: {
        medical_visits: {
        type: Array,
        },
        headers: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true
        },
        page: {
            type: Number,
            default: () => 1
        },
        pageCount: {
            type: Number
        },
        itemsPerPage: {type: Number , default: () => 20 }
    },
    data() {
        return {
            shared_id: null,
            facture: null,
            showFacture: false,
            showshared: false,
            medical_visit_selected: null,
            current_page: this.page
        }
    },
   
    methods: {
        factureShow(medical_visit) {
            this.medical_visit_selected = medical_visit
            if (medical_visit.facture) {
                this.facture = medical_visit.facture
            }
            else {
                this.facture = {
                    id: null,
                    montant: null,
                    montant_en_lettre: '',
                    payee: false,
                    medical_visit_id: medical_visit.id,
                    consultation_id: null
                }

            }
            this.showFacture = true

        },
        factureSaved(facture) {
            const index = this.medical_visits.findIndex((medical_visit) => medical_visit.id == this.medical_visit_selected.id)
            this.medical_visits[index].facture = facture
            this.$toast.success({
                title: 'Facture',
                message: 'La facture a été enregistrée avec succès !'
            })
        },
        shared(medical_visit) {
            this.shared_id = medical_visit.id
            this.showshared = true
        },
        printFacture() {
            this.showFacture = false
            window.open(`/printer/facture/medical_visit/${this.medical_visit_selected.id}`, 'printWindow', 'height=800,width=900, align: center')
        },
        paginate() {
            this.$emit('pagination',this.current_page)
        }
    }
}
</script>
  
<style></style>