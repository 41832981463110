<template>
  <div>
    <div class="mb-6">
      <card-title :title="'Présentation'" :icon="'mdi-account-file-text-outline'">
        <v-btn style="text-transform:none" color="primary" elevation="0" outlined @click="showForm=true">Modifier</v-btn>
    </card-title>

      <v-sheet class="pa-6" v-if="presentation">
        <div class="ql-editor" v-html="presentation.description" />
      </v-sheet>
      <!-- <v-sheet v-else class="pa-6">
        <div class="font-7 font-weight-bold">Quelques suggestions pour votre présentation :</div>
        <ul class="font-7">
            <li>Présentez vous en tant que praticien</li>
            <li>Mettez en avant vos spécialités.</li>
            <li>Présentez votre approche envers les soins de santé.</li>
        </ul>
      </v-sheet> -->
      <galerie v-if="presentation" :photos="presentation.photos" @deletePhoto="deletePhoto" @savedPhoto="saveFile"/>
      <form-editor v-if="showForm" v-model="showForm" :contenu="presentation ? presentation.description : ''"  @saved="savePresentation" :title="'Presentation'" />
    </div>
  </div>
</template>

<script>
import Galerie from '../../../components/doctor/Galerie.vue'
import FormEditor from '../../../components/FormEditor.vue'
import cardTitle from '../../../components/Home/utilities/cardTitle.vue'
export default {
  components: { cardTitle, FormEditor, Galerie},

    data() {
        return {
            presentation: null,
            showForm: false,
            errors: {}
        }
    },
    created() {
        this.getPresentation()
    },
    methods: {
        getPresentation() {
            this.$http.get(`presentation`).then(({ data }) => {

                this.presentation = data.data
            })
        },
        savePresentation(content) {
            const data = {
                description: content,
                doctor_id: this.$auth.user.doctor_id
            }
            if(!this.presentation) {
                this.$http.post(`presentation`, data).then(({ data }) => {
                    this.presentation = data.data
                    this.$toast.success({
                        title: 'Presentation',
                        message: "Presentation modifié avec succès"
                    })
                }).catch((error) => {
                    if(error.response.data.message)
                        this.$toast.error({
                            title: 'error',
                            message: error.response.data.message
                        })
                })
            } else {
                this.$http.put(`presentation/${this.presentation.id}`, data).then(({ data }) => {
                this.presentation = data.data
                this.$toast.success({
                    title: 'Presentation',
                    message: "Presentation modifié avec succès"
                })
            }).catch((error) => {
                if(error.response.data.message)
                    this.$toast.error({
                        title: 'error',
                        message: error.response.data.message
                    })
            })
        }
            
        },
        deletePhoto(data) {
            this.$http.put(`presentation/deleteFile/${this.presentation.id}`, data).then(() => {
                //this.presentation = data.data
                this.$toast.success({
                    title: 'Presentation',
                    message: "Photo supprimée avec succès"
                })
            }).catch((error) => {
                if(error.response.data.message)
                    this.$toast.error({
                        title: 'error',
                        message: error.response.data.message
                    })
            })
        },
        saveFile(file) {
            this.$http.post(`presentation/addFile/${this.presentation.id}`, file).then(({ data }) => {
                this.presentation = data.data
                this.$toast.success({
                    title: 'Presentation',
                    message: "Photo ajoutée avec succès"
                })
            }).catch((error) => {
                if(error.response.data.message) {
                    this.$toast.info({
                        title: 'error',
                        message: error.response.data.message
                    })
                }
                if(error.response.data.errors.file) {
                    this.$toast.error({
                        title: 'Error',
                        message: error.response.data.errors.file[0]
                    })
                }
                
            })
        }
    }
}
</script>

<style>

</style>