<template>
    <div class="user">
      <v-row>
        <v-col v-for="(patient,index) in patients" :key="index" cols="12" md="3" sm="3" align-content='space-between'>
          <v-card>
            <div class="avatar">
              <img :src="patient.user.avatar_url">
              <div>
                <span class="mdi mdi-checkbox-marked-circle-outline" style="color: green; margin-right: 5px;"
                  v-if="patient.user.actif === true"></span>
                <span class="mdi mdi-close-circle" style="color: red; margin-right: 5px;" v-if="patient.user.actif === false"></span>
                <span class="font-weight-bold">{{ patient.user.name }}</span>
              </div>
              <div class="mb-3">
                <div class="caption text-center" v-if="patient.age">{{ patient.AGE }}</div>
                <!-- <div class="caption text-center" v-if="patient.address">{{ patient.address }}</div> -->
                <div class="caption">Tel: {{ patient.user.phone }}</div>
              </div>
            </div>
            <div class="info">
                <v-btn color="info" block style="text-transform: none; text-decoration: none; color: white;" link @click="viewProfil(patient)">Voir profil</v-btn>
              <!-- <router-link class="button" v-if="patient.user.role === 'PATIENT'" :to="'#'">Voir le profil</router-link> -->
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
</template>

<script>
export default {

    props: {
        patients: Array
    },
    methods: {
        viewProfil(patient) {
            this.$emit('viewProfile', patient)
        }
    }

}
</script>

<style scoped>
  .user {
    padding: 20px 0;
    box-shadow: 0 0 2px rgb(209, 207, 207);
    padding: 5px;
    text-decoration: none;
    color: inherit;
    transition: transform ease .3s, box-shadow ease .3s;
  }

  .user .actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user .avatar img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin-top: 20px;
  }

  .user .info p {
    text-align: center;
  }

  .user .info .description {
    line-height: 1;
    font-size: .9em;
    margin: 10px 0;
  }

  .user .name .profession {
    transform: translateY(-5px);
    font-style: italic;
    color: #1F3651;
    margin: 10px 0;
    font-size: .9em;
  }

  .user .info .button {
    display: block;
    /*background-color: #1F3651;*/
    padding: 5px 30px;
    text-decoration: none;
    color: #fff;
    border-radius: 290486px;
    margin-bottom: 3px;
    margin-top: 3px;
  }

  .user .avatar,
  .user .name,
  .user .info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>