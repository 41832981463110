<template>
  <div>
    <div class="bg-1">
      <specialsHomeVue />
      <!-- <stats-home /> -->
    </div>
  </div>
</template>

<script>

import specialsHomeVue from "../components/Home/specials.home.vue";
// import StatsHome from '../components/StatsHome.vue';

export default {
  name: "Home",

  components: {
    specialsHomeVue,
    // StatsHome,
  },
};
</script>
<style scoped>
</style>
