<template>
    <drawer :links="links" />
  </template>
  <script>
    import Drawer from '../../components/Home/utilities/Drawer.vue';
    export default {
        components: {Drawer},
    data: () => ({
      links: [
        ["mdi-account-circle", "Mon compte", "patient.home"],
        ["mdi-calendar-clock", "Gérer mes rendez-vous","compte.mes-rendez-vous"],
        ["mdi-account-supervisor", "Mes proches","compte.mes-proches"],
        ["mdi-file-document", "Mes documents","compte.documents"],
        ["mdi-sofa", "Salle Attente Consultation Vidéo","patient.room.waitings"],
        ["mdi-bell", "Notifications", "patient.notify"]
      ],
    }),
   
  };
  </script>