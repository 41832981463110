<template>
 <v-app>
    <navbar />
    <div class="search">
                <search-medecins />
            </div>
    <v-main>
        <loading-page v-if="loading" />
        <div v-else>
           
        <div  class="medecins" v-if="doctors.length">
            <medecins-list>
                <medecin-view
                    v-for="doctor in doctors"
                    :key="doctor.id"
                    :medecin="doctor"
                    :specialite="doctor.department.name"
                />
            </medecins-list>
        </div>
        <div v-else>
        <div class="text-center font-4 mt-12">Pas encore de médecins pour cette spécialité</div>
        </div>
    </div>
    </v-main>
 </v-app>
</template>

<script>
import MedecinView from '../components/Home/utilities/medecin.vue'
import MedecinsList from '../components/Home/medecins.list.vue'
import navbar from '../components/Home/utilities/navbar.vue'
import SearchMedecins from './search.medecins.vue'
import LoadingPage from '../components/Home/utilities/LoadingPage.vue'
export default {
  components: { navbar, MedecinsList, MedecinView, SearchMedecins, LoadingPage },
    data() {
        return {
            type_rv: null,
            loading: false,
            doctors: []
        }
    },
    watch: {
        $route: 'getDoctors'
    },
    mounted() {
        this.getDoctors()
    },
    methods: {
        getDoctors() {
            this.loading = true
            const type = this.$route.params.type
            this.type_rv = type == 'days_telemedecine' ? 'telemedecine' : 'cabinet'
            this.$http.get('auth/doctors', { params: { type: type }}).then(({data}) => {
                this.doctors = data.data
                this.loading = false
            }).catch(() => {
                this.loading = false;
                this.$toast.error({
                    title: 'Erreur',
                    message: "Impossible de récupérer la liste des médecins"
                })
            })
        }
    }
}
</script>

<style>

</style>