<template>
      <div>
        <div style="margin-top:20px">
            <h2 class="font-6 text-center font-weight-bold" style="text-decoration:underline;">CERTIFICAT MÉDICAL</h2> <br>
        </div>
        <div class="d-flex justify-end  font-7 my-12">
      
          <div>Dakar, le {{ today }}</div>
    
        </div>
        <div class="font-7" style="margin-bottom:25px;">
            <p>Je soussigné, Docteur en Médecine, <span class="font-weight-bold"> {{ repos_medical.doctor.name }} certifie que l'état de santé de <br>
                {{ repos_medical.proche_id ? (repos_medical.proche.sexe=='homme' ? 'Mr' : 'M(me)') : (repos_medical.patient.sexe=='homme' ? 'Mr' : 'M(me)') }}  {{ repos_medical.proche_id ? repos_medical.proche.name : repos_medical.patient.name }} <br>
                Né(e) le  {{ repos_medical.proche_id ? repos_medical.proche.date_naissance : repos_medical.patient.date_naissance }}
                à {{ repos_medical.proche_id ? repos_medical.proche.lieu_naissance : repos_medical.patient.lieu_naissance }}</span></p>
            <p>1°) nécessite un traitement avec arrêt de travail de  <span class="font-weight-bold"> <br>
                    {{ repos_medical.number_days }} jours, sauf complications <br>
                    du {{ repos_medical.start_traitement }} au
                    {{ repos_medical.end_traitement }}
                </span></p>

            <p v-if="repos_medical.start_prolongation"> {{repos_medical.start_prolongation ? '2°)' : ''}} nécessite une prolongation de l'arrêt <span class="font-weight-bold"> <br>
                    {{ repos_medical.date_reprise_prolongation_formated   }} , sauf complications <br>
                    du {{repos_medical.start_prolongation  }} au
                    {{repos_medical.end_prolongation }}
                </span></p>
            <p> {{repos_medical.start_prolongation ? '3°)' : '2°)'}} lui permet de reprendre son travail à dater <span class="font-weight-bold"> <br>
                    {{ repos_medical.reprised}}
                </span></p>
        </div>
        <footer-print :signature_url="doctor.signature_url"/>
    </div>
  </template>
  
  <script>
import footerPrint from '../../components/Home/utilities/footerPrint.vue';
  export default {
    props: ['doctor'],
    components: { footerPrint },
  
    data() {
    return {
      id: this.$route.params.id,
      repos_medical: null
    }
  },
  computed: {
    today() {

            // Créer une instance de la date actuelle
      const date = new Date();

      // Obtenir les informations de la date
      const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

      // Convertir la date en chaîne de caractères
      const dateEnFrancais = date.toLocaleDateString('fr-FR', options);
      return dateEnFrancais;
    }
  },
  mounted() {
    this.getRepos_medical()
  },
  methods: {
    getRepos_medical() {
      this.$http.get(`repos_medical/${this.id}`).then(({ data }) => {
        this.repos_medical = data.data
        setTimeout(() => {
          window.print()
        }, 2000);
      })
    }
  }

  
  }
  </script>
  
  <style>
  
  </style>