<template>
   <v-dialog
      v-model="show"
      persistent
      max-width="650px"
      transition="dialog-transition"
      hide-overlay
    >
      <v-card height="800px">
            <div class="d-flex justify-space-between py-3">
                <v-btn  icon>
                    <v-icon size="30" @click="retour">mdi-arrow-left</v-icon>
                </v-btn>
                <div class="mt-2 title">
                    Selectionner un document
                </div>
                <v-btn icon @click="show = false">
                    <v-icon size="30">mdi-close</v-icon>
                </v-btn>
            </div>
            <v-divider />
            <v-card-text class="py-6">
            <div v-for="file in files" :key="file.id">
                <div class="file-item">
                  <v-btn block  outlined class="btn-file" @click="fileSelected(file)">
                        <v-icon color="primary">mdi-file-outline</v-icon>
                          <span class="primary--text">  {{ file.filename }} </span><br>
                          <span class="hidden-md-and-up caption font-weight-thin mx-2 mt-0.5">Créé par
                {{ file.creator.patient_id ? "vous" : file.creator.name }} </span>
                        <span class="hidden-sm-and-down caption font-weight-thin mx-3 "
                >Créé par
                {{ file.creator.patient_id ? "vous" : file.creator.name }} le
                {{ file.last_update }}</span
              >
                        
                  </v-btn>
                </div>
            </div>
        </v-card-text>
      </v-card>
   </v-dialog>
</template>

<script>
export default {
    props: {
        files: { type: Array},
        value: { type: Boolean }
    },
  data() {
    return {}
  },
  computed: {
    show: {
        get() {
            return this.value
        },
        set(value) {
            return this.$emit('input', value)
        }
    }
  },
  methods: {
    retour() {
        this.$emit('prevListFile');
    },
    fileSelected(file) {
        this.$emit('fileSelected',file)
    }
  }

}
</script>

<style scoped>
.btn-file {
  text-transform: none;
  justify-content: start;
  padding: 25px 0 25px 10px !important;
  
}
  .file-item {
    margin-bottom: 10px;
  }

  @media  screen and (max-width: 600px) {
    
    .file-text {
      display: none;
    }
  }

</style>