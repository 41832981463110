<template>
  <div>
    <v-sheet class="pa-6">
        <item-motif 
          v-for="motif in motifs" 
          :key="motif.id" 
          :motif="motif" 
          @deletedMotif="deletedMotif"
        ></item-motif>
    </v-sheet>
  </div>
</template>

<script>
  import ItemMotif from './ItemMotif.vue';

    export default {
        components: {ItemMotif},
        props: {
            motifs: Array
        },
        methods: {
          deletedMotif(data) {
            this.motifs = this.motifs.filter((motif) => motif.id != data.id)
            this.$emit('deletedMotif', this.motifs)
          },
          
        }

    }
</script>

<style>

</style>