<template>
  <v-app>
    <navbar>
      <div v-if="doctor" class="doctor-show">
        <v-sheet class="pa-3 mb-3" width="100%" elevation="0">
          <div class="profil">
            <div class="profil-photo" >
              <v-avatar
                size="72"
                color="grey"
              >
                <img :src="doctor.user.avatar_url" alt="avatar" />
              </v-avatar>
              
            </div>

            <div class="profil-info" v-if="doctor">
              <div>
                <strong>{{ doctor.name }}</strong>
              </div>
              <p class="font-7">
                Spécialiste en {{ doctor.department.name.toLowerCase() }}
              </p>
              
            </div>

            <v-spacer></v-spacer>
            <v-btn
              v-if="(!$auth.check() || $auth.user.is_patient) && has_schedule"
              class="btn-rdv"
              color="primary"
              style="text-transform: none; text-decoration: none;"
              link
              :to="{ name: 'medecin.rendez-vous', params: { id: doctor.id } }"
            >
              Prenez rendez-vous
            </v-btn>
          </div>
          <div> 
                <v-btn
              v-if="!$auth.check() || $auth.user.is_patient"
              class="btn-rdv-mobile"
              color="primary"
              block
              small
              style="text-transform: none; text-decoration: none"
              link
              :to="{ name: 'medecin.rendez-vous', params: { id: doctor.id } }"
            >
              Prenez rendez-vous
            </v-btn>
              </div>
        </v-sheet>
        <v-sheet light width="100%" class="pa-3 mb-3">
          <v-icon size="30">mdi-home-account</v-icon> <span class="font-weight-bold">Informations générales</span>
          <v-divider />
          <v-row>
            <v-col cols="12" md="6">
              <div class="profile-info">
                <p><strong>Identité du praticien</strong></p>
                <p>{{ doctor.name }}</p>
                <p><strong>Département:</strong></p>
                <p>{{ doctor.department.name }}</p>
                <p><strong>Cabinet</strong></p>
                <p>{{ doctor.cabinet_name }}</p>
                <p><strong>Adresse du cabinet</strong></p>
                <p>{{ doctor.address_cabinet }}</p>
                <p><strong>Email</strong></p>
                <p>{{ doctor.user.email }}</p>
                <p><strong>N° téléphone</strong></p>
                <p> {{ doctor.tel_port }}</p>
              </div>
            </v-col>
            <v-col cols="12" md="6" v-if="doctor.marker.length">
              <show-map :marker="doctor.marker" />
            </v-col>
          </v-row>
          
        </v-sheet>
        <v-sheet v-if="doctor.presentation" light width="100%" class="pa-3 mb-3">
          <v-icon size="30">mdi-account-file-text-outline</v-icon> <span class="font-weight-bold">Presentation</span>
          <v-divider />
          <div class="ql-editor" v-html="doctor.presentation.description" />
          <div class="photos" v-if="doctor.presentation.photos.length">
            <v-row>
              <v-col 
                cols="12"
                md="3"
                sm="6"
                class="d-flex child-flex"
                v-for="(photo, index) in doctor.presentation.photos" :key="index"
              >
              <v-card>
                <v-img :src="photo" alt="photo" height="200" aspect-ratio="1"></v-img>
              </v-card>
              
              </v-col>

            </v-row>
          </div>


        </v-sheet>
        <v-sheet light width="100%" class="pa-3 mb-3">
          <v-icon size="30">mdi-account-school</v-icon><span class="font-weight-bold">
            Diplômes et Formations
          </span>
          <v-divider />
          <div class="parcours-list">
            <v-list>
              <v-list-item
                two-line
                v-for="item in doctor.careers"
                :key="item.id"
              >
                <v-list-item-icon>
                  <v-icon color="primary">mdi-crown-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }} ({{ item.address }})</v-list-item-title
                  >
                  <v-list-item-subtitle
                    v-if="item.start_date && item.end_date"
                    >{{ item.start_date.substring(0, 4) }} -
                    {{ item.end_date.substring(0, 4) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-sheet>
        <v-sheet light width="100%" class="pa-3 mb-3" v-if="doctor.condictions_pay">
          <v-icon size="30">mdi-text</v-icon> 
          <span class="font-weight-bold">
            Conditions de paiement de consultation
          </span>
           
          <v-divider />
          <div class="" v-html="doctor.condictions_pay" />

        </v-sheet>
        <v-sheet light width="100%" class="pa-3 mb-3">
          <v-icon size="30">mdi-translate</v-icon> <span class="font-weight-bold">Langues Parlées</span>
          <v-divider />
          <v-chip
            v-for="(lang, i) in doctor.availability_langs"
            :key="i"
            class="ma-2"
            color="primary"
            label
            text-color="white"
          >
            <v-icon left> mdi-label </v-icon>
            {{ lang }}
          </v-chip>
        </v-sheet>
        <v-sheet light width="100%" class="pa-3 pb-6">
          <v-icon size="30">mdi-alarm</v-icon> <span class="font-weight-bold">
            Horaires
          </span> 
          <v-divider />
          <p><strong>Horaires en cabinet</strong></p>
          <!-- Horaire en cabinet -->
          <div class="" v-if="doctor.horaire_cabinet.length">
            <div v-for="(day, index) in doctor.horaire_cabinet" :key="index">
              <div class="my-3">
                <div class="">
                  {{ day.day }}
                </div>
                <div v-if="day.start_time"> {{ day.start_time }} - {{ day.end_time }}</div>
                <div v-else class="warning--text"> Aucune disponibilité.</div>
              </div>
              
            </div>

          </div>
          <!-- Horaire en cabinet non renseigne-->
          <div v-else class="mb-3"> Horaires en cabinet non renseignés</div>
          
          <p><strong>Horaires en téléconsultation</strong></p>
           <!-- Horaire en teleconsultation -->
          <div class="" v-if="doctor.horaire_teleconsultation.length">
            <div v-for="(day, index) in doctor.horaire_teleconsultation" :key="index">
              <div class="my-3">
                <div class="">
                  {{ day.day }}
                </div>
                <div v-if="day.start_time"> {{ day.start_time }} - {{ day.end_time }}</div>
                <div v-else class="warning--text" > Aucune disponibilité.</div>
              </div>
            </div>
            
          </div>
          <!-- Horaire en teleconsultation non renseigne-->
          <div v-else class=""> Horaires en teleconsultation non renseignés</div>
          
          <div v-if="$auth.check() && $auth.user.is_intervenant">
           
              <strong><span>Horaires en téléexpertise </span></strong>
              <div class="" v-if="doctor.horaire_teleexpertise.length">
            <div v-for="(day, index) in doctor.horaire_horaire_teleexpertise" :key="index">
              <div class="my-3">
                <div class="">
                  {{ day.day }}
                </div>
                <div v-if="day.start_time"> {{ day.start_time }} - {{ day.end_time }}</div>
                <div v-else class="warning--text"> Aucune disponibilité.</div>
              </div>
              
            </div>

          </div>
          <!-- Horaire en cabinet non renseigne-->
          <div v-else class="mb-3"> Horaires en téléexpertise non renseignés</div>
          </div>
        </v-sheet>
        
      </div>
      <!-- <v-dialog
          v-model="isCarousel"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
      >
          <v-card>
            <v-card-title class="grey lighten-2">
              <v-spacer></v-spacer>
              <v-btn icon @click="closeCarousel" color="error">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-img :src="doctor.presentation.photos[index]" max-height="500" height="500"></v-img>
            </v-card-text>
          </v-card>
      </v-dialog> -->
    </navbar>
  </v-app>
</template>

<script>
// import CarouselImages from '../components/doctor/CarouselImages.vue';
import ShowMap from '../components/doctor/ShowMap.vue';
import navbar from "../components/Home/utilities/navbar.vue";
export default {
   components: { navbar, ShowMap,/* CarouselImages */},
  data() {
    return {
      loadingDoctor: false,
      doctor: null,
      showPhone: false,
      isCarousel: false,
      index: 0,
    };
  },
  computed: {
    has_schedule() {
      return (
        this.doctor.horaire_cabinet.length || this.doctor.horaire_teleconsultation.length
      );
    }
  },
  watch: {
    $route: "getDoctor",
  },
  created() {
    this.getDoctor();
  },
  methods: {
    getDoctor() {
      const id = this.$route.params.id;
      this.$http.get(`auth/doctor/${id}`).then(({ data }) => {
        this.doctor = data.data;
      });
    },
    getUnavailabityDay() {
        if(this.doctor.unavailability_date_start && this.doctor.unavailability_date_end) {
          const dateDuJour = new Date();
          const dateDebut = new Date(this.doctor.unavailability_date_start);
          const dateFin = new Date(this.doctor.unavailability_date_end)
          const inDay = dateDuJour >= dateDebut && dateDuJour<= dateFin;
          console.log("in day", inDay )
          return !inDay;
        } else {
          return true;
        }
        
      },
      closeCarousel() {
      this.isCarousel = false;
    },
    getImage(index) {
      this.index = index;
      this.isCarousel = true;
    }
  },
};
</script>
<style scoped>
.btn-rdv {
  align-self: center;
}
.btn-rdv-mobile {
  margin-top: 10px;
  display: none;

}
.profil {
  display: flex;
}
.profil-photo {
  margin-right: 15px;
}
.doctor-show {
  margin-top: 3%;
}
.custom-card {
 /* border: 2px solid #3f51b5; */
 border-radius: 10px;
 box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
/* .profil-photo img{

  width: 80px;
  height: 80px;
  border-radius: 90px;
} */
.v-list {
  padding: 0 !important;
}
.v-list-item {
  padding: 0;
}
.v-list--two-line .v-list-item .v-list-item__icon,
.v-list-item--two-line .v-list-item__icon {
  margin-bottom: 0px;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 12px;
}
.main {
  background-color: #f5f8fa;
}
@media screen and (max-width: 500px) {
  
  .btn-rdv {
    display: none;
  }
  .v-btn.v-size--small{
    font-size: medium;
  }
  .btn-rdv-mobile{
    display: block;
    /* margin-bottom: 20px; */
  }
}
</style>