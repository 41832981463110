var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"header"},[_c('div',{staticClass:"d-flex justify-space-between my-2"},[_c('div',{staticClass:"text-center"},[_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"color":"primary"},on:{"click":function($event){_vm.showFormPatient=true}}},[_vm._v("Ajouter un nouveau patient")])],1),_c('div',{staticStyle:{"width":"400px"}},[_c('v-text-field',{attrs:{"placeholder":"Rechercher un patient","solo":"","dense":"","append-icon":"mdi-account-search"},on:{"input":_vm.search},model:{value:(_vm.key),callback:function ($$v) {_vm.key=$$v},expression:"key"}})],1)])]),_c('div',{staticClass:"user"},[_c('v-row',_vm._l((_vm.patients),function(patient,index){return _c('v-col',{key:patient.id+''+index,attrs:{"cols":"12","md":"3","sm":"3","align-content":"space-between"}},[_c('v-card',[_c('div',{staticClass:"avatar"},[_c('img',{attrs:{"src":patient.avatar_url}}),_c('div',[_c('span',{staticClass:"mdi mdi-checkbox-marked-circle-outline",staticStyle:{"color":"green","margin-right":"5px"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(patient.name))])]),_c('div',{staticClass:"mb-3"},[(patient.age)?_c('div',{staticClass:"caption text-center"},[_vm._v(_vm._s(patient.age))]):_vm._e(),_c('div',{staticClass:"caption"},[_vm._v("Tel: "+_vm._s(patient.phone))])])]),_c('div',{staticClass:"info"},[_c('v-btn',{staticStyle:{"text-transform":"none","text-decoration":"none","color":"white"},attrs:{"color":"info","elevation":"0","block":"","link":"","to":{ 
                      name: _vm.$auth.user.is_doctor ? 'doctor.patient.detail' : 'secretaire.patient.detail', 
                      params: { 
                          auteur: patient.auteur,
                          patient: patient.id
                      }
                      }}},[_vm._v("Voir profil")])],1)])],1)}),1)],1),_c('vue-infinite-loading',{attrs:{"spinner":"spiral"},on:{"infinite":_vm.loadMorePatients}},[_c('span',{attrs:{"slot":"no-more"},slot:"no-more"},[_vm._v("•••")]),_c('span',{attrs:{"slot":"no-results"},slot:"no-results"},[_c('v-alert',{attrs:{"color":"info","type":"info"}},[_vm._v(" Vous n'avez pas encore de patients . ")])],1),_c('span',{attrs:{"slot":"spinner"},slot:"spinner"},[_vm._v("Chargement...")])]),(_vm.key && _vm.patients.length == 0)?_c('div',{staticClass:"text-center"},[_c('v-alert',{attrs:{"color":"info","type":"info"}},[_vm._v(" Aucun patient consernant votre recherche. ")])],1):_vm._e(),(_vm.showFormPatient)?_c('form-patient',{attrs:{"patient":_vm.patient},on:{"createPatient":_vm.createPatient},model:{value:(_vm.showFormPatient),callback:function ($$v) {_vm.showFormPatient=$$v},expression:"showFormPatient"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }