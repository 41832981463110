<template>
  <div>
    <v-sheet class="pa-6">
        <div class="font-7 font-weight-bold">Rendez-vous</div>
        <v-timeline
            align-top
            dense
            v-if="appointments.length"
          >
            <v-timeline-item
              v-for="appointment in appointments"
              :key="appointment.id"
              :color="appointment.rvpasse ? 'error' : 'success'"
              small
            >
              <div class="row">
                <div class="col-8"> 
                    <div class="font-weight-normal info--text">
                        <!-- <router-link :to="`rendez-vous/${appointment.id}`" style="text-decoration: none;"> -->
                    <strong>{{ appointment.formated_date }}</strong>
                    
                    <!-- </router-link> -->
                    </div>
                    <div class="font-7">
                        <div><strong>Délai</strong> : {{ appointment.delay_status }}</div>
                        <div><strong>Description</strong> {{ appointment.description }}</div>
                        
                    </div>
                </div>
                <div class="col-2">
                    <v-btn 
                        icon 
                        color="primary" 
                        class="float-right" 
                        link 
                        :to="{ 
                            name: $auth.user.is_doctor ? 'doctor.appointment.detail': 'secretaire.appointment.detail', 
                            params: {appointment: appointment.id}
                        }" 
                    >
                        <v-icon>mdi-eye</v-icon>
                    </v-btn>
                </div>
               
              </div>
            </v-timeline-item>
        </v-timeline>
        <div class="font-weight-thin" v-else>
           
            <div class="text-center">
					<div>
						<v-icon size="40">mdi-alert-circle-outline</v-icon>
						<div class="">Pas de rendez-vous pour ce patient</div>
					</div>
				</div>
         </div>
        
    </v-sheet>
  </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            auteur: this.$route.params.auteur,
            patient_id: this.$route.params.patient,
            doctor_id: this.$auth.user.doctor_id,
            appointments: [],
        }
    },
    mounted() {
        this.getPatientAppointment()
    },
    methods: {
        getPatientAppointment(){
            this.$http.get(`patient/get/appointments?auteur=${this.auteur}&patient_id=${this.patient_id}&doctor_id=${this.doctor_id}`).then(({ data }) => {
                this.appointments = data.data
            }).catch(() => {
                this.$toast.error({
                    title: 'Erreur',
                    message: "Une erreur c'est produite lors de la recuperation du patient"
                })
            })
        }
    }
}
</script>

<style>

</style>