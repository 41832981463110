<template>
  <div>
    <div class="">
        <v-row no-gutters>
            <v-col cols="12" md="4" class="mt-3">
                <v-sheet class="nav-sheet">
                    <v-list dense>
                        <v-subheader>PARAMETRES</v-subheader>
                        <v-list-item-group
                            v-model="selectedItem"
                            color="primary"
                        >
                            <v-list-item
                                v-for="(menu, i) in menus"
                                :key="i"
                                link
                                :to="{name: menu.link}"
                            >
                            <v-list-item-icon>
                                <img :src="getPic(menu.icon)" alt="image"  class="img-icon"/>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title >{{ menu.title }}</v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-sheet>
                <v-sheet class="nav-sheet-mobile">
                    <v-tabs
                    background-color="white"
                    next-icon="mdi-arrow-right-bold-box"
                    prev-icon="mdi-arrow-left-bold-box"
                    show-arrows
                    slider-size="0"
                    >
                    <v-tabs-slider color="white"></v-tabs-slider>
                    <v-tab
                        v-for="menu in menus"
                        :key="menu.link"
                        link
                        :to="{name: menu.link}"
                    >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span
                            v-bind="attrs"
                            v-on="on"
                            >
                            <img :src="getPic(menu.icon)" alt="image"  class="img-icon-mobile"/>
                        </span>
                        </template>
                        <span>{{ menu.title }}</span>
                        </v-tooltip>
                       
                      
                    </v-tab>
                    </v-tabs>
                </v-sheet>

            </v-col>
            <v-col cols="12" md="8" sm="12" class="mt-3">
                <router-view :doctor="doctor" v-if="doctor"/>
            </v-col>
        </v-row>
    </div>

  </div>
</template>

<script>
export default {
    props: ['doctor'],
    data() {
        return {
            selectedItem: null,
            menus: [
                {
                    title: 'Prestations',
                    icon: 'liste-de-choses-a-faire.png',
                    link: 'doctor.parametre.infos.motifs'
                },
                {
                    title: 'Cabinet',
                    icon: 'bouton-daccueil.png',
                    link: 'doctor.parametre.infos.cabinet'
                },
                {
                    title: 'Téléconsultation',
                    icon: 'bouton-jouer.png',
                    link: 'doctor.parametre.infos.teleconsultation'
                },
                {
                    title: 'Téléexpertise',
                    icon: 'lecteur-video.png',
                    link: 'doctor.parametre.infos.teleexpertise'
                },
                {
                    title: 'Date d\'indisponibilité',
                    icon: 'indisponibilite.date.png',
                    link: 'doctor.parametre.infos.indisponobilite.date'
                },
                {
                    title: 'Logo',
                    icon: 'creation-de-logo.png',
                    link: 'doctor.parametre.infos.logo'
                },
                {
                    title: 'Signature',
                    icon: 'signature.png',
                    link: 'doctor.parametre.infos.signature'
                },
                {
                    title: 'Langues',
                    icon: 'linternet.png',
                    link: 'doctor.parametre.infos.langues'
                },
                {
                    title: 'Présentation',
                    icon: 'presentation.png',
                    link: 'doctor.parametre.infos.presentation'
                },
                {
                    title: 'Diplômes et formations',
                    icon: 'diplome-de-fin-detudes.png',
                    link: 'doctor.parametre.infos.careers'
                },
                {
                    title: 'Localisation',
                    icon: 'espace-reserve.png',
                    link: 'doctor.parametre.infos.localisation'
                },

                
                {
                    title: 'Condition de paiement',
                    icon: 'paiement-en-especes.png',
                    link: 'doctor.parametre.infos.condition.pay'
                },
                //
            ]
        }
    },
    methods: {
        getPic(name) {
            return require("@/assets/icons/"+name)
        }
    }
}
</script>

<style scoped>
.nav-sheet {
    width: 89%;
}
.nav-sheet-mobile {
    display: none;
}

@media screen and (max-width: 500px) {
    .nav-sheet {
        display: none;
    }
    .nav-sheet-mobile {
        display: block;
        margin-top: 12px;
    }
    .img-icon-mobile {
        width: 30px;
    }

}

</style>