<template>
  <div>
    <v-sheet 
        v-for="notification in notifications" 
        :key="notification.id"
        class="pa-6 mb-3"
    >
    <div class="d-flex justify-space-between">
        <div class="font-7 text-center" v-if="notification.data.message">
            {{ notification.data.message }}
            <span v-if="notification.data.type==='room_cancel'">
                <router-link 
                    :to="{
                        name: 'medecin.show',
                        params: { id: notification.data.doctor.id }
                    }"
                    style="text-decoration: none;"
                >
                    Voir profil
                </router-link>
            </span>
            <span v-if="notification.data.type==='doctor_register'">
                <router-link 
                    :to="{
                        name: 'admin.userProfile',
                        params: { id: notification.data.doctor_id }
                    }"
                    style="text-decoration: none;"
                >
                    Voir profil
                </router-link>
            </span>
            <span v-if="notification.data.type==='payement'">
                <router-link 
                    :to="{
                        name: 'admin.detail.payement',
                        params: { id: notification.data.payement_id }
                    }"
                    style="text-decoration: none;"
                >
                   voir plus
                </router-link>
            </span>
            <span v-if="notification.data.url_file">
                <router-link 
                    :to="notification.data.url_file"
                    style="text-decoration: none;"
                >
                   voir plus
                </router-link>
            </span>
        </div>
        <div class="mx-3">
            <v-btn small color="red" elevation="0" @click="deleteNotify(notification.id)">
                <v-icon color="white">mdi-delete</v-icon>
            </v-btn>
        </div>
       
    </div>
    </v-sheet>
    <div v-if="notifications.length == 0">
        <div>Vous n'avez pas de notifications pour le moment</div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            notifications: []
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.$http.get(`notifications/all`).then(({ data }) => {
                this.notifications = data; 
              //this.verifyNewPatientInRoom(this.notifications);
            });
        },
        deleteNotify(notification_id) {
            this.$http.delete(`notification/${notification_id}`).then((response) => {
                this.$toast.success({
                    title: "Suppression notification",
                    message: response.data.message
                })
                this.init()
            })
        }
    }
}
</script>

<style>

</style>