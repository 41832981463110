<template>
  <div class="proches">
    <slot />
  </div>
</template>

<script>
export default {
    name: 'list-proches'
}
</script>

<style>
  .proches {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: dense;
      grid-gap: 15px;
      padding: 20px;
      margin-top: 24px;
  }
  @media screen and (max-width: 600px) {
    .proches {
      display: block;
    }
  }

</style>