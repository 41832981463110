<template>
    <div style="width: 100%; height: 500px;"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'utilisateur-chart',
    props: {data: Array},
    mounted() {
    //this.getStats();
        this.renderChart();
  },
    methods: {
        renderChart() {
            const chart = echarts.init(this.$el);
            chart.setOption(this.getOption());
        },
        getOption() {
            
return {
  backgroundColor: '#0f375f',
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    data: ['line'],
    textStyle: {
      color: '#ccc'
    }
  },
  xAxis: {
    data: ['Janvier', 'Fevrier','Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'],
    axisLine: {
      lineStyle: {
        color: '#ccc'
      }
    }
  },
  yAxis: {
    splitLine: { show: false },
    axisLine: {
      lineStyle: {
        color: '#ccc'
      }
    }
  },
  series: [
    {
      name: 'Utilisateurs',
      type: 'line',
      smooth: true,
      showAllSymbol: true,
      symbol: 'emptyCircle',
      symbolSize: 15,
      data: this.data
    },
    // {
    //   name: 'bar',
    //   type: 'bar',
    //   barWidth: 10,
    //   itemStyle: {
    //     borderRadius: 5,
    //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //       { offset: 0, color: '#14c8d4' },
    //       { offset: 1, color: '#43eec6' }
    //     ])
    //   },
    //   data: this.data
    // },
    {
      name: 'Utilisateurs',
      type: 'bar',
      barGap: '-100%',
      barWidth: 10,
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: 'rgba(20,200,212,0.5)' },
          { offset: 0.2, color: 'rgba(20,200,212,0.2)' },
          { offset: 1, color: 'rgba(20,200,212,0)' }
        ])
      },
      z: -12,
      data: this.data
    },
    {
      name: 'dotted',
      type: 'pictorialBar',
      symbol: 'rect',
      itemStyle: {
        color: '#0f375f'
      },
      symbolRepeat: true,
      symbolSize: [12, 4],
      symbolMargin: 1,
      z: -10,
      data: this.data
    }
  ]
};
        }
    }
}
</script>

<style></style>