<template>
    <div class="parametres">
     <!-- <div class="bar-doctor" v-if="doctor">
           <div class="d-flex">
               <div class="photo-doct">
                   <update-photo :user="doctor.user" />
               </div>
               <div>
                   <div class="font-4 px-6">
                       <v-icon color="primary" size="30" class="">mdi-check-circle</v-icon>
                       {{ doctor.user.name }}
                   </div>
                   <div class="font-7 text-center">{{ doctor.department.name }}</div>
               </div>
               <div class="px-6">
                   <v-btn icon color="white" @click="showForm=true">
                       <v-icon size="40">mdi-pencil-box-outline</v-icon>
                   </v-btn>
               </div>
           </div>
           
           <div class="force" style="width: 300px;">
               <div class="font-5 font-weight-bold">
                   Force du profile
               </div> -->
              <!-- <force-profile :force="doctor.force" />
           </div>
     </div> -->
     <v-tabs centered>
       <v-tab 
        v-for="(tab,i) in links"
        :key="i"
        :to="{ name: tab.link}"
        >{{ tab.title }}</v-tab>

     </v-tabs>
      <div> 
        <router-view />
      </div>
      <!-- <edited-doctor/> -->
     
   
    </div>
   </template>
   
   <script>
// import EditedDoctor from '../../../components/doctor/EditedDoctor.vue';
// import ForceProfile from '../../../components/doctor/ForceProfile.vue';
//      import UpdatePhoto from '../../../components/Home/utilities/UpdatePhoto.vue';
     //import infos from '../../components/doctor/infos.vue';
     export default {
    //    components: {/*  infos, */ UpdatePhoto, ForceProfile, EditedDoctor },
       data() {
         return {
           tab: null,
           value: 65,
           showForm: false,
           doctor: null,
           links: [
            {
                title: 'formulaires',
                link: 'form.admin'
            },
            {
                title: 'Consultations',
                link: 'admin.parametre.consultation'
            },
            {
                title: 'Viste Médicales',
                link: 'admin.parametre.visit.medical'
            }
           ]
         }
       },
       mounted() {
       if (this.$auth.check()) {
         this.getDoctor();
         //this.user = this.$auth.user;
       }
     },
     methods: {
       getDoctor() {
         this.$http.get('user').then(({data}) => {
           this.doctor = data.data
         })
       }
     }
     }
   </script>
   
   <style scoped>
     .v-progress-circular {
     margin: 1rem;
   }
     .bar-doctor {
       font-weight: bold;
       display: flex;
       justify-content: space-between;
       background-color: #025397;
       width: 100%;
       padding: 20px;
       color: white;
     }
     .theme--light.v-tabs-items {
       padding: 20px 0px;
       background-color: #f5f8fa;
   }
   </style>