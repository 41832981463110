<!-- eslint-disable vue/no-unused-vars -->
<template>
  <v-app>
    <div class="send-container">
      <div class="d-flex justify-space-between py-6 mx-2">
        
        <div class="font-4 font-weight-bold">Mes Fichiers</div>
        <v-btn color="primary" @click="showForm = true">
          <v-icon>mdi-file-plus</v-icon> Ajouter un document
        </v-btn>
      </div>
      <hr />
      <div class="table-files">
        <v-data-table
          :headers="headers"
          :items="files"
          :loading="loading"
          :page.sync="current_page"
          :items-per-page="itemsPerPage"
          sort-by="created_at"
          class="elevation-0"
          hide-default-footer
        >
          <template #no-data>
            <div>
              <h2>Gérez vos documents médicaux</h2>
              <p>
                Recevez, partagez et gérez vos documents sur sahel assistance
                Seuls vous et vos praticiens y avez accès grâce au chiffrement
                de bout en bout.
              </p>
            </div>
          </template>
          <template #[`item.filename`]="{ item }">
            <v-icon :color="item.filetype == 'PDF' ? 'warning' : 'blue'">{{
              item.filetype == "PDF"
                ? "mdi-file-document-outline"
                : "mdi-file-image-outline"
            }}</v-icon>
            {{ item.filename }}
          </template>
          <template #[`item.patient`]="{ item }">
            {{ item.proche ? item.proche.name : $auth.user.name }}
          </template>
          <template #[`item.actions`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(action, index) in actions"
                  :key="index"
                  dense
                  
                >
                  <v-list-item-title>
                    <v-btn
                      text
                      small
                      style="text-transform: none"
                      :color="action.action === 'delete' ? 'error' : 'blue'"
                      @click="actionFile(action.action, item)"
                    >
                      <v-icon class="pr-2" size="15">
                        {{ action.icon }}
                      </v-icon>
                      {{ action.title }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <div class="text-center pt-2" v-if="pageCount > 1">
          <v-pagination
              v-model="current_page"
              :length="pageCount"
              @input="paginate"
          />
        </div>

        <file-form
          v-if="showForm"
          :patient_id="$auth.user.patient_id"
          :intervenant_id="$auth.user.intervenant_id"
          v-model="showForm"
          @createFile="createFile"
        />
      </div>
      <delete-dialog
        :message="'Êtes vous sur de vouloir supprimer ce document ?'"
        :title="'Suppression de document'"
        v-model="dialogDelete"
        @confirmDelete="confirmDelete"
      />
      <v-dialog persistent max-width="300px" v-model="dialogRename">
        <v-card>
          <v-card-title class="font-7">Renommer le fichier</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="filename"
              name="filename"
              placeholder="Nom du document"
              outlined
              :error-messages="errors.filename"
            ></v-text-field>
            <div class="d-flex justify-space-between">
              <v-btn
                outlined
                elevation="0"
                color="error"
                @click="dialogRename = false"
                style="text-transform: none"
                >Annuler</v-btn
              >
              <v-btn
                color="primary"
                outlined
                elevation="0"
                @click="renameFile"
                style="text-transform: none"
                >Enregistrer</v-btn
              >
              
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <view-file
        v-if="dialogView"
        :file="file_selected"
        v-model="dialogView"
        @confirmDelete="confirmDelete"
        @downloadFile="downloadFile"
      />
    </div>
    <form-shared-file v-if="dialogShare" :file="file_selected" v-model="dialogShare" />
    
  </v-app>
</template>

<script>
import FileForm from "../../components/file/FileForm.vue";
import ViewFile from "../../components/file/ViewFile.vue";
import DeleteDialog from "../../components/Home/utilities/DeleteDialog.vue";
import FormSharedFile from '../../components/patient/formSharedFile.vue';
export default {
  props: {
    files: {
      type: Array
    },
    headers: {
      type: Array
    },
    loading: {
      type: Boolean,
    },
    page: {
      type: Number,
      default: () => 1
    },
    pageCount: {
      type: Number
    },
    itemsPerPage: {
      type: Number , 
      default: () => 20 
    },
  },
  components: { FileForm, DeleteDialog, ViewFile, FormSharedFile },
  data() {
    return {
      dialogShare: false,
      filename: "",
      errors: {},
      showForm: false,
      dialogDelete: false,
      dialogRename: false,
      file_selected: null,
      dialogView: false,
      actions: [
        {
          title: "Visualiser",
          icon: "mdi-eye",
          action: "view",
        },
        {
          title: "Télécharger",
          icon: "mdi-file-upload-outline",
          action: "upload",
        },
        {
          title: "Partager",
          icon: "mdi-share",
          action: "share",
        },
        {
          title: "Renommer",
          icon: "mdi-pencil",
          action: "rename",
        },
        {
          title: "Supprimer",
          icon: "mdi-delete",
          action: "delete",
        },
      ],
      fichiers: this.files,
      current_page: this.page
    };
  },
  methods: {
   
    createFile(file) {
      this.files.unshift(file);
      this.$toast.success({
        title: "Création document",
        message: "Votre document a été ajouté avec succès",
      });
    },
    actionFile(action, file) {
      this.file_selected = file;
      if (action === "delete") {
        if(this.file_selected.is_mine) {
          this.dialogDelete = true;
        } else {
          this.$toast.info({
            title: 'Suppression fichier',
            message: "Désolé, vous ne pouvez pas supprimer le fichier"
          })
        }
      } else if (action == "rename") {
        if(this.file_selected.is_mine) {
          this.filename = this.file_selected.filename;
          this.dialogRename = true;
        } else {
          this.$toast.info({
            title: 'Renommer le fichier',
            message: "Désolé, vous ne pouvez pas renommer le fichier"
          })
        }
        
      } else if (action == "view") {
        this.file_selected = file;
        this.dialogView = true;
      } else if(action == "share") {
        if(this.file_selected.is_mine) {
          this.dialogShare = true;
        } else {
          this.$toast.info({
            title: 'Partage de fichier',
            message: "Désolé, vous ne pouvez pas partager le fichier"
          })
        }
      } else {
        const extentionFile = this.file_selected.filetype.toLowerCase();
        this.downloadFile(
          `files/${this.file_selected.id}/download`,
          `${this.file_selected.filename}.${extentionFile}`
        );
      }
    },
    confirmDelete() {
      this.$emit('deleteFile',this.file_selected);
    },
    // confirmDelete() {
    //   this.$http
    //     .delete(`file/${this.file_selected.id}`)
    //     .then(() => {
    //       this.files = this.files.filter(
    //         (file) => file.id != this.file_selected.id
    //       );
    //       this.file_selected = {};
    //       this.$toast.success({
    //         title: "Suppression document",
    //         message: "Votre document a été supprimé avec succès",
    //       });
    //     })
    //     .catch(() => {
    //       this.$toast.error({
    //         title: "Suppression document",
    //         message: "La suppression du document a échoué",
    //       });
    //     });
    // },
    renameFile() {
      this.errors = {};
      this.$http
        .put(`file/${this.file_selected.id}/rename`, {
          filename: this.filename,
        })
        .then(({ data }) => {
          let index = this.files.indexOf(this.file_selected);
          if (index > -1) {
            this.files[index].filename = data.data.filename;
          }
          this.dialogRename = false;
          this.$toast.success({
            title: "Renommer document",
            message: "Votre document a été renommé avec success",
          });
        })
        .catch((error) => {
          if (error.response) {
            this.errors = error.response.data.errors;
          } else {
            this.dialogRename = false;
            this.$toast.error({
              title: "Renommer document",
              message:
                "Une erreur est survenue lors de la mise à jour du document",
            });
          }
        });
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
    downloadFile(url, title) {
      this.$http
        .get(url, { responseType: "arraybuffer" })
        .then((response) => {
          this.forceFileDownload(response, title);
        })
        .catch(() => console.log("error occured"));
    },
    paginate() {
      this.$emit('pagination',this.current_page)
    }
  },
};
</script>
<style scoped>
.send-container {
  padding: 12px;
}
</style>