<template>
  <div class="form-patient">
    <v-dialog width="600px" persistent v-model="show">
      <v-sheet class="pa-6">
        <h4 class="mb-6">{{ formTitle }}</h4>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="patientItem.first_name"
            label="Prénom"
            outlined
            dense
            :error-messages="errors.first_name"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="patientItem.last_name"
            label="Nom"
            outlined
            dense
            :error-messages="errors.last_name"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="patientItem.address"
            label="Adresse"
            outlined
            dense
            :error-messages="errors.address"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="patientItem.post"
            label="Profession"
            outlined
            dense
            :error-messages="errors.post"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="patientItem.email"
            label="Email"
            outlined
            dense
            :error-messages="errors.email"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-radio-group
            v-model="patientItem.sexe"
            row
            dense
            label="Sexe"
          >
            <v-radio
              label="Homme"
              value="homme"
            ></v-radio>
            <v-radio
              label="Femme"
              value="femme"
            ></v-radio>
          </v-radio-group>
        </v-col>
       

        <v-col cols="12" sm="6">
          <vue-tel-input-vuetify 
            v-model="patientItem.tel_port"
            class="form"
            name="phone"
            label="N° téléphone"
            placeholder="N° téléphone"
            outlined
            mode="international"
            :error="errorPhone"
            ref="telInput"
            dense
            @change="verifyNumber"
            :error-messages="errors.tel_port"
        />
        </v-col>

        <v-col cols="12" sm="6">
          <vue-tel-input-vuetify 
            v-model="patientItem.tel_dom"
            class="form"
            name="phone"
            placeholder="Autre numéro"
            label="Autre numéro"
            outlined
            mode="international"
            :error="errorPhoneDom"
            dense
            ref="telInputDom"
            @change="verifyNumberDom"
            :error-messages="errors.tel_dom"
        />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="patientItem.lieu_naissance"
            label="Lieu de naissance"
            outlined
            dense
            :error-messages="errors.lieu_naissance"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="patientItem.date_naissance"
            label="Date de naissance"
            type="date"
            outlined
            dense
            :error-messages="errors.date_naissance"
          ></v-text-field>
        </v-col>
      </v-row>
      <div class="d-flex justify-space-between">
        
        <v-btn color="error" style="text-transform: none" elevation="0" @click="show = false" outlined>
          Annuler
        </v-btn>
        <v-btn color="primary" style="text-transform: none; margin-right: 10px ;" elevation="0" @click="save" outlined>
          Enregistrer
        </v-btn>
      </div>
    </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: { patient: Object, value: Boolean },
  
  data() {
    return {
      errorPhone: false,
      errorPhoneDom:false,
      patientItem: null,
      errors: {}
    };
  },
  watch: {
      patient: {
        handler: 'fillPatient',
        immediate: true
      }

  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        return this.$emit('input', value)
      }
    },
    formTitle() {
      return this.patientItem.id ? 'Modifier profil' : 'Ajouter un patient';
    }
  },
  methods: {
    fillPatient() {
      this.patientItem = {
        id:             this.patient.id ? this.patient.id : null,
        first_name:     this.patient.first_name ? this.patient.first_name : '',
        last_name:      this.patient.last_name ? this.patient.last_name : '',
        email:          this.patient.user ? this.patient.user.email : '',
        date_naissance: this.patient.date_naissance ? this.patient.date_naissance : '',
        lieu_naissance: this.patient.lieu_naissance ? this.patient.lieu_naissance : '',
        tel_dom:        this.patient.tel_dom ? this.patient.tel_dom : '',
        tel_port:       this.patient.tel_port ? this.patient.tel_port : '',
        sexe:           this.patient.sexe ? this.patient.sexe : null,
        address:        this.patient.address ? this.patient.address : '',
        post:           this.patient.post ? this.patient.post : ''
        
      }
    },
    verifyNumberDom() {
      if(!this.$refs.telInputDom._computedWatchers.phoneObject.value.isValid) {
                this.errorPhoneDom = true;
                this.errors.tel_dom = "Le numéro est incorrecte"
            } else {
                this.errorPhoneDom = false;
                this.errors.tel_Dom = null;
            }
    },
    verifyNumber() {
            if(!this.$refs.telInput._computedWatchers.phoneObject.value.isValid) {
                this.errorPhone = true;
                this.errors.tel_port = "Le numéro est incorrecte"
            } else {
                this.errorPhone = false;
                this.errors.tel_port = null;
            }
      
    },
    save() {
      this.errors = {}
      if(this.patientItem.tel_port) {
        this.patientItem.tel_port = this.patientItem.tel_port.match(/\S+/g).join("")
      }
     
      if(this.patientItem.tel_dom){
        this.patientItem.tel_dom = this.patientItem.tel_dom.match(/\S+/g).join("")
      }
      
      if(this.patientItem.id) {
        this.$http.put(`patient/${this.patientItem.id}`,this.patientItem).then(({ data })=> {
          this.show = false;
          this.$emit('updatedPatient', data.data)
        }).catch((error) => {
          if(error.response.data.errors) {
            this.errors = error.response.data.errors
          }else {
            this.$toast.error({
              title: "Modification profil",
              message: error.response.data.message
            })
          }
        })
      } else {
        if(this.$auth.check() && (this.$auth.user.is_doctor || this.$auth.user.is_secretaire || this.$auth.user.is_entreprise)) {
            this.errors = {}
                if(this.$auth.user.is_entreprise) {
                  this.patientItem.entreprise_id = this.$auth.user.entreprise_id
                } else {
                this.patientItem.doctor_id = this.$auth.user.doctor_id
                }
                this.$http.post(`patient/store`, this.patientItem).then(({data}) => {
                    this.show = false
                    this.$emit('createPatient', data.data)
                   // this.patients.unshift(data)
                }).catch((error) => {
                  if(error.response.data.errors)
                    this.errors = error.response.data.errors
                })
                
            }
      }
      
    }
  }
};
</script>

<style>
</style>