<template>
<v-app>
    <div class="container" v-show="access_page"> 
        <v-sheet width="450px" class="pa-6">
            
                <div class="logo-img">
                    <v-avatar
                        size="100"
                        color="green"
                    >
                        <img src="../assets/logo_sahel.png" alt="logo_sahelassistance">
                    </v-avatar>
                   
                </div>
                <div class="text-center font-weight-bold mb-3">Sahelassistance</div>
                
                <div class="form">
                    <div class="message">
                        <v-alert :type="error ? 'error' : 'info'">
                           {{ message }}
                        </v-alert>
                    </div>
                    <label class="font-weight-bold">Email ou N° téléphone</label>
                    <vue-tel-input-vuetify v-if="isNumeric(email)" v-model="email" @input="handleTelInput" class="form" name="phone" label=""
            placeholder="Entrer votre N° téléphone" outlined mode="international" defaultCountry="SN" :error="errorPhone"
            ref="telInput" :error-messages="errors.login"></vue-tel-input-vuetify>

          <!-- Affichez v-text-field si la saisie contient des caractères autres que des chiffres -->
          <v-text-field v-else v-model="email" label="" placeholder="Adresse e-mail ou numéro téléphone" outlined
            :error-messages="errors.login" />
                    <label class="font-weight-bold my-3">Mot de Passe</label>
                    <v-text-field
                        v-model="password"
                        name="password"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show ? 'text' : 'password'"
                        @click:append="show = !show"
                        placeholder="Saisir votre mot de passe"
                        outlined
                    ></v-text-field>
                    <div class="d-flex justify-space-between">
                        <v-btn color="red" style="text-transform:none ; width: 130px;" outlined @click="reset">Annuler</v-btn>
                        <v-btn color="info" style="text-transform:none ; width: 130px;" outlined @click="login" :loading="loading" >Se connecter</v-btn>
                    </div>
                </div>
           
            </v-sheet>
        
    </div>
</v-app>
    
</template>

<script>
export default {
    data() {
        return {
            errors: {},
            show: false,
            error: false,
            errorPhone: false,
            message: "Votre session a expiré. Veuillez vous reconnecter pour continuer.",
            loading: false,
            //token: this.$route.query.token,
            email: '',
            password: '',
            access_page: false,
        }
    },
    created() {

        if(window.localStorage.getItem('session_expired')) {
            this.access_page = true
        } else {
            const last_route = window.localStorage.getItem('last_route')
            
            if(last_route && last_route != '/session-expire') {
                this.$router.push({path: last_route})
                window.localStorage.removeItem('last_route')
            } else {
                this.$router.push({ name: 'home'})
            }
            
        }
    },
    methods: {
        login() {
           
       
        this.loading = true;
        const data = {
            login: this.email.match(/\S+/g).join(""),
            password: this.password,
        };
        this.$http
            .post("auth/login", data)
            .then((res) => {
            this.error = false;
            this.loading = false;
            window.localStorage.removeItem('session_expired')
            this.$auth.login(res.data.token, res.data.user);
            this.email = "";
            this.password = "";
            
            //this.$router.push({name: 'home'})
            })
            .catch(({ response }) => {
            this.loading = false;
            if(response.data.errors) {
                this.errors = response.data.errors
            } 
            else {
                this.error = true;
                this.message = response.data.message;
            }
           
            });
        },
        reset() {
            if(this.$auth.check()) {
                this.$auth.logout()
            }
            
        },
        verifyNumber() {
      if (!this.$refs.telInput._computedWatchers.phoneObject.value.isValid) {
        this.errorPhone = true;
        this.errors.phone = "Le numéro est incorrecte"
      } else {
        this.errorPhone = false;
        this.errors.phone = null;
      }

    },
    handleTelInput() {
      // Définir le focus sur le champ d'entrée lorsque l'utilisateur saisit quelque chose
      this.$refs.telInput.focus();
    },
    isNumeric(value) {
      // Utilisez une expression régulière pour vérifier si la saisie ne contient que des chiffres
      const numericPattern = /^\d+$/;
      
      return numericPattern.test(value);
    },
    }
}
</script>

<style scoped>
    .container {
        display: grid;
        place-items: center;
        height: 100vh;
    }
    .logo-img{
    display: flex;
      justify-content: center;
      align-content: center;
      background-size: cover;
      height: auto;
      margin: 4%;
      border-radius: 150px;
    }
</style>