<!-- eslint-disable no-undef -->
<template>
  <div>
    <v-dialog
      v-model="show"
      persistent
      :overlay="false"
      max-width="450px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-subtitle class="py-6">
          <div class="d-flex justify-center">
            <h2>Ajouter un nouveau document</h2>
          </div>
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            v-model="filename"
            name="filename"
            label="Nom du document"
            outlined
            dense
            :error-messages="errors.filename"
          ></v-text-field>
          <div v-if="modify ">
              <p><strong>Patient concerné</strong></p>
              <div class="" v-if="patient_concerne">
                <v-icon class="mr-3">mdi-account</v-icon> {{ patient_concerne.name }}
              </div>
          </div>
          <v-autocomplete
            v-if="!modify"
            v-model="patient_concerne"
            :items="patients"
            chips
            small-chips
            clearable
            hide-selected
            item-text="name"
            return-object
            label="Séléctionner le patient concerné"
            outlined
            dense
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  <span> resultat, voulez-vous </span> 
                  <v-btn
                    color="primary"
                    text
                    small
                    style="text-transform: none; padding: 0%;"
                    @click="showFormProche = true"
                  >
                    <v-icon size="15" class="pa-1">mdi-account-plus</v-icon>
                    Ajouter un nouveau proche
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-file-input
            v-if="!modify"
            ref="file-input"
            prepend-icon=""
            label="Parcourir"
            v-model="fichier"
            outlined
            dense
            :error-messages="errors.file"
          />
          <div>
            <v-progress-linear
                v-if="uploading"
                v-model="uploadPercentage"
                height="25"
            >
                <strong>{{ uploadPercentage }}%</strong>
            </v-progress-linear>
          </div>
          <div class="d-flex justify-end py-3">
          <v-btn color="error" @click="close" class="mr-2">Annuler</v-btn>
          <v-btn color="primary"  @click="submitFile">Enregistrer</v-btn>
          </div>
          
        </v-card-text>
      </v-card>
    </v-dialog>
    <proche-form v-if="showFormProche" v-model="showFormProche" @createProche="createProche"/>
  </div>
</template>

<script>
import ProcheForm from '../proche/ProcheForm.vue';
export default {
  components: { ProcheForm },
  name: "add-file-form",

  props: {
    value: {
        type:Boolean,
        required: true
    },
    patient_id: {
      type: String,
      required: false,
    },
    intervenant_id: {
      type: String,
      required: false,
    },
    file: {
      type: File,
      default: null
    },
    patient: {
      type: Object,
      default: null
    },
    modify: {
      type: Boolean,
      default: false
    },
    appointment:{
      type: Object,
      default: null
    }
  },

  data() {
    return {
      proches: [],
      patients: [],
      errors: {},
      filename: "",
      uploading: false,
      uploadPercentage: 0,
      showFormProche: false,
      patient_concerne : this.patient,
      fichier: this.file
    };
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        return this.$emit('input', value)
      }
    }
  },
  mounted() {
    this.patients.push(this.$auth.user);
    this.getProches();
  },

  methods: {
   /*  getPatient() {}, */
    getProches() {
      this.$http.get(`proches`).then(({ data }) => {
        this.proches = data.data;
        if (this.proches.length != 0) {
          this.patients = this.patients.concat(data.data);
        }
        
      });
     /*  this.getpatient(); */
    },
    createProche(data) {
        this.patients.unshift(data)
        this.proches.unshift(data);
        this.patient = data
    },
    submitFile() {
      this.uploading = true;

      const config = {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        },
      };

      const data = new FormData();
      if(this.patient_id) {
        data.append("patient_id", this.patient_id);
      }
     
      data.append("intervenant_id", this.intervenant_id);
      data.append("file", this.fichier);
      data.append("filename", this.filename);
      if(this.proches.includes(this.patient_concerne)) {

        data.append("proche_id", this.patient_concerne.id)
      }
      if(this.appointment) {
        data.append("appointment_id", this.appointment.id)
        //data.append("doctor_id", this.appointment.doctor.user.id)
      }
     

      this.$http.post(`file`, data, config).then(({ data }) => {
          this.$emit("createFile", data.data);
          this.close();
          //this.$handleMessage(`Le fichier a bien été téléversé.`, 'success')
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        })
        .finally(() => {
          this.uploading = false;
          //this.close()
        });
    },
    close() {
      this.patient_concerne = null;
      this.filename = null;
      this.fichier = null;
      this.show = false;
    }
  },
};
</script>

<style scoped>
p.help {
  display: inline-block !important;
}
</style>