<template>
    <v-dialog
      v-model="dialog"
      width="100%"
      height="100%"
      fullscreen
      hide-overlay
      persistent
    >
      <v-card color="grey lighten-3">
        <v-btn icon color="error" @click="dialog = false" class="float-right">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        <div class="white pa-6">
          <div class="d-flex justify-center">
            {{ file.filename }}
          </div>
          <!--  -->
        </div>
        <v-divider />
        <v-row>
          <v-col cols="12" md="2" class="">
            <v-sheet class="pa-3" height="100%" style="overflow: hidden;">
              <div class="py-3">
                <div class="font-weight-light">Nom du document</div>
                <div class="font-weight-bold">{{ file.filename }}</div>
              </div>
              <v-divider />
              <div class="py-3">
                <div class="font-weight-light">Patient</div>
                <div class="font-weight-bold">
                  <v-icon>mdi-account-multiple</v-icon>
                  {{ file.proche ? file.proche.name : file.patient ? file.patient.name : file.intervenant.first_name + ' ' +  file.intervenant.last_name }}
                </div>
              </div>

              <v-divider />
              <div class="py-3">
                <div class="font-weight-light">Crée par</div>
                <div class="font-weight-bold">
                  <v-avatar color="warning" class="white--text" size="30">
                    {{ file.creator.name.substring(0, 1)
                      }}{{ file.creator.name.substring((file.creator.name.indexOf(" ")+1),(file.creator.name.indexOf(" ")) +2) }}
                  </v-avatar>
                  {{ file.creator.name }}
                </div>
              </div>

              <v-divider />
              <div class="py-3">
                <div class="font-weight-light">Crée le</div>
                <div class="font-weight-bold">
                  {{ file.uploaded_at }}
                </div>
              </div>
            </v-sheet>
          </v-col>
          <v-col cols="12" md="8" class="overflow-y-auto pa-6">
            <div class="view-document">
              <view-file-item :url="file.file_url" />
            </div>
            
          </v-col>
          <v-col cols="12" md="2">
            <v-sheet class="pa-3" height="100%" style="overflow: hidden;">
              <div class="py-3">
                <div>Document chiffré de bout en bout</div>
              </div>
              <v-divider />
              <div class="py-3">
                <div>
                  <v-btn
                    color="primary"
                    text
                    style="text-transform: none"
                    @click="downloadFile()"
                  >
                    <v-icon>mdi-file-upload-outline</v-icon>
                    Télécharger
                  </v-btn>
                </div>
                <div>
                  <v-btn color="info" text style="text-transform: none" @click="dialogShare = true" :disabled="!file.is_mine">
                    <v-icon color="info">mdi-share</v-icon>
                    Partager
                  </v-btn>
                </div>
                <div>
                  <v-btn color="error" text style="text-transform: none" @click="dialogDelete = true" :disabled="!file.is_mine">
                    <v-icon color="error">mdi-delete</v-icon>
                    Supprimer
                  </v-btn>
                </div>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
        <delete-dialog 
          :message="'Êtes vous sûr de vouloire supprimer le document. Si vous l’aviez partagé à des praticiens, leur accès sera révoqué.'" 
          :title="'Suppression de document'" 
          v-model="dialogDelete" 
          @confirmDelete="confirmDelete"
        />
      </v-card>
      <form-shared-file v-if="dialogShare" :file="file" v-model="dialogShare" />
    </v-dialog>
</template>

<script>
import DeleteDialog from '../Home/utilities/DeleteDialog.vue';
import FormSharedFile from '../patient/formSharedFile.vue';
import ViewFileItem from './ViewFileItem.vue';

export default {
  components: { DeleteDialog, ViewFileItem, FormSharedFile },
  props: {
    value: {
      type: Boolean,
    },
    file: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogShare: false,
      token: "",
      url:null,
      filename:null,
      dialogDelete: false,
    };
  },
  mounted() {
    
    if (this.$auth.check()) {
      this.url = `files/${this.file.id}/download`
      this.filename = this.file.filename+"."+(this.file.filetype).toLowerCase()
    }
  },
  
  computed: {
   
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      },
    },
  },
  methods: {
    
    downloadFile() {
      this.$emit('downloadFile', this.url,this.filename);
    },
    confirmDelete() {
      this.dialog = false
      this.$emit('confirmDelete')
    },
  },
};
</script>

<style>
.v-dialog--fullscreen {
    overflow: hidden !important;
    border-radius: 0;
    margin: 0;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}
.view-document {
  /*width: 100%*/
  height: 880px;
}
</style>