<template>
    <v-container>
    <div class="form-intervenant mt-12">
      <v-row justify="space-between">
        <v-col cols="12" md="7" v-if="intervenant">
          <v-sheet class="pa-3">
            <div class="text-center pa-6">
              <update-photo :user="user" />
              <div class="pt-3 font-weight-bold font-6">{{ intervenant.first_name }}  {{ intervenant.last_name }}</div>
            </div>
            <v-row class="py-2">
              <v-col class="subtitle-2 font-weight-bold">
                Nom Cabinet
              </v-col>
              <v-col>
                {{ intervenant.cabinet_name }}
              </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-2">
              <v-col class="subtitle-2 font-weight-bold">
                Secteur d'activité
              </v-col>
              <v-col>
                {{ intervenant.secteur_activite.libelle }}
              </v-col>
            </v-row>
            <v-divider />
            
            <v-row class="py-2">
              <v-col class="subtitle-2 font-weight-bold">
                Email
              </v-col>
              <v-col>
                {{ user.email }}
              </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-2">
              <v-col class="subtitle-2 font-weight-bold">
                Adresse
              </v-col>
              <v-col>
                {{ intervenant.address_cabinet}}
              </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-2">
              <v-col class="subtitle-2 font-weight-bold">
                N° téléphone
              </v-col>
              <v-col>
                {{ intervenant.tel_port }}
              </v-col>
            </v-row>
            <div class="py-6">
              <div class="d-flex justify-center">
                <v-btn color="primary" style="text-transform: none" class="mr-3" outlined rounded @click="editedIntervenant">
                  <v-icon>mdi-pencil</v-icon>  Modifier
                </v-btn>
                
              </div>
            </div>
           
          
          </v-sheet>
        </v-col>
        <v-col cols="12" md="4">
          <v-sheet class="pa-6 mb-3">
            <h4>Modifier votre mot de passe</h4>
          </v-sheet>
          <v-sheet class="pa-3">
            <v-text-field
              v-model="password_old"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              label="Ancien mot de passe"
              outlined
              dense
              :type="show ? 'text' : 'password'"
              @click:append="show = !show"
              :error-messages="errors.current_password"
            ></v-text-field>
            <v-text-field
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              label="Nouveau mot de passe"
              outlined
              dense
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
              :error-messages="errors.password"
            ></v-text-field>
            <v-text-field
              v-model="password_confirm"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              label="Confirmer votre mot de passe"
              outlined
              dense
              :type="show2 ? 'text' : 'password'"
              @click:append="show2 = !show2"
              :error-messages="errors.password_confirmation"
            ></v-text-field>
            <v-btn block color="primary" dark outlined class="my-3" @click="changePassword">Enregistrer</v-btn>
            <v-btn block color="error" dark outlined @click="close">Annuler</v-btn>
          </v-sheet>
        </v-col>
      </v-row>
      <div>
      </div>
    </div>
    <v-dialog
      v-model="showForm"
      persistent
      width="500"
    >
    <v-card>
      <v-card-title>
        Modifier informations
        <v-spacer></v-spacer>
        <v-btn icon color="error" @click="showForm=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            v-model="editedItem.first_name"
            label="Prénom"
            outlined
            dense
            :error-messages="errors.first_name"
            disabled
          />
          <v-text-field
            v-model="editedItem.last_name"
            label="Nom"
            outlined
            dense
            :error-messages="errors.last_name"
            disabled
          />
          <v-text-field
            v-model="editedItem.cabinet_name"
            label="Nom du Cabinet"
            outlined
            dense
            :error-messages="errors.cabinet_name"

          />
          <v-text-field
            v-model="editedItem.address_cabinet"
            label="Adresse cabinet"
            outlined
            dense
            :error-messages="errors.address_cabinet"

          />
          <vue-tel-input-vuetify 
            v-model="editedItem.tel_port"
            class="form"
            name="phone"
            placeholder="Entrer votre N° téléphone"
            outlined
            mode="international"
            :error="errorPhone"
            ref="telInput"
            @change="verifyNumber"
            :error-messages="errors.tel_dom"
        />
          <div class="d-flex justify-end">
            <v-btn color="error" outlined style="text-transform: none; margin-right: 20px;" @click="closeForm">Annuler</v-btn>
            <v-btn color="primary" outlined style="text-transform: none;" @click="save" :loading="loading">Enregistrer</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>

    </v-dialog>
  </v-container>
  </template>
  <script>
  import UpdatePhoto from '../../components/Home/utilities/UpdatePhoto.vue';
  export default {
    components: { UpdatePhoto },
    data() {
      return {
        errorPhone: false,
        show: false,
        show1: false,
        show2: false,
        errors: {},
        showForm: false,
        user: null,
        intervenant: null,
        password_old: null,
        password: null,
        password_confirm: null,
        loading: false,
        editedItem:{
          first_name: '',
          last_name: '',
          address_cabinet: '',
          cabinet_name: '',
          tel_port: ''
        }
      };
    },
    mounted() {
      if (this.$auth.check()) {
        this.user = this.$auth.user;
        this.getintervenant();
      }
    },
    methods: {
      verifyNumber() {
            if(!this.$refs.telInput._computedWatchers.phoneObject.value.isValid) {
                this.errorPhone = true;
                this.errors.tel_port = "Le numéro est incorrecte"
            } else {
                this.errorPhone = false;
                this.errors.tel_port = null;
            }
      
    },
      getintervenant() {
        this.$http.get(`user`).then(({ data }) => {
          this.intervenant = data.data;
        });
      },
      updatedintervenant(data) {
        this.intervenant = data
        this.$toast.success({
          title:'Modification profil',
          message:'Votre profil a été mise à jour avec succès'
        })
      },
      save() {
        this.loading = true
        if(this.editedItem.tel_port) {
          this.editedItem.tel_port = this.editedItem.tel_port.match(/\S+/g).join("")
        }
        
        this.$http.put(`intervenant/${this.intervenant.id}`, this.editedItem).then(({data}) => {
          this.intervenant = data.data
          this.$toast.success({
            title: 'Modification profil',
            message: 'Vos informations ont été modifiées avec succès !!'
          });
          this.closeForm()
        }).catch((error) => {
          if(error.response.data.errors) {
            this.errors = error.response.data.errors
          } else {
            this.$toast.error({
              title: 'Erreur',
              message: "Une erreur c'est produite lors de la modification de vos importions"
            })
          }
          this.loading = false
        })
        
      },
      changePassword() {
        this.errors = {};
        const data = {
          current_password: this.password_old,
          password: this.password,
          password_confirmation: this.password_confirm
        }
        this.$http.post('profile/change-password', data).then(() => {
          this.$toast.success({
          title:'Modification Mot de Passe',
          message:'Votre mot de passe a été mise à jour avec succès'
        })
          this.close()
        }).catch((error) => {
          if(error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else {
            this.$toast.error({
              title: 'Erreur',
              message: 'Une erreur est survenue lors du modification de votre mot de passe'
            })
          }
         
        })
      },
      close() {
        this.password = '';
        this.password_old = '';
        this.password_confirm = '';
        this.errors = {};
      }, 
      closeForm() {
        this.editedItem = {
          first_name: '',
          last_name: '',
          address_cabinet: '',
          cabinet_name: '',
          tel_port: ''
        }
        this.showForm = false
        this.errors = {}
        this.loading = false
      },
      editedIntervenant() {
        this.editedItem = Object.assign({}, this.intervenant)
        this.showForm = true
      },
      
    },
    
  };
  </script>
  <style scoped>
  </style>