<template>
  <div v-if="doctor">
    <v-row align="start" justify="space-between">
      <!-- Les etapes de prise de rendez-vous -->
      <v-col cols="12" md="8">
        <v-stepper v-model="step" vertical elevation="0">
          <v-stepper-step :complete="step > 1" step="1">
            Choisir la date de consultation
            <!-- <small>Summarize if needed</small> -->
          </v-stepper-step>

          <v-stepper-content step="1">
              
            <v-card height="350px">
              <meeting-selector :doctor="doctor" @dateSelected="dateSelected" />
            </v-card>
            <!-- <v-btn color="primary" @click="e6 = 2"> Continue </v-btn>
            <v-btn text> Cancel </v-btn> -->
          </v-stepper-content>

          <v-stepper-step :complete="step > 2" step="2">
            Choix du motif
          </v-stepper-step>

          <v-stepper-content step="2">
            <div class="motif-consultation">
              <v-radio-group v-model="motif_selected" column>
                <v-radio
                  v-for="motif in doctor.motifs"
                  :key="motif.id"
                  :label="motif.name"
                  :value="motif"
                  @change="motifSelected(motif)"
                ></v-radio>
              </v-radio-group>
            </div>
            <!-- <v-btn color="primary" @click="e6 = 3"> Continue </v-btn>
            <v-btn text> Cancel </v-btn> -->
          </v-stepper-content>

          <v-stepper-step :complete="step > 3" step="3">
            Identification
          </v-stepper-step>

          <v-stepper-content step="3">
            <!--  <v-card
              
            > -->
            <div class="form-login text-center" v-if="!authenticated">
              <v-btn
                color="primary"
                outlined
                style="text-transform: none"
                @click="dialog = true"
                >Se connecter | S'inscrire</v-btn
              >
             
            </div>
            <div class="form-login text-center" v-else>
              <v-btn color="success" style="text-transform: none" @click="step = 5">continuer</v-btn>
            </div>
            
          </v-stepper-content>

          <v-stepper-step :complete="step > 4" step="4">
            Vérification
          </v-stepper-step>
          <v-stepper-content step="4">
            
          </v-stepper-content>
          <v-stepper-step :complete="step > 5" step="5">
            Informations patient
          </v-stepper-step>

          <v-stepper-content step="5">
            <div class="list-proches">
              <div class="row" v-if="$auth.check() && $auth.user.is_intervenant">
                <v-col cols="12" md="6" class="pa-6">
                  <v-text-field v-model="first_name" placeholder="Rechercher par prénom" outlined clearable dense @input="search" />
                </v-col>
                <v-col cols="12" md="6" class="pa-6">
                  <v-text-field v-model="last_name" placeholder="Rechercher par nom" outlined clearable dense @input="search" />
                </v-col>
              </div>
              <v-radio-group v-model="patient_selected" column>
              <v-card class="mb-3 pa-3 grey lighten-5" v-if="patient">
                <v-radio :value="patient">
                  <template #label>
                    <v-avatar
                      size="25"
                      color="primary"
                      class="white--text font-weight-bold mx-6 pa-1"
                    >
                      {{ (patient.name).substring(0, 1)
                      }}{{ (patient.name).substring((patient.name.indexOf(" ")+1),(patient.name.indexOf(" ")) +2) }}
                    </v-avatar>
                    <div>
                      {{ patient.name }} (<strong>Moi</strong>)
                    </div>
                  </template>
                </v-radio>
              </v-card>
              <v-card
                v-for="proche in proches"
                :key="proche.id"
                class="mb-3 pa-3 grey lighten-4"
              >
                <v-radio :value="proche" @change="procheSelected(proche)">
                  <template #label>
                    <v-avatar
                      size="25"
                      color="warning"
                      class="white--text font-weight-bold mx-6 pa-1"
                    >
                      {{ proche.first_name.substring(0, 1)
                      }}{{ proche.last_name.substring(0, 1) }}
                    </v-avatar>
                    <div>{{ proche.name }}</div>
                  </template>
                </v-radio>
              </v-card>
            </v-radio-group>
            </div>
            
            <div class="add-proche mb-3">
              <v-btn
                text
                color="blue"
                class=""
                style="text-transform: none"
                @click="showFormProche = true"
              >
                <v-icon>mdi-account-plus</v-icon> Ajouter un nouveau proche
              </v-btn>
            </div>
            <div class="btn-group">
              <!-- <v-spacer></v-spacer> -->
              <v-btn 
                class="btn"
                outlined
                rounded
                color="error" 
                @click="annuler"
              >
                Annuler 
              </v-btn>
              <v-btn
                class="btn"
                color="primary"
                @click="verify"
                style="text-transform: none"
                outlined
                rounded
                :loading="loading"
              >
                Confirmer le RDV
              </v-btn>
              
            </div>
          </v-stepper-content>
        </v-stepper>
      </v-col>
      <!-- Detail du rende-vous -->
      <v-col cols="12" md="4">
        <v-sheet>
          <div
            class="pa-3 primary white--text rounded-t-xl"
            v-if="date_selected"
          >
          <div class="d-flex justify-space-between">
            <div> 
              <span><v-icon color="white">mdi-calendar</v-icon> {{ date_formatting }} </span>
            </div>
            <div> 
              <span
              ><v-icon size="20" color="white" class="px-2">mdi-alarm</v-icon>
            </span>
            {{ heures }}h{{ minutes }}
            </div>
          </div>
            
            
          </div>
          <div class="d-flex">
            <div class="profile-photo" v-if="doctor.user.avatar_url">
              <img :src="doctor.user.avatar_url" alt="avatar" />
            </div>
            <div class="profile-info">
              <div>
                <strong>{{ doctor.name }}</strong>
              </div>
              <div>
                Spécialiste en {{ doctor.department.name.toLowerCase() }}
              </div>
            </div>
          </div>
          <div class="detail-rv pa-5">
            <div class="mb-2"><strong>Votre rendez-vous en detail</strong></div>
            <div class="lieu mb-2" v-if="typeRV ==='cabinet'">
              <v-icon>mdi-hospital-building</v-icon> {{ doctor.address_cabinet }}
            </div>
            <div class="lieu mb-2" v-else>
              <v-icon>mdi-video-outline</v-icon> Consultation vidéo
            </div>
            <div class="tarif-consultation mb-2"  v-if="tarif">
              <v-icon>mdi-stethoscope</v-icon> {{ tarif.name }}
            </div>
            <div class="tarif-consultation mb-2"  v-if="tarif">
              <v-icon>mdi-cash</v-icon>Montant à payer: {{ tarif.montant.toLocaleString("fr-FR") }} fcfa
            </div>
            <div class="lieu mb-2" v-if="patient_selected">
              <v-icon>mdi-account</v-icon> {{ patient_selected.name }}
            </div>
          </div>
          
        </v-sheet>
      </v-col>
    </v-row>
    <proche-form v-model="showFormProche" @createProche="createProche" />
    <v-dialog class="text-center" v-model="dialog" max-width="430px">
      <v-card>
        <v-tabs v-model="tab" grow slider-size="6">
        <!-- <v-tabs v-model="tab" grow background-color="#025397" slider-size="6"> -->
          <v-tab > Se connecter </v-tab>
          <v-tab > S'inscrire </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card color="basil" flat>
              <v-card-text>
                <login @connected="connected" />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="basil" flat>
              <v-card-text>
                <register @codeValide="codeValide" />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
    <conditions-pay v-model="showDialog" v-if="showDialog && doctor.condictions_pay" :content="doctor.condictions_pay" @confirmer="priveRv" />
  </div>
</template>

<script>
import ConditionsPay from './doctor/ConditionsPay.vue';
import meetingSelector from "./Home/utilities/meeting.selector.vue";
import Login from "./login.vue";
import ProcheForm from "./proche/ProcheForm.vue";
import Register from "./register.vue";
export default {
  components: { meetingSelector, ProcheForm, Login, Register, ConditionsPay },
  props: {
    doctor: { type: Object },
    e6: { type: Number, default: 1 },
    dateSelect: { type: Object, default: null }
  },
  data() {
    return {
      typeRV: null,
      showDialog: false,
      tab: null,
      dialog: false,
      date_formatting: null,
      heures: null,
      minutes: null,
      options: { year: "numeric", month: "long", day: "numeric" },
      opt_weekday: { weekday: "long" },
      showFormProche: false,
      authenticated: false,
      date_selected: null,
      motif_selected: null,
      tarif: null,
      patient: null,
      patient_selected: null,
      proche: null,
      proches: [],
      loading: false,
      dataCookies : {},
      first_name: '',
      last_name: '',
      step: this.e6
    };
  },
  async created() {
    await this.$auth.user ? (this.authenticated = true) : (this.authenticated = false);
      if(this.dateSelect) {
        this.dateSelected(this.dateSelect)
      }
      if(this.$cookies.isKey('appointment')) {
        const data = this.$cookies.get('appointment');
        this.renderCookie(data);
      }
      
  },
  methods: {
    verify(){
      if(this.doctor.condictions_pay && this.typeRV != 'cabinet') {
        this.showDialog = true
      } else {
        this.priveRv()
      }
    },
    async dateSelected(data) {
      this.typeRV = data.typeRV
      const myDate = new Date(data.date);
      const weekday = this.toTitleCase(
        myDate.toLocaleDateString("fr-FR", this.opt_weekday)
      );
      const date_day =
        weekday + " " + myDate.toLocaleDateString("fr-FR", this.options);
      this.date_formatting = date_day;
      
      this.dataCookies.date_formatting = this.date_formatting;
      this.heures = myDate.getHours();
      this.dataCookies.heures = this.heures
      this.minutes = myDate.getMinutes();
      this.dataCookies.minutes = this.minutes;
      this.date_selected = new Date(data.date);
      this.dataCookies.date_selected = this.date_selected;
      this.typeRV = data.typeRV
      this.step++;
    },
    async motifSelected(motif) {
      this.dataCookies.typeRV = this.typeRV
      this.tarif = motif;
      this.dataCookies.tarif = motif;
      this.dataCookies.url = this.$route.fullPath
      const medecin = {
        avatar_url: this.doctor.user.avatar_url,
        name: this.doctor.name,
        specialite: this.doctor.department.name.toLowerCase(),
        //adresse: this.doctor.adresse
      }
      this.dataCookies.medecin = medecin

      if(this.authenticated) {
        this.dataCookies.step = 5
        this.getProches();
        await this.$cookies.set('appointment', this.dataCookies);
        this.step = 5;
      } else {
        this.step++
      }
    },
    renderCookie(data) {
      this.date_selected = data.date_selected;
      this.typeRV = data.typeRV
      this.tarif = data.tarif;
      this.date_formatting = data.date_formatting;
      this.heures = data.heures;
      this.minutes = data.minutes;
      this.step = data.step;
      this.getProches();
    },

    async priveRv() {
      if(this.patient_selected) {
        this.loading = true;

      const data = {
        date: this.date_selected,
        description: this.tarif.name,
        patient_id: this.$auth.user.patient_id,
        proche_id:  this.patient_selected.isMe ? null : this.patient_selected.id,
        motif_id: this.tarif.id,
        user_id: this.patient.id,
        doctor_id: this.doctor.id,
        intervenant_id: this.$auth.user.intervenant_id,
        // receiver_id: this.doctor.user.id,
        is_cabinet: this.typeRV == 'cabinet' ? true : false
      };
       await this.$http
        .post("appointment", data)
        .then(({ data }) => {
          this.loading = false;
          this.$toast.success({
            title: 'Prise de RDV',
            message: `Votre rendez-vous a été confirmé pour le ${data.data.formated_date}!`
          })
          this.$cookies.remove('appointment');
          this.dataCookies = null
          this.$router.push({name: this.$auth.user.is_patient ? 'compte.mes-rendez-vous' : 'intervenant.mes-rendez-vous'});
          
        })
        .catch((error) => {
          this.loading = false;
          if(error.response.data.message) {
            this.$toast.error({
              title: 'Prendre RVD',
              message:error.response.data.message //"Une erreur est produite lors de la creation du rendez-vous"
            })
          } else {
            this.$toast.error({
              title: 'Prendre RVD',
              message: "Une erreur est produite lors de la creation du rendez-vous"
            })
          }
          
        });
      }
     
    },
    procheSelected(proche) {
      this.proche = proche;
    },
    getProches() {
      this.$http
        .get(`proches`)
        .then(({ data }) => {
          this.proches = data.data;
        });
      this.getpatient();
    },
    getpatient() {
      this.patient = this.$auth.user;
    },
    createProche(proche) {
      this.proches.push(proche);
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    async connected() {
      this.dataCookies.step = 5;
      await this.$cookies.set('appointment', this.dataCookies);
      this.dialog = false;
      this.$router.go()
    },
    annuler() {
      this.$cookies.remove("appointment");
      this.dataCookies = null;
      this.$router.go(-1)
    },
    codeValide() {
      this.tab = 1;
    },
    pay(data) {
      data.name = this.tarif.name
      data.amount = this.tarif.montant
      data.command_name = 'teleconsultation'
      data.type_payement= 'Téléconsultation'
      this.$http.post('paytech/abonnement',data ).then((response) => {
      this.url_pay = response.data.url;
      this.$cookies.remove('appointment');
      this.dataCookies = null
       window.open(this.url_pay, "_self");
        })
    },
    search(key) {
      if (key) {
        this.$http.get('proches/search',{ params: {first_name: this.first_name, last_name: this.last_name}}).then(res => {
          this.proches = res.data.data
        }).catch(() => {

        })
      } else {
      this.getProches()
       //this.$refs.loader.$emit('$InfiniteLoading:loaded')
      }
    }
  },
};
</script>

<style scoped>
.btn-group {
  display: flex;
  justify-content:flex-end;

}
.btn {
  margin-right: 12px;
}
.profile-photo img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.profile-photo {
  padding: 10px;
  margin-right: 20px;
}
.profile-info {
  /*padding: 10px;*/
  margin: 10px 0px 0px 17px;
  /*text-align: center;*/
  line-height: 1.5;
  font-size: 0.9em;
}
.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 30px;
}
@media screen and (max-width: 600px) {
  .v-application--is-ltr .v-stepper--vertical .v-stepper__content {
    margin: 0;
}
  .btn {
    font-size: 12px;
  }
  .v-application--is-ltr .v-stepper--vertical .v-stepper__content {
      padding: 0 0 0 12px;
  }
  .btn-group {
    justify-content: space-around;
  }

}
</style>