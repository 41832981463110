<template>
    <div>
        <v-tabs grow v-model="tab">
            <v-tab >
                Creation Entreprise
            </v-tab>
            <v-tab>
                Creation Compte
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <div class="form mt-6">
                    <v-text-field
                    v-model="defaulItem.name"
                    name="name"
                    label="Nom de l'entreprise"
                    outlined
                    :error-messages="errors.name"
                ></v-text-field>
                <v-text-field
                    v-model="defaulItem.phone"
                    name="phone"
                    label="N° téléphone"
                    outlined
                    :error-messages="errors.phone"
                ></v-text-field>
                <v-text-field
                    v-model="defaulItem.email"
                    name="email"
                    label="Email"
                    outlined
                    :error-messages="errors.email"
                ></v-text-field>
                <v-text-field
                    v-model="defaulItem.address"
                    name="adresse"
                    label="Adresse"
                    outlined
                    :error-messages="errors.address"
                    
                ></v-text-field>
                <!-- <v-file-input
                    v-model="file"
                    accept="image/*"
                    label="Choisir le logo"
                    prepend-icon=""
                    append-inner-icon="mdi-file-upload"
                    outlined
                    :error-messages="errors.logo"
                >
                <template v-slot:selection="{ index, text }">
                    <v-chip
                        v-if="index < 2"
                        color="primary"
                        dark
                        label
                        small
                    >
                        {{ text }}
                    </v-chip>

                    </template>
                </v-file-input> -->
                <div class="">
                    <v-btn color="primary" style="text-transform: none;" @click="saveEntreprise" elevation="1">Entregistrer</v-btn>
                </div>
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="form mt-6">
                    <v-text-field
                        v-model="defaulItem.user.first_name"
                        name="user.first_name"
                        label="Prénom"
                        outlined
                        :error-messages="user_errors.last_name"
                    ></v-text-field>
                    <v-text-field
                        v-model="defaulItem.user.last_name"
                        name="user.last_name"
                        label="Nom"
                        outlined
                        :error-messages="user_errors.last_name"
                    ></v-text-field>
                    <v-text-field
                        v-model="defaulItem.user.phone"
                        name="user.phone"
                        label="N° téléphone"
                        outlined
                        :error-messages="user_errors.phone"
                    ></v-text-field>
                    <v-text-field
                        v-model="defaulItem.user.email"
                        name="user.email"
                        label="Email"
                        outlined
                        :error-messages="user_errors.email"
                    ></v-text-field>
                    <div class="">
                        <v-btn color="primary" style="text-transform: none;" @click="saveCompteEntreprise" elevation="1" :loading="loading">Entregistrer</v-btn>
                    </div>
                </div>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
export default {
    props: {
        entreprise: {
            type: Object
        }
    },
    data() {
        return {
            errors: {},
            defaulItem: null,
            tab: null,
            loading: false,
           // file: [],
            user_errors: {}
        }
    },
    watch: {
        entreprise: {
            handler: 'updateDefaulItem',
            immediate: true // Pour appeler updateDefaulItem lors de la création du composant
        }
    },
    methods: {
        updateDefaulItem() {
            this.defaulItem = JSON.parse(JSON.stringify(this.entreprise));
            this.tab =0
        },
        saveEntreprise() {
            this.errors = {}
            const data = {
                name: this.defaulItem.name,
                address: this.defaulItem.address,
                email: this.defaulItem.email,
                phone: this.defaulItem.phone
            }
            // const data = new FormData();
            // data.append('name', this.defaulItem.name)
            // data.append('address', this.defaulItem.address)
            // data.append('email', this.defaulItem.email)
            // data.append('phone', this.defaulItem.phone)
            // if(this.file.length !=0) {
            //    data.append('logo', this.file )
            // }
            if(this.defaulItem.id) {
                this.$http.put(`entreprise/${this.defaulItem.id}`, data).then(() => {
                    //console.log("data", data)
                    this.tab = 1
                    this.$toast.success({
                        title: "Modification Entreprise",
                        message: "Entreprise modifié avec succès !"
                    })
            }).catch((error) => {
                if(error.response.data.errors)
                    this.errors = error.response.data.errors
            })
            }
            else {
                this.$http.post('entreprise', data).then(({data}) => {
                    this.defaulItem = Object.assign(this.defaulItem, data.data);
                    this.tab = 1
                    this.$toast.success({
                        title: "Creation Entreprise",
                        message: "Entreprise créée avec succès !"
                    })
                }).catch((error) => {
                    if(error.response.data.errors)
                        this.errors = error.response.data.errors
                })
            }
           
        },
        saveCompteEntreprise() {
            this.loading = true
           this.user_errors = {}
            const data = {
                first_name: this.defaulItem.user.first_name,
                last_name: this.defaulItem.user.last_name,
                email: this.defaulItem.user.email,
                phone: this.defaulItem.user.phone
            }

            if(this.defaulItem.user.id){
                this.$http.put(`entreprise/compte/${this.defaulItem.user.id}`, data).then((response) => {
                    this.$emit('updatedEntreprise')
                    this.$toast.success({
                        title: "Modification compte",
                        message: response.data.message
                    })
                   this.loading = false
                }).catch((error) => {
                    if(error.response.data.message){
                        this.$toast.error({
                            title: 'Entreprise',
                            message: error.response.data.message
                        })
                    }
                    if(error.response.data.errors) {
                        this.user_errors = error.response.data.errors
                    }
                    this.loading = false
                
                })
            } else {
                this.$http.post(`entreprise/compte/${this.defaulItem.id}`, data).then((response) => {
                    this.$emit('createdEntreprise')
                    this.$toast.success({
                        title: "Creation compte",
                        message: response.data.message
                    })
                    this.loading = false
                }).catch((error) => {
                    if(error.response.data.message){
                        this.$toast.error({
                            title: 'Entreprise',
                            message: error.response.data.message
                        })
                    }
                    if(error.response.data.errors) {
                        this.user_errors = error.response.data.message
                    }
                    this.loading = false
                    
                })
            }
            
        }
    }
}
</script>

<style>

</style>