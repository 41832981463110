<template>
    <div style="width: 100%; height: 100%;"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'force-profil',
    props: {
        force: Number
    },
    mounted() {
        this.renderChart();
    },
    methods: {
    
    renderChart() {
      const chart = echarts.init(this.$el);
      chart.setOption(this.getOption());
    },
    getOption() {
        return {
            series: [
    {
      type: 'gauge',
      axisLine: {
        lineStyle: {
          width: 5,
          color: [
            [0.3, '#fd666d'],
            [0.7, '#37a2da'],
            [1, '#67e0e3']
          ]
        }
      },
      pointer: {
        itemStyle: {
          color: 'inherit'
        }
      },
      axisTick: {
        distance: -5,
        length: 5,
        lineStyle: {
          color: '#fff',
          width: 2
        }
      },
      splitLine: {
        distance: -5,
        length: 5,
        lineStyle: {
          color: '#fff',
          width: 4
        }
      },
      axisLabel: {
        color: 'inherit',
        distance: 10,
        fontSize: 8
      },
      detail: {
        valueAnimation: true,
        color: '#fff',
        fontSize: 20,
        offsetCenter: [0, '70%']
      },
      title: {
        show: true,
         color: '#fff',
        offsetCenter: [0, '110%'],
        fontWeight: 'bold' ,
     },
      data: [
        {
          value: this.force,
          name: 'Force du Profil'
        }
      ]
    }
  ]
    //         series: [
    // {
    //   type: 'gauge',
    //   progress: {
    //     show: true,
    //     width: 5
    //   },
    //   axisLine: {
    //     lineStyle: {
    //       width: 5,
    //       color: [
    //         [0.3, '#67e0e3'],
    //         [0.7, '#37a2da'],
    //         [1, '#fd666d']
    //       ]
    //     }
    //   },
    //   axisTick: {
    //     show: false
    //   },
    //   splitLine: {
    //     length: 5,
    //     lineStyle: {
    //       width: 2,
    //       color: '#fff'
    //     }
    //   },
    //   axisLabel: {
    //     distance: 8,
    //     color: '#fff',
    //     fontSize: 8
    //   },
    //   anchor: {
    //     show: true,
    //     showAbove: true,
    //     size: 5,
    //     itemStyle: {
    //       borderWidth: 0
    //     }
    //   },
    //   title: {
    //     show: true,
    //     color: '#fff',
    //     offsetCenter: [0, '100%'],
    //     fontWeight: 'bold' ,
    //   },
    //   detail: {
    //     valueAnimation: true,
    //     fontSize: 20,
    //     color: '#fff',
    //     offsetCenter: [0, '60%']
    //   },
    //   data: [
    //     {
    //       value: this.force,
    //       name: 'Force Profile'
    //     }
    //   ]
    // }
    //         ]
        }
        
    }
  }
}
</script>

<style>

</style>