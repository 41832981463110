<template>
  <div>
    <v-dialog
        v-model="show"
        persistent
        width="500"
    >
    <v-card>
        <v-card-title>
            <div class="font-6 font-weight-bold text-center"> Ma Note </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
            <div class="font-7 font-weight-bold"> Titre</div>
            <v-text-field
                v-model="note.title"
                name="title"
                placeholder="Exemple: Rendez-vous chez le dentiste"
                id="title"
                :error-messages="errors.title"
                outlined
            ></v-text-field>
            <div class="font-7 font-weight-bold"> Contenu</div>
            <v-textarea
                v-model="note.content"
                name="title"
                placeholder="votre note"
                id="title"
                :error-messages="errors.content"
                outlined
            ></v-textarea>
            <div class="d-flex py-3">
                <v-btn style="text-transform: none; margin-right: 20px;" color="primary" elevation="0" :loading="loading" @click="save">Enregistrer</v-btn>
                <v-btn style="text-transform: none;" color="error" outlined @click="close"> Annuler</v-btn>
            </div>
        </v-card-text>
    </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
    props: {
        value: Boolean,
        note: {
            type: Object,
        }
    },
    data() {
        return {
            loading: false,
            errors: {}
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                return this.$emit('input', value)
            }
        },
    },
    methods: {
        save() {
            this.loading = true
            if(this.note.id) {
                this.$http.put('note/'+this.note.id, this.note).then(({ data }) => {
                    this.$emit('updatedNote', data.data)
                }).catch((error) => {
                    if(error.response.data.errors) {
                        this.errors = error.response.data.errors
                    } else {
                        this.$toast.error({
                            title: 'Erreur',
                            message: error.response.data.error
                        })
                    }
                }).finally(() => this.loading = false)
            } else {
                this.$http.post('note', this.note).then(({ data }) => {
                    this.$emit('createdNote', data.data)
                }).catch((error) => {
                    if(error.response.data.errors) {
                        this.errors = error.response.data.errors
                    } else {
                        this.$toast.error({
                            title: 'Erreur',
                            message: error.response.data.error
                        })
                    }
                }).finally(() => this.loading = false)
            }
        },
        close() {
            this.$emit('closeForm')
        }
    }
}
</script>

<style>

</style>