<template>
  <v-app>
    <navbar />
    <v-main>
        <div>
           
        <div  class="medecins" v-if="doctors.length">
            <medecins-list>
                <medecin-view
                    v-for="doctor in doctors"
                    :key="doctor.id"
                    :medecin="doctor"
                    :specialite="doctor.department.name"
                    :type_rv="'teleexpertise'"
                    @click="doctorSected(doctor)"
                    @dateSelect="dateSelect"
                />
            </medecins-list>
        </div>
        <div v-else>
        <div class="text-center font-4 mt-12">Pas encore de médecins pour cette spécialité</div>
        </div>
    </div>
    <v-dialog v-model="showForm" persistent width="500px" v-if="showForm">
        <v-card>
            <v-card-title>Confirmer le rendez-vous de Téléexpertise</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <div class="mt-3">
                    Voulez-vous confirmer votre rendez-vous de téléexpertise avec <span class="font-weight-bold">
                    {{ doctor.user.name }} 
                  {{ date_formatting }}
                </span>  ,
                pour un montant de <span class="font-weight-bold"> {{ doctor.teleexpertise_amount }} fcfa</span>
                ?
                </div>
               
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <div class="">
                    <v-btn color="error" outlined style="text-transform: none;margin-right: 25px;" @click="showForm=false">Annuler</v-btn>
                    <v-btn color="primary" outlined style="text-transform: none" @click="confirmer">Confirmer</v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import MedecinView from '../components/Home/utilities/medecin.vue'
import MedecinsList from '../components/Home/medecins.list.vue'
//import MeetingSelector from '../components/Home/utilities/meeting.selector.vue'
import navbar from '../components/Home/utilities/navbar.vue'
export default {
  components: { navbar, MedecinsList,MedecinView/* MeetingSelector*/ },
  data() {
    return {
        doctors: [],
        showForm: false,
        date_formatting: null,
        date_rv : null,
        doctor: null,
        heures: null,
        minutes: null,
        loading: false
    }
  },
  mounted() {
        this.getDoctors()
  },
  methods: {
    getDoctors() {
        this.$http.get('auth/doctors', { params: {type: 'horaire_teleexpertise'}}).then(({data}) => {
                this.doctors = data.data
            })
    },
    dateSelect(data) {
        this.date_formatting = this.convertirEnDateFrancaise(data.date)
        this.date_rv = new Date(data.date)
        this.doctor = data.doctor
        this.showForm = true
        
    },
   
    convertirEnDateFrancaise(date) {
        // Tableaux des noms de jours et de mois en français
        const jours = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
        const mois = ["Janvier", "Févier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

        // Fonction pour obtenir un format à deux chiffres pour les jours et les mois
        function formatDeuxChiffres(nombre) {
            return nombre < 10 ? "0" + nombre : nombre;
        }

        // Créer un objet Date à partir de la date fournie en paramètre
        const dateOriginale = new Date(date);

        // Obtenir les composants de la date
        const jour = jours[dateOriginale.getDay()];
        const moisTexte = mois[dateOriginale.getMonth()];
        const annee = dateOriginale.getFullYear();
        const heures = dateOriginale.getHours();
        const minutes = dateOriginale.getMinutes();
        

        // Construire la date au format français (jour mois année)
        const dateFrancaise = jour + " " +formatDeuxChiffres(dateOriginale.getDate())  + " "+ moisTexte + " " + " " + annee + " à " + heures + "h " + minutes;

        return dateFrancaise;
    },
    confirmer() {
        this.loading = true;
        const data = {
            amount          : this.doctor.teleexpertise_amount,
            name            : 'Téléexpertise avec '+ this.doctor.user.name,
            command_name    : 'teleexpertise',
            receiver_id     : this.doctor.user.id,
            doctor_id       : this.doctor.id,
            intervenant_id  : this.$auth.user.intervenant_id,
            description     : 'Téléexpertise',
            type_payement   : 'Téléexpertise',
            date            : this.date_rv,
            is_cabinet      : false
        }
       this.$http.post('paytech/abonnement', data).then((response) => {
        window.open(response.data.url, "_self");
            this.loading = false
            this.showForm = false
       }).catch(() => {
            this.$toast.error({
                title: 'Téléexpertise',
                message: "Une erreur c'est produite lors de la confirmation "
            })
       })
       
    }
  }

}
</script>

<style>

</style>