<template>
    <div class="mt-6">
        <v-row justify="space-between">
            <v-col cols="12" md="6">
                <div class="d-flex">
                    <v-text-field
                    name="searchQuery"
                    label="Rechercher un lieu"
                    id="searchQuery"
                    v-model="searchQuery"
                    dense
                    outlined
                    
                ></v-text-field> 
                <v-btn 
                  @click="searchLocation" 
                  color="primary" 
                  style="text-transform: none;margin-left: 10px;" 
                  elevation="0"  
                  :loading="loading"
                >
                  Rechercher
                </v-btn>
                </div>
            </v-col>
            <v-col cols="12" md="3">
              <div class="d-flex justify-end">
                <v-btn outlined style="text-transform: none;" color="primary" @click="saveMarker" :loading="loadingSave" :disabled="markerChanged">Enregistrer ma position</v-btn>
              </div>
              
            </v-col>
        </v-row>
        

      <v-sheet width="100%" height="500px" class="mt-3">
      <l-map :zoom="11" :center="center" v-if="center">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <!-- <l-control class="example-custom-control">
          <v-btn outlined style="text-transform: none;">Enregistrer votre position</v-btn>
      </l-control> -->
        <l-marker v-if="marker" :lat-lng="marker" :draggable="true"></l-marker>
      </l-map>
      
     <!--  <input v-model="searchQuery" @keyup.enter="searchLocation"/> -->
    </v-sheet>
    </div>
   
  </template>
  
  <script>

  //import axios from "axios";
  
  export default {
    props: {
      marker_doctor: Array
    },
    data() {
      return {
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution:'&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
        searchQuery: "",
        marker: [],
        loading: false,
        loadingSave: false,
        center: null
      };
    },
    mounted () {
        if(this.marker_doctor.length) {
          this.center = this.marker_doctor
          this.marker = this.marker_doctor
        } 
        else {
          navigator.geolocation.getCurrentPosition(position => {
            
            this. center= [position.coords.latitude, position.coords.longitude]
            this.marker = this.center
            
            })
        }
    },
    computed: {
      markerChanged() {
        if(this.marker.length != this.marker_doctor.length) {
          return false;
        }

        return this.marker_doctor.every((element, index) => element === this.marker[index]);
      }
    },
    methods: {
      saveMarker() {
        this.loadingSave = true
        this.$http.put(`update/marker/${this.$auth.user.doctor_id}`, { marker: this.marker}).then((response) => {
          this.$toast.success({
            title: 'Position enregistré',
            message: response.data.message
          })
          this.$emit('markerSaved', this.marker)
        }).catch((error) => {
            this.$toast.error({
              title: 'Position',
              message: error.response.data.message
            })
        }).finally(() => this.loadingSave = false)
      },
      async searchLocation() {
        
        this.loading = true;

        const url = new URL("https://nominatim.openstreetmap.org/search");
        url.searchParams.append("q", this.searchQuery);
        url.searchParams.append("format", "json");

        try {
          const response = await fetch(url);
          if (response.ok) {
            const data = await response.json();
            const firstResult = data[0];
            if (firstResult) {
              this.center = [firstResult.lat, firstResult.lon];
              this.marker = this.center;
              this.$toast.success({
                title: 'Position trouvée',
                message: "Lieu trouvé , veuillez cliquer sur le bouton enregistrer position pour sauvegarder votre position",
                timeOut: 5000,
              });
            } else {
              this.$toast.info({
                title: 'Position introuvable',
                message: "Désolé, nous n'avons pas trouvé le lieu recherché"
              });
            }
          } else {
            console.error('Response not ok:', response);
            // Handle the error case
          }
        } catch (error) {
          console.error('Error:', error);
          // Handle errors that occurred during the fetch or processing
        } finally {
          this.loading = false;
        }
       
      }
    }
  };
  </script>
  <style scoped>
  .example-custom-control {
  border-radius: 0.1em;
}
.custom-control-watermark {
  font-size: 200%;
  font-weight: bolder;
  color: #aaa;
  text-shadow: #555;
}
  .leaflet-container {
    z-index: 1;
  }
</style>