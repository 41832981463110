<template>
  <div class="medecin">
    <div class="profile">
      <div class="info-doc">
        <div class="avatar">
          <router-link
            :to="{ name: 'medecin.show', params: { id: medecin.id } }"
          >
            <img :src="medecin.user.avatar_url" />
          </router-link>
        </div>
        <div class="name">
          <h4>{{ medecin.name }}</h4>
          <span class="profession">{{
            medecin.department && medecin.department.name
              ? medecin.department.name
              : specialite
          }}</span>
           <span v-if="medecin.is_telemedecine" class="caption mb-3">
            <v-icon color="primary">mdi-video-outline</v-icon> Téléconsultation disponible
          </span>
        </div>
        <div class="info-user">
          <v-btn
            class="hidden-sm-and-down"
            color="primary"
            small
            rounded
            style="text-transform: none; font-weight: 600"
            @click="priseRv()"
            elevation="0"
          >
            Prendre Rendez-vous
          </v-btn>
          <v-btn
            class="hidden-sm-and-up"
            color="primary"
            small
            
            style="text-transform: none; font-weight: 600"
            @click="priseRv()"
            elevation="0"
          >
            Prendre rendez-vous
          </v-btn>
        </div>
      </div>

      <div class="doc-meeting hidden-sm-and-down">
        <meeting-selector v-if="has_schedule" :doctor="medecin" @dateSelected="dateSelected"  />
        <p class="text-center" v-else>
          L’option de réservation en ligne n’est pas encore activée pour ce praticien.
          Appelez-le directement pour prendre un RDV.</p>
      </div>
    </div>
  </div>
</template>

<script>

import MeetingSelector from "./meeting.selector.vue";
export default {
  props: { 
    medecin: Object,
    specialite: String,
    type_rv: { type: String, }
  },
  data() {
    return {
      
      dateSelect: null,
      unavailability: true
    };
  },
  methods: {
    priseRv() {
      //this.$auth.check() ? this.$router.push({name: 'medecin.show', params: { id: this.medecin.id }}) : this.dialog = true
      this.$router.push({
        name: "medecin.show",
        params: { id: this.medecin.id },
      });
    },
    dateSelected(date) {
      // if(this.type_rv == 'teleexpertise') {
      //   this.$emit('dateSelect', {date: date.date, doctor: this.medecin })
      // } else {
        this.dateSelect = date
        this.$router.push({ name: 'medecin.rendez-vous', params: { id: this.medecin.id , date: this.dateSelect }})
      //}
      //console.log("date", data.date)
      
    },
      getUnavailabityDay() {
        if(this.medecin.unavailability_date_start && this.medecin.unavailability_date_end) {
          const dateDuJour = new Date();
          const dateDebut = new Date(this.medecin.unavailability_date_start);
          const dateFin = new Date(this.medecin.unavailability_date_end)
          const inDay = dateDuJour >= dateDebut && dateDuJour<= dateFin;
          //console.log("in day", inDay )
          return !inDay;
        } else {
          return true;
        }
        
      }
  },
  computed: {
    has_schedule() {
      return (
      ( ( Object.keys(this.medecin.horaire_cabinet).length !=0 || (Object.keys(this.medecin.horaire_teleconsultation).length !=0)) && this.getUnavailabityDay()
      ));
    },
    
  },
  components: { MeetingSelector },
};
</script>

<style scoped>
.profile {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /*align-items: center;*/
}
.medecins .medecin {
  background-color: #fff;
  padding: 20px 0;
  /*border-top: 2px solid #151616;*/
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  padding: 5px;
  text-decoration: none;
  color: inherit;
  transition: transform ease 0.3s, box-shadow ease 0.3s;
  margin-bottom: 20px;
  min-height: 360px;
}
.medecins .medecin .avatar img {
  width: 72px;
  height: 72px;
  border-radius: 72px;
}
.medecins .medecin .info-user p {
  text-align: center;
}
.medecins .medecin .info-user .description {
  line-height: 1;
  font-size: 0.9em;
  margin: 10px 0;
}
.medecins .medecin .info-user .availability {
  font-size: 0.8em;
  color: #1f3651;
  font-weight: bold;
  margin: 10px 0;
  
}
.medecins .medecin .name .profession {
  transform: translateY(-5px);
  font-style: italic;
  color: #1f3651;
  margin-top: 10px ;
  font-size: 0.9em;
}
.medecins .medecin .info-user .button {
  display: block;
  background-color: #00a19a;
  padding: 5px 30px;
  text-decoration: none;
  color: #fff;
  border-radius: 290486px;
}
.medecins .medecin .avatar,
.medecins .medecin .name,
.medecins .medecin .info-user {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.doc-meeting {
  width: 50%;
  margin-right: 30px;
  
}
 .info-doc {
  align-content: center;
  /*align-items: center;*/
  margin-left: 50px;
  
}
@media only screen and (max-width: 600px) {
  .profile {
  display: flex;
  align-items: center;
  justify-content: center;
  /*align-items: center;*/
}
.info-doc {
  margin-left: 0;
}
}
</style>
