<template>
    <div class="user">
        <v-row>
            <v-col
                v-for="(user, index) in users" :key="index"
                cols="12"
                sm="3"
                align-content='space-between'
                >
                <v-card>
                    <div class="avatar">
                        <img :src="user.avatar_url">
                        <div>
                            <span class="mdi mdi-checkbox-marked-circle-outline" style="color: green;" v-if="user.actif === true"></span>
                            <span class="mdi mdi-close-circle" style="color: red;"  v-if="user.actif === false"></span>
                            {{ user.name }}
                            </div>
                            <h3> {{ user.role }}</h3>
                        </div>
                    <div class="info">
                        <div v-if="user.role === 'MEDECIN'" class="infos">
                            <p class="availability"></p>
                        </div>
                        <router-link class="button" v-if="user.role === 'MEDECIN'" :to="{name: 'admin.userProfile', params: {id: user.doctor_id}}">Voir le profil</router-link>
                        <router-link class="button" v-if="user.role === 'INTERVENANT'" :to="{name: 'admin.IntervenantProfile', params: {id: user.intervenant_id}}">Voir le profil</router-link>
                        <router-link class="button" v-if="user.role === 'PATIENT' " :to="{name: 'admin.patientProfile', params: {id: user.patient_id}}">Voir le profil</router-link>
                    </div>
                </v-card> 
            </v-col>
        </v-row>     
    </div>
</template>

<script>
export default {
    props: {
           users: {
            type: Array,
           required: true
        }
       
   },
}
</script>

<style scoped>
     .user {
        padding:20px 0;
        box-shadow: 0 0 2px rgba(0,0,0,.5);
        padding:5px;
        text-decoration:none;
        color:inherit;
        transition: transform ease .3s, box-shadow ease .3s;
    }
    .user .actions {
        display:flex;
        align-items: center;
        justify-content: center;
    }
 .user .avatar img {
        width:100px;
        height:100px;
        border-radius:50px;
    }
    .user .info p {
        text-align: center;
    }
    .user .info .description {
        line-height: 1;
        font-size: .9em;
         margin:10px 0;
    }
    .user .info .availability {
        font-size: .8em;
        font-weight: bold;
        margin:10px 0;
    }
    .user .name .profession {
        transform:translateY(-5px);
        font-style: italic;
        color:#1F3651;
        margin:10px 0;
        font-size: .9em;
    }
    .user .info .button {
        display: block;
        background-color: #1F3651;
        padding:5px 30px;
        text-decoration: none;
        color:#fff;
        border-radius: 290486px;
        margin-bottom: 3px;
        margin-top: 3px;
    }
    .user .avatar,
     .user .name,
     .user .info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
</style>
