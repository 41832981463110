import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"
import createPersistedState from 'vuex-persistedstate'
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: true,
  devtools: true,
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {
    visio: null
  },
  getters: {
    visio: state => {
      return state.visio
    },
    isVisio: state => {
      return state.visio && state.visio.actif;
    }
  },
  mutations: {
    setVisio(state, payload) {
      state.visio = payload
    }
  },
  actions: {
    async getVisio(context, payload) {
      try {
        const response = await axios.get(`opentok_session/${payload.sessionId}`)
        response.data.type = payload.type
        context.commit('setVisio', response.data)
      } catch (error) {
        console.log(error)
      }
    }
  },
  modules: {
  },
})
export default store;