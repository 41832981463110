<template>
    <div id="videos" v-if="apiKey">
        <div id="subscriber" ref="subscriber"></div>
        <div id="publisher" ref="publisher" :style="'width:' + widthPublisher + ';' +'height:' +heightPublisher+ ';'"></div>
        <div class="btn-controls">
            <v-btn rounded class="icon" id="audio" @click="publishingAudio" color="primary">
                <v-icon id="audio-on">{{ audioEnabled ? 'mdi-microphone' : 'mdi-microphone-off'}}</v-icon>
            </v-btn>
            <v-btn class="icon" id="video" @click="publishingVideo" color="primary">
                <v-icon id="video-on">{{ videoEnabled ? 'mdi-video-outline' : 'mdi-video-off-outline'}}</v-icon>
            </v-btn>
            <v-btn class="icon" id="viewFull" @click="viewFull" color="primary">
                <v-icon>mdi-fullscreen</v-icon>
            </v-btn>
            <!-- <v-btn  class="icon" id="chat" @click="chated" color="primary">
                <v-icon>mdi-message-text-outline</v-icon>
                <div id="message-notification"></div>
            </v-btn> -->
            <!-- <v-btn icon class="icon" id="record">
                <v-icon>mdi-record-circle-outline</v-icon>
            </v-btn> -->
            <v-btn class="icon" id="end" @click="showSubscriberDialog=true" style="color: brown;" color="error">
                <v-icon color="white">mdi-phone-hangup</v-icon>
            </v-btn>
        </div>
        <v-dialog v-model="showSubscriberDialog"
            width="300px"
        >
            <v-card>
                <v-card-title>Appel terminé</v-card-title>
                <v-card-text>
                <p>Souhaitez-vous vraiment couper l'appel vidéo en cours ?</p>
                </v-card-text>
                <v-card-actions>
                <v-btn color="red" text @click="showSubscriberDialog = false">Non</v-btn>
                <v-btn color="green" text @click="disconnect">Oui</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import OT from '@opentok/client';
export default {
    props: {
        apiKey: String,
        sessionId: String,
        token: String,
        name: String
    },
    data() {
        return {
            audioEnabled: true,
            videoEnabled: true,
            heightPublisher: '100%',
            widthPublisher: '100%',
            withSucriber: '100%',
            heightSucriber: '350px',
            publisher: null,
            //subscriber: null,
            showSubscriberDialog: false
        }
    },
    mounted() {
        this.initializeSession()
    },
    methods: {
    publishingAudio() {
        const audio = this.publisher.getAudioSource()
            this.audioEnabled = !audio.enabled
            this.publisher.publishAudio(this.audioEnabled)
            localStorage.setItem('audioEnabled', JSON.stringify(this.audioEnabled));
    },
    disconnect() {
        if (this.publisher) {
            this.publisher.destroy();
        }
        this.$emit('disconnect')
    },
    chated() {},
    viewFull() {
        this.$router.replace({ name: 'join.meet', params: { sessionId: this.sessionId, name: this.name}})
        this.$router.go(0)
    },
    publishingVideo() {
        const video = this.publisher.getVideoSource()
        video.track ? this.videoEnabled = false : this.videoEnabled = true ;
        this.publisher.publishVideo(this.videoEnabled)
        localStorage.setItem('videoEnabled', JSON.stringify(this.videoEnabled))
    },
    initializeSession() {
        const session = OT.initSession(this.apiKey, this.sessionId);

        // Subscribe to a newly created stream
        session.on('streamCreated', (event) => {
            this.widthPublisher = '150px';
            this.heightPublisher = '100px';
            const subscriberOptions = {
                insertMode: 'append',
                width: this.withSucriber,
                height: this.heightSucriber
            };
            session.subscribe(event.stream, this.$refs.subscriber, subscriberOptions, this.handleError);
        });

        session.on('sessionDisconnected', (event) => {
            console.log('You were disconnected from the session.', event.reason);
        });

        // initialize the publisher
        const publisherOptions = {
            insertMode: 'append',
            width: this.widthPublisher,
            height: this.heightPublisher,
            name: this.name
        };
        this.publisher = OT.initPublisher(this.$refs.publisher, publisherOptions, this.handleError);

        // Connect to the session
        session.connect(this.token, (error) => {
            if (error) {
            this.handleError(error);
            } else {
            // If the connection is successful, publish the publisher to the session
            session.publish(this.publisher, this.handleError);
            }
        });
    },
    handleError(error) {
        if (error) {
            console.error(error);
        }
    }
    }
}
</script>

<style scoped>
#videos {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}
.btn-controls {
    width: 100%;
    position: absolute;
    top: 305px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*background-color: rgb(102, 102, 102);*/
    opacity: 0;
    padding: 5px;
    z-index: 101;
    
}
.btn-controls:hover {
    background-color: rgba(0,0,0,.1);
    opacity: 0.9;
    /*transition: opacity .5s ease 2s;*/
}

/* #subscriber {
    width: 100%;
    height: 100%;
} */

#publisher {
    /*border: 2px solid rgb(243, 243, 243);*/
    position: absolute;
    /* width: 150px;
    height: 100px; */
    top: 0px;
    right: 0px;
    z-index: 100;
    border-radius: 3px;
}

/* 
#subscriber {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}
#publisher {
    position: absolute;
    width: 100px;
    height: 100px;
    bottom: 10px;
    left: 10px;
    z-index: 100;
    border: 3px solid white;
    border-radius: 3px;
} */

/* #videos {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}
#subscriber {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
} */
/* #publisher {
    position: absolute;
    width: 60px;
    height: 40px;
    bottom: 10px;
    left: 10px;
    z-index: 100;
    border: 3px solid white;
    border-radius: 3px;
} */

</style>