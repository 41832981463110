<template>
    <v-app>
      <navbar />
      <v-main>
          <v-container>
            <v-row align="center" justify="center" v-if="paiement">
                <v-col cols="12" md="6">
                    <v-row align="center" justify="center">
                        <v-col cols="12" md="8">
                            <v-card class="rounded-xl" elevation="0">
                                <v-card-title class="primary text-center">
                                    <div class="white--text">Payement avec succès !</div>
                                </v-card-title>
                                <v-divider />
                                <v-card-text>
                                    <p class="text-center">
                                        Merci d'avoir utilisé nos services.
                                    </p>
                                    <v-btn color="success" text style="text-decoration: none; text-transform: none;" link to="/">Retour à l'accueil </v-btn>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                   
                </v-col>
            </v-row>
            
          </v-container>
      </v-main>
    </v-app>
  </template>
  
  <script>
  import navbar from '../../components/Home/utilities/navbar.vue'
  export default {
    components: { navbar },

    data() {
        return {
            paiement: null,
        }
    },
    watch: {
        $route: 'getPaiement'
    },
    mounted(){
        this.getPaiement()
    },
    methods: {
        getPaiement() {
            const id = this.$route.params.id;

            this.$http.get(`paiement/${id}`).then(({ data }) => {
                if(data.data) {
                    this.paiement = data.data
                } else {
                    this.$router.go(-1)
                }
                
            }).catch(() => {

                this.$toast.error({
                    title: 'Paiement',
                    message: 'Paiement Introuvable !!'
                })
                this.$router.go(-1)
                
            })
        }
    }
  
  }
  </script>
  
  <style>
  
  </style>