<template>
    <div>
        <h2 class="mb-3">Nouvelle Viste médicale</h2>
        <v-sheet
            class="pa-6 mb-6"
        >
        <div class="font-6 font-weight-bold mb-3">
            Patient
        </div>
        <v-divider></v-divider>
        <div class="info-patient" v-if="patient">
            <div class="font-3 font-weight-bold">{{ patient.name }}</div>
            <!-- <div class="text-center">{{ patient.age }}</div> -->
            <div class="d-flex">
                <div class="">{{ auteur === 'proche' ? patient.phone : patient.tel_port }}</div>
                <div class=""> / {{ patient.address }}</div>
            </div>
        </div>
        </v-sheet>
        <v-sheet class="pa-6">
            <div class="font-6 font-weight-bold">
                Visite médicale
            </div>
            <v-divider></v-divider>
            <div class="col-md-6">
                <div class="font-7 font-weight-bold">Type de visite médicale</div>
                    <v-autocomplete
                        v-model="type_medical_visit"
                        :items="types"
                        item-text="name"
                        item-value="id"
                        outlined
                        placeholder="Choisir un type de visite médicale"
                        :error-messages="errors.type_medical_visit"
                    >
                    </v-autocomplete>
                    <div class="d-flex">
                        <v-btn style="text-transform: none;margin-right: 20px;" color="primary" elevation="0" @click="save" :loading="loading">Enregistrer</v-btn>
                        <v-btn style="text-transform: none;" color="error" outlined @click="$router.go(-1)"> Annuler</v-btn>
                    </div>
            </div>
        </v-sheet>
    </div>
</template>
<script>
export default {
    data() {
        return {
            auteur: this.$route.params.auteur,
            patient_id: this.$route.params.patient,
            patient: null,
            type_medical_visit: '',
            types: [],
            errors: [],
            loading: false
        }
    },
    mounted() {
        this.getPatient()
        this.getTypes()
    },
    methods: {
        getPatient() {
            this.$http.get(`${this.auteur}/${this.patient_id}`).then(({ data }) => {
                this.patient = data.data
            }).catch(({error}) => {
                this.$toast({
                    title: 'Erreur',
                    message: error.response.error
                })
            })
        },
        getTypes() {
            this.$http.get('medical_visit_types').then(({data}) => {
                this.types = data.data
            })
        },
        save() {
            this.loading = true
            const data = {
                doctor_id: this.$auth.user.doctor_id,
                patient_id: this.auteur === 'patient' ? this.patient.id : this.patient.patient_id,
                proche_id: this.auteur === 'proche' ? this.patient.id : null,
                intervenant_id: this.auteur == 'proche' ? this.patient.intervenant_id : null,
                medical_visit_type_id :this.type_medical_visit,
                note: '',
                aptitude: '',
            }
            this.$http.post('medical_visit', data).then(({data}) => {
                this.$toast.success({
                    title: 'Viste médicale',
                    message: "Une nouvelle visite médicale vient d'être enregistrer avec succès !"
                })
                this.$router.push({ name: 'doctor.visit.medical.view', params: {medical_visit_id: data.id }})
            }).catch((error) => {
                if(error.response.data.abonnement) {
                    this.$toast.info({
                        title: 'Abonnement',
                        message: "Abonnez-vous pour pouvoir utiliser ces resources"
                    })
                    const timer = setInterval(() => {
                        this.$router.push('/abonnement')
                        clearInterval(timer)
                    }, 3000);
                }
                if(error.response.error.message) {
                    this.$toast.error({
                        title: 'Nouvelle Consultation',
                        message: error.response.error.message
                    })
                }
                this.errors = error.response.data.errors
                this.loading = false
            }).finally(() => this.loading = false)
        }
    }
}
</script>