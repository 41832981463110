<template>
    <v-dialog
      v-model="show"
      :loading="loading"
      width="500"
      persistent
      z-index="10"
    >
    <v-card>
      <v-card-title>
        <div class="font-6 font-weight-bold">Langue(s) parlée(s)</div>
        <v-spacer></v-spacer>
        <v-btn  icon color="error" @click="show=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-6">
        
        <v-row>
            <!-- <v-col cols="12" md="12" sm="12">
                <div class="font-7 font-weight-bold">Choisir vos Langues</div>
            </v-col> -->
            <v-col cols="12" md="12" sm="12">
                <v-autocomplete
                    v-model="doctor.availability_langs"
                    :items="langs"
                    label="Langues"
                    chips
                    small-chips
                    placeholder="Selectionnez vos langues"
                    clearable
                    outlined
                    dense
                    multiple
                    :error-messages="errors.availability_langs"
                ></v-autocomplete>
            </v-col>
          
        </v-row>
          <v-row>
            <v-btn color="primary" block style="text-transform: none;" elevation="0" @click="save">Enregistrer</v-btn>
          </v-row>
          
      </v-card-text>
    </v-card>
  
    </v-dialog>
  </template>
  
  <script>
    export default {
      props: {
        value: Boolean,
        availability_langs: Array,
        doctor_id: String
      },
      data() {
        return {
          errors: {},
          loading: true,
          doctor: {},
          langs: [
            "Pulaar",
            "Wolof",
            "Sereer",
            "Joola",
            "Malinké",
            "Soninké",
            "Français",
            "Anglais",
            "Portugais",
            "Allemand",
          ],
          //langs: ["Lundi", "Mardi","Mercredi","Jeudi","Vendredi","Samedi","Dimanche"]
        }
      },
      mounted() {
        try {
          this.doctor.availability_langs = this.availability_langs
        
        } catch (error) {
            console.log(error)
        } finally {
          this.loading = false;
        }
        
      },
      computed: {
        show: {
          get() {
            return this.value;
          },
          set(value) {
            return this.$emit('input', value)
          }
        }
      },
   
      methods: {
        save() {
            this.loading = true
            this.$http.put(`doctor/langs/${this.doctor_id}`,this.doctor).then(( ) => {
              this.$emit('saveLangs', this.doctor);
                this.$toast.success({
                    title: 'Modification horaires',
                    message: 'Les modifications ont été bien prises en charges'
                })
                this.loading = false
                this.show = false
            }).catch((error) => {
                this.loading = false
                this.errors = error.response.data.errors
            })
          
        }
      }
  }
  </script>
  
  <style>
  
  </style>