<template>
    <v-dialog
      v-model="show"
      persistent
      width="500px"
      >
      <v-card>
          <v-card-title>
             {{ formTitle }} 
             <v-spacer></v-spacer>
             <v-btn icon color="error" @click="show=false">
              <v-icon>mdi-close</v-icon>
             </v-btn>
          </v-card-title>
          <v-card-text>
              <v-form>
                    <v-select
                        v-model="bulletin.type"
                        :items="types"
                        outlined
                        label="Type"
                        :error-messages="errors.type"
                    >

                    </v-select>
                  <v-textarea
                      v-model="bulletin.diagnostic"
                      name="diagnostic"
                      label="Diagnostic"
                      outlined
                      :error-messages="errors.diagnostic"
                  ></v-textarea>
                  <v-textarea
                      v-model="bulletin.examen"
                      name="examen"
                      label="Examens"
                      id="examen"
                      outlined
                      :error-messages="errors.examen"
                  ></v-textarea>
                  <!-- <v-textarea
                      v-model="bulletin.resultat"
                      name="resultat"
                      label="Resultat"
                      outlined
                      :error-messages="errors.resultat"
                  ></v-textarea> -->
                 
                  <div class="d-flex">
                      <v-btn 
                          style="text-transform: none;" 
                          color="primary" elevation="0" 
                          class="mr-3" 
                          @click="save" 
                          :loading="loading"
                      >
                          {{ bulletin.id ? 'Modifier' : 'Ajouter' }}
                      </v-btn>
                      <v-btn style="text-transform: none;" color="error" outlined @click="show=false"> Annuler</v-btn>
                  </div>
              </v-form>
          </v-card-text>
      </v-card>
  </v-dialog>
  
  </template>
  
  <script>
 
  export default {
      props: {
          value: Boolean,
          bulletin:{ 
              type: Object,
          }
      },
      data() {
          return {
  
             loading: false,
             errors: {},
             types: ['Analyse','Consultation', 'Radio', 'Autre']
          }
      },
      computed: {
          show: {
              get() {
                  return this.value
              },
              set(value) {
                  return this.$emit('input', value)
                 
              }
          },
          formTitle() {
              if(this.bulletin.id) {
                  return 'Modifier Bulletin'
              } else {
                  return 'Ajouter un Bulletin'
              }
          }
         
      },
      methods: {
          save() {
              if(this.bulletin.id) {
                  this.$http.put(`bulletin/${this.bulletin.id}`, this.bulletin).then(({data}) => {
                      this.$emit('bulletinUpdated', data.data)
                      this.show = false
                  }).catch((error) => {
                      this.errors = error.response.data.errors
                  }).finally(()=> this.loading = false)
              } else {
                  this.$http.post(`bulletin`, this.bulletin).then(( {data}) => {
                      this.$emit('bulletinSaved', data.data)
                      this.show = false
                  }).catch((error) => {
                      this.errors = error.response.data.errors
                  }).finally(()=> this.loading = false)
              }
             
              
          },
         
          
      }
  }
  </script>
  
  <style>
  
  </style>