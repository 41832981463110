<template>
    <div v-if="medical_visit"> 
        <div class="d-flex justify-end  font-7 my-12">
      
      <div>Dakar, le {{ today }}</div>
    
   </div>
  <div style="margin-top:40px">
    <h1 class="text-center mb-12 text-decoration-underline text-uppercase">Certificat d'aptitude physique et mentale</h1>
    <div class="font-7">
        <div>Nous soussignés, Docteur en {{ doctor.department.name }}, {{ doctor.name }} ,</div>
        <div>
           certifie que  {{ medical_visit.proche ? (medical_visit.proche.sexe=='homme' ? 'Mr' : 'M(me)') : (medical_visit.patient.sexe=='homme' ? 'Mr' : 'M(me)') }}  {{ medical_visit.proche ? medical_visit.proche.name : medical_visit.patient.name }}
           Né(e) le  {{ medical_visit.proche_id ? medical_visit.proche.date_naissance : medical_visit.patient.date_naissance }}
            à {{ medical_visit.proche_id ? medical_visit.proche.lieu_naissance : medical_visit.patient.lieu_naissance }}
        </div>
        <div>
            Adresse {{ medical_visit.proche ? medical_visit.proche.address : medical_visit.patient.address }}
        </div>
        <div> {{ medical_visit.aptitude =='Inapte' ? 'NE REPOND PAS' : 'REPOND' }}</div>
        <div>
            aux conditions d’aptitude physique et mentale exigées par la réglementation en vigueur pour
            l’exercice de la fonction occupée à son poste de travail.
        </div>
        <div> 
           <div> Date de l’examen : {{ medical_visit.date_examen }} </div> 
           <div v-if="medical_visit.date_limite"> Limite de validité : {{ medical_visit.date_limite }} </div> 
           <div> Restrictions éventuelles à l’aptitude : {{ medical_visit.note }}</div>
            
        </div>
        
    </div>
  </div> 
  <footer-print :signature_url="doctor.signature_url"/>
    </div>
  </template>
<script>
import footerPrint from '../../components/Home/utilities/footerPrint.vue'
    export default {
  components: { footerPrint },
  props: {doctor: Object},
        data() {
            return {
                medical_visit_id: this.$route.params.medical_visit,
                medical_visit: null,
            }
        },
        async mounted() {
        this.getMedicalVisit()
        
        },
        methods: {
            getMedicalVisit() {
                this.$http.get(`print/medical_visit/${this.medical_visit_id}`).then(({data}) => {
                    this.medical_visit = data
                    setTimeout(() => {
                        window.print()
                    }, 2000);
                    
                })
                
            }
        }

    }
</script>

<style scoped>

</style>