<template>
  <v-container>
  <div class="form-patient mt-6">
    <v-row justify="space-between">
      <v-col cols="12" md="7" v-if="patient">
        <v-sheet class="pa-3">
          <div class="text-center pa-6">
            <update-photo :user="user" />
            <div class="pt-3 font-5 font-weight-bold">{{ patient.name }}</div>
          </div>
          <div class="patient">
            <v-row class="py-2">
              <v-col class="subtitle-2 font-weight-bold">
                Profession
              </v-col>
              <v-col>
                {{ patient.post ? patient.post : '-' }}
              </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-2">
              <v-col class="subtitle-2 font-weight-bold">
                Email
              </v-col>
              <v-col>
                {{ patient.user.email ?  patient.user.email : '-' }}
              </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-2">
              <v-col class="subtitle-2 font-weight-bold">
                Adresse
              </v-col>
              <v-col>
                {{ patient.address ? patient.address : '-'}}
              </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-2">
              <v-col class="subtitle-2 font-weight-bold">
                N° tel port
              </v-col>
              <v-col>
                {{ patient.tel_port ? patient.tel_port : '-' }}
              </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-2">
              <v-col class="subtitle-2 font-weight-bold">
                N° tel dom
              </v-col>
              <v-col>
                {{ patient.tel_dom ? patient.tel_dom : '-' }}
              </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-2">
              <v-col class="subtitle-2 font-weight-bold">
                Date de naissance
              </v-col>
              <v-col>
                {{ patient.formatted_date_naissance ? patient.formatted_date_naissance : '-' }}
              </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-2">
              <v-col class="subtitle-2 font-weight-bold">
                Lieu de naissance
              </v-col>
              <v-col>
                {{ patient.lieu_naissance ? patient.lieu_naissance : '-' }}
              </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-2">
              <v-col class="subtitle-2 font-weight-bold">
                AGE
              </v-col>
              <v-col>
                {{ patient.age !=0 ? patient.AGE : '-' }}
              </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-2">
              <v-col class="subtitle-2 font-weight-bold">
                Entreprise
              </v-col>
              <v-col>
                {{ patient.entreprise ? patient.entreprise.name : '-' }}
              </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-2">
              <v-col class="subtitle-2 font-weight-bold">
                Ajouté 
              </v-col>
              <v-col>
                {{ patient.created_at ? patient.created_at : '-' }}
              </v-col>
            </v-row>
            <div class="py-6">
              <div class="d-flex justify-center">
                <v-btn color="primary" style="text-transform: none" class="mr-3" outlined rounded @click="showForm = true">
                  <v-icon>mdi-pencil</v-icon> Modifier
                </v-btn>
                
              </div>
            </div>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="4">
        <v-sheet class="pa-6 mb-3">
          <h4>Modifier votre mot de passe</h4>
        </v-sheet>
        <v-sheet class="pa-3">
          <v-text-field
            v-model="password_old"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            label="Ancien mot de passe"
            outlined
            dense
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
            :error-messages="errors.current_password"
          ></v-text-field>
          <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            label="Nouveau mot de passe"
            outlined
            dense
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            :error-messages="errors.password"
          ></v-text-field>
          <v-text-field
            v-model="password_confirm"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            label="Confirmer votre mot de passe"
            outlined
            dense
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
            :error-messages="errors.password_confirmation"
          ></v-text-field>
          <v-btn block color="primary" dark outlined class="my-3" @click="changePassword">Enregistrer</v-btn>
          <v-btn block color="error" dark outlined @click="close">Annuler</v-btn>
        </v-sheet>
      </v-col>
    </v-row>
    <div>
      <form-patient v-if="showForm" v-model="showForm" :patient="patient"  @updatedPatient="updatedPatient" />
    </div>
  </div>
</v-container>
</template>
<script>
import FormPatient from '../../components/patient/FormPatient.vue';
import UpdatePhoto from '../../components/Home/utilities/UpdatePhoto.vue';
export default {
  components: { FormPatient, UpdatePhoto },
  data() {
    return {
      show: false,
      show1: false,
      show2: false,
      errors: {},
      showForm: false,
      user: null,
      patient: null,
      password_old: null,
      password: null,
      password_confirm: null,
    };
  },
  mounted() {
    if (this.$auth.check()) {
      this.user = this.$auth.user;
      this.getPatient();
    }
  },
  methods: {
    getPatient() {
      this.$http.get(`auth/patient/${this.$auth.user.patient_id}`).then(({ data }) => {
        this.patient = data.data;
      });
    },
    updatedPatient(data) {
      this.patient = data
      this.$toast.success({
        title:'Modification profil',
        message:'Votre profil a été mise à jour avec succès'
      })
    },
    changePassword() {
      this.errors = {};
      const data = {
        current_password: this.password_old,
        password: this.password,
        password_confirmation: this.password_confirm
      }
      this.$http.post('profile/change-password', data).then(() => {
        this.$toast.success({
        title:'Modification Mot de Passe',
        message:'Votre mot de passe a été mise à jour avec succès'
      })
        this.close()
      }).catch((error) => {
        if(error.response.data.errors) {
          this.errors = error.response.data.errors;
        } else {
          this.$toast.error({
            title: 'Erreur',
            message: 'Une erreur est survenue lors du modification de votre mot de passe'
          })
        }
       
      })
    },
    close() {
      this.password = '';
      this.password_old = '';
      this.password_confirm = '';
      this.errors = {};
    }
  },
};
</script>
<style scoped>
</style>