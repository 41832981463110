<template>
  <div>
    <h1 class="font-weight-bold text-center my-12">Reçu</h1>
    <div class="font-6">
        <div>Date: {{ today }}</div>
        <p>
            Je soussigné {{ facture.doctor.cabinet_name }} avoir reçu {{ facture.proche ? (facture.proche.sexe=='homme' ? 'Mr' : 'M(me)') : (facture.patient.sexe=='homme' ? 'Mr' : 'M(me)') }}  {{ facture.proche ? facture.proche.name : facture.patient.name }}
        </p>
        <div class="mt-6">
            <p>Montant :{{ facture.facture.montant_en_lettre}} ( {{ facture.facture.montant.toLocaleString('fr-FR') }} ) Fcfa</p>
            <p>Motif: {{ type === 'consultation' ? 'Consultation' : 'Visite médicale' }}</p>
        </div>
    </div>

    <footer-print :signature_url="doctor.signature_url"/>
  </div>
</template>

<script>
import footerPrint from '../../components/Home/utilities/footerPrint.vue';
export default {
    props: ['doctor'],
  components: { footerPrint },
    data() {
        return {
            type: this.$route.params.type,
            id: this.$route.params.id,
            facture: null,
        }
    },
    computed: {
    today() {

            // Créer une instance de la date actuelle
      const date = new Date();

      // Obtenir les informations de la date
      const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

      // Convertir la date en chaîne de caractères
      const dateEnFrancais = date.toLocaleDateString('fr-FR', options);
      return dateEnFrancais;
    }
  },
    mounted() {
       this.getFacture()
    },
    methods: {
        getFacture() {
            this.$http.get(`print/facture?id=${this.id}&type=${this.type}`).then(({ data }) => {
                this.facture = data
                setTimeout(() => {
                    window.print()
                }, 2000);
            })
        }
    }
}
</script>

<style>

</style>