var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-sheet',{staticClass:"pa-6"},[(_vm.$auth.user.is_doctor)?_c('div',{staticClass:"d-flex justify-end my-3"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary","link":"","to":{ 
							name: 'doctor.consultation.patient.create', 
							params: { 
								patient: _vm.patient_id,
								auteur: _vm.auteur
							},
							
						}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Nouvelle consultation ")],1)],1):_vm._e(),_c('table-consultations',{attrs:{"loading":_vm.loading,"consultations":_vm.consultations,"headers":_vm.headers,"page":_vm.current_page,"page-count":_vm.last_page,"items-per-page":_vm.per_page},on:{"pagination":_vm.getConsultations}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }