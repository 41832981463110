<template>
    <drawer :links="links" />
  </template>
<script>
    import Drawer from '../../components/Home/utilities/Drawer.vue';
    export default {
        components: {Drawer},
    data: () => ({
      links: [
      
        [ "mdi-view-dashboard","Tableau de bord", "doctor.home"],
        ["mdi-account-circle", "Mon profil", "doctor.profil"],
        [ "mdi-doctor", "Mes Paramètres","doctor.parametre" ],
        [ "mdi-calendar-clock", "Rendez-vous","doctor.appointments" ],
        [ "mdi-clipboard-pulse", "Consultations","doctor.consultations" ],
        [ "mdi-stethoscope", "Visites Médicales","doctor.visite-medicales" ],
        [ "mdi-account-tie-woman", "Secrétaire","doctor.secretaria" ],
        [ "mdi-account-multiple", "Patients","doctor.patients" ],
        // [ "mdi-share-variant", "Fichiers Partagés", "doctor.shared.files"],
        [ "mdi-sofa", "Salle d'attente téléconsultation","doctor.room" ],





      
      ],
    }),
   
  };
  </script>