<template>
  <div> 
    <v-row align-content="space-between">
      <v-col cols="12" md="5" class="mr-3">
        <div class="font-6 font-weight-bold mb-6">Ajouter champs</div>
        <v-form>
        <div class="font-7 font-weight-bold">Libelle</div>
          <v-text-field
            v-model="field.label"
            name="label"
            placeholder="label"
            id="label"
            outlined
            :error-messages="errors.label"
            dense
          ></v-text-field>
          <div class="font-7 font-weight-bold">Selectionner Type</div>
          <v-select
            v-model="field.type"
            :items="types"
            item-text="name"
            item-value="slug"
            name="type"
            placeholder="type"
            id="type"
            outlined
            :error-messages="errors.type"
            @change="typeChange"
            dense
          ></v-select>
          <v-combobox
            v-if="field.is_select"
            v-model="field.options"
            placeholder="Ajouter une (des) option(s)"
            :prepend-inner-icon="field.type !='radio' ? 'mdi-checkbox-multiple-marked' : 'mdi-checkbox-multiple-marked-circle'"
            chips
            deletable-chips
            multiple
            small-chips
            dense
            outlined
            :error-messages="errors.options"
          >
          </v-combobox>

          <div class="d-flex">
            <v-btn style="text-transform: none;" color="primary" elevation="0" class="mr-3" @click="save">{{ title }}</v-btn>
            <v-btn style="text-transform: none;" color="error" outlined @click="clearForm"> Annuler</v-btn>
        </div>
        </v-form>
      </v-col>
      <v-col>
        <div class="font-6 font-weight-bold mb-6">Liste des Champs</div>
        <v-row justify-space-between class="mb-3">
            <v-col>
                <div class="font-7 font-weight-bold">Libelle</div>
            </v-col>
            <v-col cols="12" md="4">
                <div class="font-7 font-weight-bold">Type</div>
            </v-col>
            <v-col cols="12" md="3">
                <div class="font-7 font-weight-bold">Actions</div>
            </v-col>
        </v-row>

        <item-field 
            v-for="field in fields"
            :key="field.id"
            :field="field"
            @updatedField="updatedField"
            @deletedField="deletedField"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ItemField from './ItemField.vue'
  export default {
  components: { ItemField },
    data() {
      return {
        types: [
          {
            name: 'Text Simple',
            slug: 'text'
          },
          {
            name: 'Text long',
            slug: 'textarea'
          },
          {
            name: 'Case(s) à cocher',
            slug: 'checkbox'
          },
          {
            name: 'Case(s) à option(s)',
            slug: 'radio'
          },
          {
            name: 'Date',
            slug: 'date'
          }

        ],
        field: {
          id: null,
          label:'',
          type: '',
          form_id: this.$route.params.id,
          is_select: false,
          options: []
        },
        fields: [],
        errors:{},
        title: 'Ajouter'
      }
    },
    watch: {
      $route: 'getField'
    },
    mounted() {
      this.getField()
    },
    methods: {
        getField() {
            const form = this.$route.params.id
            this.$http.get(`form/${form}`).then(({ data }) => {
                this.fields = data.data.fields
            }).catch(() => {
                this.$toast.error({
                    title: 'Erreur',
                    messages: 'Impossible accéder aux resources'
                })
            })
        },
        typeChange() {
            if(this.field.type === 'radio' || this.field.type === 'checkbox') {
                this.field.is_select = true
            } else {
                this.field.is_select = false
            }
        },
        clearForm() {
            this. field = {
                id: null,
                type: '',
                label: '',
                form_id: this.$route.params.id,
                is_select: false,
                options: []
            }
            this.title = 'Ajouter'
            this.errors = {}
        },
        updatedField(data) {
            this.field = Object.assign({}, data)
            this.title = 'Modifier'
        },
        deletedField(data) {
            this.fields = this.fields.filter((field) => field.id != data.id);
        },
        save() {
            if(this.field.id) {

               this.$http.put(`field/${this.field.id}`, this.field).then(({ data }) => {
                const index = this.fields.findIndex((field) => field.id == this.field.id)
                    this.fields[index] = data.data
                    this.$toast.success({
                        title: 'Modification Champs',
                        message: 'Votre champs a été modifié avec succès !'
                    })
                    this.clearForm()
                }).catch((error) => {
                    this.errors = error.response.data.errors
                })
            } else {
               this.$http.post(`field`, this.field).then(({ data }) => {
                    this.fields.unshift(data.data)
                    this.$toast.success({
                        title: 'Création Champs',
                        message: 'Votre formulaire a été crée avec succès !'
                    })
                    this.clearForm()
                }).catch((error) => {
                    this.errors = error.response.data.errors
                })
            }
        }

    }
  }
</script>

<style>

</style>