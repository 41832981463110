<template>
    <div>
      <!-- Motifs doctors -->
      <div class="motifs mb-6">
            <card-title :title="'Prestations de Rendez-vous'" :icon="'mdi-pulse'" >
            <v-btn style="text-transform:none" color="primary" elevation="0" outlined @click="showFormMotif=true">Ajouter une prestation</v-btn>
            </card-title>
            <motifs :motifs="doctor.motifs"  @deletedMotif="deletedMotif" />
      </div> 
        <!-- Formulaire motif de rendez-vous -->
    <form-motif 
        v-if="showFormMotif" 
        v-model="showFormMotif" 
        @createdMotif="createdMotif"
       
    />
    </div>
  </template>
  
  <script>
import FormMotif from '../../../components/doctor/FormMotif.vue'
import Motifs from '../../../components/doctor/Motifs.vue'
import cardTitle from '../../../components/Home/utilities/cardTitle.vue'
  export default {
    props: ['doctor'],
    components: { cardTitle, Motifs, FormMotif },
  data() {
    return {
      showFormMotif: false,
    }
  },
  methods: {
    createdMotif(motif) {
      this.doctor.motifs.push(motif)  
    },
    deletedMotif(motifs) {
      this.doctor.motifs = motifs
    }
  }
  
  }
  </script>
  
  <style>
  
  </style>