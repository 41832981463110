<template>
  <div style="display: flex; width: 100%">
    <v-stepper v-model="e1" style="width: 100%">
      <v-stepper-items v-for="n in steps" :key="`${n}-content`">
        <v-stepper-content v-if="n == 1" :step="n">
          <div
            style="
              display: flex;
              width: 100%;
              flex-direction: column;
              margin: 0%;
            "
          >
            <v-form
              style="
                display: flex;
                width: 100%;
                flex-direction: column;
                margin: 0%;
              "
              ref="form"
            >
              <div class="font-6 textpraticien mb-6" style="color: green">
                <div>
                  Vous êtes praticien ? Un membre de notre équipe vous
                  contactera.
                </div>
              </div>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="first_name"
                    label="Nom"
                    outlined
                    required
                    :error-messages="errors.first_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="last_name"
                    label="Prénom"
                    outlined
                    required
                    :error-messages="errors.last_name"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="cabinet_name"
                    label="Nom de la structure"
                    outlined
                    required
                    :error-messages="errors.cabinet_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="address_cabinet"
                    label="Adresse professionnelle"
                    outlined
                    required
                    :error-messages="errors.address_cabinet"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <vue-tel-input-vuetify
                    v-model="phone"
                    label="Téléphone"
                    outlined
                    mode="international"
                    ref="telInput"
                    @validate="validatePhoneNumber"
                    required
                    :error-messages="errors.phone"
                  ></vue-tel-input-vuetify>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="email"
                    label="Votre adresse mail"
                    outlined
                    required
                    :error-messages="errors.email"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="department_id"
                    :items="listeDepartement"
                    item-text="name"
                    item-value="id"
                    label="Veuillez indiquer votre spécialité"
                    outlined
                    required
                    :error-messages="errors.address_cabinet"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="6">
                  <v-autocomplete
                    v-model="villeChoisie"
                    :items="villesSenegal"
                    outlined
                    :error-messages="errors.ville"
                    label="Votre Région"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </div>

          <v-btn
            color="primary"
            @click="nextStep(n)"
            style="text-transform: none"
          >
            Continuer
          </v-btn>
        </v-stepper-content>

        <v-stepper-content :step="n + 1">
          <div
            style="
              display: flex;
              width: 100%;
              flex-direction: column;
              margin: 0%;
            "
          >
            <v-form
              style="
                display: flex;
                width: 100%;
                flex-direction: column;
                margin: 0%;
              "
              ref="form"
            >
              <div class="file">
                <div
                  class="textpraticien"
                  style="color: green; text-align: center"
                >
                  <div>Chargez les fichiers pour valider votre inscription</div>
                </div>
                <div ref="form" class="fildcard">
                  <v-row
                    class="filerow"
                    style="margin-bottom: 2.3%; margin-top: 2%"
                  >
                    <v-col cols="12" md="12">
                      <v-file-input
                        v-model="cni_file"
                        label="Carte d'identité ou du passeport"
                        outlined
                        :error-messages="errors.cni_file"
                      ></v-file-input>
                    </v-col>
                  </v-row>

                  <v-row class="filerow" style="margin-bottom: 3%">
                    <v-col cols="12" md="12">
                      <v-file-input
                        v-model="diplome_file"
                        label="diplôme de médecin ou équivalent"
                        outlined
                        :error-messages="errors.diplome_file"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-row class="filerow" style="margin-bottom: 3%">
                    <v-col cols="12" md="12">
                      <v-file-input
                        v-model="autorisation_file"
                        label="l'ordre des médecins"
                        outlined
                        :error-messages="errors.autorisation_file"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-form>
          </div>

          <div
            style="
              display: flex;
              justify-content: space-between;
              margin-bottom: 2%;
            "
          >
            <v-btn
              style="color: red; text-transform: none"
              @click="backStep(n)"
              elevation="0"
            >
              Retour
            </v-btn>

            <v-btn
              color="primary"
              style="text-transform: none"
              @click="saveDoctor"
              elevation="0"
              :loading="loading"
            >
              S'inscrire
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    valid: true,
    validPhone: false,
    message: "",
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    cabinet_name: "",
    address_cabinet: "",
    department_id: "",
    listeDepartement: [],
    errors: {},
    dialogfile: false,
    fordoc: true,
    cni_file: null,
    diplome_file: null,
    autorisation_file: null,
    // ordreDesMedcin_file: '',
    villeChoisie: null,
    e1: 1,
    e2: 1,
    steps: 2,
    villesSenegal: [
      "Dakar",
      "Diourbel",
      "Fatick",
      "Kaffrine",
      "Kaolack",
      "Kédougou",
      "Kolda",
      "Louga",
      " Matam",
      "Saint-Louis",
      "Sédhiou",
      "Tambacounda",
      "Thiès",
      "Ziguinchor",
    ],
  }),
  created() {
    this.departmentListe();
  },

  methods: {
    departmentListe() {
      //this.loading = true
      this.$http.get(`auth/departments`).then(({ data }) => {
        this.listeDepartement = data.data;
      });
    },

    saveDoctor() {
      if (!this.validPhone) {
        this.errors.phone = ["Le numéro de téléphone est incorrect."];
        this.loading = false;
        this.e1 = 1;
        return;
      }
        if(this.phone) {
            this.phone = this.phone.match(/\S+/g).join("");
        }
      
      if (
        (this.first_name == "") |
        (this.last_name == "") |
        (this.phone == "") |
        (this.email == "") |
        (this.cabinet_name == "") |
        (this.address_cabinet == "") |
        (this.villeChoisie == "")
      ) {
        this.e1 = 1;
      }
      this.loading = true;
      let formData = new FormData();
      formData.append("first_name", this.first_name);
      formData.append("last_name", this.last_name);
      formData.append("phone", this.phone);
      formData.append("email", this.email);
      formData.append("cabinet_name", this.cabinet_name);
      formData.append("address_cabinet", this.address_cabinet);
      formData.append("department_id", this.department_id);
      formData.append("ville", this.villeChoisie);
      formData.append("cni_file", this.cni_file);
      formData.append("diplome_file", this.diplome_file);
      formData.append("autorisation_file", this.autorisation_file);
      formData.append("phone_valid", this.validPhone);
      const baseURI = "auth/doctor/register";
      this.$http
        .post(baseURI, formData)
        .then(() => {
            this.loading = false
          this.ressetForm();
          (this.e1 = 1),
            this.$toast.success({
              title: "Inscription docteur",
              message: "Merci d'attendre la validation de votre compte",
            });
        })
        .catch((error) => {
            this.loading = false
          this.errors = error.response.data.errors;
          if (error.response.data.errors.email) {
            this.e1 = 1;
          }
          if (error.response.data.errors.phone) {
            this.e1 = 1;
          }
        });
    },

    validatePhoneNumber(event) {
      const isValid = event.isValid;
      this.validPhone = isValid;
      
    },
    handleNumber(event) {
        console.log('change valide',event)
    },
    ressetForm() {
      this.first_name = null
        this.last_name = null
        this.phone = null
        this.cabinet_name = null
        this.address_cabinet = null
        this.department_id = null
        this.listeDepartement = null
        this.email = null
        this.cni_file = null
        this.diplome_file = null
        this.autorisation_file = null
        this.villeChoisie = null
        this.validPhone = false
    },

    formFile() {
      if (!this.dialogfile) {
        this.dialogfile = true;
        this.fordoc = false;
      } else {
        this.dialogfile = false;
        this.fordoc = true;
      }
    },
    nextStep(n) {
       
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },

    backStep(n) {
      let newn = n;
      n = newn - 1;
      this.e1 = this.e1 - 1;
    },
  },

  // watch: {
  //   steps (val) {
  //     if (this.e1 >  val) {
  //       this.e1 = val
  //     }
  //   },
  // },
};
</script>
<style scoped></style>
