<template>
  <div class="parametres">
    <v-tabs v-model="tab" centered @change="changeTab">
      <v-tab v-for="roleTab in roleTabs" :key="roleTab.value" :href="roleTab.href" :tab-value="roleTab.value">
        {{ roleTab.label }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="roleTab in roleTabs" :key="roleTab.value" :value="roleTab.value">
        <v-row align="end" justify="end">
          <v-col cols="12" md="6">
            <v-text-field v-model="key" :placeholder="`Rechercher un ${roleTab.label} par nom ou numero téléphone`" outlined dense clearable @input="search"/>
          </v-col>
          <!-- <v-col cols="12" md="4">
            <v-text-field v-model="phone" :placeholder="`Rechercher un ${roleTab.label} par tél`" outlined dense clearable @input="search"/>
          </v-col> -->
        </v-row>
       
        <UsersListe v-if="users" :users="users" />
        <vue-infinite-loading @infinite="loadMoreUsers" spinner="spiral" v-if="users.length">
          <span slot="no-more">•••</span>
          <span slot="no-results" > ...
            <!-- <v-alert color="info" type="info" >
              Vous n'avez pas encore de {{ roleTab.label }}.
            </v-alert> -->
          </span>
          <span slot="spinner">Chargement...</span>
        </vue-infinite-loading>
        
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import UsersListe from './UsersListe.vue'
import VueInfiniteLoading from 'vue-infinite-loading'

export default {
  components: { VueInfiniteLoading, UsersListe },
  data() {
    return {
      tab: 'MEDECIN',
      page: 1,
      key: null,
      phone: null,
      users: [],
      roleTabs: [
        { label: 'Doctor', value: 'MEDECIN', href: '#doctor-list' },
        { label: 'Patient', value: 'PATIENT', href: '#patient-list' },
        { label: 'Intervenant', value: 'INTERVENANT', href: '#intervenant-list' },
      ],
    }
  },
  mounted() {
    this.loadMoreUsers({})
  },
  
  methods: {
    loadMoreUsers($state) {
      this.$http.get('users/role', { params: { role: this.tab, page: this.page, key: this.key} })
        .then(({ data }) => {
          if (data.data.length > 0) {
            this.users.push(...data.data)
            this.page++
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch(error => {
          if (error.response.data.message) {
            this.$toast.error({
              title: 'Erreur',
              message: error.response.data.message
            })
          }
        })
    },
    changeTab(role) {
      this.tab = role
        this.users = []
        this.page = 1
        this.loadMoreUsers({})
    },
    search() {
      if(this.key) {
        this.$http.get('users/role', { params: { role: this.tab, key: this.key } })
        .then(({ data }) => {
          this.users = data.data
        })
      } else{
        this.loadMoreUsers({})
      }
    }
  },
}
</script>

<style scoped>
.bar-doctor {
  font-weight: bold;
  display: flex;
  background-color: #025397;
  width: 100%;
  padding: 20px;
  color: white;
}
.theme--light.v-tabs-items {
  padding: 20px 0px;
  background-color: #f5f8fa;
}
</style>
