<template>
  <div>
    <!-- Parcours Doctor -->
    <div class="parcours mb-6">
      <card-title :title="'Diplômes et Formations'" :icon="'mdi-account-school'">
        <v-btn style="text-transform:none" color="primary" elevation="0" outlined @click="showFormCareer = true">Ajouter un
          parcours</v-btn>
      </card-title>
      <careers :careers="doctor.careers" @deletedCareer="deletedCareer" />
    </div>
    <!-- Formulaire Career -->
    <form-career v-if="showFormCareer" v-model="showFormCareer" @createdCareer="createdCareer" />
  </div>
</template>
  
<script>
import Careers from '../../../components/doctor/Careers.vue'
import FormCareer from '../../../components/doctor/FormCareer.vue'
import cardTitle from '../../../components/Home/utilities/cardTitle.vue'
export default {
  components: { cardTitle, Careers, FormCareer },
  props: ['doctor'],
  data() {
    return {
      showFormCareer: false
    }
  },
  methods: {
    createdCareer(career) {
      this.doctor.careers.push(career)

    },
    deletedCareer(careers) {
      this.doctor.careers = careers
    }
  }
}
</script>
  
<style></style>