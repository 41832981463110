<template>
  <div>
    <div class="btn-header">
      <div class="row justify-space-between">
        <v-col cols="12" md="3" class="pa-6">
          <v-text-field v-model="first_name" placeholder="Rechercher par prénom" outlined clearable dense
            @input="search" />
        </v-col>
        <v-col cols="12" md="3" class="pa-6">
          <v-text-field v-model="last_name" placeholder="Rechercher par nom" outlined clearable dense @input="search" />
        </v-col>
        <v-col cols="12" md="4">
          <div class="d-flex justify-end">
            <v-btn color="primary" style="text-transform: none;margin-right: 24px;" @click="importing = true">
              <v-icon class="mr-1">mdi-file-import</v-icon>
              Importer des patients
            </v-btn>
            <v-btn color="primary" style="text-transform: none;" @click="showFormPatient = true">
              <v-icon class="mr-1">mdi-account-plus</v-icon>
              Ajouter un patient
            </v-btn>
          </div>

        </v-col>
      </div>
      <listes-patiens :patients="patients" @viewProfile="viewPatient" />

    </div>
    <vue-infinite-loading @infinite="loadMorePatients" spinner="spiral">
        <span slot="no-more">•••</span>
        <span slot="no-results">
          <v-alert color="info" type="info" >
            Vous n'avez pas encore de patients .
          </v-alert>
        </span>
        <span slot="spinner">Chargement...</span>
    </vue-infinite-loading>
      <div class="text-center" v-if="key && patients.length == 0">
        <v-alert color="info" type="info">
          Aucun patient consernant votre recherche.
        </v-alert>
      </div>
    <form-patient :patient="patient" v-if="showFormPatient" v-model="showFormPatient" @createPatient="createPatient"/>
    <v-dialog 
      v-model="importing" 
      persistent
      max-width="800"
    >
      <v-card>
        <v-card-title>
          <span class="font-7">Importer des patients</span>
          <v-spacer></v-spacer>
          <v-btn color="error" icon @click="closeImport" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <div class="d-flex justify-space-between mb-3">
            <v-btn outlined style="text-transform: none;" color="info" @click="onpenFile">
              <v-icon>mdi-file-import</v-icon>
              Importer des patients
            </v-btn>
            <input type="file" ref="uploadFile" id="import_patients" @change="event => loadCSV(event)" style="display: none;">

            <v-btn 
              outlined 
              style="text-transform: none;" 
              color="primary" 
              :loading="loading" 
              :disabled="!csv_content.length" 
              @click=" importPatients"
            >
              Enregistrer
            </v-btn>
          </div>
          
          <div v-if="csv_content.length" style="overflow-x:auto;">
            <v-data-table
              :headers="header_content"
              :items="csv_content"
              :items-per-page="20"
            />

          </div>

        </v-card-text>
      </v-card>
     
    </v-dialog>
  </div>
</template>

<script>
import VueInfiniteLoading from 'vue-infinite-loading';
import FormPatient from '../../components/patient/FormPatient.vue';
import ListesPatiens from '../../components/patient/listesPatiens.vue';
export default {
  components: { FormPatient,VueInfiniteLoading,
    ListesPatiens, },
  data() {
    return {
      loading: false,
      showDialog: false,
      key: '',
      first_name: null,
      last_name: null,
      patients: [],
      importing: false,
      loading_patients: false,
      header_content: [],
      csv_content: [],
      page: 1,
      showFormPatient: false,
      patient: {

      }
    }
  },
  methods: {
    loadMorePatients($state) {
      //const patient_id = this.$auth.user.patient_id;
      this.$http.get(`patients/${this.$auth.user.entreprise_id}/entreprise`,{ params: { page: this.page } }).then(({ data }) => {
            if (data.data.length > 0) {
            this.patients.push(...data.data);
            
            this.page++;
            $state.loaded();
          } else {
            $state.complete();
          }
        //this.proches = res.data.data;
      }).catch(() => {
      })
    },
    onpenFile() {
      this.$refs.uploadFile.click()
    },
    getPatients() {
      this.loading = true
      this.$http.get(`patients/${this.$auth.user.entreprise_id}/entreprise`).then(({ data }) => {
        this.patients = data.data
        this.loading = false
      }).catch((error) => {
        this.loading = false
        if (error.response.data.message) {
          this.$toast.error({
            title: 'Erreur',
            message: error.response.data.message
          })
        }
      })
    },
    search(key) {
      if (key) {
        this.$http.get('search/patients/entreprise',{ params: {first_name: this.first_name, last_name: this.last_name}}).then(({data})=> {
          this.patients = data.data
        }).catch(() => {

        })
      } else {
      this.loadMoreProches
       //this.$refs.loader.$emit('$InfiniteLoading:loaded')
      }

    },
    toJson(csv) {
      let lines = csv.split("\n")
      let result = []
      const headers = this.getLine(lines[0])
      if (headers !== undefined) {
        headers.map(header => {
          this.header_content.push({
            text: header.trim(),
            value: header.toUpperCase().trim()
          })
        })
      }
      lines.forEach((line, lineIndex) => {
        if (lineIndex < 1) return
        let obj = new Object()
        let currentLine = this.getLine(line)
        headers.forEach((header, headerIndex) => {
          if (header && header.trim() && currentLine !== undefined)
            obj[header.trim()] = currentLine[headerIndex]
        })
        result.push(obj)
      })
      return result
    },
    loadCSV(e) {
      this.loading_patients = true
      this.header_content = []
      this.csv_content = []

      if (window.FileReader) {
        let reader = new FileReader()

        let extension = e.target.files[0].name.split('.').pop()

        if (extension.includes('csv')) {
          reader.readAsText(e.target.files[0])
          reader.onload = (event) => {
            let csv = event.target.result
            this.csv_content = this.toJson(csv)
            console.log(this.csv_content)
            this.loading_patients = false
          }
          reader.onerror = (evt) => {
            if (evt.target.error.name == 'NotReadableError') {
              this.$toast.error({
                title: 'Importation patients',
                message: 'Le fichier ne peut être lu'
              })
              // this.$handleMessage('Le fichier ne peut être lu', 'danger')
            }
          }
        } else {
          this.$toast.error({
            title: 'Importation patients',
            message: 'Vous devez utiliser un fichier csv'
          })
          //this.$handleMessage('Vous devez utiliser un fichier csv', 'danger')
          return;
        }
      } else {
        this.$toast.error({
          title: 'Importation patients',
          message: 'FileReader is not supported on this browser'
        })
        //this.$handleMessage('FileReader is not supported on this browser :(', 'danger')
      }
    },
    importPatients () {
      this.loading = true 
      this.$http.post(`import/patients/${this.$auth.user.entreprise_id}/entreprise`, {
                 params: {patients: this.csv_content}
             }).then(({data}) => {
               console.log(data)
                 this.getPatients()
                 this.$toast.success({
                   title: 'Importation patients',
                   message: "patients importés avec succès"
                 })
             }).catch(({message}) => {
               console.log(message)
               this.$toast.error({
                 title: 'Import patient',
                 message: "Une erreur est survenue pendant l'importation des patients"
               })
               
                 //this.$handleMessage(`Une erreur est survenue pendant l'importation des patients`, 'danger')
             }).finally(() => {
               this.closeImport()
                 //this.loading = false
             })
    },
    closeImport() {
           this.csv_content = []
           this.header_content = []
           this.importing = false
           this.loading = false
    },
    getLine (line) {
             if (line.includes(',')) { 
                 return line.split(',')
             } else if (line.includes(';')) { 
                 return line.split(';') 
             }
    },
    viewPatient(patient) {
     this.$router.push({ name: 'entreprise.patient.detail', params: {id: patient.id }})
    },
    createPatient(patient) {
      
      this.patients.unshift(patient)
      this.$toast.success({
        title: 'Ajout patient',
        message: 'Patient Ajouter avec succès !'
      })
    }
  },
}
</script>

