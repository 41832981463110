<template>
  <v-dialog
    v-model="show"
    persistent
    width="500px"
  >
  <v-card>
    <v-card-title>
      <div class="font-6 font-weight-bold">
        Ajout de formulaire
      </div>
      <v-spacer></v-spacer>
      <v-btn icon color="error" @click="show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
        <div class="font-7 font-weight-bold">
            Selectionner un formulaire
        </div>
        <v-autocomplete
            v-model="form"
            outlined
            :items="items"
            item-text="name"
            item-value="id"
            placeholder="Selectionner un formulaire"
        ></v-autocomplete>

        <div class="d-flex">
            <v-btn style="text-transform: none;margin-right: 15px;" color="primary" elevation="0" @click="save">Ajouter</v-btn>
            <v-btn style="text-transform: none;" color="error" outlined @click="show = false"> Annuler</v-btn>
        </div>

    </v-card-text>
  </v-card>
  <v-form>
    
  </v-form>
  </v-dialog>
</template>

<script>
export default {
    props: { value: Boolean, items: Array },
    data() {
        return {
            form: null,
        }
    },
    
    computed: {
        show: {
        get() {
            return this.value;
        },
        set(value) {
            return this.$emit('input', value)
        }
    }
    },
    mounted() {
        //this.getForms()
        
    },
    methods: {
        
        save(){
            console.log(this.items);
        console.log('tested');
            this.$emit('formAdd', this.form);
                this.show = false 
        }
    }
 }

</script>

<style>

</style>