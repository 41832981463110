<template>
  <v-dialog
    v-model="show"
    persistent
    width="500px"
    >
    <v-card>
        <v-card-title>
           {{ formTitle }} 
           <v-spacer></v-spacer>
           <v-btn icon color="error" @click="show=false">
            <v-icon>mdi-close</v-icon>
           </v-btn>
        </v-card-title>
        <v-card-text>
            <v-form>
                <v-text-field
                    v-model="facture.montant"
                    name="montant"
                    label="Montant(en chiffre)"
                    id="montant"
                    type="number"
                    hide-spin-buttons
                    outlined
                    @input="nombre_en_lettres"
                    :error-messages="errors.montant"
                ></v-text-field>
                <v-text-field
                    v-model="facture.montant_en_lettre"
                    name="montant_en_lettre"
                    label="Montant(en lettre)"
                    id="montant_en_lettre"
                    outlined
                    :error-messages="errors.montant_en_lettre"
                ></v-text-field>
                <v-switch
                v-model="facture.payee"
                label="Payee"
                ></v-switch>
                <div class="d-flex">
                    <v-btn 
                        style="text-transform: none;" 
                        color="primary" elevation="0" 
                        class="mr-3" 
                        @click="save" 
                        :loading="loading"
                    >
                    <v-icon>{{ facture.id ? 'mdi-pencil' : 'mdi-plus' }}</v-icon>
                        {{ facture.id ? 'Modifier' : 'Ajouter' }}
                    </v-btn>
                    <v-btn v-if="(facture.id) && (facture.payee)" style="text-transform: none; margin-right: 12px ;" color="info" outlined @click="printFacture"> 
                        <v-icon>mdi-printer</v-icon> Imprimer
                    </v-btn>
                    <v-btn style="text-transform: none;" color="error" outlined @click="show=false">
                        <v-icon>mdi-close </v-icon>
                         Annuler
                    </v-btn>
                </div>
            </v-form>
        </v-card-text>
    </v-card>
</v-dialog>

</template>

<script>
import { NumberToLetter } from 'convertir-nombre-lettre';
//import numberToWords from 'number-to-words';
export default {
    props: {
        value: Boolean,
        facture:{ 
            type: Object,
        }
    },
    data() {
        return {

           loading: false,
           errors: {}
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                return this.$emit('input', value)
               
            }
        },
        formTitle() {
            if(this.facture.id) {
                return 'Modifier la facture'
            } else {
                return 'Ajouter une facture'
            }
        },
        
       
    },
    methods: {
        save() {
            if(this.facture.id) {
                this.$http.put(`facture/${this.facture.id}`, {
                    montant: this.facture.montant,
                    montant_en_lettre: this.facture.montant_en_lettre,
                    payee: this.facture.payee
                }).then(({data}) => {
                    
                    this.$emit('factureSaved', data.data)
                    this.show = false
                }).catch((error) => {
                    this.errors = error.response.data.errors
                }).finally(()=> this.loading = false)
            } else {
                this.$http.post(`facture`, this.facture).then(({data}) => {
                    this.$emit('factureSaved', data.data)
                    this.show = false
                }).catch((error) => {
                    this.errors = error.response.data.errors
                }).finally(()=> this.loading = false)
            }
           
            
        },
        nombre_en_lettres() {
        this.facture.montant_en_lettre = NumberToLetter(this.facture.montant);
        return this.facture.montant_en_lettre;
        },
        printFacture() {

            let type;
            let id;
            if(this.facture.payee) {
                type = this.facture.is_consultation ? 'consultation' : 'medical_visit';
                id = this.facture.is_consultation ? this.facture.consultation_id : this.facture.medical_visit_id;
            } else {
               this.$toast.info({
                title: 'Facture',
                message: 'Veuillez enregistrer la facture pour pouvoir imprimer la facture !!!'
               })
            }
            
            window.open(`/printer/facture/${type}/${id}`, 'printWindow', 'height=800,width=900, align: center')
        }
        
    }
}
</script>

<style>

</style>