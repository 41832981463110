<template>
    <div>
        <div class="simple-example">
            <div v-if="doctor.horaire_teleconsultation.length || doctor.horaire_cabinet.length != 0 || doctor.horaire_teleexpertise">
                <v-radio-group v-model="typeRV" row @change="changeTypeRv">
                    <v-radio value="cabinet" v-if="doctor.horaire_cabinet.length != 0">
                        <template #label>
                            <div>
                                <v-icon color="primary">mdi-home-outlined</v-icon> Au Cabinet
                            </div>
                        </template>
                    </v-radio>
                    <v-radio value="telemedecine" v-if="doctor.horaire_teleconsultation.length">
                        <template v-slot:label>
                            <div><v-icon>mdi-video-outlined</v-icon> Consultation vidéo</div>
                        </template>
                    </v-radio>
                    <v-radio value="teleexpertise"
                        v-if="doctor.horaire_teleexpertise.length && ($auth.check() && $auth.user.is_intervenant)">
                        <template v-slot:label>
                            <div><v-icon>mdi-video-outlined</v-icon> Téléexpertise</div>
                        </template>
                    </v-radio>
                </v-radio-group>
            </div>
            <vue-meeting-selector class="simple-example__meeting-selector" v-model="meeting" :date="date" :loading="loading"
                :class-names="classNames" :meetings-days="meetingsDays" :calendar-options="calendarOptions"
                @next-date="nextDate" @previous-date="previousDate" @change="selectedDate">

            </vue-meeting-selector>
        </div>
    </div>
</template>
      
<script>
import VueMeetingSelector from "vue-meeting-selector";

export default {
    components: {
        VueMeetingSelector,
    },
    props: {
        doctor: Object,
        type_rv: { type: String, default: () => null }
    },
    data() {
        return {
            date: new Date(),
            horaires: [],
            typeRV: this.type_rv,
            meeting: null,
            loading: false,
            daysFilters: [],
            meetingsDays: [],
            appointments: [],
            calendarOptions: {
                daysLabel: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
            },
        };
    },
    computed: {
        // because of line-height, font-type you might need to change top value
        classNames() {
            return {
                tabLoading: "loading-div",
                tabMeetingButton: this.typeRV === 'cabinet' ? "meeting-btn" : 'meeting-btn-video',
                tabPaginationPreviousButton: "pagination-prev-btn",
                tabPaginationNextButton: "pagination-next-btn",
                tabPaginationUpButton: "pagination-next-btn",
                tabPaginationDownButton: "pagination-next-btn",
                tabMeeting: 'meeting'
            };
        },
    },
    methods: {
        getMeetings(date) {
            const myDate = date.toString();
            this.meetingsDays = [];
            this.meetingsDays.push(this.createMeeting(date));

            for (let index = 0; index < 6; index++) {
                const element = date;
                element.setDate(date.getDate() + 1);
                this.meetingsDays.push(this.createMeeting(element));
            }
            this.date = new Date(myDate);
        },
        filterDays() {
            this.horaires.forEach((day) => {
                if (day.start_time != null) {
                    this.daysFilters.push(day);
                }
            });
        },
        verifyAppointment(element) {

            if (this.appointments.length != 0) {
                element = new Date(element)
                var value = false;
                this.appointments.forEach((appointment) => {
                    const date_debut = new Date(appointment.date)
                    const date_fin = new Date(appointment.date_end)
                    if (element.getTime() >= date_debut.getTime() && element.getTime() <= date_fin.getTime()) {
                        value = true;
                    }
                })
                return value;
            }

        },
        nextDate() {
            this.loading = true;
            const date = new Date(this.date);
            date.setDate(date.getDate() + 7);
            this.getMeetings(date);
            this.loading = false;
        },
        previousDate() {
            this.loading = true;
            const date = new Date(this.date);
            date.setDate(date.getDate() - 7);
            this.getMeetings(date);
            this.loading = false;
        },
        createMeeting(date) {
            const meeting = {
                date: date.toString(),
                slots: [],
            };

            let nomDuJour = date.toLocaleString('fr-FR', { weekday: 'long' });
            let dateFilter = this.daysFilters.find(jour => jour.day.toLowerCase() === nomDuJour);

            if (dateFilter) {
                const lastTime = new Date(date);
                lastTime.setHours(dateFilter.end_time.substr(0, 2), dateFilter.end_time.substr(3, 2));
                lastTime.setMinutes(lastTime.getMinutes() - 30);
                date.setHours(dateFilter.start_time.substr(0, 2), dateFilter.start_time.substr(3, 2));
                let element = new Date(date);

                while (lastTime.getTime() > element.getTime()) {
                    const currentTime = new Date();  // Obtenez l'heure actuelle
                    currentTime.setSeconds(0);  // Ignorer les secondes pour la comparaison

                    if (currentTime < element) {
                        const value = this.verifyAppointment(element.toString());
                        meeting.slots.push({ date: value ? null : element.toString() });
                    }

                    element.setMinutes(element.getMinutes() + 30);
                    element.setSeconds(0);
                }
            }

            return meeting;
        },
        selectedDate() {
            this.$emit('dateSelected', { 'date': this.meeting.date, 'typeRV': this.typeRV })
        },
        changeTypeRv() {
            this.daysFilters = []
            if (this.typeRV === "cabinet") {
                this.horaires = this.doctor.horaire_cabinet
                
            }
            else if (this.typeRV === 'teleexpertise') {
                this.horaires = this.doctor.horaire_teleexpertise
            }
            else {
                this.horaires = this.doctor.horaire_teleconsultation
            }
            this.filterDays();
            this.getMeetings(this.date);
        },
        getCommingAppointmentMedecin() {
            this.$http.get(`auth/appointments/comming/${this.doctor.id}/medecin`).then(({ data }) => {
                this.appointments = data.data
                this.filterDays();
                this.loading = true;
                this.getMeetings(this.date);
                this.loading = false;
            }).catch(() => {

            })
        }
    },
    mounted() {
        if (this.doctor) {
            if (this.typeRV === "cabinet") {
                this.horaires = this.doctor.horaire_cabinet
            }
            else if (this.typeRV === 'teleexpertise') {
                this.horaires = this.doctor.horaire_teleexpertise
            }
            else if (this.typeRV == 'teleconsultation') {
                this.horaires = this.doctor.horaire_teleconsultation
            }
            else {

                if (this.doctor.horaire_cabinetlength != 0) {
                    this.typeRV = 'cabinet';
                } else if (this.doctor.horaire_teleconsultation.length !=0) {
                    this.typeRV = 'teleconsultation'
                }
                this.changeTypeRv()
            }
            this.getCommingAppointmentMedecin();
        }


    },
};
</script>
     
<style scoped>
.simple-example {
    font-family: "Poppins" !important;
    min-height: 330px !important;
    overflow: auto;
}
::v-deep .meeting-btn {
    background-color: #00a19a !important;
    color: white !important;
    padding-left: 8px !important;
}
::v-deep .meeting-btn-video {
    background-color: #009ee2 !important;
    color: white !important;
    padding-left: 8px !important;
}
::v-deep .pagination-prev-btn {
    color: #00a19a !important;
}
::v-deep .pagination-next-btn {
    color: #00a19a !important;
}
::v-deep .loading-div {
    top: 58px !important;
}
@media screen and (max-width: 500px) {
    ::v-deep .meeting {
        margin-left: 8px !important;
    }
}
</style>