<template>
  <div>
    <div class="btn-header">
      <div class="d-flex">
        <v-spacer></v-spacer>
        <v-btn
        color="primary"
        style="text-transform: none;margin-right: 24px;"
        @click="showDialog = true"
      >
        <v-icon class="mr-1">mdi-account-plus</v-icon>
        Ajouter un proche
      </v-btn>
      </div>
      
    </div>
    <div class="list-proches">
      <list-proche>
        <item-proche
          v-for="proche in proches"
          :key="proche.id"
          :proche="proche"
          @deleteProche="deleteProche"
        ></item-proche>
      </list-proche>
    </div>

    <proche-form
      v-model="showDialog"
      @createProche="createProche"
      v-if="showDialog"
    />
  </div>
</template>

<script>
import ItemProche from "../../components/proche/ItemProche.vue";
import ListProche from "../../components/proche/ListProche.vue";
import ProcheForm from "../../components/proche/ProcheForm.vue";
export default {
  components: { ProcheForm, ListProche, ItemProche },
  data() {
    return {
      showDialog: false,
      proches: [],
    };
  },
  created() {
    this.getProches();
  },
  methods: {
    getProches() {
      const patient_id = this.$auth.user.patient_id;
      this.$http.get(`proches/${patient_id}/patient`).then((res) => {
        this.proches = res.data.data;
      });
    },
    createProche(data) {
      this.proches.unshift(data);
      this.$toast.success({
        title: "Création proche",
        message: "Un nouveau proche a été ajouté avec succès",
      });
    },
    deleteProche(proche) {
      this.proches = this.proches.filter((p) => p.id != proche.id);
      this.$toast.success({
        title: "Suppression proche",
        message: "Votre Proche a été supprimé avec succès",
      });
    },
  },
};
</script>

<style>
.btn-header {
  margin-top: 12px;
}
</style>