<template>
  <div class="container" v-if="doctor">
    <v-row justify="space-between">
      <v-col cols="12" md="12" v-if="doctor.user">
        <v-sheet class="pa-3 mb-12">
          <div class="text-center pa-6">
            <update-photo :user="doctor.user" />
            <div class="pt-3 font-5 font-weight-bold">{{ doctor.user.name }}</div>
            <div class="font-7">{{ doctor.department.name }}</div>
            </div>
        </v-sheet>
      </v-col>
    </v-row>
    <v-sheet width="100%" class="pa-6 mb-12" elevation="1">
      <div class="font-6 mb-2">Informations sur le profil</div>
      <div class="font-7">Mettez à jour les informations de profil de votre compte</div>
      <div class="form-profile mt-6">
        <v-row>
          <v-col cols="12" md="6">
            <div class="" md="6">
              <div class="label">Nom Complet</div>
              <v-text-field dense outlined v-model="doctor.user.name" disabled/>
            </div>
            <div class="" md="6">
              <div class="label">Email</div>
              <v-text-field dense outlined v-model="doctor.user.email" disabled />
            </div>
            <div class="" md="6">
              <div class="label">Numero téléphone</div>
              <vue-tel-input-vuetify 
                v-model="doctor.tel_port"
                class="form"
                name="phone"
                placeholder="Entrer votre N° téléphone"
                label=""
                dense
                outlined
                mode="international"
                :error="errorPhone"
                ref="telInput"
                @change="verifyNumber"
                :error-messages="errors.tel_port"
              >
                    </vue-tel-input-vuetify>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="" md="6">
              <div class="label">Nom cabinet</div>
              <v-text-field dense outlined v-model="doctor.cabinet_name"  :error-messages="errors.cabinet_name"/>
            </div>
            <div class="" md="6">
              <div class="label">Adresse Cabinet</div>
              <v-text-field dense outlined  v-model="doctor.address_cabinet" />
            </div>
            <div class="" md="6">
              <div class="label">Ville</div>
              <v-autocomplete
                        v-model="doctor.ville"
                        :items="villesSenegal"
                        name="ville"
                        dense
                        outlined
                        :error-messages="errors.ville"
                    ></v-autocomplete>
            </div>
          </v-col>
        </v-row>

        <div class="btn-form">
          <v-btn color="primary" style="text-transform: none;" @click="updated">Enregistrer</v-btn>
        </div>

      </div>
    </v-sheet>
    <v-sheet width="100%" class="pa-6 mb-12" elevation="1">
      <div class="font-6 mb-2">Mettre à jour le mot de passe</div>
      <div class="font-7">Assurez-vous que votre compte utilise un mot de passe long et aléatoire pour rester en sécurité</div>
      <div class="form-profile mt-6">

        <div class="form-field" md="6">
          <div class="label">Ancien mot de passe</div>
          <v-text-field 
            v-model="password_old"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            outlined
            dense
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
            :error-messages="errors.current_password"
           />
        </div>
        <div class="form-field" md="6">
          <div class="label">Nouveau mot de passe</div>
          <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            outlined
            dense
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            :error-messages="errors.password"
          />
        </div>
        <div class="form-field" md="6">
          <div class="label">Confirmer votre mot de passe</div>
          <v-text-field
            v-model="password_confirm"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            outlined
            dense
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
            :error-messages="errors.password_confirmation"
          />
        </div>
        <div class="btn-form">
          <v-btn color="primary" style="text-transform: none;" @click="changePassword">Enregistrer</v-btn>
        </div>

      </div>
    </v-sheet>
    <!-- <v-sheet width="100%" class="pa-6 mb-12" elevation="1">
      <div class="font-6 mb-2">Mettre à jour votre abonnement </div>
      <div class="font-7 mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus ex fugit voluptatum ducimus vel maxime deleniti illum ullam unde ab? Suscipit corporis libero laudantium! Expedita impedit dolorum pariatur assumenda ratione.</div>
      <div class="" v-if="abonnement">
        <div class="" v-if="abonnement.days_remaining !=0">
          <div class="label">Abonnement en cours</div>
          <div class="">Type abonnement : {{ abonnement.type }}</div>
          <div class="label">{{ abonnement.days_remaining }} jours restants</div>
        </div>
        <div v-else>
          <div class="label">Abonnement expire</div>
          <v-btn style="text-transform: none;" color="primary" elevation="0" link to="/abonnement">Renouveler</v-btn>
        </div>

      </div>
      <div v-else>
        <v-btn class="btn-form" style="text-transform: none;" color="primary" elevation="0" link to="/abonnement">Souscrire</v-btn>
      </div>
      

    </v-sheet> -->
  </div>
</template>

<script>
import UpdatePhoto from '../../components/Home/utilities/UpdatePhoto.vue'
export default {
  components: { UpdatePhoto },
  data() {
    return {
      show: false,
      show1: false,
      show2: false,
      password_old: null,
      password: null,
      abonnement: null,
      password_confirm: null,
      errors: {},
      doctor: null,
      doctor_id: this.$auth.user.doctor_id,
      errorPhone: false,
      loading: false,
      errors_profil: {},
      villesSenegal: ['Dakar', 'Diourbel', 'Fatick', 'Kaffrine', 'Kaolack', 'Kédougou', 'Kolda', 'Louga', ' Matam', 'Saint-Louis', 'Sédhiou', 'Tambacounda', 'Thiès', 'Ziguinchor'],
    }
  },
  created(){
    this.getDoctor()
    // this.getAbonnement()
  },
  methods: {
    getDoctor() {
      this.$http.get('user').then(({data}) => {
           this.doctor = data.data
      })
    },
    getAbonnement() {
      this.$http.get('abonnement').then(({ data }) => {
          this.abonnement = data.data
      })
    },
    verifyNumber() {
      if (!this.$refs.telInput._computedWatchers.phoneObject.value.isValid) {
        this.errorPhone = true;
        this.errors.tel_port = "Le numéro est incorrecte"
      } else {
        this.errorPhone = false;
        this.errors.tel_port = null;
      }

    },
    updated() {
      const data = {
        ville: this.doctor.ville,
        address_cabinet: this.doctor.address_cabinet,
        cabinet_name: this.doctor.cabinet_name,
        tel_port: this.doctor.tel_port.match(/\S+/g).join("")
      }
      this.$http.put(`doctor/${this.doctor.id}`, data).then((response) => {
        this.$toast.success({
          title: 'Modification',
          message: response.data.message
        })
        this.show = false
      }).catch((error) => {

        if (error.response.data.errors) {
          this.errors = error.response.data.errors

        } else {
          this.$toast.error({
            title: 'Echec',
            message: error.response.data.message
          })
        }

      })
    },
    changePassword() {
      this.errors = {};
      const data = {
        current_password: this.password_old,
        password: this.password,
        password_confirmation: this.password_confirm
      }
      this.$http.post('profile/change-password', data).then(() => {
        this.$toast.success({
        title:'Modification Mot de Passe',
        message:'Votre mot de passe a été mise à jour avec succès'
      })
        this.close()
      }).catch((error) => {
        if(error.response.data.errors) {
          this.errors = error.response.data.errors;
        } else {
          this.$toast.error({
            title: 'Erreur',
            message: 'Une erreur est survenue lors du modification de votre mot de passe'
          })
        }
       
      })
    },
    close() {
      this.password = '';
      this.password_old = '';
      this.password_confirm = '';
      this.errors = {};
    }
  }
}
</script>

<style scoped>
.form-field {
  width: 50%;
}
@media screen and (max-width: 700px) {
  .form-field {
  width: 100%;
}
}

</style>