<template>
    <div>
        <!-- Jour(s) de disponibilité -->
        <div class="mb-6">
            <card-title :title="'Jour(s) de disponibilité en cabinet'" :icon="'mdi-calendar-check'">
            </card-title>
            <horaire-form @saveHoraire="saveHoraire" :horaire="doctor.horaire_cabinet" v-if="doctor" />

        </div>
    </div>
</template>
  
<script>
import cardTitle from '../../../components/Home/utilities/cardTitle.vue'
import HoraireForm from '../../../components/horaireForm.vue'
export default {
    components: { cardTitle, HoraireForm },
    props: ['doctor'],

    methods: {

        saveHoraire(dayofWeeks) {
            const data = {
                horaire_cabinet: dayofWeeks
            }
            this.$http.put(`horaire/update/${this.doctor.id}`, data).then(({ data }) => {
                this.doctor.horaire_cabinet = data.horaire_cabinet
                this.$toast.success({
                    title: 'Horaire cabinet',
                    message: "Horaire cabinet modifié avec succès"
                })
            })
        }
    }

}
</script>
  
<style></style>