<template>
    <div>
      <v-sheet class="pa-6">
          <div>
              <div class="text-center">
                  <img
                      v-if="logo_cabinet"
                      style="cursor:pointer;border-solid:1px;"
                      :src="logo_cabinet_url"
                      width="300"
                      height="250"
                      alt="Logo"
                      @click="showLogo = true"
                  />
                  <div v-else> Télécharger votre Logo de Cabinet<v-btn outlined @click="showLogo=true" style="text-transform: none;" small class="mx-3">Télécharger</v-btn></div>
              </div>
              
  
              <form action enctype="mulipart/form-data">
                <my-upload
                  field="logo_cabinet"
                  @crop-upload-success="cropUploadSuccessLogo"
                  @crop-upload-fail="cropUploadFailLogo"
                  v-model="showLogo"
                  :width="300"
                  :height="200"
                  method="POST"
                  :url="urlLogo"
                  :params="params"
                  :headers="headers"
                  langType="fr"
                  img-format="png"
                ></my-upload>
              </form>
          </div>
          
      </v-sheet>
    </div>
  </template>
  
  <script>
  import myUpload from "vue-image-crop-upload";
  export default {
      props: {
        logo_cabinet: String,
        logo_cabinet_url: String,
      },
      components: { myUpload },
      data() {
          return {
              showLogo: false,
              urlLogo: `${this.$api.url_base}change-logo/${this.$auth.user.doctor_id}`,
              params: { _method: "POST" },
              headers: { 
              'Authorization': 'Bearer ' + this.$auth.token 
              },
          }
      },
      methods: {
          cropUploadSuccessLogo(jsonData,) {
        //const url_logo = jsonData.data.Logo_url;
        this.showLogo = false;
        //console.log('url logo', jsonData.signature_url)
        this.$emit('saveLogoCabinet',{url: jsonData.logo_cabinet_url, logo_cabinet: jsonData.logo_cabinet })
        this.$toast.success({
          title: 'Logo',
          message: 'Votre Logo a bien été modifié'
        })
        //this.$handleMessage(`Votre Logo a bien été modifié`, "success");
      },
  
      cropUploadFailLogo() {
        this.showLogo = false;
        this.$toast.error({
          title: 'Logo',
          message: 'Impossible de modifier le Logo'
        })
        //this.$handleMessage(`Impossible de modifier le Logo`, "danger");
      }
      }
  }
  </script>
  
  <style>
  
  </style>