<template>
<div>
    <v-sheet class="item-form pa-4 mb-3">
    <div class="d-flex justify-space-between">
        <div class="font-7 font-weight-bold">
            <router-link v-if="user.doctor_id" :to="{name: 'doctor.create.fields.form', params: { id: form.id }}" style="text-decoration: none" > {{ form.name }} </router-link>

            <router-link v-else :to="{name: 'admin.create.fields.form', params: { id: form.id }}" style="text-decoration: none" > {{ form.name }} </router-link>
        </div>
        <div class="actions">
            <v-btn icon class="mx-2" color="#025397" outlined small @click="updatedForm" :disabled="!form.is_me_add">
                <v-icon size="20" >mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon class="" color="error" outlined small :disabled="!form.is_me_add">
                <v-icon size="20" @click="dialogDelete=true">mdi-delete</v-icon>
            </v-btn>
        </div>

    </div>
  </v-sheet>
	<!--  Dialog suppression de formulaire -->
	<delete-dialog
		v-if="dialogDelete"
		v-model="dialogDelete"
		:message="'Etes-vous sûr de voulaire supprimer ce formualire. Une fois supprimé tous champs attachés à ce formulaire serons supprimés'"
		:title="'Suppression Formulaire'"
		@confirmDelete="formDelete"
        
	/>
</div>

</template>

<script>
import DeleteDialog from '../Home/utilities/DeleteDialog.vue';
export default {
  components: { DeleteDialog },
    props: {form: Object},
    data() {
        return {
            dialogDelete: false,
            user:this.$auth.user,
        }
    },
    methods: {
        updatedForm(){
            this.$emit('updatedForm', this.form);
        },
        formDelete() {
        this.$http.delete(`form/${this.form.id}`).then(() => {
            this.$toast.success({
                title: 'Suppression formulaire',
                message: 'Votre formulaire a été supprimer avec succès !'
            })
            this.$emit('deletedForm', this.form)
        }).catch(() => {
            this.$toast.error({
                title: 'Suppression formulaire',
                message: 'Erreur impossible de supprimer le formulaire !'
            })

        })

    }
    },
    


}
</script>

<style scoped>
 /* .item-form {
    background-color: aliceblue;
 } */

</style>