<template>
  <div>
    <div class="btn-header">
      <div class="row justify-space-between">
        <v-col cols="12" md="3" class="pa-6">
          <v-text-field v-model="first_name" placeholder="Rechercher par prénom" outlined clearable dense @input="search" />
        </v-col>
        <v-col cols="12" md="3" class="pa-6">
          <v-text-field v-model="last_name" placeholder="Rechercher par nom" outlined clearable dense @input="search" />
        </v-col>
        <v-col cols="12" md="4">
          <div class="d-flex justify-end">
              <v-btn color="primary" style="text-transform: none;margin-right: 24px;" @click="importing = true">
                <v-icon class="mr-1">mdi-file-import</v-icon>
                Importer
              </v-btn>
              <v-btn color="primary" style="text-transform: none;margin-right: 24px;" @click="exportToCSV">
                <v-icon class="mr-1">mdi-file-export</v-icon>
                Exporter
              </v-btn>
            <v-btn color="primary" style="text-transform: none;" @click="showDialog = true">
            <v-icon class="mr-1">mdi-account-plus</v-icon>
            Ajouter un patient
          </v-btn>
          </div>
          
        </v-col>
        


      </div>

    </div>
    <div class="list-proches">
      <list-proche>
        <item-proche v-for="(proche,i) in proches" :key="i" :proche="proche"
          @deleteProche="deleteProche"></item-proche>
      </list-proche>
      <vue-infinite-loading @infinite="loadMoreProches" spinner="spiral">
        <span slot="no-more">•••</span>
        <span slot="no-results">
          <v-alert color="info" type="info" >
            Vous n'avez pas encore de patients .
          </v-alert>
        </span>
        <span slot="spinner">Chargement...</span>
    </vue-infinite-loading>
      <div class="text-center" v-if="key && proches.length == 0">
        <v-alert color="info" type="info">
          Aucun patient consernant votre recherche.
        </v-alert>
      </div>
      
    </div>
    <v-dialog 
      v-model="importing" 
      persistent
      max-width="700"
    >
      <v-card>
        <v-card-title>
          <span class="font-7">Importer des patients</span>
          <v-spacer></v-spacer>
          <v-btn color="error" icon @click="closeImport" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <div class="d-flex justify-space-between mb-3">
            <v-btn outlined style="text-transform: none;" color="info" @click="onpenFile">
              <v-icon>mdi-file-import</v-icon>
              Importer des patients
            </v-btn>
            <input type="file" ref="uploadFile" id="import_patients" @change="event => loadCSV(event)" style="display: none;">

            <v-btn 
              outlined 
              style="text-transform: none;" 
              color="primary" 
              :loading="loading" 
              :disabled="!csv_content.length" 
              @click=" importPatients"
            >
              Enregistrer
            </v-btn>
          </div>
          
          <div v-if="csv_content.length" style="overflow-x:auto;">
            <v-data-table
              :headers="header_content"
              :items="csv_content"
              hide-default-footer
            />

          </div>

        </v-card-text>
      </v-card>
     
    </v-dialog>

    <proche-form v-model="showDialog" @createProche="createProche" v-if="showDialog" />
  </div>
</template>
  
<script>
import VueInfiniteLoading from 'vue-infinite-loading';
import ItemProche from "../../components/proche/ItemProche.vue";
import ListProche from "../../components/proche/ListProche.vue";
import ProcheForm from "../../components/proche/ProcheForm.vue";
import Papa from 'papaparse';
export default {
  components: { ProcheForm, ListProche, ItemProche, VueInfiniteLoading },
  data() {
    return {
      showDialog: false,
      key: '',
      first_name: null,
      last_name: null,
      proches: [],
      importing: false,
      loading: false,
      loading_patients: false,
      header_content: [],
      csv_content: [],
      page: 1,
    };
  },
  created() {
    
  },
  methods: {
    onpenFile() {
      this.$refs.uploadFile.click()
    },
    loadMoreProches($state) {
      //const patient_id = this.$auth.user.patient_id;
      this.$http.get(`proches`,{ params: { page: this.page } }).then(({ data }) => {
            if (data.data.length > 0) {
            this.proches.push(...data.data);
            
            this.page++;
            $state.loaded();
          } else {
            $state.complete();
          }
        //this.proches = res.data.data;
      });
    },
    createProche(data) {
      this.proches.unshift(data);
      this.$toast.success({
        title: "Création patient",
        message: "Un nouveau patient a été ajouté avec succès",
      });
    },
    deleteProche(proche) {
      this.proches = this.proches.filter((p) => p.id != proche.id);
      this.$toast.success({
        title: "Suppression patient",
        message: "Votre Patient a été supprimé avec succès",
      });
    },
    search(key) {
      if (key) {
        
        this.$http.get('proches/search',{ params: {first_name: this.first_name, last_name: this.last_name}}).then(res => {
          this.proches = res.data.data
        }).catch(() => {

        })
      } else {
      this.loadMoreProches
       //this.$refs.loader.$emit('$InfiniteLoading:loaded')
      }

    },
    toJson (csv) {
                let lines = csv.split("\n") 
                let result = []
                const headers = this.getLine(lines[0])
                if (headers !== undefined) {
                    headers.map(header => {
                        this.header_content.push({
                          text: header.trim(),
                          value: header.toUpperCase().trim()
                        })
                    })
                }
                lines.forEach((line, lineIndex) => {
                    if (lineIndex < 1) return 
                    let obj = new Object()
                    let currentLine = this.getLine(line)
                    headers.forEach((header, headerIndex) => {
                        if (header && header.trim() && currentLine !== undefined) 
                            obj[header.trim()] = currentLine[headerIndex]
                    })
                    result.push(obj)
                })
                return result 
            },
            loadCSV (e) {
                this.loading_patients = true 
                this.header_content = []
                this.csv_content = []

                if (window.FileReader) {
                    let reader = new FileReader()

                    let extension = e.target.files[0].name.split('.').pop()

                    if (extension.includes('csv')) {
                        reader.readAsText(e.target.files[0])
                        reader.onload = (event) => {
                            let csv = event.target.result 
                            this.csv_content = this.toJson(csv) 
                            console.log(this.csv_content)
                            this.loading_patients = false
                        }
                        reader.onerror = (evt) => {
                            if (evt.target.error.name == 'NotReadableError') {
                              this.$toast.error({
                                title: 'Importation patients',
                                message: 'Le fichier ne peut être lu'
                              })
                               // this.$handleMessage('Le fichier ne peut être lu', 'danger')
                            }
                        }
                    } else {
                      this.$toast.error({
                                title: 'Importation patients',
                                message: 'Vous devez utiliser un fichier csv'
                              })
                        //this.$handleMessage('Vous devez utiliser un fichier csv', 'danger')
                        return;
                    }
                } else {
                              this.$toast.error({
                                title: 'Importation patients',
                                message: 'FileReader is not supported on this browser'
                              })
                    //this.$handleMessage('FileReader is not supported on this browser :(', 'danger')
                }
            },
            exportToCSV() {
              const dataToExport = this.proches.map(patient => [
                patient.first_name,
                patient.last_name,
                patient.phone,
                null,
                patient.sexe,
                patient.address,
                patient.date_naissance,
                patient.lieu_naissance,
              ]);
              dataToExport.unshift(['PRENOM', 'NOM', 'N° TEL','EMAIL','SEXE','ADRESSE', 'DATE DE NAISSANCE', 'LIEU DE NAISSANCE']);
              const csv = Papa.unparse(dataToExport);
              const blob = new Blob([csv], { type: 'text/csv' });
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = 'patients.csv';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              this.$toast.success({
                title: 'Export',
                message: "Patients exportés avec succès, veuillez verifier le fichier patient.csv dans les téléchargements"
              })
            },


            importPatients () {
             
                this.loading = true 
                this.$http.post(`patients/${this.$auth.user.intervenant_id}/import`, {
                    params: {patients: this.csv_content}
                }).then(({data}) => {
                  console.log(data)
                    this.getProches()
                    this.$toast.success({
                      title: 'Importation patients',
                      message: "patients importés avec succès"
                    })
                }).catch(({message}) => {
                  console.log(message)
                  this.$toast.error({
                    title: 'Import patient',
                    message: "Une erreur est survenue pendant l'importation des patients"
                  })
                  
                    //this.$handleMessage(`Une erreur est survenue pendant l'importation des patients`, 'danger')
                }).finally(() => {
                  this.closeImport()
                    //this.loading = false
                })
            },
            closeImport() {
              this.csv_content = []
              this.header_content = []
              this.importing = false
              this.loading = false
            },
            getLine (line) {
                if (line.includes(',')) { 
                    return line.split(',')
                } else if (line.includes(';')) { 
                    return line.split(';') 
                }
            }

  },
};
</script>
  
<style scoped>
.btn-header {
  margin-top: 12px;
}
/* label[for="import_patients"] {
        display: block;
        width:25%;
        height:35px;
        line-height: 35px;
        text-align: center;
        color:#fff;
        background-color: #1F3651;
        cursor: pointer;
        border-radius: 5px;
        font-weight: bold;
        transition: all .3s ease;
    }
    label[for="import_patients"]:hover {
        box-shadow: 0 0 2px #1F3651;
        background-color: white;
        color:#1F3651;
    }
    label[for="import_patients"] + input {
        display: none;
    } */
</style>