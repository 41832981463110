<template>
  <div class="formulaire">
    <v-row align-content="space-between">
        <v-col cols="12" md="4">
            <div>
                <div class="font-6 font-weight-bold mb-6">Ajouter un nouveau type de visite médicale</div>
                <v-form>
                   <!--  <div class="font-7 font-weight-bold mb-2">Type de visite</div> -->
                    <v-text-field
                        v-model="type.name"
                        name="name"
                        placeholder="Saisir le nom du type visite "
                        id="name"
                        outlined
                        :error-messages="errors.name"
                    ></v-text-field>
                    <div class="d-flex">
                        <v-btn style="text-transform: none;" color="primary" elevation="0" class="mr-3" @click="save" :loading="loading">{{ title }}</v-btn>
                        <v-btn style="text-transform: none;" color="error" outlined @click="clear"> Annuler</v-btn>
                    </div>
                </v-form>
            </div>
        </v-col>
        <v-col cols="12" md="8">
            <div class="font-6 font-weight-bold mb-6">Type de visite médicale</div>
            <item-type
                v-for="type_visit in types"
                :key="type_visit.id"
                :type="type_visit"
                @updatedType="updatedType"
                @deletedType="deletedType"
            />
            
        </v-col>
    </v-row>
  </div>
</template>

<script>
import ItemType from './itemType.vue';

export default {
  components: { ItemType },
    data() {
        return {
            type:{
                id: null,
                name: ""
            }, 
            title: 'Ajouter',
            types: [],
            errors: {},
            loading: false
        }
    },
    mounted() {
        this.getTypes();
    },

    methods: {
        getTypes() {
            this.$http.get('medical_visit_types').then(({ data }) => {
                this.types = data.data;
            })
        },
        save() {
            this.loading = true
            if(this.type.id) {
                this.$http.put(`medical_visit_types/${this.type.id}`, { name: this.type.name }).then(({ data }) => {
                    const index = this.types.findIndex((type_visit) => type_visit.id == this.type.id)
                    this.types[index].name = data.data.name
                    this.$toast.success({
                        title: 'Modification formulaire',
                        message: 'Votre formulaire a été modifié avec succès !'
                    })
                    this.clear()
                   
                }).catch((error) => {
                    this.errors = error.response.data.errors
                }).finally( () => this.loading = false)
            } else {
                this.$http.post('medical_visit_types', { name: this.type.name }).then(({ data }) => {
                    this.types.unshift(data.data)
                    this.$toast.success({
                        title: 'Création formulaire',
                        message: 'Votre formulaire a été crée avec succès !'
                    })
                    this.clear()
                }).catch((error) => {
                    this.errors = error.response.data.errors
                }).finally( () => this.loading = false)
            }
        },
        clear() {
            this.title = 'Ajouter'
            this.type = { id: null, name: ''}
        },
        updatedType(data) {
            this.title = 'Modifier'
            this.type = data
        },
        deletedType(data) {
            this.types = this.types.filter((type_visit) => type_visit.id != data.id);
        }
    }
}
</script>

<style scoped>
    .formulaire {
        margin-top: 25px;
    }

</style>