<template>
    <div>
      <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher..."
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="payements"
        :search="search"
      >
      <template #[`item.user`]="{ item }">
          <div class="d-flex py-2">
              <v-avatar
              size="50"
              color="white"
              >
              <img :src="item.user.avatar_url" alt="avatar">
              
              </v-avatar>
          
          <div class="mx-2" > 
              <div class=""> 
                  {{ item.user.name }}
              </div >
              <div class="font-weight-thin"> 
                  {{ (item.user.role) }}
              </div>
             
          </div>
      </div>
      </template>
      <template #[`item.amount`]="{ item }">
          <div class="font-weight-bold"> {{ (item.amount).toLocaleString("fr-FR") }} Fcfa</div>
      </template>
      <template #[`item.status`]="{ item }">
          <v-chip
              class="ma-2"
              :color="findColor(item.status)"
              label
              text-color="white"
              style="width: 120px;"
          >
              <v-icon left>
                  mdi-label
              </v-icon>
              {{ item.status }}
          </v-chip>
      </template>
      <template #[`item.view`]="{ item }">
          <v-btn  icon color="primary" link :to="{name: 'admin.detail.payement', params: {id: item.id}}" style="text-decoration: none;">
              <v-icon>
                  mdi-eye-circle
              </v-icon>
          </v-btn>
      </template>
      </v-data-table>
    </v-card>
    </div>
  </template>
  
  <script>
  export default {
      data() {
          return  {
              loading: false,
              payements: [],
              search: '',
              headers: [
                  
                  {
                      text: 'Payant(e)',
                      value: 'user'
                  },
                  {
                      text: 'Montant',
                      value: 'amount'
                  },
                  {
                      text: 'Date',
                      value: 'formated_date' 
                  },
                  {
                      text: 'Type Payement',
                      value: 'type'
                  },
                  {
                      text: 'Statut',
                      value: 'status'
                  },
                  {
                      text: 'Action',
                      value: 'view'
                  }
              ]
          }
      },
      created() {
          this.init()
      },
      methods: {
          init() {
              this.$http.get('payements').then(({ data }) => {
                  this.payements = data.data
              })
          },
          viewPayement(payement) {
              console.log(payement)
          },
          findColor(status) {
              if(status == 'Payé') {
                  return 'primary'
              } else if(status == 'En attente') {
                  return 'cyan'
              } else if(status == 'Annuler') {
                  return 'pink'
              } else {
                  return ;
              }
          }
      }
  }
  </script>
  
  <style>
  
  </style>