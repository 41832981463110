<template class="bgpage">
  <v-app class="bg-1">
      <navbar />
    <div class="container">
      <div class="control-container">
        <h1 class="mb-6">Télémedecine Sahel Asssistance</h1>

        <div class="join control">
          <div class="font-7 font-weight-bold mb-3">Nom Complete</div>
          <v-text-field 
            dense 
            outlined 
            v-model="name" 
            type="text" 
            id="name" 
            placeholder="Nom complet" 
            :error-messages="errors.name"
          />
        </div>
        <div class="join control">
          <div class="font-7 font-weight-bold mb-3">Joindre la réunion</div>
          <div class="d-flex"> 
            <v-text-field 
                dense 
                outlined 
                v-model="sessionId" 
                type="text" 
                id="session" 
                placeholder="Session ID" 
                :error-messages="errors.sessionId"
            />
            <v-btn class="primary" id="join" @click="joinSession" style="text-transform: none; margin-left: 15px;">Joindre</v-btn>
          </div>
          
        </div>
        
        <div class="">
          <p class="font-6 font-weight-bold mb-3">Creer une réunion</p>
          <v-btn 
            class="info mb-6" 
            id="create" 
            @click="createSession" 
            style="text-transform: none; margin: 12px 10px 12px 0;"
            :loading="loading"
            >
            Nouvelle réunion
          </v-btn>
        </div>
      </div>
      <div class="preview-container">
        <div id="preview-wrapper">
          <div id="preview" ref="preview"></div>
        </div>
        <div >
            <div class="btn-select">
                <div>
                <v-select 
                    outlined
                    dense
                    :items="videos" item-text="label" item-value="id" label="Video"
                    v-model="videoSelector"
                ></v-select>
            </div>
            <div class="ml-3">
                <v-select 
                    outlined
                    dense
                    :items="audios" 
                    item-text="label" 
                    item-value="id" 
                    label="Audio" 
                    v-model="audioSelector"
                ></v-select>
            </div>
            </div>
           
        </div>
        <v-btn 
            color="primary" 
            style="text-transform: none;" 
            id="start-preview" 
            @click="preview"
            outlined
            
        > 
            Aperçu
        </v-btn>
      </div>
    </div>
  </v-app>
</template>

<script>
import OT from '@opentok/client';
import navbar from '../Home/utilities/navbar.vue';
export default {
  components: { navbar },
    data() {
        return {
            audios: [{id: "", label: "No audio"}],
            videos: [{ id: "", label: "No video"}],
            name: '',
            sessionId: '',
            audioSelector : '',
            videoSelector : '',
            publisher: null,
            errors: {},
            loading: false
        }
    },
    mounted(){
        this.loadAVSources()
    },
    methods: {
        async loadAVSources() {
            OT.getDevices((err, devices) => {
                if (err) {
                alert('Not a supported browser');
                }
                devices.forEach((device) => {
                   
                if (device.kind.toLowerCase() === 'audioinput') {
                    const audio = {id:device.deviceId, label:  device.label}

                    this.audios.push(audio)
                    
                }
                if (device.kind.toLowerCase() === 'videoinput') {
                    const video = {id:device.deviceId, label:  device.label}
                    this.videos.push(video)
                }
                });
                   
                // this.audioSelector.innerHTML += '<option value="">No audio</option>';
                // this.videoSelector.innerHTML += '<option value="">No video</option>';
            });
        },
        preview() {
            this.publisher = OT.initPublisher(this.$refs.preview, { height: '100%', width: '100%' });
            if (this.audioSelector === '') {
                this.publisher.publishAudio(false);
            } else {
                this.publisher.setAudioSource(this.audioSelector);
                this.publisher.publishAudio(true);
            }

            if (this.videoSelector === '') {
                this.publisher.publishVideo(false);
            } else {
                this.publisher.setVideoSource(this.audioSelector);
                this.publisher.publishVideo(true);
            }
            console.log('device ',this.publisher.getVideoSource().deviceId)
        },
        createSession() {
            this.loading = true
            this.errors = {}
                if(this.name !== '') {
                    this.$http.post('auth/opentok_session').then((response) => {
                        this.sessionId = response.data.sessionId
                        if (this.publisher) {
                            this.setDevicePreference();
                        }
                        this.$router.push({name: 'join.meet', params: { sessionId: this.sessionId, name: this.name}})
                    }).catch(() => {
                        alert('Error lors de la creation de session');
                    }).finally(() => this.loading = false)
                } else {
                    this.errors.name = ['Veuillez saisir votre nom complet'];
                }
                
                
        },
        joinSession() {
            this.errors = {}
            if (this.sessionId !== '' && this.name !== '') {
                if (this.publisher) {
                this.setDevicePreference();
                }
                this.$router.push({name: 'join.meet', params: {sessionId: this.sessionId, name: this.name}})
                //window.location.href = `/session/${sessionId}?name=${name}`;
            } else {
                if(this.sessionId == '') {
                    this.errors.sessionId = ["Veuillez saisir code"]
                } 
                if(this.name == '') {
                    this.errors.name = ['Veuillez saisir votre nom complet'];
                }
            }
        },
        setDevicePreference() {
            const audioSourceId = this.publisher.getAudioSource().id;
            const videoSourceId = this.publisher.getVideoSource().deviceId;
            const videoEnabled = !!this.publisher.getVideoSource().track;
            const audioEnabled = this.publisher.getAudioSource().enabled;

            localStorage.setItem('audioSourceId', audioSourceId);
            localStorage.setItem('videoSourceId', videoSourceId);
            localStorage.setItem('audioEnabled', JSON.stringify(audioEnabled));
            localStorage.setItem('videoEnabled', JSON.stringify(videoEnabled));
        },
        
    }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

/* body {
  background-color: #E8F4FB;
  font-family: 'Open Sans', sans-serif;
  min-height: 100vh;
} */

.container {
  display: flex;
  min-height: 90vh;
  /* padding: 0 15px; */
}

.control-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 60%;
  margin-right: 128px;
}

/* .control-container .control {
  display: flex;
  align-items: center;
  
} */

.preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.btn-select {
    display: flex;
    width: 480px;
}

#preview-control > div {
  margin: 8px 0;
}

/* .control input {
  margin-left: 24px;
  width: 400px;
  height: 32px;
  padding: 0 16px;
  border: #9CD3FE;
  border-radius: 8px;
} */

/* button {
  margin: 0 24px;
  height: 32px;
  width: 128px;
  border-radius: 8px;
  border: 0;
  background-color: #D3E8FC;
} */

/* v-btn:hover {
  cursor: pointer;
  background-color: #9CD3FE;
} */

/* select {
  height: 32px;
  border-radius: 8px;
  border: 0;
} */

#preview-wrapper {
  height: 270px;
  width: 480px;
  background-color: black;
  border-radius: 8px;
  margin-bottom: 20px;
}

#preview {
  border-radius: 8px;
}

</style>