<template>
    <div>
        <h1 class="text-center">Salle d'attente</h1>
    <div class="mt-6">
        <v-sheet 
            v-for="room in room_waitings"
            :key="room.id"
            class="mb-3"
        >
            <div class="d-flex justify-space-between">
                <div> 
                    <div class="d-flex">
                        <div class="avatar">
                        <v-avatar
                        size="100"
                        color="white"
                        >
                            <img :src="room.doctor.user.avatar_url" alt="avatar">
                        </v-avatar>
                    </div>
                    <div class="mx-6">
                    <div>
                        <router-link :to="{name: 'medecin.show', params: {id: room.doctor.id }}" style="text-decoration: none;">{{ room.doctor.user.name }} </router-link>
                    </div>
                    <div class="caption">{{ room.doctor.department.name }}</div>
                    <div class="caption">{{ room.doctor.address_cabinet }}</div>
                </div>
                </div>
                
            </div>

            <div class="btn-room">
                <div class="font-weight-bold white--text text-center mb-2">Position : <span>
                    <v-chip small color="white">{{ room.position }}</v-chip>
                </span></div>
                <div class="d-flex justify-center">
                    <v-tooltip bottom>
                        <template #activator="{on, attr}">
                            <v-btn icon color="primary" v-on="on" v-bind="attr" class="mr-2" @click="joinMeet(room.sessionId)" :disabled="room.position !=1">
                    <v-icon size="30" color="white">mdi-video-box</v-icon>
                   </v-btn>
                        </template>
                        <span>Lancer l'appel</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template #activator="{on, attr}">
                            <v-btn icon color="red" v-on="on" v-bind="attr" @click="cancelRoom(room)">
                    <v-icon size="30">mdi-video-off</v-icon>
                   </v-btn>
                        </template>
                        <span>Annuler la salle</span>
                    </v-tooltip>
                   
                </div>
            </div>
            </div>
            
        </v-sheet>
        <div class="text-center" v-if="room_waitings.length==0">
            <v-alert type="info" >
                <span>Vous n'êtes inscrit à aucune salle d'attente pour le moment.</span>
            </v-alert>
        </div>
        <dialog-cancel-room v-if="showForm" v-model="showForm" @confirmed="cancelRoomConfirm" />
    </div>
  </div>
  
</template>

<script>
import dialogCancelRoom from '../../components/Home/utilities/dialogCancelRoom.vue'
export default {
  components: { dialogCancelRoom },
    data() {
        return {
            showForm: false,
            room_selected: null,
            room_waitings: []
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init(){
            this.$http.get(`room_waitings/${this.$auth.user.id}`).then(({data}) => {
            this.room_waitings = data.data
        })
        },
        cancelRoom(item) {
            this.room_selected = item
            this.showForm = true
        },
        cancelRoomConfirm() {
            this.$http.delete(`room_waiting/${this.room_selected.room_waiting_id}`).then((response) => {
                this.$toast.success({
                    tilte: "Annuler Appel",
                    message: response.data.message
                })
                this.init()
            }).catch((error) => {
                this.$toast.error({
                    title: 'Error',
                    message: error.response.data.message
                })
            })
        },
        joinMeet(sessionId) {
            this.$router.push({name: 'join.meet', params: {sessionId: sessionId, name: this.$auth.user.name}});
        }
    }
}
</script>

<style scoped>
.btn-room {
    background-color: #025397;
    color: white;
    /*display: grid;
    align-items: center;
    grid-template:
        ". closing ." 25px
        ". waiting ." 25px
        ". button ." 1fr;*/
    padding: 12px;
    width: 190px;
  }

</style>