<template>
  <div class="container">
    <loading-page v-if="loading"></loading-page>
    <list-files :files="files" v-else />
    
  </div>
</template>

<script>
import listFiles from '../../components/consultation/listFiles.vue'
import LoadingPage from '../../components/Home/utilities/LoadingPage.vue'
export default {
  components: { listFiles, LoadingPage },
    data() {
        return {
            loading: true,
            files: []
        }
    },
    mounted(){
        this.getFiles()
    },
    methods: {
        getFiles(){
            this.$http.get('shared/files').then(({data}) => {
                this.files = data.data;
                this.loading = false;
            }).catch((error) => {
                if(error.response.data.abonnement) {
                    this.$toast.info({
                        title: 'Abonnement',
                        message: "Abonnez-vous pour pouvoir utiliser ces resources"
                    })
                    const timer = setInterval(() => {
                        this.$router.push('/abonnement')
                        clearInterval(timer)
                    }, 3000);
                } else {
                    this.$toast.error({
                    title: 'Fichiers partagés',
                    message: "Une erreur c'est produite lors de la récupération des fichiers partagés"
                })
                }
               
                this.loading = false;
            })
        }
    }
}
</script>

<style>

</style>